import React from "react";
import { TOBEACQUIRED } from "../app/GenEnums";
import { safeRead } from "../app/GenFunctions";
import { fxdProfile, fxdRead } from "../app/Storage";
import { TSetBoolean, TSetString } from "./afx/AType";
import { TGetValidation, TGetValidationResults } from "./afx/AType";

// Strings /////////////////////////////////////////////////////////////////////

const TX = {
  exists: "Already exists.",
  required: "This field is required.",
};

export var noValidation: TGetValidation = {
  required: false,
  condition: "none",
  message: { label: "" },
};

// Genral Function /////////////////////////////////////////////////////////////

/**
 * Validations:
 * none, name, duplicate, range, contact, and year
 */
export function Validate(props: {
  val: any;
  validation: TGetValidation;
  setStatus: TSetBoolean;
  setText: TSetString;
}) {
  if (props.val && props.validation && props.validation?.message) {
    var a = String(safeRead(props.val)).toUpperCase();
    var b = String(TOBEACQUIRED).toUpperCase();
    var DataToBeAcquired = a === b;
    if (DataToBeAcquired) {
      props.setStatus(false);
      props.setText("");
      return;
    }

    var result;

    if (props.validation.required && props.val.length === 0)
      result = { status: true, message: TX.required };

    if (props.validation.condition === "name") {
      result = CheckName({
        value: props.val,
        message: props.validation?.message,
      });
    } else if (props.validation.condition === "duplicate") {
      result = CheckName({
        value: props.val,
        message: props.validation?.message,
        checkIfItAlreadyExists: true,
      });
    }

    if (props.validation.condition === "range") {
      if (props.validation?.range) {
        result = CheckRange({
          value: props.val,
          message: props.validation?.message,
          range: props.validation?.range,
        });
      }
    }

    if (props.validation.condition === "contact") {
      result = CheckContact({
        value: props.val,
        message: props.validation?.message,
      });
    }

    if (props.validation.condition === "year") {
      result = CheckYear({
        value: props.val,
        message: props.validation?.message,
        range: props.validation?.range,
      });
    }

    if (result) {
      props.setStatus(result.status);
      props.setText(result.message);
    }
  }
}

// Individual Tests ////////////////////////////////////////////////////////////

type SetterFunction = React.Dispatch<
  React.SetStateAction<{ status: boolean; msg: string }>
>;

/** Formerly: resetval */
export const ResetValidation = (setter: SetterFunction) => {
  setter({ status: false, msg: "" });
};

/** Formerly: nameValidation
 */
export function CheckName(props: {
  value: any;
  message: { label: string; duplicate?: string };
  checkIfItAlreadyExists?: boolean;
}): TGetValidationResults {
  if (props.value !== null) {
    props.value = safeRead(props.value);
    var index = props.value.indexOf(" ");
    var length = props.value.length;
    var testIsEmpty = props.value.length === 0;
    var testIsOneWord = index === -1 || index === length - 1;
    var testIsDuplicate;

    if (props.checkIfItAlreadyExists) {
      testIsDuplicate =
        fxdRead(fxdProfile.profiles) !== null
          ? JSON.parse(fxdRead(fxdProfile.profiles)!).find((item: any) => {
              return item.sP1Name.toLowerCase() === props.value.toLowerCase();
            })
          : false;
    }

    if (String(props.value) !== TOBEACQUIRED) {
      if (!testIsEmpty) {
        if (testIsOneWord) {
          return { status: true, message: props.message.label };
        } else if (testIsDuplicate && props.checkIfItAlreadyExists) {
          return {
            status: true,
            message: props.message.duplicate ?? TX.exists,
          };
        }
      }
    }
    return { status: false, message: "" };
  }
  return null;
}

// export const completeNameVal = (setter: SetterFunction, iRes: any) => {};
// export const NameValNoDatabase = (setter: SetterFunction, iRes: any) => {};

/** Formerly: numberRangeValidation */
export function CheckRange(props: {
  value: any;
  message: { label: string };
  range: { min: number; max: number };
}): TGetValidationResults {
  if (props.value !== null) {
    props.value = safeRead(props.value);
    var testIsEmpty = props.value.length === 0;
    var numIRes = parseInt(props.value);
    var testIsNotNumber = /[^\d.]/.test(props.value);

    if (String(props.value) !== TOBEACQUIRED) {
      if (!testIsEmpty) {
        if (
          testIsNotNumber ||
          numIRes < props.range.min ||
          numIRes > props.range.max
        ) {
          return { status: true, message: props.message.label };
        }
      }
    }
    return { status: false, message: "" };
  }
  return null;
}

// export const rangeVal = (
//   setter: SetterFunction,
//   iRes: any,
//   customErrorMessage?: string,
//   lowValue?: number,
//   highValue?: number
// ) => {};

export function CheckContact(props: {
  value: any;
  message: { label: string };
}) {
  if (props.value !== null) {
    props.value = props.value.label ?? props.value;
    var testIsEmpty = props.value.length === 0;
    var testIsNotNumber = /[^0-9]./.test(props.value);

    if (String(props.value) !== "Data to be acquired") {
      if (!testIsEmpty) {
        if (
          testIsNotNumber ||
          props.value.length < 10 ||
          props.value.length > 11
        ) {
          return { status: true, message: props.message.label };
        } else if (props.value.length === 10) {
          if (props.value.substring(0, 2) !== "02") {
            return { status: true, message: props.message.label };
          }
        } else if (props.value.length === 11) {
          if (props.value.substring(0, 2) !== "09") {
            return { status: true, message: props.message.label };
          }
        }
      }
    }
    return { status: false, message: "" };
  }
  return null;
}

/** Formerly: yearValidation */
export function CheckYear(props: {
  value: any;
  message: { label: string };
  range: { min: number; max: number };
}): TGetValidationResults {
  if (props.value !== null) {
    var valYear = props.value.$y;

    if (valYear > props.range.max || valYear < props.range.min) {
      return { status: true, message: props.message.label };
    } else return { status: false, message: "" };
  }
  return null;
}

// export const yearVal = (
//   setter: SetterFunction,
//   iRes: any,
//   customErrorMessage?: string,
//   lowValue?: number,
//   highValue?: number
// ) => {
//   if (iRes !== null) {
//     //console.log(iRes);
//     var iResYear = iRes.$y;
//     var testIsEmpty = iResYear === "NaN";

//     //console.log(iResYear);
//     //var numIRes = parseInt(iResYear);

//     var testIsNotNumberMsg = String(customErrorMessage);

//     if (testIsEmpty || iRes.lenght === 0) {
//       setter({ status: false, msg: "" });
//       //setter({ status: true, msg: "here" });
//     } else if (iResYear > highValue! || iResYear < lowValue!) {
//       setter({ status: true, msg: testIsNotNumberMsg });
//     } else {
//       setter({ status: false, msg: "" });
//     }
//   }
// };
