import axios from "axios";
import { AppSettings } from "../../app/Settings";
import { ReadAllRowsButImgs } from "../../app/Server";

// Machines ////////////////////////////////////////////////////////////////////

export async function Machines(): Promise<any[]> {
  var machines: any[] = [];

  AppSettings.DATABASES.forEach(async (db) => {
    await axios
      .get(ReadAllRowsButImgs("data_" + db + "_machine"))
      .then((res) => {
        // machines.push(response.data);
        res.data.forEach((machine: any) => {
          machine.db = db;
          machines.push(machine);
        });
        // console.log(res.data);
      });
  });

  return machines;
}

// Implements //////////////////////////////////////////////////////////////////

export async function Implements(): Promise<any[]> {
  var implementss: any[] = [];

  AppSettings.DATABASES.forEach(async (db) => {
    await axios
      .get(ReadAllRowsButImgs("data_" + db + "_implement"))
      .then((res) => {
        // implements.push(response.data);
        res.data.forEach((implement: any) => {
          implement.db = db;
          implementss.push(implement);
        });
        // console.log(res.data);
      });
  });

  return implementss;
}
