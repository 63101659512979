// TODO Replace Raw CSS with TailwindCSS

import { ID } from "./ElementID";

/**
 * Use this component to display a map with a marker on it.
 * @returns {JSX.Element} The map with a marker.
 */
export const GeoLocationPane = (): JSX.Element => {
  return (
    <div
      className={
        "d-md-flex " +
        "justify-content-md-center " +
        "align-items-md-center " +
        "avz-border-radius"
      }
      style={{
        width: "100%",
        maxWidth: "100%",
        minHeight: "250px",
        maxHeight: "250px",
        position: "relative",
      }}
      id={ID.GeoLocationPaneMap}
    ></div>
  );
};
