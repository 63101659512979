import "leaflet/dist/leaflet.css";
import "../../assets/scss/Forms.css";
import strMac from "./MachinePage.json";
import strPrf from "../profile/ProfilePage.json";
import strImp from "../implement/ImplementPage.json";
import { fxdDelete, fxdGen, fxdUser } from "../../app/Storage";
import { fxdMachine, fxdRead, fxdWrite } from "../../app/Storage";
import { fxdMachineBackup, fxdProfile } from "../../app/Storage";
import { NavLink, redirect, useNavigate } from "react-router-dom";
import { LoaderFunctionArgs } from "react-router-dom";
import { DATA_STATUS, FORMS } from "../../app/GenEnums";
import { gotoLogin } from "../../app/Routes";
import { AvzRoute, requireLoadProperly } from "../../app/Routes";
import { useEffect, useState } from "react";
import { TGetString, TGetValidation } from "../../custom/afx/AType";
import { TSetAny, TSetString, TSetValidation } from "../../custom/afx/AType";
import { Card, HBar, VBar } from "../../custom/afx/ABox";
import { TitleBar } from "../encode/EncodePage";
import { ATextInput, AText, AButton } from "../../custom/afx/AFX";
import { APopup, AAlert, ATick, OverrideBackup } from "../../custom/afx/AFX";
import WebCam from "../../custom/Camera";
import { GeoLocationPane } from "../../app/GeoLocation";
import { Address, CreateNewAddress } from "../../custom/RAddress";
import { Barangay, Municipality, Province } from "../../custom/RAddress";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { BrowserQRCodeReader } from "@zxing/library";
import { createZIP, safeRead } from "../../app/GenFunctions";
import L from "leaflet";
import { AppSettings } from "../../app/Settings";
import iMachine from "../../assets/img/icon/map/machine.png";
import { machines, oOBrands, oOModels } from "./MachineBrandsAndModels";
import { tCane, tHaul, tHrvt } from "./MachineBrandsAndModels";
import { tPump, tRGun, tTrc2, tTrc4 } from "./MachineBrandsAndModels";
import { ID } from "../../app/ElementID";
import { noValidation } from "../../custom/Validations";
import SaveIcon from "@mui/icons-material/Save";
import { Nav } from "react-bootstrap";

// /////////////////////////////////////////////////////////////////////////////
// PRELOADER ///////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

const MCBKUP = fxdMachineBackup as Record<string, any>;
export async function MachinePageLoader({ request }: LoaderFunctionArgs) {
  if (fxdRead(fxdProfile.profiles) == null) {
    APopup({
      icon: "error",
      title: strMac.notif.noProfile.title,
      text: strMac.notif.noProfile.message,
      confirmButtonText: strMac.notif.noProfile.button,
    });
    return redirect(AvzRoute.profile);
  } else {
    fxdWrite(fxdGen.editing, FORMS.machine);
    return gotoLogin(request.url) ?? null;
  }
}

// /////////////////////////////////////////////////////////////////////////////
// THE MACHINE PAGE ////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export default function MachinePage() {
  // ///////////////////////////////////////////////////////////////////////////

  const GOTO = useNavigate();

  const restoreFromLocalStorage = () => {
    var restoreFromBackup: Record<string, any> = {};
    Object.keys(MCBKUP).forEach((key) => {
      restoreFromBackup[key] = fxdRead(MCBKUP[key])
        ? JSON.parse(fxdRead(MCBKUP[key]) || "")
        : "";
    });
    return restoreFromBackup;
  };

  const isAllEmpty = (backup: Record<string, any>) => {
    return Object.values(backup).every((x) => safeRead(x) === "");
  };

  const backup = restoreFromLocalStorage();

  const restoreMachineBackups = (from: Record<string, any>) => {
    for (let i = 0; i < QUESTIONS.length; i++) {
      var val = from["b" + QUESTIONS[i].backup.slice(3)];
      if (val && typeof QUESTIONS[i].setValue === "function") {
        QUESTIONS[i].setValue(val);
      }
    }
  };

  function deleteMachineBackups() {
    Object.values(MCBKUP).forEach((val) => fxdDelete(val));
    fxdDelete(fxdMachine.backup);
  }

  useEffect(() => {
    if (!isAllEmpty(backup)) {
      fxdWrite(fxdMachine.backup, JSON.stringify(backup));
    }

    var restored = JSON.parse(fxdRead(fxdMachine.backup) || "{}");

    if (!isAllEmpty(restored)) {
      APopup({
        title: strMac.notif.backup.title,
        text: strMac.notif.backup.message,
        confirmButtonText: "Recover",
        showDenyButton: true,
        denyButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          restoreMachineBackups(restored);
        } else if (result.isDenied) {
          deleteMachineBackups();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ///////////////////////////////////////////////////////////////////////////
  // STATES ////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  const [iM1ResName, setIM1ResName] = useState("");
  const [eM1ResName, setEM1ResName] = useState(noValidation);

  const [iM1QRCode, setIM1QRCode] = useState("");
  const [eM1QRCode, setEM1QRCode] = useState(noValidation);

  const [iM1FrontVw, setIM1FrontVw] = useState("");
  const [eM1FrontVw, setEM1FrontVw] = useState(noValidation);

  const [iM1SideVw, setIM1SideVw] = useState("");
  const [eM1SideVw, setEM1SideVw] = useState(noValidation);

  const [iM1SpecVw, setIM1SpecVw] = useState("");
  const [eM1SpecVw, setEM1SpecVw] = useState(noValidation);

  const [iM1Acc, setIM1Acc] = useState("");
  const [eM1Acc, setEM1Acc] = useState(noValidation);

  const [iM1Lat, setIM1Lat] = useState("");
  const [eM1Lat, setEM1Lat] = useState(noValidation);

  const [iM1Lng, setIM1Lng] = useState("");
  const [eM1Lng, setEM1Lng] = useState(noValidation);

  const [iM1Loc, setIM1Loc] = useState("");
  const [eM1Loc, setEM1Loc] = useState(noValidation);

  const [iM1Prov, setIM1Prov] = useState("");
  const [eM1Prov, setEM1Prov] = useState(noValidation);

  const [iM1Mun, setIM1Mun] = useState("");
  const [eM1Mun, setEM1Mun] = useState(noValidation);

  const [iM1Brgy, setIM1Brgy] = useState("");
  const [eM1Brgy, setEM1Brgy] = useState(noValidation);

  const [iM1Type, setIM1Type] = useState("");
  const [eM1Type, setEM1Type] = useState(noValidation);

  const [iM1Serial, setIM1Serial] = useState("");
  const [eM1Serial, setEM1Serial] = useState(noValidation);

  const [iM1CovHlng, setIM1CovHlng] = useState("");
  const [eM1CovHlng, setEM1CovHlng] = useState(noValidation);

  const [iM2Brand, setIM2Brand] = useState("");
  const [eM2Brand, setEM2Brand] = useState(noValidation);

  const [iM2Model, setIM2Model] = useState("");
  const [eM2Model, setEM2Model] = useState(noValidation);

  const [iM2RtdPwr, setIM2RtdPwr] = useState("");
  const [eM2RtdPwr, setEM2RtdPwr] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.power },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  const [iM2TtlSrvArea, setIM2TtlSrvArea] = useState("");
  const [eM2TtlSrvArea, setEM2TtlSrvArea] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.area },
    range: { min: 0, max: 99999 },
  } as TGetValidation);

  const [iM2AvgOpHrDy, setIM2AvgOpHrDy] = useState("");
  const [eM2AvgOpHrDy, setEM2AvgOpHrDy] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.value },
    range: { min: 0, max: 24 },
  } as TGetValidation);

  const [iM2AvgOpDySsn, setIM2AvgOpDySsn] = useState("");
  const [eM2AvgOpDySsn, setEM2AvgOpDySsn] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.value },
    range: { min: 0, max: 366 },
  } as TGetValidation);

  const [iM2LdCap, setIM2LdCap] = useState("");
  const [eM2LdCap, setEM2LdCap] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.capacity },
    range: { min: 0, max: 99999 },
  } as TGetValidation);

  const [iM2LdCapUnit, setIM2LdCapUnit] = useState("");
  const [eM2LdCapUnit, setEM2LdCapUnit] = useState(noValidation);

  const [iM2LdCapDay, setIM2LdCapDay] = useState("");
  const [eM2LdCapDay, setEM2LdCapDay] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.load },
    range: { min: 0, max: 9999 },
  } as TGetValidation);

  const [iM2TmCom1Ha, setIM2TmCom1Ha] = useState("");
  const [eM2TmCom1Ha, setEM2TmCom1Ha] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.time },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  const [iM2SgrcnYld, setIM2SgrcnYld] = useState("");
  const [eM2SgrcnYld, setEM2SgrcnYld] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.yield },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  const [iM3TpTbWlls, setIM3TpTbWlls] = useState("");
  const [eM3TpTbWlls, setEM3TpTbWlls] = useState(noValidation);

  const [iM3Brand, setIM3Brand] = useState("");
  const [eM3Brand, setEM3Brand] = useState(noValidation);

  const [iM3Model, setIM3Model] = useState("");
  const [eM3Model, setEM3Model] = useState(noValidation);

  const [iM3SzPmp, setIM3SzPmp] = useState("");
  const [eM3SzPmp, setEM3SzPmp] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.size },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  const [iM3SrcPwr, setIM3SrcPwr] = useState("");
  const [eM3SrcPwr, setEM3SrcPwr] = useState(noValidation);

  const [iM3SrcBrand, setIM3SrcBrand] = useState("");
  const [eM3SrcBrand, setEM3SrcBrand] = useState(noValidation);

  const [iM3SrcModel, setIM3SrcModel] = useState("");
  const [eM3SrcModel, setEM3SrcModel] = useState(noValidation);

  const [iM3RtdPwr, setIM3RtdPwr] = useState("");
  const [eM3RtdPwr, setEM3RtdPwr] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.power },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  const [iM3DisCap, setIM3DisCap] = useState("");
  const [eM3DisCap, setEM3DisCap] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.discharge },
    range: { min: 0, max: 9999 },
  } as TGetValidation);

  const [iM3DisCapUnit, setIM3DisCapUnit] = useState("");
  const [eM3DisCapUnit, setEM3DisCapUnit] = useState(noValidation);

  const [iM3TtlSrvArea, setIM3TtlSrvArea] = useState("");
  const [eM3TtlSrvArea, setEM3TtlSrvArea] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.area },
    range: { min: 0, max: 99999 },
  } as TGetValidation);

  const [iM3AvgOpHrDy, setIM3AvgOpHrDy] = useState("");
  const [eM3AvgOpHrDy, setEM3AvgOpHrDy] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.value },
    range: { min: 0, max: 12 },
  } as TGetValidation);

  const [iM3AvgOpDySsn, setIM3AvgOpDySsn] = useState("");
  const [eM3AvgOpDySsn, setEM3AvgOpDySsn] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.value },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  const [iM4Usage, setIM4Usage] = useState("");
  const [eM4Usage, setEM4Usage] = useState(noValidation);

  const [iM4Mode, setIM4Mode] = useState("");
  const [eM4Mode, setEM4Mode] = useState(noValidation);

  const [iM4Agency, setIM4Agency] = useState("");
  const [eM4Agency, setEM4Agency] = useState(noValidation);

  const [iM4YrAcqrd, setIM4YrAcqrd] = useState("");
  const [eM4YrAcqrd, setEM4YrAcqrd] = useState({
    required: false,
    condition: "year",
    message: { label: strPrf.validation.year },
    range: { min: 1949, max: 2024 },
  } as TGetValidation);

  const [iM4ConAcqrd, setIM4ConAcqrd] = useState("");
  const [eM4ConAcqrd, setEM4ConAcqrd] = useState(noValidation);

  const [iM4PrsntCon, setIM4PrsntCon] = useState("");
  const [eM4PrsntCon, setEM4PrsntCon] = useState(noValidation);

  const [iM4NoBrkdwn, setIM4NoBrkdwn] = useState("");
  const [eM4NoBrkdwn, setEM4NoBrkdwn] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.breakdown },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  const [iM4CmnBrkdwn, setIM4CmnBrkdwn] = useState("");
  const [eM4CmnBrkdwn, setEM4CmnBrkdwn] = useState(noValidation);

  const [iM4YrUnused, setIM4YrUnused] = useState("");
  const [eM4YrUnused, setEM4YrUnused] = useState({
    required: false,
    condition: "year",
    message: { label: strPrf.validation.year },
    range: { min: 1949, max: 2024 },
  } as TGetValidation);

  const [iM4RsnUnused, setIM4RsnUnused] = useState("");
  const [eM4RsnUnused, setEM4RsnUnused] = useState(noValidation);

  const [iM4UuOthers, setIM4UuOthers] = useState("");
  const [eM4UuOthers, setEM4UuOthers] = useState(noValidation);

  const [iM4YrUnserv, setIM4YrUnserv] = useState("");
  const [eM4YrUnserv, setEM4YrUnserv] = useState({
    required: false,
    condition: "year",
    message: { label: strPrf.validation.year },
    range: { min: 1949, max: 2024 },
  } as TGetValidation);

  const [iM4RsnUnserv, setIM4RsnUnserv] = useState("");
  const [eM4RsnUnserv, setEM4RsnUnserv] = useState(noValidation);

  const [iM4UsOthers, setIM4UsOthers] = useState("");
  const [eM4UsOthers, setEM4UsOthers] = useState(noValidation);

  const [iM5SameOwn, setIM5SameOwn] = useState("");
  const [eM5SameOwn, setEM5SameOwn] = useState(noValidation);

  const [iM5BusCont1, setIM5BusCont1] = useState("");
  const [eM5BusCont1, setEM5BusCont1] = useState({
    required: false,
    condition: "contact",
    message: { label: strPrf.validation.contact },
  } as TGetValidation);

  const [iM5BusCont2, setIM5BusCont2] = useState("");
  const [eM5BusCont2, setEM5BusCont2] = useState({
    required: false,
    condition: "contact",
    message: { label: strPrf.validation.contact },
  } as TGetValidation);

  const [iM5Consent, setIM5Consent] = useState("");
  const [eM5Consent, setEM5Consent] = useState(noValidation);

  const [iM5Ope1, setIM5Ope1] = useState("");
  const [iM5Ope4, setIM5Ope4] = useState("");
  const [eM5Ope1, setEM5Ope1] = useState(noValidation);

  const [iM5Ope1Rate, setIM5Ope1Rate] = useState("");
  const [iM5Ope4Rate, setIM5Ope4Rate] = useState("");
  const [eM5Ope1Rate, setEM5Ope1Rate] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.rate },
    range: { min: 0, max: 9999999 },
  } as TGetValidation);

  const [iM5Ope1Unit, setIM5Ope1Unit] = useState("");
  const [iM5Ope4Unit, setIM5Ope4Unit] = useState("");
  const [eM5Ope1Unit, setEM5Ope1Unit] = useState(noValidation);

  const [iM5Ope1Fuel, setIM5Ope1Fuel] = useState("");
  const [iM5Ope4Fuel, setIM5Ope4Fuel] = useState("");
  const [eM5Ope1Fuel, setEM5Ope1Fuel] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.fuel },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  const [iM5Ope2, setIM5Ope2] = useState("");
  const [iM5Ope5, setIM5Ope5] = useState("");
  const [eM5Ope2, setEM5Ope2] = useState(noValidation);

  const [iM5Ope2Rate, setIM5Ope2Rate] = useState("");
  const [iM5Ope5Rate, setIM5Ope5Rate] = useState("");
  const [eM5Ope2Rate, setEM5Ope2Rate] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.rate },
    range: { min: 0, max: 9999999 },
  } as TGetValidation);

  const [iM5Ope2Unit, setIM5Ope2Unit] = useState("");
  const [iM5Ope5Unit, setIM5Ope5Unit] = useState("");
  const [eM5Ope2Unit, setEM5Ope2Unit] = useState(noValidation);

  const [iM5Ope2Fuel, setIM5Ope2Fuel] = useState("");
  const [iM5Ope5Fuel, setIM5Ope5Fuel] = useState("");
  const [eM5Ope2Fuel, setEM5Ope2Fuel] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.fuel },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  const [iM5Ope3, setIM5Ope3] = useState("");
  const [iM5Ope6, setIM5Ope6] = useState("");
  const [eM5Ope3, setEM5Ope3] = useState(noValidation);

  const [iM5Ope3Rate, setIM5Ope3Rate] = useState("");
  const [iM5Ope6Rate, setIM5Ope6Rate] = useState("");
  const [eM5Ope3Rate, setEM5Ope3Rate] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.rate },
    range: { min: 0, max: 9999999 },
  } as TGetValidation);

  const [iM5Ope3Unit, setIM5Ope3Unit] = useState("");
  const [iM5Ope6Unit, setIM5Ope6Unit] = useState("");
  const [eM5Ope3Unit, setEM5Ope3Unit] = useState(noValidation);

  const [iM5Ope3Fuel, setIM5Ope3Fuel] = useState("");
  const [iM5Ope6Fuel, setIM5Ope6Fuel] = useState("");
  const [eM5Ope3Fuel, setEM5Ope3Fuel] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.fuel },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  const [iM5SrvcLoc1P, setIM5SrvcLoc1P] = useState("");
  const [eM5SrvcLoc1P, setEM5SrvcLoc1P] = useState(noValidation);

  const [iM5SrvcLoc1M, setIM5SrvcLoc1M] = useState("");
  const [eM5SrvcLoc1M, setEM5SrvcLoc1M] = useState(noValidation);

  const [iM5SrvcLoc2P, setIM5SrvcLoc2P] = useState("");
  const [eM5SrvcLoc2P, setEM5SrvcLoc2P] = useState(noValidation);

  const [iM5SrvcLoc2M, setIM5SrvcLoc2M] = useState("");
  const [eM5SrvcLoc2M, setEM5SrvcLoc2M] = useState(noValidation);

  const [iM5SrvcLoc3P, setIM5SrvcLoc3P] = useState("");
  const [eM5SrvcLoc3P, setEM5SrvcLoc3P] = useState(noValidation);

  const [iM5SrvcLoc3M, setIM5SrvcLoc3M] = useState("");
  const [eM5SrvcLoc3M, setEM5SrvcLoc3M] = useState(noValidation);

  const [iM6Total, setIM6Total] = useState("");
  const [eM6Total, setEM6Total] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.operator },
    range: { min: 0, max: 10 },
  } as TGetValidation);

  const [iM6L2Formal, setIM6L2Formal] = useState("");
  const [eM6L2Formal, setEM6L2Formal] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.operator },
    range: { min: 0, max: 10 },
  } as TGetValidation);

  const [iM6L2NtFrml, setIM6L2NtFrml] = useState("");
  const [eM6L2NtFrml, setEM6L2NtFrml] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.operator },
    range: { min: 0, max: 10 },
  } as TGetValidation);

  const [iM6G2Formal, setIM6G2Formal] = useState("");
  const [eM6G2Formal, setEM6G2Formal] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.operator },
    range: { min: 0, max: 10 },
  } as TGetValidation);

  const [iM6G2NtFrml, setIM6G2NtFrml] = useState("");
  const [eM6G2NtFrml, setEM6G2NtFrml] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.operator },
    range: { min: 0, max: 10 },
  } as TGetValidation);

  const [iM7AirFltr, setIM7AirFltr] = useState("");
  const [eM7AirFltr, setEM7AirFltr] = useState(noValidation);

  const [iM7AirFltrUnit, setIM7AirFltrUnit] = useState("");
  const [eM7AirFltrUnit, setEM7AirFltrUnit] = useState(noValidation);

  const [iM7EngOil, setIM7EngOil] = useState("");
  const [eM7EngOil, setEM7EngOil] = useState(noValidation);

  const [iM7EngOilUnit, setIM7EngOilUnit] = useState("");
  const [eM7EngOilUnit, setEM7EngOilUnit] = useState(noValidation);

  const [iM7EngOilFltr, setIM7EngOilFltr] = useState("");
  const [eM7EngOilFltr, setEM7EngOilFltr] = useState(noValidation);

  const [iM7EngOilFltrUnit, setIM7EngOilFltrUnit] = useState("");
  const [eM7EngOilFltrUnit, setEM7EngOilFltrUnit] = useState(noValidation);

  const [iM7HydOil, setIM7HydOil] = useState("");
  const [eM7HydOil, setEM7HydOil] = useState(noValidation);

  const [iM7HydOilUnit, setIM7HydOilUnit] = useState("");
  const [eM7HydOilUnit, setEM7HydOilUnit] = useState(noValidation);

  const [iM7HydOilFltr, setIM7HydOilFltr] = useState("");
  const [eM7HydOilFltr, setEM7HydOilFltr] = useState(noValidation);

  const [iM7HydOilFltrUnit, setIM7HydOilFltrUnit] = useState("");
  const [eM7HydOilFltrUnit, setEM7HydOilFltrUnit] = useState(noValidation);

  const [iM7Tire, setIM7Tire] = useState("");
  const [eM7Tire, setEM7Tire] = useState(noValidation);

  const [iM7TireUnit, setIM7TireUnit] = useState("");
  const [eM7TireUnit, setEM7TireUnit] = useState(noValidation);

  const [iM7SrvcPrvdr, setIM7SrvcPrvdr] = useState("");
  const [eM7SrvcPrvdr, setEM7SrvcPrvdr] = useState(noValidation);

  const [iM7Prov, setIM7Prov] = useState("");
  const [eM7Prov, setEM7Prov] = useState(noValidation);

  const [iM7Mun, setIM7Mun] = useState("");
  const [eM7Mun, setEM7Mun] = useState(noValidation);

  const [iM7Brgy, setIM7Brgy] = useState("");
  const [eM7Brgy, setEM7Brgy] = useState(noValidation);

  const [iM7TimeRspnd, setIM7TimeRspnd] = useState("");
  const [eM7TimeRspnd, setEM7TimeRspnd] = useState(noValidation);

  const [iM7PrtsAvail, setIM7PrtsAvail] = useState("");
  const [eM7PrtsAvail, setEM7PrtsAvail] = useState(noValidation);

  const [iM8OthCrp, setIM8OthCrp] = useState("");
  const [eM8OthCrp, setEM8OthCrp] = useState(noValidation);

  const [iM8Rice, setIM8Rice] = useState("");
  const [eM8Rice, setEM8Rice] = useState(noValidation);

  const [iM8RiceHa, setIM8RiceHa] = useState("");
  const [eM8RiceHa, setEM8RiceHa] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.area },
    range: { min: 0, max: 9999 },
  } as TGetValidation);

  const [iM8Corn, setIM8Corn] = useState("");
  const [eM8Corn, setEM8Corn] = useState(noValidation);

  const [iM8CornHa, setIM8CornHa] = useState("");
  const [eM8CornHa, setEM8CornHa] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.area },
    range: { min: 0, max: 9999 },
  } as TGetValidation);

  const [iM8Othr1, setIM8Othr1] = useState("");
  const [iM8Othr2, setIM8Othr2] = useState("");
  const [iM8Othr3, setIM8Othr3] = useState("");
  const [eM8Othr1, setEM8Othr1] = useState(noValidation);

  const [iM8Othr1Ha, setIM8Othr1Ha] = useState("");
  const [iM8Othr2Ha, setIM8Othr2Ha] = useState("");
  const [iM8Othr3Ha, setIM8Othr3Ha] = useState("");
  const [eM8Othr1Ha, setEM8Othr1Ha] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.area },
    range: { min: 0, max: 9999 },
  } as TGetValidation);

  const [iM9Notes, setIM9Notes] = useState("");
  const [eM9Notes, setEM9Notes] = useState(noValidation);

  // ///////////////////////////////////////////////////////////////////////////
  // QUESTIONS /////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  type TQuestion = {
    value: TGetString | undefined;
    setValue: TSetString | TSetAny;
    errorMessage: TGetValidation;
    setErrorMessage: TSetValidation;
    backup: TGetString;
    label: TGetString;
  };

  // TODO Clean json
  const QUESTIONS: TQuestion[] = [
    {
      value: iM1ResName,
      setValue: setIM1ResName,
      errorMessage: eM1ResName,
      setErrorMessage: setEM1ResName,
      backup: MCBKUP.bM1ResName,
      label: strMac.bscInfo.resName.label,
    },
    {
      value: iM1QRCode,
      setValue: setIM1QRCode,
      errorMessage: eM1QRCode,
      setErrorMessage: setEM1QRCode,
      backup: MCBKUP.bM1QRCode,
      label: strMac.bscInfo.qrcode.label,
    },
    {
      value: iM1FrontVw,
      setValue: setIM1FrontVw,
      errorMessage: eM1FrontVw,
      setErrorMessage: setEM1FrontVw,
      backup: MCBKUP.bM1FrontVw,
      label: strMac.bscInfo.front.label,
    },
    {
      value: iM1SideVw,
      setValue: setIM1SideVw,
      errorMessage: eM1SideVw,
      setErrorMessage: setEM1SideVw,
      backup: MCBKUP.bM1SideVw,
      label: strMac.bscInfo.side.label,
    },
    {
      value: iM1SpecVw,
      setValue: setIM1SpecVw,
      errorMessage: eM1SpecVw,
      setErrorMessage: setEM1SpecVw,
      backup: MCBKUP.bM1SpecVw,
      label: strMac.bscInfo.plate.label,
    },
    {
      value: iM1Acc,
      setValue: setIM1Acc,
      errorMessage: eM1Acc,
      setErrorMessage: setEM1Acc,
      backup: MCBKUP.bM1Acc,
      label: strMac.bscInfo.accuracy.label,
    },
    {
      value: iM1Lat,
      setValue: setIM1Lat,
      errorMessage: eM1Lat,
      setErrorMessage: setEM1Lat,
      backup: MCBKUP.bM1Lat,
      label: strMac.bscInfo.latitude.label,
    },
    {
      value: iM1Lng,
      setValue: setIM1Lng,
      errorMessage: eM1Lng,
      setErrorMessage: setEM1Lng,
      backup: MCBKUP.bM1Lng,
      label: strMac.bscInfo.longitude.label,
    },
    {
      value: iM1Loc,
      setValue: setIM1Loc,
      errorMessage: eM1Loc,
      setErrorMessage: setEM1Loc,
      backup: MCBKUP.bM1Loc,
      label: strMac.bscInfo.location.label,
    },
    {
      value: iM1Prov,
      setValue: setIM1Prov,
      errorMessage: eM1Prov,
      setErrorMessage: setEM1Prov,
      backup: MCBKUP.bM1Prov,
      label: strMac.bscInfo.province.label,
    },
    {
      value: iM1Mun,
      setValue: setIM1Mun,
      errorMessage: eM1Mun,
      setErrorMessage: setEM1Mun,
      backup: MCBKUP.bM1Mun,
      label: strMac.bscInfo.municipality.label,
    },
    {
      value: iM1Brgy,
      setValue: setIM1Brgy,
      errorMessage: eM1Brgy,
      setErrorMessage: setEM1Brgy,
      backup: MCBKUP.bM1Brgy,
      label: strMac.bscInfo.barangay.label,
    },
    {
      value: iM1Type,
      setValue: setIM1Type,
      errorMessage: eM1Type,
      setErrorMessage: setEM1Type,
      backup: MCBKUP.bM1Type,
      label: strMac.macInfo.type.label,
    },
    {
      value: iM1Serial,
      setValue: setIM1Serial,
      errorMessage: eM1Serial,
      setErrorMessage: setEM1Serial,
      backup: MCBKUP.bM1Serial,
      label: strMac.macInfo.serial.label,
    },
    {
      value: iM1CovHlng,
      setValue: setIM1CovHlng,
      errorMessage: eM1CovHlng,
      setErrorMessage: setEM1CovHlng,
      backup: MCBKUP.bM1CovHlng,
      label: strMac.macInfo.truck.label,
    },
    {
      value: iM2Brand,
      setValue: setIM2Brand,
      errorMessage: eM2Brand,
      setErrorMessage: setEM2Brand,
      backup: MCBKUP.bM2Brand,
      label: strMac.macInfo.brand.label,
    },
    {
      value: iM2Model,
      setValue: setIM2Model,
      errorMessage: eM2Model,
      setErrorMessage: setEM2Model,
      backup: MCBKUP.bM2Model,
      label: strMac.macInfo.model.label,
    },
    {
      value: iM2RtdPwr,
      setValue: setIM2RtdPwr,
      errorMessage: eM2RtdPwr,
      setErrorMessage: setEM2RtdPwr,
      backup: MCBKUP.bM2RtdPwr,
      label: strMac.macInfo.rPower.label,
    },
    {
      value: iM2TtlSrvArea,
      setValue: setIM2TtlSrvArea,
      errorMessage: eM2TtlSrvArea,
      setErrorMessage: setEM2TtlSrvArea,
      backup: MCBKUP.bM2TtlSrvArea,
      label: strMac.macInfo.sArea.label,
    },
    {
      value: iM2AvgOpHrDy,
      setValue: setIM2AvgOpHrDy,
      errorMessage: eM2AvgOpHrDy,
      setErrorMessage: setEM2AvgOpHrDy,
      backup: MCBKUP.bM2AvgOpHrDy,
      label: strMac.macInfo.oDay.label,
    },
    {
      value: iM2AvgOpDySsn,
      setValue: setIM2AvgOpDySsn,
      errorMessage: eM2AvgOpDySsn,
      setErrorMessage: setEM2AvgOpDySsn,
      backup: MCBKUP.bM2AvgOpDySsn,
      label: strMac.macInfo.oSeason.label,
    },
    {
      value: iM2LdCap,
      setValue: setIM2LdCap,
      errorMessage: eM2LdCap,
      setErrorMessage: setEM2LdCap,
      backup: MCBKUP.bM2LdCap,
      label: strMac.cane.lCap.val.label,
    },
    {
      value: iM2LdCapUnit,
      setValue: setIM2LdCapUnit,
      errorMessage: eM2LdCapUnit,
      setErrorMessage: setEM2LdCapUnit,
      backup: MCBKUP.bM2LdCapUnit,
      label: strMac.cane.lCap.unit.label,
    },
    {
      value: iM2LdCapDay,
      setValue: setIM2LdCapDay,
      errorMessage: eM2LdCapDay,
      setErrorMessage: setEM2LdCapDay,
      backup: MCBKUP.bM2LdCapDay,
      label: strMac.cane.lCap.load.label,
    },
    {
      value: iM2TmCom1Ha,
      setValue: setIM2TmCom1Ha,
      errorMessage: eM2TmCom1Ha,
      setErrorMessage: setEM2TmCom1Ha,
      backup: MCBKUP.bM2TmCom1Ha,
      label: strMac.harvester.time.label,
    },
    {
      value: iM2SgrcnYld,
      setValue: setIM2SgrcnYld,
      errorMessage: eM2SgrcnYld,
      setErrorMessage: setEM2SgrcnYld,
      backup: MCBKUP.bM2SgrcnYld,
      label: strMac.harvester.yield.label,
    },
    {
      value: iM3TpTbWlls,
      setValue: setIM3TpTbWlls,
      errorMessage: eM3TpTbWlls,
      setErrorMessage: setEM3TpTbWlls,
      backup: MCBKUP.bM3TpTbWlls,
      label: strMac.wPump.type.label,
    },
    {
      value: iM3Brand,
      setValue: setIM3Brand,
      errorMessage: eM3Brand,
      setErrorMessage: setEM3Brand,
      backup: MCBKUP.bM3Brand,
      label: strMac.wPump.brand.label,
    },
    {
      value: iM3Model,
      setValue: setIM3Model,
      errorMessage: eM3Model,
      setErrorMessage: setEM3Model,
      backup: MCBKUP.bM3Model,
      label: strMac.wPump.model.label,
    },
    {
      value: iM3SzPmp,
      setValue: setIM3SzPmp,
      errorMessage: eM3SzPmp,
      setErrorMessage: setEM3SzPmp,
      backup: MCBKUP.bM3SzPmp,
      label: strMac.wPump.size.label,
    },
    {
      value: iM3SrcPwr,
      setValue: setIM3SrcPwr,
      errorMessage: eM3SrcPwr,
      setErrorMessage: setEM3SrcPwr,
      backup: MCBKUP.bM3SrcPwr,
      label: strMac.wPump.source.label,
    },
    {
      value: iM3SrcBrand,
      setValue: setIM3SrcBrand,
      errorMessage: eM3SrcBrand,
      setErrorMessage: setEM3SrcBrand,
      backup: MCBKUP.bM3SrcBrand,
      label: strMac.wPump.pBrand.label,
    },
    {
      value: iM3SrcModel,
      setValue: setIM3SrcModel,
      errorMessage: eM3SrcModel,
      setErrorMessage: setEM3SrcModel,
      backup: MCBKUP.bM3SrcModel,
      label: strMac.wPump.pModel.label,
    },
    {
      value: iM3RtdPwr,
      setValue: setIM3RtdPwr,
      errorMessage: eM3RtdPwr,
      setErrorMessage: setEM3RtdPwr,
      backup: MCBKUP.bM3RtdPwr,
      label: strMac.wPump.pRPower.label,
    },
    {
      value: iM3DisCap,
      setValue: setIM3DisCap,
      errorMessage: eM3DisCap,
      setErrorMessage: setEM3DisCap,
      backup: MCBKUP.bM3DisCap,
      label: strMac.wPump.discharge.label,
    },
    {
      value: iM3DisCapUnit,
      setValue: setIM3DisCapUnit,
      errorMessage: eM3DisCapUnit,
      setErrorMessage: setEM3DisCapUnit,
      backup: MCBKUP.bM3DisCapUnit,
      label: strMac.wPump.discharge.unitval.label,
    },
    {
      value: iM3TtlSrvArea,
      setValue: setIM3TtlSrvArea,
      errorMessage: eM3TtlSrvArea,
      setErrorMessage: setEM3TtlSrvArea,
      backup: MCBKUP.bM3TtlSrvArea,
      label: strMac.wPump.sArea.label,
    },
    {
      value: iM3AvgOpHrDy,
      setValue: setIM3AvgOpHrDy,
      errorMessage: eM3AvgOpHrDy,
      setErrorMessage: setEM3AvgOpHrDy,
      backup: MCBKUP.bM3AvgOpHrDy,
      label: strMac.wPump.oDay.label,
    },
    {
      value: iM3AvgOpDySsn,
      setValue: setIM3AvgOpDySsn,
      errorMessage: eM3AvgOpDySsn,
      setErrorMessage: setEM3AvgOpDySsn,
      backup: MCBKUP.bM3AvgOpDySsn,
      label: strMac.wPump.oSeason.label,
    },
    {
      value: iM4Usage,
      setValue: setIM4Usage,
      errorMessage: eM4Usage,
      setErrorMessage: setEM4Usage,
      backup: MCBKUP.bM4Usage,
      label: strMac.usage.usage.label,
    },
    {
      value: iM4Mode,
      setValue: setIM4Mode,
      errorMessage: eM4Mode,
      setErrorMessage: setEM4Mode,
      backup: MCBKUP.bM4Mode,
      label: strMac.usage.mode.label,
    },
    {
      value: iM4Agency,
      setValue: setIM4Agency,
      errorMessage: eM4Agency,
      setErrorMessage: setEM4Agency,
      backup: MCBKUP.bM4Agency,
      label: strMac.usage.agency.label,
    },
    {
      value: iM4YrAcqrd,
      setValue: setIM4YrAcqrd,
      errorMessage: eM4YrAcqrd,
      setErrorMessage: setEM4YrAcqrd,
      backup: MCBKUP.bM4YrAcqrd,
      label: strMac.usage.acqYear.label,
    },
    {
      value: iM4ConAcqrd,
      setValue: setIM4ConAcqrd,
      errorMessage: eM4ConAcqrd,
      setErrorMessage: setEM4ConAcqrd,
      backup: MCBKUP.bM4ConAcqrd,
      label: strMac.usage.cond.label,
    },
    {
      value: iM4PrsntCon,
      setValue: setIM4PrsntCon,
      errorMessage: eM4PrsntCon,
      setErrorMessage: setEM4PrsntCon,
      backup: MCBKUP.bM4PrsntCon,
      label: strMac.usage.pCond.label,
    },
    {
      value: iM4YrUnused,
      setValue: setIM4YrUnused,
      errorMessage: eM4YrUnused,
      setErrorMessage: setEM4YrUnused,
      backup: MCBKUP.bM4YrUnused,
      label: strMac.unused.uuYear.label,
    },
    {
      value: iM4RsnUnused,
      setValue: setIM4RsnUnused,
      errorMessage: eM4RsnUnused,
      setErrorMessage: setEM4RsnUnused,
      backup: MCBKUP.bM4RsnUnused,
      label: strMac.unused.subtitle,
    },
    {
      value: iM4UuOthers,
      setValue: setIM4UuOthers,
      errorMessage: eM4UuOthers,
      setErrorMessage: setEM4UuOthers,
      backup: MCBKUP.bM4UuOthers,
      label: strMac.unused.subtitle + " " + strMac.unused.uuOthers.label,
    },
    {
      value: iM4YrUnserv,
      setValue: setIM4YrUnserv,
      errorMessage: eM4YrUnserv,
      setErrorMessage: setEM4YrUnserv,
      backup: MCBKUP.bM4YrUnserv,
      label: strMac.unserv.usYear.label,
    },
    {
      value: iM4RsnUnserv,
      setValue: setIM4RsnUnserv,
      errorMessage: eM4RsnUnserv,
      setErrorMessage: setEM4RsnUnserv,
      backup: MCBKUP.bM4RsnUnserv,
      label: strMac.unserv.subtitle,
    },
    {
      value: iM4UsOthers,
      setValue: setIM4UsOthers,
      errorMessage: eM4UsOthers,
      setErrorMessage: setEM4UsOthers,
      backup: MCBKUP.bM4UsOthers,
      label: strMac.unserv.subtitle + " " + strMac.unserv.usOthers.label,
    },
    {
      value: iM4NoBrkdwn,
      setValue: setIM4NoBrkdwn,
      errorMessage: eM4NoBrkdwn,
      setErrorMessage: setEM4NoBrkdwn,
      backup: MCBKUP.bM4NoBrkdwn,
      label: strMac.perf.breakdowns.label,
    },
    {
      value: iM4CmnBrkdwn,
      setValue: setIM4CmnBrkdwn,
      errorMessage: eM4CmnBrkdwn,
      setErrorMessage: setEM4CmnBrkdwn,
      backup: MCBKUP.bM4CmnBrkdwn,
      label: strMac.perf.common.label,
    },
    {
      value: iM5SameOwn,
      setValue: setIM5SameOwn,
      errorMessage: eM5SameOwn,
      setErrorMessage: setEM5SameOwn,
      backup: MCBKUP.bM5SameOwn,
      label: strMac.usage.contact.same,
    },
    {
      value: iM5BusCont1,
      setValue: setIM5BusCont1,
      errorMessage: eM5BusCont1,
      setErrorMessage: setEM5BusCont1,
      backup: MCBKUP.bM5BusCont1,
      label: strMac.usage.contact1.label,
    },
    {
      value: iM5BusCont2,
      setValue: setIM5BusCont2,
      errorMessage: eM5BusCont2,
      setErrorMessage: setEM5BusCont2,
      backup: MCBKUP.bM5BusCont2,
      label: strMac.usage.contact2.label,
    },
    {
      value: iM5Consent,
      setValue: setIM5Consent,
      errorMessage: eM5Consent,
      setErrorMessage: setEM5Consent,
      backup: MCBKUP.bM5Consent,
      label: "",
    },
    {
      value: iM5Ope1,
      setValue: setIM5Ope1,
      errorMessage: eM5Ope1,
      setErrorMessage: setEM5Ope1,
      backup: MCBKUP.bM5Ope1,
      label: strMac.rent.ope.first,
    },
    {
      value: iM5Ope1Rate,
      setValue: setIM5Ope1Rate,
      errorMessage: eM5Ope1Rate,
      setErrorMessage: setEM5Ope1Rate,
      backup: MCBKUP.bM5Ope1Rate,
      label: strMac.rent.ope.first + " " + strMac.rent.fORate.label,
    },
    {
      value: iM5Ope1Unit,
      setValue: setIM5Ope1Unit,
      errorMessage: eM5Ope1Unit,
      setErrorMessage: setEM5Ope1Unit,
      backup: MCBKUP.bM5Ope1Unit,
      label:
        strMac.rent.ope.first +
        " " +
        strMac.rent.fORate.label +
        " " +
        strMac.rent.fORate.unitval.label,
    },
    {
      value: iM5Ope1Fuel,
      setValue: setIM5Ope1Fuel,
      errorMessage: eM5Ope1Fuel,
      setErrorMessage: setEM5Ope1Fuel,
      backup: MCBKUP.bM5Ope1Fuel,
      label: strMac.rent.ope.first + " " + strMac.rent.fOFuel.label,
    },
    {
      value: iM5Ope2,
      setValue: setIM5Ope2,
      errorMessage: eM5Ope2,
      setErrorMessage: setEM5Ope2,
      backup: MCBKUP.bM5Ope2,
      label: strMac.rent.ope.second,
    },
    {
      value: iM5Ope2Rate,
      setValue: setIM5Ope2Rate,
      errorMessage: eM5Ope2Rate,
      setErrorMessage: setEM5Ope2Rate,
      backup: MCBKUP.bM5Ope2Rate,
      label: strMac.rent.ope.second + " " + strMac.rent.fORate.label,
    },
    {
      value: iM5Ope2Unit,
      setValue: setIM5Ope2Unit,
      errorMessage: eM5Ope2Unit,
      setErrorMessage: setEM5Ope2Unit,
      backup: MCBKUP.bM5Ope2Unit,
      label:
        strMac.rent.ope.second +
        " " +
        strMac.rent.fORate.label +
        " " +
        strMac.rent.fORate.unitval.label,
    },
    {
      value: iM5Ope2Fuel,
      setValue: setIM5Ope2Fuel,
      errorMessage: eM5Ope2Fuel,
      setErrorMessage: setEM5Ope2Fuel,
      backup: MCBKUP.bM5Ope2Fuel,
      label: strMac.rent.ope.second + " " + strMac.rent.fOFuel.label,
    },
    {
      value: iM5Ope3,
      setValue: setIM5Ope3,
      errorMessage: eM5Ope3,
      setErrorMessage: setEM5Ope3,
      backup: MCBKUP.bM5Ope3,
      label: strMac.rent.ope.third,
    },
    {
      value: iM5Ope3Rate,
      setValue: setIM5Ope3Rate,
      errorMessage: eM5Ope3Rate,
      setErrorMessage: setEM5Ope3Rate,
      backup: MCBKUP.bM5Ope3Rate,
      label: strMac.rent.ope.third + " " + strMac.rent.fORate.label,
    },
    {
      value: iM5Ope3Unit,
      setValue: setIM5Ope3Unit,
      errorMessage: eM5Ope3Unit,
      setErrorMessage: setEM5Ope3Unit,
      backup: MCBKUP.bM5Ope3Unit,
      label:
        strMac.rent.ope.third +
        " " +
        strMac.rent.fORate.label +
        " " +
        strMac.rent.fORate.unitval.label,
    },
    {
      value: iM5Ope3Fuel,
      setValue: setIM5Ope3Fuel,
      errorMessage: eM5Ope3Fuel,
      setErrorMessage: setEM5Ope3Fuel,
      backup: MCBKUP.bM5Ope3Fuel,
      label: strMac.rent.ope.third + " " + strMac.rent.fOFuel.label,
    },
    {
      value: iM5Ope4,
      setValue: setIM5Ope4,
      errorMessage: eM5Ope1,
      setErrorMessage: setEM5Ope1,
      backup: MCBKUP.bM5Ope4,
      label: strMac.rent.ope.first,
    },
    {
      value: iM5Ope4Rate,
      setValue: setIM5Ope4Rate,
      errorMessage: eM5Ope1Rate,
      setErrorMessage: setEM5Ope1Rate,
      backup: MCBKUP.bM5Ope4Rate,
      label: strMac.rent.ope.first + " " + strMac.rent.fORate.label,
    },
    {
      value: iM5Ope4Unit,
      setValue: setIM5Ope4Unit,
      errorMessage: eM5Ope1Unit,
      setErrorMessage: setEM5Ope1Unit,
      backup: MCBKUP.bM5Ope4Unit,
      label:
        strMac.rent.ope.first +
        " " +
        strMac.rent.fORate.label +
        " " +
        strMac.rent.fORate.unitval.label,
    },
    {
      value: iM5Ope4Fuel,
      setValue: setIM5Ope4Fuel,
      errorMessage: eM5Ope1Fuel,
      setErrorMessage: setEM5Ope1Fuel,
      backup: MCBKUP.bM5Ope4Fuel,
      label: strMac.rent.ope.first + " " + strMac.rent.fOFuel.label,
    },
    {
      value: iM5Ope5,
      setValue: setIM5Ope5,
      errorMessage: eM5Ope2,
      setErrorMessage: setEM5Ope2,
      backup: MCBKUP.bM5Ope5,
      label: strMac.rent.ope.second,
    },
    {
      value: iM5Ope5Rate,
      setValue: setIM5Ope5Rate,
      errorMessage: eM5Ope2Rate,
      setErrorMessage: setEM5Ope2Rate,
      backup: MCBKUP.bM5Ope5Rate,
      label: strMac.rent.ope.second + " " + strMac.rent.fORate.label,
    },
    {
      value: iM5Ope5Unit,
      setValue: setIM5Ope5Unit,
      errorMessage: eM5Ope2Unit,
      setErrorMessage: setEM5Ope2Unit,
      backup: MCBKUP.bM5Ope5Unit,
      label:
        strMac.rent.ope.second +
        " " +
        strMac.rent.fORate.label +
        " " +
        strMac.rent.fORate.unitval.label,
    },
    {
      value: iM5Ope5Fuel,
      setValue: setIM5Ope5Fuel,
      errorMessage: eM5Ope2Fuel,
      setErrorMessage: setEM5Ope2Fuel,
      backup: MCBKUP.bM5Ope5Fuel,
      label: strMac.rent.ope.second + " " + strMac.rent.fOFuel.label,
    },
    {
      value: iM5Ope6,
      setValue: setIM5Ope6,
      errorMessage: eM5Ope3,
      setErrorMessage: setEM5Ope3,
      backup: MCBKUP.bM5Ope6,
      label: strMac.rent.ope.third,
    },
    {
      value: iM5Ope6Rate,
      setValue: setIM5Ope6Rate,
      errorMessage: eM5Ope3Rate,
      setErrorMessage: setEM5Ope3Rate,
      backup: MCBKUP.bM5Ope6Rate,
      label: strMac.rent.ope.third + " " + strMac.rent.fORate.label,
    },
    {
      value: iM5Ope6Unit,
      setValue: setIM5Ope6Unit,
      errorMessage: eM5Ope3Unit,
      setErrorMessage: setEM5Ope3Unit,
      backup: MCBKUP.bM5Ope6Unit,
      label:
        strMac.rent.ope.third +
        " " +
        strMac.rent.fORate.label +
        " " +
        strMac.rent.fORate.unitval.label,
    },
    {
      value: iM5Ope6Fuel,
      setValue: setIM5Ope6Fuel,
      errorMessage: eM5Ope3Fuel,
      setErrorMessage: setEM5Ope3Fuel,
      backup: MCBKUP.bM5Ope6Fuel,
      label: strMac.rent.ope.third + " " + strMac.rent.fOFuel.label,
    },
    {
      value: iM5SrvcLoc1P,
      setValue: setIM5SrvcLoc1P,
      errorMessage: eM5SrvcLoc1P,
      setErrorMessage: setEM5SrvcLoc1P,
      backup: MCBKUP.bM5SrvcLoc1P,
      label: strMac.rent.service.label,
    },
    {
      value: iM5SrvcLoc1M,
      setValue: setIM5SrvcLoc1M,
      errorMessage: eM5SrvcLoc1M,
      setErrorMessage: setEM5SrvcLoc1M,
      backup: MCBKUP.bM5SrvcLoc1M,
      label: strMac.rent.service.label,
    },
    {
      value: iM5SrvcLoc2P,
      setValue: setIM5SrvcLoc2P,
      errorMessage: eM5SrvcLoc2P,
      setErrorMessage: setEM5SrvcLoc2P,
      backup: MCBKUP.bM5SrvcLoc2P,
      label: strMac.rent.service.label,
    },
    {
      value: iM5SrvcLoc2M,
      setValue: setIM5SrvcLoc2M,
      errorMessage: eM5SrvcLoc2M,
      setErrorMessage: setEM5SrvcLoc2M,
      backup: MCBKUP.bM5SrvcLoc2M,
      label: strMac.rent.service.label,
    },
    {
      value: iM5SrvcLoc3P,
      setValue: setIM5SrvcLoc3P,
      errorMessage: eM5SrvcLoc3P,
      setErrorMessage: setEM5SrvcLoc3P,
      backup: MCBKUP.bM5SrvcLoc3P,
      label: strMac.rent.service.label,
    },
    {
      value: iM5SrvcLoc3M,
      setValue: setIM5SrvcLoc3M,
      errorMessage: eM5SrvcLoc3M,
      setErrorMessage: setEM5SrvcLoc3M,
      backup: MCBKUP.bM5SrvcLoc3M,
      label: strMac.rent.service.label,
    },
    {
      value: iM6Total,
      setValue: setIM6Total,
      errorMessage: eM6Total,
      setErrorMessage: setEM6Total,
      backup: MCBKUP.bM6Total,
      label: strMac.ope.operator,
    },
    {
      value: iM6L2Formal,
      setValue: setIM6L2Formal,
      errorMessage: eM6L2Formal,
      setErrorMessage: setEM6L2Formal,
      backup: MCBKUP.bM6L2Formal,
      label: strMac.ope.operator,
    },
    {
      value: iM6L2NtFrml,
      setValue: setIM6L2NtFrml,
      errorMessage: eM6L2NtFrml,
      setErrorMessage: setEM6L2NtFrml,
      backup: MCBKUP.bM6L2NtFrml,
      label: strMac.ope.operator,
    },
    {
      value: iM6G2Formal,
      setValue: setIM6G2Formal,
      errorMessage: eM6G2Formal,
      setErrorMessage: setEM6G2Formal,
      backup: MCBKUP.bM6G2Formal,
      label: strMac.ope.operator,
    },
    {
      value: iM6G2NtFrml,
      setValue: setIM6G2NtFrml,
      errorMessage: eM6G2NtFrml,
      setErrorMessage: setEM6G2NtFrml,
      backup: MCBKUP.bM6G2NtFrml,
      label: strMac.ope.operator,
    },
    {
      value: iM7AirFltr,
      setValue: setIM7AirFltr,
      errorMessage: eM7AirFltr,
      setErrorMessage: setEM7AirFltr,
      backup: MCBKUP.bM7AirFltr,
      label: strMac.maint.aFilter.label,
    },
    {
      value: iM7AirFltrUnit,
      setValue: setIM7AirFltrUnit,
      errorMessage: eM7AirFltrUnit,
      setErrorMessage: setEM7AirFltrUnit,
      backup: MCBKUP.bM7AirFltrUnit,
      label: strMac.maint.aFilter.label,
    },
    {
      value: iM7EngOil,
      setValue: setIM7EngOil,
      errorMessage: eM7EngOil,
      setErrorMessage: setEM7EngOil,
      backup: MCBKUP.bM7EngOil,
      label: strMac.maint.eOil.label,
    },
    {
      value: iM7EngOilUnit,
      setValue: setIM7EngOilUnit,
      errorMessage: eM7EngOilUnit,
      setErrorMessage: setEM7EngOilUnit,
      backup: MCBKUP.bM7EngOilUnit,
      label: strMac.maint.eOil.label,
    },
    {
      value: iM7EngOilFltr,
      setValue: setIM7EngOilFltr,
      errorMessage: eM7EngOilFltr,
      setErrorMessage: setEM7EngOilFltr,
      backup: MCBKUP.bM7EngOilFltr,
      label: strMac.maint.oFilter.label,
    },
    {
      value: iM7EngOilFltrUnit,
      setValue: setIM7EngOilFltrUnit,
      errorMessage: eM7EngOilFltrUnit,
      setErrorMessage: setEM7EngOilFltrUnit,
      backup: MCBKUP.bM7EngOilFltrUnit,
      label: strMac.maint.oFilter.label,
    },
    {
      value: iM7HydOil,
      setValue: setIM7HydOil,
      errorMessage: eM7HydOil,
      setErrorMessage: setEM7HydOil,
      backup: MCBKUP.bM7HydOil,
      label: strMac.maint.hOil.label,
    },
    {
      value: iM7HydOilUnit,
      setValue: setIM7HydOilUnit,
      errorMessage: eM7HydOilUnit,
      setErrorMessage: setEM7HydOilUnit,
      backup: MCBKUP.bM7HydOilUnit,
      label: strMac.maint.hOil.label,
    },
    {
      value: iM7HydOilFltr,
      setValue: setIM7HydOilFltr,
      errorMessage: eM7HydOilFltr,
      setErrorMessage: setEM7HydOilFltr,
      backup: MCBKUP.bM7HydOilFltr,
      label: strMac.maint.hOFil.label,
    },
    {
      value: iM7HydOilFltrUnit,
      setValue: setIM7HydOilFltrUnit,
      errorMessage: eM7HydOilFltrUnit,
      setErrorMessage: setEM7HydOilFltrUnit,
      backup: MCBKUP.bM7HydOilFltrUnit,
      label: strMac.maint.hOFil.label,
    },
    {
      value: iM7Tire,
      setValue: setIM7Tire,
      errorMessage: eM7Tire,
      setErrorMessage: setEM7Tire,
      backup: MCBKUP.bM7Tire,
      label: strMac.maint.tire.label,
    },
    {
      value: iM7TireUnit,
      setValue: setIM7TireUnit,
      errorMessage: eM7TireUnit,
      setErrorMessage: setEM7TireUnit,
      backup: MCBKUP.bM7TireUnit,
      label: strMac.maint.tire.label,
    },
    {
      value: iM7SrvcPrvdr,
      setValue: setIM7SrvcPrvdr,
      errorMessage: eM7SrvcPrvdr,
      setErrorMessage: setEM7SrvcPrvdr,
      backup: MCBKUP.bM7SrvcPrvdr,
      label: strMac.supplier.sName.label,
    },
    {
      value: iM7Prov,
      setValue: setIM7Prov,
      errorMessage: eM7Prov,
      setErrorMessage: setEM7Prov,
      backup: MCBKUP.bM7Prov,
      label: strMac.supplier.sProv.label,
    },
    {
      value: iM7Mun,
      setValue: setIM7Mun,
      errorMessage: eM7Mun,
      setErrorMessage: setEM7Mun,
      backup: MCBKUP.bM7Mun,
      label: strMac.supplier.sMun.label,
    },
    {
      value: iM7Brgy,
      setValue: setIM7Brgy,
      errorMessage: eM7Brgy,
      setErrorMessage: setEM7Brgy,
      backup: MCBKUP.bM7Brgy,
      label: strMac.supplier.sBrgy.label,
    },
    {
      value: iM7TimeRspnd,
      setValue: setIM7TimeRspnd,
      errorMessage: eM7TimeRspnd,
      setErrorMessage: setEM7TimeRspnd,
      backup: MCBKUP.bM7TimeRspnd,
      label: strMac.supplier.sTimeRes.label,
    },
    {
      value: iM7PrtsAvail,
      setValue: setIM7PrtsAvail,
      errorMessage: eM7PrtsAvail,
      setErrorMessage: setEM7PrtsAvail,
      backup: MCBKUP.bM7PrtsAvail,
      label: strMac.supplier.sPartsAvail.label,
    },
    {
      value: iM8OthCrp,
      setValue: setIM8OthCrp,
      errorMessage: eM8OthCrp,
      setErrorMessage: setEM8OthCrp,
      backup: "",
      label: strMac.othCrops.question.label,
    },
    {
      value: iM8Rice,
      setValue: setIM8Rice,
      errorMessage: eM8Rice,
      setErrorMessage: setEM8Rice,
      backup: MCBKUP.bM8Rice,
      label: "",
    },
    {
      value: iM8RiceHa,
      setValue: setIM8RiceHa,
      errorMessage: eM8RiceHa,
      setErrorMessage: setEM8RiceHa,
      backup: MCBKUP.bM8RiceHa,
      label:
        strMac.othCrops.sArea.rice.label + " " + strMac.othCrops.sArea.label,
    },
    {
      value: iM8Corn,
      setValue: setIM8Corn,
      errorMessage: eM8Corn,
      setErrorMessage: setEM8Corn,
      backup: MCBKUP.bM8Corn,
      label: "",
    },
    {
      value: iM8CornHa,
      setValue: setIM8CornHa,
      errorMessage: eM8CornHa,
      setErrorMessage: setEM8CornHa,
      backup: MCBKUP.bM8CornHa,
      label:
        strMac.othCrops.sArea.corn.label + " " + strMac.othCrops.sArea.label,
    },
    {
      value: iM8Othr1,
      setValue: setIM8Othr1,
      errorMessage: eM8Othr1,
      setErrorMessage: setEM8Othr1,
      backup: MCBKUP.bM8Othr1,
      label: "",
    },
    {
      value: iM8Othr1Ha,
      setValue: setIM8Othr1Ha,
      errorMessage: eM8Othr1Ha,
      setErrorMessage: setEM8Othr1Ha,
      backup: MCBKUP.bM8Othr1Ha,
      label: strMac.othCrops.sArea.label,
    },

    {
      value: iM8Othr2,
      setValue: setIM8Othr2,
      errorMessage: eM8Othr1,
      setErrorMessage: setEM8Othr1,
      backup: MCBKUP.bM8Othr2,
      label: "",
    },
    {
      value: iM8Othr2Ha,
      setValue: setIM8Othr2Ha,
      errorMessage: eM8Othr1Ha,
      setErrorMessage: setEM8Othr1Ha,
      backup: MCBKUP.bM8Othr2Ha,
      label: strMac.othCrops.sArea.label,
    },

    {
      value: iM8Othr3,
      setValue: setIM8Othr3,
      errorMessage: eM8Othr1,
      setErrorMessage: setEM8Othr1,
      backup: MCBKUP.bM8Othr3,
      label: "",
    },
    {
      value: iM8Othr3Ha,
      setValue: setIM8Othr3Ha,
      errorMessage: eM8Othr1Ha,
      setErrorMessage: setEM8Othr1Ha,
      backup: MCBKUP.bM8Othr3Ha,
      label: strMac.othCrops.sArea.label,
    },
    {
      value: iM9Notes,
      setValue: setIM9Notes,
      errorMessage: eM9Notes,
      setErrorMessage: setEM9Notes,
      backup: MCBKUP.bM9Notes,
      label: strMac.notes.label,
    },
  ];

  // ///////////////////////////////////////////////////////////////////////////
  // CONSTANTS /////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  // Address ///////////////////////////////////////////////////////////////////

  const adPvd: Address = CreateNewAddress();
  const srvc1: Address = CreateNewAddress();
  const srvc2: Address = CreateNewAddress();
  const srvc3: Address = CreateNewAddress();
  const adAts: Address = CreateNewAddress();

  // Camera ////////////////////////////////////////////////////////////////////

  const [camUser, setCamUser] = useState<CameraUsers>();

  enum CameraUsers {
    QRCode,
    FrontView,
    SideView,
    SpecView,
  }
  const [qrDevice, setQRDevice] = useState("");
  const [qrVideos, setQRVideos] = useState([]);
  const [qrCameras, setQRCameras] = useState<
    { label: string; value: string }[]
  >([]);

  const [qrReader, setQRReader] = useState(new BrowserQRCodeReader());

  useEffect(() => {
    setQRCameras(
      qrVideos.map((element: any) => ({
        label: element.label,
        value: element.deviceId,
      }))
    );
  }, [qrVideos]);

  function resetQR() {
    qrReader.reset();
    setIM1QRCode("");
  }

  function stopQR() {
    qrReader.reset();
    setQRReader(new BrowserQRCodeReader());
  }

  function againQR() {
    qrReader
      .getVideoInputDevices()
      .then((qrVideo) => {
        setupDevices(qrVideo);
      })
      .catch((err) => {
        console.error(err);
      });
    decodeOnce(qrReader, qrDevice);
  }

  function setupDevices(videos: any) {
    setQRDevice(videos[0].deviceId);
    if (videos.length >= 1) setQRVideos(videos);
  }

  function decodeOnce(codeReader: any, selectedDeviceId: any) {
    codeReader
      .decodeFromInputVideoDevice(selectedDeviceId, "video")
      .then((result: any) => {
        let res = result!.getText();
        res = res.substring(res.lastIndexOf("/") + 1);
        if (
          res.charAt(6) === "M" &&
          res.length === 12 &&
          res.charAt(0) === "R"
        ) {
          setIM1QRCode(res);
        } else {
          APopup({
            title: strMac.notif.qrError.title,
            text: strMac.notif.qrError.message,
            denyButtonText: strMac.notif.qrError.center,
          });
          setTimeout(() => {
            resetQR();
            againQR();
          }, 5000);
        }
      })
      .catch((err: any) => {
        if (err.name === "NotFoundException") {
          // Video stream has ended before any code could be detected.
        } else console.error(err);
      });
  }

  useEffect(() => {
    if (iM1QRCode.length > 0) stopQR();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iM1QRCode]);

  // Geolocation ///////////////////////////////////////////////////////////////

  var gLat: any, gLong: any, gAcc: any;
  const str = (n: any) => String(n);
  const isNum = (n: any) => !isNaN(n);
  const num = (n: any) => Number(n);

  function loadMachineMap() {
    APopup({
      icon: "info",
      title: strMac.notif.geoloc.init.title,
      text: strMac.notif.geoloc.init.message,
      confirmButtonText: strMac.notif.geoloc.init.button,
    });
    var map = L.map(ID.GeoLocationPaneMap).setView([14.16255, 121.248497], 6);
    // var lyr = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
    var lyr = "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}";
    var osm = L.tileLayer(lyr);
    osm.addTo(map);

    var refreshMap: any;
    if (!navigator.geolocation) {
      APopup({
        icon: "error",
        title: strMac.notif.geoloc.initError.title,
        text: strMac.notif.geoloc.initError.message,
        confirmButtonText: strMac.notif.geoloc.initError.button,
      });
    } else {
      refreshMap = setInterval(() => {
        if (isNum(gLat) && isNum(gLong) && isNum(gAcc)) {
          setIM1Lat(str(gLat));
          setIM1Lng(str(gLong));
          setIM1Acc(str(gAcc));
        }

        var dev = AppSettings.SERVER === "https://127.0.0.1:4433";
        var locValid = isNum(gLat) && isNum(gLong) && isNum(gAcc);
        var accValid = gAcc <= AppSettings.MIN_ACCURACY;

        if (locValid && (accValid || dev)) {
          clearInterval(refreshMap);
          APopup({
            icon: "success",
            title: strMac.notif.geoloc.success.title,
            text:
              strMac.notif.geoloc.success.lat +
              gLat +
              strMac.notif.geoloc.success.lng +
              gLong +
              strMac.notif.geoloc.success.acc +
              gAcc,
            confirmButtonText: strMac.notif.geoloc.success.button,
          });
          setIM1Lat(str(gLat));
          setIM1Lng(str(gLong));
          setIM1Acc(str(gAcc));
        } else {
          navigator.geolocation.getCurrentPosition(getPosition);
        }
      }, 3000);
    }

    var marker: any, circle: any;
    function getPosition(position: any) {
      gLat = position.coords.latitude;
      gLong = position.coords.longitude;
      gAcc = position.coords.accuracy / 3;
      if (marker) map.removeLayer(marker);
      if (circle) map.removeLayer(circle);

      var machineIcon = L.icon({
        iconUrl: iMachine,
        iconSize: [32, 32],
        popupAnchor: [0, -16],
      });

      marker = L.marker([gLat, gLong], { icon: machineIcon }).addTo(map);
      circle = L.circle([gLat, gLong], { radius: gAcc });
      var featureGroup = L.featureGroup([circle]).addTo(map);
      map.fitBounds(featureGroup.getBounds());
    }
  }

  // Machine type based visibility /////////////////////////////////////////////

  // Common Specs Card
  const [vCSpecs, setVCSpecs] = useState(false);
  // WaterPump Specs Card
  const [vPSpecs, setVPSpecs] = useState(false);
  // Specifics
  const [vCnHlr, setVCnHlr] = useState(false);
  const [vHrv, setVHrv] = useState(false);
  const [vOCrops, setVOCrops] = useState(false);

  useEffect(() => {
    var macType = iM1Type ? (iM1Type as any).label : iM1Type;
    const T = true,
      F = false;

    switch (macType) {
      case tTrc2.label:
        views({ cSpecs: T, pSpecs: F, cnHlr: F, hrv: F, othCrops: T });
        break;
      case tTrc4.label:
        views({ cSpecs: T, pSpecs: F, cnHlr: F, hrv: F, othCrops: T });
        break;
      case tCane.label:
        views({ cSpecs: T, pSpecs: F, cnHlr: T, hrv: F, othCrops: F });
        break;
      case tHrvt.label:
        views({ cSpecs: T, pSpecs: F, cnHlr: F, hrv: T, othCrops: F });
        break;
      case tPump.label:
        views({ cSpecs: F, pSpecs: T, cnHlr: F, hrv: F, othCrops: F });
        break;
      case tHaul.label:
        views({ cSpecs: T, pSpecs: F, cnHlr: T, hrv: F, othCrops: F });
        break;
      case tRGun.label:
        views({ cSpecs: F, pSpecs: T, cnHlr: F, hrv: F, othCrops: F });
        break;
      default: {
        views({ cSpecs: T, pSpecs: F, cnHlr: T, hrv: F, othCrops: F });
        break;
      }
    }
  }, [iM1Type]);

  function views(p: {
    cSpecs: boolean;
    pSpecs: boolean;
    cnHlr: boolean;
    hrv: boolean;
    othCrops: boolean;
  }) {
    setVCSpecs(p.cSpecs);
    setVPSpecs(p.pSpecs);
    setVCnHlr(p.cnHlr);
    setVHrv(p.hrv);
    setVOCrops(p.othCrops);
  }

  // Brand and Model ///////////////////////////////////////////////////////////

  const [oBrands, setOBrands] = useState(oOBrands);
  const [oModels, setOModels] = useState(oOModels);

  const [dBrand, setDBrand] = useState(true);
  const [dModel, setDModel] = useState(true);

  const [kBrand, setKBrand] = useState(0);
  const [kModel, setKModel] = useState(10000);

  useEffect(() => {
    var macType = safeRead(iM1Type);
    setOBrands(oOBrands.filter((o) => o.type.label === macType));

    if (macType === "") {
      setKBrand(kBrand + 1);
      setKModel(kModel + 1);
      setDBrand(true);
      setDModel(true);
    } else {
      setDBrand(false);
    }
  }, [iM1Type, kBrand, kModel]);

  useEffect(() => {
    var brand = safeRead(iM2Brand);
    setOModels(oOModels.filter((o) => o.brand === brand));

    if (brand === "") {
      setKModel(kModel + 1);
      setDModel(true);
    } else {
      setDModel(false);
    }
  }, [iM2Brand, kModel]);

  // Operators /////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (iM6L2Formal && iM6L2NtFrml && iM6G2Formal && iM6G2NtFrml) {
      if (
        parseInt(iM6L2Formal) +
          parseInt(iM6L2NtFrml) +
          parseInt(iM6G2Formal) +
          parseInt(iM6G2NtFrml) !==
        parseInt(iM6Total)
      ) {
        APopup({
          icon: "error",
          title: strMac.notif.ope.with.title,
          text: strMac.notif.ope.with.message,
          confirmButtonText: strMac.notif.ope.ok,
        });
      }
    }
  }, [iM6G2Formal, iM6G2NtFrml, iM6L2Formal, iM6L2NtFrml, iM6Total]);

  // Main Layout ///////////////////////////////////////////////////////////////

  return (
    requireLoadProperly(AvzRoute.machine) ?? (
      <VBar class="main-bg-form form-design">
        <VBar class="v-bar">
          <TitleBar category={strMac.category} />
          <Card title={strMac.bscInfo.title} titleStyle="title">
            <ATextInput
              text={strMac.bscInfo.resName}
              options={(() => {
                const profiles = fxdRead(fxdProfile.profiles);
                if (profiles) {
                  const profs = JSON.parse(profiles);
                  var oUsernames = [];
                  for (let i = 0; i < profs.length; i++) {
                    oUsernames.push({ label: profs[i].sP1Name });
                  }
                  return oUsernames;
                } else return [{ label: strMac.notif.noProfile.title }];
              })()}
              value={[iM1ResName, setIM1ResName]}
              validation={eM1ResName}
              backup={MCBKUP.bM1ResName}
            />
            <HBar>
              <AButton
                disabled={(() => {
                  var noUser = !camUser;
                  var qrIsUser = camUser === CameraUsers.QRCode;
                  return !(noUser || qrIsUser);
                })()}
                position="left"
                icon={<QrCode2Icon />}
                onClick={() => {
                  if (camUser === CameraUsers.QRCode) {
                    setCamUser(undefined);
                    stopQR();
                  } else {
                    setCamUser(CameraUsers.QRCode);
                    resetQR();
                    againQR();
                  }
                }}
              ></AButton>
              <ATextInput
                disabled
                position="right"
                text={strMac.bscInfo.qrcode}
                value={[iM1QRCode, setIM1QRCode]}
                validation={eM1QRCode}
                backup={MCBKUP.bM1QRCode}
              />
            </HBar>
            <VBar
              force
              in={
                camUser === CameraUsers.QRCode && String(iM1QRCode).length === 0
              }
              class="spacer"
            >
              <ATextInput
                text={strMac.bscInfo.qrcodevid}
                options={qrCameras}
                onInputChange={(e, val: any) => {
                  resetQR();
                  let v = qrCameras.find((o) => o.label === val)?.value;
                  if (v !== undefined) {
                    setQRDevice(v);
                    resetQR();
                    decodeOnce(qrReader, v);
                  }
                }}
                value={[
                  qrCameras.find((o) => o.value === qrDevice)?.label || "",
                  null,
                ]}
                validation={null}
                backup={OverrideBackup}
              />
              <video id="video" />
            </VBar>
            <HBar>
              <AButton
                disabled={(() => {
                  var noUser = !camUser;
                  var frontIsUser = camUser === CameraUsers.FrontView;
                  return !(noUser || frontIsUser);
                })()}
                position="left"
                icon={<CameraAltIcon />}
                onClick={() => {
                  if (camUser === CameraUsers.FrontView) {
                    setCamUser(undefined);
                  } else setCamUser(CameraUsers.FrontView);
                }}
              ></AButton>
              <ATextInput
                disabled
                position="right"
                text={strMac.bscInfo.front}
                value={[iM1FrontVw, setIM1FrontVw]}
                validation={eM1FrontVw}
                backup={MCBKUP.bM1FrontVw}
              />
            </HBar>
            <VBar force in={camUser === CameraUsers.FrontView} class="spacer">
              <WebCam setImage={setIM1FrontVw} />
            </VBar>
            <HBar>
              <AButton
                disabled={(() => {
                  var noUser = !camUser;
                  var sideIsUser = camUser === CameraUsers.SideView;
                  return !(noUser || sideIsUser);
                })()}
                position="left"
                icon={<CameraAltIcon />}
                onClick={() => {
                  if (camUser === CameraUsers.SideView) {
                    setCamUser(undefined);
                  } else setCamUser(CameraUsers.SideView);
                }}
              ></AButton>
              <ATextInput
                disabled
                position="right"
                text={strMac.bscInfo.side}
                value={[iM1SideVw, setIM1SideVw]}
                validation={eM1SideVw}
                backup={MCBKUP.bM1SideVw}
              />
            </HBar>
            <VBar force in={camUser === CameraUsers.SideView} class="spacer">
              <WebCam setImage={setIM1SideVw} />
            </VBar>
            <HBar>
              <AButton
                disabled={(() => {
                  var noUser = !camUser;
                  var specIsUser = camUser === CameraUsers.SpecView;
                  return !(noUser || specIsUser);
                })()}
                position="left"
                icon={<CameraAltIcon />}
                onClick={() => {
                  if (camUser === CameraUsers.SpecView) {
                    setCamUser(undefined);
                  } else setCamUser(CameraUsers.SpecView);
                }}
              ></AButton>
              <ATextInput
                disabled
                position="right"
                text={strMac.bscInfo.plate}
                value={[iM1SpecVw, setIM1SpecVw]}
                validation={eM1SpecVw}
                backup={MCBKUP.bM1SpecVw}
              />
            </HBar>
            <VBar force in={camUser === CameraUsers.SpecView} class="spacer">
              <WebCam setImage={setIM1SpecVw} />
            </VBar>
            <HBar>
              <AButton
                position="left"
                icon={<MyLocationIcon />}
                onClick={() => {
                  loadMachineMap();
                }}
              ></AButton>
              <ATextInput
                disabled
                position="right"
                text={strMac.bscInfo.coordinates}
                value={[iM1Acc, setIM1Acc]}
                validation={eM1Acc}
                backup={MCBKUP.bM1Acc}
              />
            </HBar>
            <VBar in={iM1Acc.length > 0} class="spacer">
              <ATextInput
                disabled
                text={strMac.bscInfo.latitude}
                value={[iM1Lat, setIM1Lat]}
                validation={eM1Lat}
                backup={MCBKUP.bM1Lat}
              />
              <ATextInput
                disabled
                text={strMac.bscInfo.longitude}
                value={[iM1Lng, setIM1Lng]}
                validation={eM1Lng}
                backup={MCBKUP.bM1Lng}
              />
              <AAlert
                severity={(() => {
                  var acc = num(iM1Acc);
                  return acc === undefined
                    ? "error"
                    : isNum(acc) && acc <= 5
                    ? "success"
                    : isNum(acc) && acc <= 8
                    ? "warning"
                    : "error";
                })()}
                sx={{ width: "100%" }}
              >
                {(() => {
                  var acc = num(iM1Acc);
                  return acc === undefined
                    ? strMac.notif.accuracy.init
                    : acc <= 5
                    ? strMac.notif.accuracy.high
                    : acc <= 8
                    ? strMac.notif.accuracy.low
                    : strMac.notif.accuracy.search;
                })()}
              </AAlert>
            </VBar>
            <GeoLocationPane />
            <ATextInput
              text={strMac.bscInfo.during}
              options={strMac.bscInfo.during.options}
              value={[iM1Loc, setIM1Loc]}
              validation={eM1Loc}
              backup={MCBKUP.bM1Loc}
            />
            <hr />
            <AText text={strMac.bscInfo.garage} />
            <Province
              text={strMac.bscInfo.province}
              address={adPvd}
              value={[iM1Prov, setIM1Prov]}
              validation={eM1Prov}
              backup={MCBKUP.bM1Prov}
            />
            <Municipality
              text={strMac.bscInfo.municipality}
              address={adPvd}
              value={[iM1Mun, setIM1Mun]}
              validation={eM1Mun}
              backup={MCBKUP.bM1Mun}
            />
            <Barangay
              text={strMac.bscInfo.barangay}
              address={adPvd}
              value={[iM1Brgy, setIM1Brgy]}
              validation={eM1Brgy}
              backup={MCBKUP.bM1Brgy}
            />
            <hr />
            <ATextInput
              value={[iM1Type, setIM1Type]}
              text={strMac.macInfo.type}
              options={machines.types}
              validation={eM1Type}
              backup={MCBKUP.bM1Type}
            />
            <HBar in={safeRead(iM1Type).length > 0}>
              <ATextInput
                text={strMac.macInfo.serial}
                value={[iM1Serial, setIM1Serial]}
                validation={eM1Serial}
                backup={MCBKUP.bM1Serial}
              />
            </HBar>
            <HBar in={safeRead(iM1Type) === safeRead(tHaul)}>
              <ATextInput
                text={strMac.macInfo.truck}
                options={strMac.macInfo.truck.options}
                value={[iM1CovHlng, setIM1CovHlng]}
                validation={eM1CovHlng}
                backup={MCBKUP.bM1CovHlng}
              />
            </HBar>
          </Card>
          <Card
            in={vCSpecs && safeRead(iM1Type).length > 0}
            title={strMac.macInfo.title}
            titleStyle="title"
          >
            <ATextInput
              free
              disabled={dBrand}
              text={strMac.macInfo.brand}
              options={oBrands}
              value={[iM2Brand, setIM2Brand]}
              validation={eM2Brand}
              backup={MCBKUP.bM2Brand}
            />
            <ATextInput
              free
              disabled={dModel}
              text={strMac.macInfo.model}
              options={oModels}
              value={[iM2Model, setIM2Model]}
              validation={eM2Model}
              backup={MCBKUP.bM2Model}
            />
            <HBar gap={10}>
              <ATextInput
                text={strMac.macInfo.rPower}
                value={[iM2RtdPwr, setIM2RtdPwr]}
                validation={eM2RtdPwr}
                backup={MCBKUP.bM2RtdPwr}
              />
              <ATextInput
                text={strMac.macInfo.sArea}
                value={[iM2TtlSrvArea, setIM2TtlSrvArea]}
                validation={eM2TtlSrvArea}
                backup={MCBKUP.bM2TtlSrvArea}
              />
            </HBar>
            <ATextInput
              text={strMac.macInfo.oDay}
              value={[iM2AvgOpHrDy, setIM2AvgOpHrDy]}
              validation={eM2AvgOpHrDy}
              backup={MCBKUP.bM2AvgOpHrDy}
            />
            <ATextInput
              text={strMac.macInfo.oSeason}
              value={[iM2AvgOpDySsn, setIM2AvgOpDySsn]}
              validation={eM2AvgOpDySsn}
              backup={MCBKUP.bM2AvgOpDySsn}
            />
            <HBar in={vCnHlr}>
              <ATextInput
                position="left"
                text={strMac.cane.lCap.val}
                value={[iM2LdCap, setIM2LdCap]}
                validation={eM2LdCap}
                backup={MCBKUP.bM2LdCap}
              />
              <ATextInput
                position="center"
                text={strMac.cane.lCap.unit}
                options={strMac.cane.lCap.unit.options}
                value={[iM2LdCapUnit, setIM2LdCapUnit]}
                validation={eM2LdCapUnit}
                backup={MCBKUP.bM2LdCapUnit}
              />
              <ATextInput
                disabled={!safeRead(iM2LdCapUnit).includes(strMac.load)}
                position="right"
                text={strMac.cane.lCap.load}
                value={[iM2LdCapDay, setIM2LdCapDay]}
                validation={eM2LdCapDay}
                backup={MCBKUP.bM2LdCapDay}
              />
            </HBar>
            <VBar in={vHrv || vCnHlr} class="spacer">
              <ATextInput
                text={strMac.harvester.time}
                value={[iM2TmCom1Ha, setIM2TmCom1Ha]}
                validation={eM2TmCom1Ha}
                backup={MCBKUP.bM2TmCom1Ha}
              />
            </VBar>
            <VBar in={vHrv} class="spacer">
              <ATextInput
                text={strMac.harvester.yield}
                value={[iM2SgrcnYld, setIM2SgrcnYld]}
                validation={eM2SgrcnYld}
                backup={MCBKUP.bM2SgrcnYld}
              />
            </VBar>
          </Card>
          <Card
            in={vPSpecs && safeRead(iM1Type).length > 0}
            title={
              iM1Type && (iM1Type as any).label === tRGun.label
                ? strMac.wPump.rgun
                : strMac.wPump.title
            }
            titleStyle="title"
          >
            <AText text={strMac.wPump.sSpecs} />
            <ATextInput
              free
              text={strMac.wPump.type}
              options={strMac.wPump.type.options2}
              value={[iM3TpTbWlls, setIM3TpTbWlls]}
              validation={eM3TpTbWlls}
              backup={MCBKUP.bM3TpTbWlls}
            />
            <ATextInput
              free
              options={[]}
              text={strMac.macInfo.brand}
              value={[iM3Brand, setIM3Brand]}
              validation={eM3Brand}
              backup={MCBKUP.bM3Brand}
            />
            <ATextInput
              free
              options={[]}
              text={strMac.macInfo.model}
              value={[iM3Model, setIM3Model]}
              validation={eM3Model}
              backup={MCBKUP.bM3Model}
            />
            <ATextInput
              text={strMac.wPump.size}
              value={[iM3SzPmp, setIM3SzPmp]}
              validation={eM3SzPmp}
              backup={MCBKUP.bM3SzPmp}
            />
            <AText text={strMac.wPump.sPrime} />
            <ATextInput
              text={strMac.wPump.source}
              options={strMac.wPump.source.options}
              value={[iM3SrcPwr, setIM3SrcPwr]}
              validation={eM3SrcPwr}
              backup={MCBKUP.bM3SrcPwr}
            />
            <ATextInput
              free
              options={[]}
              text={strMac.wPump.pBrand}
              value={[iM3SrcBrand, setIM3SrcBrand]}
              validation={eM3SrcBrand}
              backup={MCBKUP.bM3SrcBrand}
            />
            <ATextInput
              free
              options={[]}
              text={strMac.wPump.pModel}
              value={[iM3SrcModel, setIM3SrcModel]}
              validation={eM3SrcModel}
              backup={MCBKUP.bM3SrcModel}
            />
            <ATextInput
              text={strMac.wPump.pRPower}
              value={[iM3RtdPwr, setIM3RtdPwr]}
              validation={eM3RtdPwr}
              backup={MCBKUP.bM3RtdPwr}
            />
            <AText text={strMac.wPump.sOutput} />
            <HBar>
              <ATextInput
                position="left"
                text={strMac.wPump.discharge}
                value={[iM3DisCap, setIM3DisCap]}
                validation={eM3DisCap}
                backup={MCBKUP.bM3DisCap}
              />
              <ATextInput
                free
                options={strMac.wPump.discharge.options}
                position="right"
                text={strMac.wPump.discharge.unitval}
                value={[iM3DisCapUnit, setIM3DisCapUnit]}
                validation={eM3DisCapUnit}
                backup={MCBKUP.bM3DisCapUnit}
              />
            </HBar>
            <ATextInput
              text={strMac.wPump.sArea}
              value={[iM3TtlSrvArea, setIM3TtlSrvArea]}
              validation={eM3TtlSrvArea}
              backup={MCBKUP.bM3TtlSrvArea}
            />
            <ATextInput
              text={strMac.wPump.oDay}
              value={[iM3AvgOpHrDy, setIM3AvgOpHrDy]}
              validation={eM3AvgOpHrDy}
              backup={MCBKUP.bM3AvgOpHrDy}
            />
            <ATextInput
              text={strMac.wPump.oSeason}
              value={[iM3AvgOpDySsn, setIM3AvgOpDySsn]}
              validation={eM3AvgOpDySsn}
              backup={MCBKUP.bM3AvgOpDySsn}
            />
          </Card>
          <Card
            in={safeRead(iM1Type).length > 0}
            title={strMac.usage.title}
            titleStyle="title"
          >
            <ATextInput
              text={strMac.usage.usage}
              options={strMac.usage.usage.options}
              value={[iM4Usage, setIM4Usage]}
              validation={eM4Usage}
              backup={MCBKUP.bM4Usage}
            />
            <ATextInput
              text={strMac.usage.mode}
              options={strMac.usage.mode.options}
              value={[iM4Mode, setIM4Mode]}
              validation={eM4Mode}
              backup={MCBKUP.bM4Mode}
            />
            <HBar
              in={
                safeRead(iM4Mode) === safeRead(strMac.usage.mode.options[2]) ||
                safeRead(iM4Mode) === safeRead(strMac.usage.mode.options[3])
              }
            >
              <ATextInput
                text={strMac.usage.agency}
                value={[iM4Agency, setIM4Agency]}
                validation={eM4Agency}
                backup={MCBKUP.bM4Agency}
              />
            </HBar>
            <ATextInput
              text={strMac.usage.acqYear}
              value={[iM4YrAcqrd, setIM4YrAcqrd]}
              validation={eM4YrAcqrd}
              backup={MCBKUP.bM4YrAcqrd}
            />
            <ATextInput
              text={strMac.usage.cond}
              options={strMac.usage.cond.options}
              value={[iM4ConAcqrd, setIM4ConAcqrd]}
              validation={eM4ConAcqrd}
              backup={MCBKUP.bM4ConAcqrd}
            />
            <ATextInput
              text={strMac.usage.pCond}
              value={[iM4PrsntCon, setIM4PrsntCon]}
              options={strMac.usage.pCond.options}
              validation={eM4PrsntCon}
              backup={MCBKUP.bM4PrsntCon}
            />
            <VBar
              in={safeRead(iM4PrsntCon) === strMac.usage.pCond.options[1].label}
              id="unused"
              class="spacer"
            >
              <ATextInput
                text={strMac.unused.uuYear}
                value={[iM4YrUnused, setIM4YrUnused]}
                validation={eM4YrUnused}
                backup={MCBKUP.bM4YrUnused}
              />
              <AText text={strMac.unused.subtitle} />
              <ATick
                options={strMac.unused.options}
                value={[iM4RsnUnused, setIM4RsnUnused]}
                backup={MCBKUP.bM4RsnUnused}
              />
              <ATextInput
                text={strMac.unused.uuOthers}
                value={[iM4UuOthers, setIM4UuOthers]}
                validation={eM4UuOthers}
                backup={MCBKUP.bM4UuOthers}
              />
            </VBar>
            <VBar
              in={safeRead(iM4PrsntCon) === strMac.usage.pCond.options[2].label}
              id="unserviceable"
              class="spacer"
            >
              <ATextInput
                text={strMac.unserv.usYear}
                value={[iM4YrUnserv, setIM4YrUnserv]}
                validation={eM4YrUnserv}
                backup={MCBKUP.bM4YrUnserv}
              />
              <AText text={strMac.unserv.subtitle} />
              <ATick
                options={strMac.unserv.options}
                value={[iM4RsnUnserv, setIM4RsnUnserv]}
                backup={MCBKUP.bM4RsnUnserv}
              />
              <ATextInput
                text={strMac.unserv.usOthers}
                value={[iM4UsOthers, setIM4UsOthers]}
                validation={eM4UsOthers}
                backup={MCBKUP.bM4UsOthers}
              />
            </VBar>
            <ATextInput
              text={strMac.perf.breakdowns}
              value={[iM4NoBrkdwn, setIM4NoBrkdwn]}
              validation={eM4NoBrkdwn}
              backup={MCBKUP.bM4NoBrkdwn}
            />
            <ATextInput
              max={3}
              text={strMac.perf.common}
              value={[iM4CmnBrkdwn, setIM4CmnBrkdwn]}
              options={strMac.perf.common.options}
              validation={eM4CmnBrkdwn}
              backup={MCBKUP.bM4CmnBrkdwn}
            />
          </Card>
          <Card
            in={(() => {
              var usage = safeRead(iM4Usage);
              return (
                usage === safeRead(strMac.usage.usage.options[1]) ||
                usage === safeRead(strMac.usage.usage.options[2])
              );
            })()}
            title={strMac.rent.title}
            titleStyle="title"
          >
            <AText text={strMac.usage.contact.title} />
            <ATick
              options={[{ label: strMac.usage.contact.same, key: 0 }]}
              value={[iM5SameOwn, setIM5SameOwn]}
              backup={MCBKUP.bM5SameOwn}
            />
            <HBar>
              <ATextInput
                width={50}
                position="left"
                text={strMac.usage.contact1}
                value={[iM5BusCont1, setIM5BusCont1]}
                validation={eM5BusCont1}
                backup={MCBKUP.bM5BusCont1}
              />
              <ATextInput
                width={50}
                position="right"
                text={strMac.usage.contact2}
                value={[iM5BusCont2, setIM5BusCont2]}
                validation={eM5BusCont2}
                backup={MCBKUP.bM5BusCont2}
              />
            </HBar>
            <ATick
              options={[{ label: strMac.usage.contact.consent, key: 0 }]}
              value={[iM5Consent, setIM5Consent]}
              backup={MCBKUP.bM5Consent}
            />
            <HBar gap={10}>
              <VBar
                w={
                  safeRead(iM4Usage) ===
                    safeRead(strMac.usage.usage.options[2]) ||
                  AppSettings.showAll
                    ? 50
                    : 100
                }
              >
                <VBar gap={10}>
                  <p>
                    <strong>{strMac.rent.member}</strong>
                  </p>
                  <AText text={strMac.rent.ope.first} />
                  <ATextInput
                    text={strMac.rent.fOper}
                    options={strImp.basicInfo.type.options}
                    value={[iM5Ope1, setIM5Ope1]}
                    validation={eM5Ope1}
                    backup={MCBKUP.bM5Ope1}
                  />
                  <ATextInput
                    unitPosition="start"
                    text={strMac.rent.fORate}
                    value={[iM5Ope1Rate, setIM5Ope1Rate]}
                    validation={eM5Ope1Rate}
                    backup={MCBKUP.bM5Ope1Rate}
                  />
                  <ATextInput
                    text={strMac.rent.fORate.unitval}
                    options={strMac.rent.fORate.options}
                    value={[iM5Ope1Unit, setIM5Ope1Unit]}
                    validation={eM5Ope1Unit}
                    backup={MCBKUP.bM5Ope1Unit}
                  />
                  <ATextInput
                    text={strMac.rent.fOFuel}
                    value={[iM5Ope1Fuel, setIM5Ope1Fuel]}
                    validation={eM5Ope1Fuel}
                    backup={MCBKUP.bM5Ope1Fuel}
                  />
                </VBar>
                <VBar in={safeRead(iM5Ope1).length > 0} gap={10}>
                  <AText text={strMac.rent.ope.second} className="pt-2" />
                  <ATextInput
                    text={strMac.rent.fOper}
                    options={strImp.basicInfo.type.options}
                    value={[iM5Ope2, setIM5Ope2]}
                    validation={eM5Ope2}
                    backup={MCBKUP.bM5Ope2}
                  />
                  <ATextInput
                    unitPosition="start"
                    text={strMac.rent.fORate}
                    value={[iM5Ope2Rate, setIM5Ope2Rate]}
                    validation={eM5Ope2Rate}
                    backup={MCBKUP.bM5Ope2Rate}
                  />
                  <ATextInput
                    free
                    text={strMac.rent.fORate.unitval}
                    options={strMac.rent.fORate.options}
                    value={[iM5Ope2Unit, setIM5Ope2Unit]}
                    validation={eM5Ope2Unit}
                    backup={MCBKUP.bM5Ope2Unit}
                  />
                  <ATextInput
                    text={strMac.rent.fOFuel}
                    value={[iM5Ope2Fuel, setIM5Ope2Fuel]}
                    validation={eM5Ope2Fuel}
                    backup={MCBKUP.bM5Ope2Fuel}
                  />
                </VBar>
                <VBar in={safeRead(iM5Ope2).length > 0} gap={10}>
                  <AText text={strMac.rent.ope.third} className="pt-2" />
                  <ATextInput
                    text={strMac.rent.fOper}
                    options={strImp.basicInfo.type.options}
                    value={[iM5Ope3, setIM5Ope3]}
                    validation={eM5Ope3}
                    backup={MCBKUP.bM5Ope3}
                  />
                  <ATextInput
                    unitPosition="start"
                    text={strMac.rent.fORate}
                    value={[iM5Ope3Rate, setIM5Ope3Rate]}
                    validation={eM5Ope3Rate}
                    backup={MCBKUP.bM5Ope3Rate}
                  />
                  <ATextInput
                    free
                    text={strMac.rent.fORate.unitval}
                    options={strMac.rent.fORate.options}
                    value={[iM5Ope3Unit, setIM5Ope3Unit]}
                    validation={eM5Ope3Unit}
                    backup={MCBKUP.bM5Ope3Unit}
                  />
                  <ATextInput
                    text={strMac.rent.fOFuel}
                    value={[iM5Ope3Fuel, setIM5Ope3Fuel]}
                    validation={eM5Ope3Fuel}
                    backup={MCBKUP.bM5Ope3Fuel}
                  />
                </VBar>
              </VBar>
              <VBar
                w={100}
                in={
                  safeRead(iM4Usage) === safeRead(strMac.usage.usage.options[2])
                }
              >
                <VBar gap={10}>
                  <p>
                    <strong>{strMac.rent.nonMember}</strong>
                  </p>
                  <AText text={strMac.rent.ope.first} />
                  <ATextInput
                    text={strMac.rent.fOper}
                    options={strImp.basicInfo.type.options}
                    value={[iM5Ope4, setIM5Ope4]}
                    validation={eM5Ope1}
                    backup={MCBKUP.bM5Ope4}
                  />
                  <ATextInput
                    unitPosition="start"
                    text={strMac.rent.fORate}
                    value={[iM5Ope4Rate, setIM5Ope4Rate]}
                    validation={eM5Ope1Rate}
                    backup={MCBKUP.bM5Ope4Rate}
                  />
                  <ATextInput
                    text={strMac.rent.fORate.unitval}
                    options={strMac.rent.fORate.options}
                    value={[iM5Ope4Unit, setIM5Ope4Unit]}
                    validation={eM5Ope1Unit}
                    backup={MCBKUP.bM5Ope4Unit}
                  />
                  <ATextInput
                    text={strMac.rent.fOFuel}
                    value={[iM5Ope4Fuel, setIM5Ope4Fuel]}
                    validation={eM5Ope1Fuel}
                    backup={MCBKUP.bM5Ope4Fuel}
                  />
                </VBar>
                <VBar in={safeRead(iM5Ope4).length > 0} gap={10}>
                  <AText text={strMac.rent.ope.second} className="pt-2" />
                  <ATextInput
                    text={strMac.rent.fOper}
                    options={strImp.basicInfo.type.options}
                    value={[iM5Ope5, setIM5Ope5]}
                    validation={eM5Ope2}
                    backup={MCBKUP.bM5Ope5}
                  />
                  <ATextInput
                    unitPosition="start"
                    text={strMac.rent.fORate}
                    value={[iM5Ope5Rate, setIM5Ope5Rate]}
                    validation={eM5Ope2Rate}
                    backup={MCBKUP.bM5Ope5Rate}
                  />
                  <ATextInput
                    free
                    text={strMac.rent.fORate.unitval}
                    options={strMac.rent.fORate.options}
                    value={[iM5Ope5Unit, setIM5Ope5Unit]}
                    validation={eM5Ope2Unit}
                    backup={MCBKUP.bM5Ope5Unit}
                  />
                  <ATextInput
                    text={strMac.rent.fOFuel}
                    value={[iM5Ope5Fuel, setIM5Ope5Fuel]}
                    validation={eM5Ope2Fuel}
                    backup={MCBKUP.bM5Ope5Fuel}
                  />
                </VBar>
                <VBar in={safeRead(iM5Ope5).length > 0} gap={10}>
                  <AText text={strMac.rent.ope.third} className="pt-2" />
                  <ATextInput
                    text={strMac.rent.fOper}
                    options={strImp.basicInfo.type.options}
                    value={[iM5Ope6, setIM5Ope6]}
                    validation={eM5Ope3}
                    backup={MCBKUP.bM5Ope6}
                  />
                  <ATextInput
                    unitPosition="start"
                    text={strMac.rent.fORate}
                    value={[iM5Ope6Rate, setIM5Ope6Rate]}
                    validation={eM5Ope3Rate}
                    backup={MCBKUP.bM5Ope6Rate}
                  />
                  <ATextInput
                    free
                    text={strMac.rent.fORate.unitval}
                    options={strMac.rent.fORate.options}
                    value={[iM5Ope6Unit, setIM5Ope6Unit]}
                    validation={eM5Ope3Unit}
                    backup={MCBKUP.bM5Ope6Unit}
                  />
                  <ATextInput
                    text={strMac.rent.fOFuel}
                    value={[iM5Ope6Fuel, setIM5Ope6Fuel]}
                    validation={eM5Ope3Fuel}
                    backup={MCBKUP.bM5Ope6Fuel}
                  />
                </VBar>
              </VBar>
            </HBar>
            <hr />
            <AText text={strMac.rent.service.label} />
            <HBar gap={10}>
              <Province
                text={strMac.bscInfo.province}
                address={srvc1}
                value={[iM5SrvcLoc1P, setIM5SrvcLoc1P]}
                validation={eM5SrvcLoc1P}
                backup={MCBKUP.bM5SrvcLoc1P}
              />
              <Municipality
                text={strMac.bscInfo.municipality}
                address={srvc1}
                value={[iM5SrvcLoc1M, setIM5SrvcLoc1M]}
                validation={eM5SrvcLoc1M}
                backup={MCBKUP.bM5SrvcLoc1M}
              />
            </HBar>
            <HBar gap={10} in={safeRead(iM5SrvcLoc1M).length > 0}>
              <Province
                text={strMac.bscInfo.province}
                address={srvc2}
                value={[iM5SrvcLoc2P, setIM5SrvcLoc2P]}
                validation={eM5SrvcLoc2P}
                backup={MCBKUP.bM5SrvcLoc2P}
              />
              <Municipality
                text={strMac.bscInfo.municipality}
                address={srvc2}
                value={[iM5SrvcLoc2M, setIM5SrvcLoc2M]}
                validation={eM5SrvcLoc2M}
                backup={MCBKUP.bM5SrvcLoc2M}
              />
            </HBar>
            <HBar gap={10} in={safeRead(iM5SrvcLoc2M).length > 0}>
              <Province
                text={strMac.bscInfo.province}
                address={srvc3}
                value={[iM5SrvcLoc3P, setIM5SrvcLoc3P]}
                validation={eM5SrvcLoc3P}
                backup={MCBKUP.bM5SrvcLoc3P}
              />
              <Municipality
                text={strMac.bscInfo.municipality}
                address={srvc3}
                value={[iM5SrvcLoc3M, setIM5SrvcLoc3M]}
                validation={eM5SrvcLoc3M}
                backup={MCBKUP.bM5SrvcLoc3M}
              />
            </HBar>
            <VBar id="yes" class="spacer"></VBar>
          </Card>
          <Card
            in={safeRead(iM1Type).length > 0}
            title={strMac.ope.title}
            titleStyle="title"
          >
            <AText text={strMac.ope.operator} />
            <ATextInput
              text={strMac.ope.ops}
              value={[iM6Total, setIM6Total]}
              validation={eM6Total}
              backup={MCBKUP.bM6Total}
            />
            <AText text={strMac.ope.exp} />
            <ATextInput
              text={strMac.ope.expA}
              value={[iM6L2Formal, setIM6L2Formal]}
              validation={eM6L2Formal}
              backup={MCBKUP.bM6L2Formal}
            />
            <ATextInput
              text={strMac.ope.expB}
              value={[iM6L2NtFrml, setIM6L2NtFrml]}
              validation={eM6L2NtFrml}
              backup={MCBKUP.bM6L2NtFrml}
            />
            <ATextInput
              text={strMac.ope.expC}
              value={[iM6G2Formal, setIM6G2Formal]}
              validation={eM6G2Formal}
              backup={MCBKUP.bM6G2Formal}
            />
            <ATextInput
              text={strMac.ope.expD}
              value={[iM6G2NtFrml, setIM6G2NtFrml]}
              validation={eM6G2NtFrml}
              backup={MCBKUP.bM6G2NtFrml}
            />
          </Card>
          <Card
            in={safeRead(iM1Type).length > 0}
            title={strMac.maint.title}
            titleStyle="title"
          >
            <VBar in={vCSpecs} class="spacer">
              <HBar>
                <ATextInput
                  width={70}
                  position="left"
                  text={strMac.maint.aFilter}
                  value={[iM7AirFltr, setIM7AirFltr]}
                  validation={eM7AirFltr}
                  backup={MCBKUP.bM7AirFltr}
                />
                <ATextInput
                  width={30}
                  position="right"
                  text={strMac.maint.maintUnit}
                  options={strMac.maint.units}
                  value={[iM7AirFltrUnit, setIM7AirFltrUnit]}
                  validation={eM7AirFltrUnit}
                  backup={MCBKUP.bM7AirFltrUnit}
                />
              </HBar>
              <HBar>
                <ATextInput
                  width={70}
                  position="left"
                  text={strMac.maint.eOil}
                  value={[iM7EngOil, setIM7EngOil]}
                  validation={eM7EngOil}
                  backup={MCBKUP.bM7EngOil}
                />
                <ATextInput
                  width={30}
                  position="right"
                  text={strMac.maint.maintUnit}
                  options={strMac.maint.units}
                  value={[iM7EngOilUnit, setIM7EngOilUnit]}
                  validation={eM7EngOilUnit}
                  backup={MCBKUP.bM7EngOilUnit}
                />
              </HBar>
              <HBar>
                <ATextInput
                  width={70}
                  position="left"
                  text={strMac.maint.oFilter}
                  value={[iM7EngOilFltr, setIM7EngOilFltr]}
                  validation={eM7EngOilFltr}
                  backup={MCBKUP.bM7EngOilFltr}
                />
                <ATextInput
                  width={30}
                  position="right"
                  text={strMac.maint.maintUnit}
                  options={strMac.maint.units}
                  value={[iM7EngOilFltrUnit, setIM7EngOilFltrUnit]}
                  validation={eM7EngOilFltrUnit}
                  backup={MCBKUP.bM7EngOilFltrUnit}
                />
              </HBar>
              <HBar>
                <ATextInput
                  width={70}
                  position="left"
                  text={strMac.maint.hOil}
                  value={[iM7HydOil, setIM7HydOil]}
                  validation={eM7HydOil}
                  backup={MCBKUP.bM7HydOil}
                />
                <ATextInput
                  width={30}
                  position="right"
                  text={strMac.maint.maintUnit}
                  options={strMac.maint.units}
                  value={[iM7HydOilUnit, setIM7HydOilUnit]}
                  validation={eM7HydOilUnit}
                  backup={MCBKUP.bM7HydOilUnit}
                />
              </HBar>
              <HBar>
                <ATextInput
                  width={70}
                  position="left"
                  text={strMac.maint.hOFil}
                  value={[iM7HydOilFltr, setIM7HydOilFltr]}
                  validation={eM7HydOilFltr}
                  backup={MCBKUP.bM7HydOilFltr}
                />
                <ATextInput
                  width={30}
                  position="right"
                  text={strMac.maint.maintUnit}
                  options={strMac.maint.units}
                  value={[iM7HydOilFltrUnit, setIM7HydOilFltrUnit]}
                  validation={eM7HydOilFltrUnit}
                  backup={MCBKUP.bM7HydOilFltrUnit}
                />
              </HBar>
              <HBar>
                <ATextInput
                  width={70}
                  position="left"
                  text={strMac.maint.tire}
                  value={[iM7Tire, setIM7Tire]}
                  validation={eM7Tire}
                  backup={MCBKUP.bM7Tire}
                />
                <ATextInput
                  width={30}
                  position="right"
                  text={strMac.maint.maintUnit}
                  options={strMac.maint.units}
                  value={[iM7TireUnit, setIM7TireUnit]}
                  validation={eM7TireUnit}
                  backup={MCBKUP.bM7TireUnit}
                />
              </HBar>
              <hr />
            </VBar>
            <AText text={strMac.supplier.title} />
            <ATextInput
              text={strMac.supplier.sName}
              options={strMac.supplier.options}
              value={[iM7SrvcPrvdr, setIM7SrvcPrvdr]}
              validation={eM7SrvcPrvdr}
              backup={MCBKUP.bM7SrvcPrvdr}
            />
            <VBar class="spacer indent">
              <Province
                text={strMac.supplier.sProv}
                address={adAts}
                value={[iM7Prov, setIM7Prov]}
                validation={eM7Prov}
                backup={MCBKUP.bM7Prov}
              />
              <Municipality
                text={strMac.supplier.sMun}
                address={adAts}
                value={[iM7Mun, setIM7Mun]}
                validation={eM7Mun}
                backup={MCBKUP.bM7Mun}
              />
              <Barangay
                text={strMac.supplier.sBrgy}
                address={adAts}
                value={[iM7Brgy, setIM7Brgy]}
                validation={eM7Brgy}
                backup={MCBKUP.bM7Brgy}
              />
            </VBar>
            <ATextInput
              text={strMac.supplier.sTimeRes}
              options={strMac.supplier.sTimeRes.options}
              value={[iM7TimeRspnd, setIM7TimeRspnd]}
              validation={eM7TimeRspnd}
              backup={MCBKUP.bM7TimeRspnd}
            />
            <ATextInput
              text={strMac.supplier.sPartsAvail}
              options={strMac.supplier.sPartsAvail.options}
              value={[iM7PrtsAvail, setIM7PrtsAvail]}
              validation={eM7PrtsAvail}
              backup={MCBKUP.bM7PrtsAvail}
            />
          </Card>
          <Card in={vOCrops} title={strMac.othCrops.title} titleStyle="title">
            <ATextInput
              text={strMac.othCrops.question}
              options={strMac.othCrops.question.options}
              value={[iM8OthCrp, setIM8OthCrp]}
              validation={eM8OthCrp}
              backup={MCBKUP.bM8OthCrp}
            />
            <VBar
              in={
                safeRead(iM8OthCrp) ===
                strMac.othCrops.question.options[0].label
              }
              class="spacer"
            >
              <HBar>
                <AText text={strMac.othCrops.sArea.label} />
              </HBar>
              <HBar gap={10}>
                <ATick
                  width={50}
                  options={[{ label: strMac.othCrops.sArea.rice.text, key: 0 }]}
                  value={[iM8Rice, setIM8Rice]}
                  backup={MCBKUP.bM8Rice}
                />
                <HBar in={safeRead(iM8Rice).length > 0}>
                  <ATextInput
                    open
                    text={strMac.othCrops.sArea.rice}
                    value={[iM8RiceHa, setIM8RiceHa]}
                    validation={eM8RiceHa}
                    backup={MCBKUP.bM8RiceHa}
                  />
                </HBar>
              </HBar>
              <HBar gap={10}>
                <ATick
                  width={50}
                  options={[{ label: strMac.othCrops.sArea.corn.text, key: 0 }]}
                  value={[iM8Corn, setIM8Corn]}
                  backup={MCBKUP.bM8Corn}
                />
                <HBar in={safeRead(iM8Corn).length > 0}>
                  <ATextInput
                    open
                    text={strMac.othCrops.sArea.corn}
                    value={[iM8CornHa, setIM8CornHa]}
                    validation={eM8CornHa}
                    backup={MCBKUP.bM8CornHa}
                  />
                </HBar>
              </HBar>
              <HBar gap={10}>
                <ATextInput
                  width={50}
                  text={strMac.othCrops.sArea.othersName}
                  value={[iM8Othr1, setIM8Othr1]}
                  validation={eM8Othr1}
                  backup={MCBKUP.bM8Othr1}
                />
                <HBar in={iM8Othr1.length > 0}>
                  <ATextInput
                    open
                    text={strMac.othCrops.sArea.othersVal}
                    value={[iM8Othr1Ha, setIM8Othr1Ha]}
                    validation={eM8Othr1Ha}
                    backup={MCBKUP.bM8Othr1Ha}
                  />
                </HBar>
              </HBar>
              <HBar gap={10}>
                <ATextInput
                  width={50}
                  text={strMac.othCrops.sArea.othersName}
                  value={[iM8Othr2, setIM8Othr2]}
                  validation={eM8Othr1}
                  backup={MCBKUP.bM8Othr2}
                />
                <HBar in={iM8Othr2.length > 0}>
                  <ATextInput
                    open
                    text={strMac.othCrops.sArea.othersVal}
                    value={[iM8Othr2Ha, setIM8Othr2Ha]}
                    validation={eM8Othr1Ha}
                    backup={MCBKUP.bM8Othr2Ha}
                  />
                </HBar>
              </HBar>
              <HBar gap={10}>
                <ATextInput
                  width={50}
                  text={strMac.othCrops.sArea.othersName}
                  value={[iM8Othr3, setIM8Othr3]}
                  validation={eM8Othr1}
                  backup={MCBKUP.bM8Othr3}
                />
                <HBar in={iM8Othr3.length > 0}>
                  <ATextInput
                    open
                    text={strMac.othCrops.sArea.othersVal}
                    value={[iM8Othr3Ha, setIM8Othr3Ha]}
                    validation={eM8Othr1Ha}
                    backup={MCBKUP.bM8Othr3Ha}
                  />
                </HBar>
              </HBar>
            </VBar>
          </Card>
          <Card>
            <ATextInput
              rows={7}
              text={strMac.notes}
              value={[iM9Notes, setIM9Notes]}
              validation={eM9Notes}
              backup={MCBKUP.bM9Notes}
            />
          </Card>
          <AButton
            label={strMac.save}
            color="primary"
            icon={<SaveIcon />}
            onClick={async () => {
              saveEncodedMachine();
            }}
            height={50}
          />
          <div className="btn-group" role="group">
            <button className="btn btn-primary" type="button">
              <Nav.Link
                key={AvzRoute.encode}
                as={NavLink}
                to={AvzRoute.encode}
                end
              >
                Go to Encode
              </Nav.Link>
            </button>
            <button className="btn btn-primary" type="button">
              <Nav.Link
                key={AvzRoute.implement}
                as={NavLink}
                to={AvzRoute.implement}
                end
              >
                Go to Implement
              </Nav.Link>
            </button>
          </div>
        </VBar>
      </VBar>
    )
  );

  async function saveEncodedMachine() {
    const Question = (value: any) => {
      return QUESTIONS.find((q) => q.label === value);
    };

    var required = [
      Question(iM1ResName),
      Question(iM1QRCode),
      Question(iM1FrontVw),
      Question(iM1SideVw),
      // Question(iM1SpecVw),
      Question(iM1Acc),
      Question(iM1Lat),
      Question(iM1Lng),
      Question(iM1Loc),
      Question(iM1Prov),
      Question(iM1Mun),
      Question(iM1Brgy),
      Question(iM1Type),
    ];

    if (safeRead(iM1Type).length > 0) {
      required.push(Question(iM1Serial));
    }

    if (safeRead(iM1Type) === safeRead(tHaul)) {
      required.push(Question(iM1CovHlng));
    }

    if (vCSpecs && safeRead(iM1Type).length > 0) {
      required.push(Question(iM2Brand));
      // required.push(Question(iM2Model));
      required.push(Question(iM2RtdPwr));
      required.push(Question(iM2TtlSrvArea));
      required.push(Question(iM2AvgOpHrDy));
      required.push(Question(iM2AvgOpDySsn));

      if (vCnHlr) {
        required.push(Question(iM2LdCap));
        required.push(Question(iM2LdCapUnit));
      }

      if (safeRead(iM2LdCapUnit).includes(strMac.load)) {
        required.push(Question(iM2LdCapDay));
      }
      if (vHrv || vCnHlr) {
        required.push(Question(iM2TmCom1Ha));
        required.push(Question(iM2SgrcnYld));
      }
    }

    if (vPSpecs && safeRead(iM1Type).length > 0) {
      required.push(Question(iM3TpTbWlls));
      required.push(Question(iM3Brand));
      required.push(Question(iM3Model));
      required.push(Question(iM3SzPmp));
      required.push(Question(iM3SrcPwr));
      required.push(Question(iM3SrcBrand));
      required.push(Question(iM3SrcModel));
      required.push(Question(iM3RtdPwr));
      required.push(Question(iM3DisCap));
      required.push(Question(iM3DisCapUnit));
      required.push(Question(iM3TtlSrvArea));
      required.push(Question(iM3AvgOpHrDy));
      required.push(Question(iM3AvgOpDySsn));
    }

    if (safeRead(iM1Type).length > 0) {
      required.push(Question(iM4Usage));
      required.push(Question(iM4Mode));

      if (
        safeRead(iM4Mode) === safeRead(strMac.usage.mode.options[2]) ||
        safeRead(iM4Mode) === safeRead(strMac.usage.mode.options[3])
      ) {
        required.push(Question(iM4Agency));
      }

      required.push(Question(iM4YrAcqrd));
      required.push(Question(iM4ConAcqrd));
      required.push(Question(iM4PrsntCon));

      if (safeRead(iM4PrsntCon) === strMac.usage.pCond.options[1].label) {
        required.push(Question(iM4YrUnused));
        required.push(Question(iM4RsnUnused));
        // required.push(Question(iM4UuOthers));
      }

      if (safeRead(iM4PrsntCon) === strMac.usage.pCond.options[2].label) {
        required.push(Question(iM4YrUnserv));
        required.push(Question(iM4RsnUnserv));
        // required.push(Question(iM4UsOthers));
      }

      required.push(Question(iM4NoBrkdwn));
      required.push(Question(iM4CmnBrkdwn));
    }

    var usage = safeRead(iM4Usage);
    if (
      usage === safeRead(strMac.usage.usage.options[1]) ||
      usage === safeRead(strMac.usage.usage.options[2])
    ) {
      required.push(Question(iM5SameOwn));
      required.push(Question(iM5BusCont1));
      required.push(Question(iM5BusCont2));
      required.push(Question(iM5Consent));

      required.push(Question(iM5Ope1));
      required.push(Question(iM5Ope1Rate));
      required.push(Question(iM5Ope1Unit));
      required.push(Question(iM5Ope1Fuel));

      if (safeRead(iM5Ope1).length > 0) {
        required.push(Question(iM5Ope2));
        required.push(Question(iM5Ope2Rate));
        required.push(Question(iM5Ope2Unit));
        required.push(Question(iM5Ope2Fuel));
      }

      if (safeRead(iM5Ope2).length > 0) {
        required.push(Question(iM5Ope3));
        required.push(Question(iM5Ope3Rate));
        required.push(Question(iM5Ope3Unit));
        required.push(Question(iM5Ope3Fuel));
      }

      if (safeRead(iM4Usage) === safeRead(strMac.usage.usage.options[2])) {
        required.push(Question(iM5Ope4));
        required.push(Question(iM5Ope4Rate));
        required.push(Question(iM5Ope4Unit));
        required.push(Question(iM5Ope4Fuel));

        if (safeRead(iM5Ope4).length > 0) {
          required.push(Question(iM5Ope5));
          required.push(Question(iM5Ope5Rate));
          required.push(Question(iM5Ope5Unit));
          required.push(Question(iM5Ope5Fuel));
        }

        if (safeRead(iM5Ope5).length > 0) {
          required.push(Question(iM5Ope6));
          required.push(Question(iM5Ope6Rate));
          required.push(Question(iM5Ope6Unit));
          required.push(Question(iM5Ope6Fuel));
        }
      }

      required.push(Question(iM5SrvcLoc1P));
      required.push(Question(iM5SrvcLoc1M));

      if (safeRead(iM5SrvcLoc1M).length > 0) {
        required.push(Question(iM5SrvcLoc2P));
        required.push(Question(iM5SrvcLoc2M));
      }

      if (safeRead(iM5SrvcLoc2M).length > 0) {
        required.push(Question(iM5SrvcLoc3P));
        required.push(Question(iM5SrvcLoc3M));
      }
    }

    if (safeRead(iM1Type).length > 0) {
      required.push(Question(iM6Total));
      required.push(Question(iM6L2Formal));
      required.push(Question(iM6L2NtFrml));
      required.push(Question(iM6G2Formal));
      required.push(Question(iM6G2NtFrml));
    }

    if (safeRead(iM1Type).length > 0) {
      required.push(Question(iM7AirFltr));
      required.push(Question(iM7AirFltrUnit));
      required.push(Question(iM7EngOil));
      required.push(Question(iM7EngOilUnit));
      required.push(Question(iM7EngOilFltr));
      required.push(Question(iM7EngOilFltrUnit));
      required.push(Question(iM7HydOil));
      required.push(Question(iM7HydOilUnit));
      required.push(Question(iM7HydOilFltr));
      required.push(Question(iM7HydOilFltrUnit));
      required.push(Question(iM7Tire));
      required.push(Question(iM7TireUnit));
      required.push(Question(iM7SrvcPrvdr));
      required.push(Question(iM7Prov));
      required.push(Question(iM7Mun));
      required.push(Question(iM7Brgy));
      required.push(Question(iM7TimeRspnd));
      required.push(Question(iM7PrtsAvail));
    }

    if (vOCrops) {
      required.push(Question(iM8OthCrp));
      if (safeRead(iM8OthCrp) === strMac.othCrops.question.options[0].label) {
        required.push(Question(iM8Rice));
        if (safeRead(iM8Rice).length > 0) {
          required.push(Question(iM8RiceHa));
        }
        required.push(Question(iM8Corn));
        if (safeRead(iM8Corn).length > 0) {
          required.push(Question(iM8CornHa));
        }
        required.push(Question(iM8Othr1));
        if (safeRead(iM8Othr1).length > 0) {
          required.push(Question(iM8Othr1Ha));
        }
        required.push(Question(iM8Othr2));
        if (safeRead(iM8Othr2).length > 0) {
          required.push(Question(iM8Othr2Ha));
        }
        required.push(Question(iM8Othr3));
        if (safeRead(iM8Othr3).length > 0) {
          required.push(Question(iM8Othr3Ha));
        }
      }
    }

    required.forEach((r) => {
      if (r) r.errorMessage.required = true;
    });

    var error;
    var hasError = required.some((r) => {
      if (r) {
        if (r.value === null || r.value === "") {
          error = r.label;
          return true;
        }
      }
      return false;
    });

    if (typeof JSON == "undefined") {
      APopup({
        type: "error",
        text: strPrf.notif.browserError,
      });
    } else if (hasError && error) {
      APopup({
        type: "error",
        title: error,
        text: strPrf.notif.missing,
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      APopup({
        type: "success",
        text: strPrf.notif.saveIni,
      });
    }

    if (!hasError || !error) {
      let data = {
        sM1ResName: iM1ResName,
        sM1QRCode: iM1QRCode,
        sM1FrontVw: iM1FrontVw,
        sM1SideVw: iM1SideVw,
        sM1SpecVw: iM1SpecVw,
        sM1Acc: iM1Acc,
        sM1Lat: iM1Lat,
        sM1Lng: iM1Lng,
        sM1Loc: iM1Loc,
        sM1Prov: iM1Prov,
        sM1Mun: iM1Mun,
        sM1Brgy: iM1Brgy,
        sM1Type: iM1Type,
        sM1Serial: iM1Serial,
        sM1CovHlng: iM1CovHlng,
        sM2Brand: iM2Brand,
        sM2Model: iM2Model,
        sM2RtdPwr: iM2RtdPwr,
        sM2TtlSrvArea: iM2TtlSrvArea,
        sM2AvgOpHrDy: iM2AvgOpHrDy,
        sM2AvgOpDySsn: iM2AvgOpDySsn,
        sM2LdCap: iM2LdCap,
        sM2LdCapUnit: iM2LdCapUnit,
        sM2LdCapDay: iM2LdCapDay,
        sM2TmCom1Ha: iM2TmCom1Ha,
        sM2SgrcnYld: iM2SgrcnYld,
        sM3TpTbWlls: iM3TpTbWlls,
        sM3Brand: iM3Brand,
        sM3Model: iM3Model,
        sM3SzPmp: iM3SzPmp,
        sM3SrcPwr: iM3SrcPwr,
        sM3SrcBrand: iM3SrcBrand,
        sM3SrcModel: iM3SrcModel,
        sM3RtdPwr: iM3RtdPwr,
        sM3DisCap: iM3DisCap,
        sM3DisCapUnit: iM3DisCapUnit,
        sM3TtlSrvArea: iM3TtlSrvArea,
        sM3AvgOpHrDy: iM3AvgOpHrDy,
        sM3AvgOpDySsn: iM3AvgOpDySsn,
        sM4Usage: iM4Usage,
        sM4Mode: iM4Mode,
        sM4Agency: iM4Agency,
        sM4YrAcqrd: iM4YrAcqrd,
        sM4ConAcqrd: iM4ConAcqrd,
        sM4PrsntCon: iM4PrsntCon,
        sM4YrUnused: iM4YrUnused,
        sM4RsnUnused: iM4RsnUnused,
        sM4UuOthers: iM4UuOthers,
        sM4YrUnserv: iM4YrUnserv,
        sM4RsnUnserv: iM4RsnUnserv,
        sM4UsOthers: iM4UsOthers,
        sM4NoBrkdwn: iM4NoBrkdwn,
        sM4CmnBrkdwn: iM4CmnBrkdwn,
        sM5SameOwn: iM5SameOwn,
        sM5BusCont1: iM5BusCont1,
        sM5BusCont2: iM5BusCont2,
        sM5Consent: iM5Consent,
        sM5Ope1: iM5Ope1,
        sM5Ope1Rate: iM5Ope1Rate,
        sM5Ope1Unit: iM5Ope1Unit,
        sM5Ope1Fuel: iM5Ope1Fuel,
        sM5Ope2: iM5Ope2,
        sM5Ope2Rate: iM5Ope2Rate,
        sM5Ope2Unit: iM5Ope2Unit,
        sM5Ope2Fuel: iM5Ope2Fuel,
        sM5Ope3: iM5Ope3,
        sM5Ope3Rate: iM5Ope3Rate,
        sM5Ope3Unit: iM5Ope3Unit,
        sM5Ope3Fuel: iM5Ope3Fuel,
        sM5Ope4: iM5Ope4,
        sM5Ope4Rate: iM5Ope4Rate,
        sM5Ope4Unit: iM5Ope4Unit,
        sM5Ope4Fuel: iM5Ope4Fuel,
        sM5Ope5: iM5Ope5,
        sM5Ope5Rate: iM5Ope5Rate,
        sM5Ope5Unit: iM5Ope5Unit,
        sM5Ope5Fuel: iM5Ope5Fuel,
        sM5Ope6: iM5Ope6,
        sM5Ope6Rate: iM5Ope6Rate,
        sM5Ope6Unit: iM5Ope6Unit,
        sM5Ope6Fuel: iM5Ope6Fuel,
        sM5SrvcLoc1P: iM5SrvcLoc1P,
        sM5SrvcLoc1M: iM5SrvcLoc1M,
        sM5SrvcLoc2P: iM5SrvcLoc2P,
        sM5SrvcLoc2M: iM5SrvcLoc2M,
        sM5SrvcLoc3P: iM5SrvcLoc3P,
        sM5SrvcLoc3M: iM5SrvcLoc3M,
        sM6Total: iM6Total,
        sM6L2Formal: iM6L2Formal,
        sM6L2NtFrml: iM6L2NtFrml,
        sM6G2Formal: iM6G2Formal,
        sM6G2NtFrml: iM6G2NtFrml,
        sM7AirFltr: iM7AirFltr,
        sM7AirFltrUnit: iM7AirFltrUnit,
        sM7EngOil: iM7EngOil,
        sM7EngOilUnit: iM7EngOilUnit,
        sM7EngOilFltr: iM7EngOilFltr,
        sM7EngOilFltrUnit: iM7EngOilFltrUnit,
        sM7HydOil: iM7HydOil,
        sM7HydOilUnit: iM7HydOilUnit,
        sM7HydOilFltr: iM7HydOilFltr,
        sM7HydOilFltrUnit: iM7HydOilFltrUnit,
        sM7Tire: iM7Tire,
        sM7TireUnit: iM7TireUnit,
        sM7SrvcPrvdr: iM7SrvcPrvdr,
        sM7Prov: iM7Prov,
        sM7Mun: iM7Mun,
        sM7Brgy: iM7Brgy,
        sM7TimeRspnd: iM7TimeRspnd,
        sM7PrtsAvail: iM7PrtsAvail,
        sM8OthCrp: iM8OthCrp,
        sM8Rice: iM8Rice,
        sM8RiceHa: iM8RiceHa,
        sM8Corn: iM8Corn,
        sM8CornHa: iM8CornHa,
        sM8Othr1: iM8Othr1,
        sM8Othr1Ha: iM8Othr1Ha,
        sM8Othr2: iM8Othr2,
        sM8Othr2Ha: iM8Othr2Ha,
        sM8Othr3: iM8Othr3,
        sM8Othr3Ha: iM8Othr3Ha,
        sM9Notes: iM9Notes,
        sM0Uname: fxdRead(fxdUser.name),
        sM0Encoded: Date.now().toString(),
        sM0Status: DATA_STATUS.local,
      } as MachineData;

      fxdWrite(fxdGen.editing, FORMS.null);
      var zipname = safeRead(iM1QRCode) + ".zip";
      var filename = safeRead(iM1QRCode) + ".json";

      createZIP(zipname, AppSettings.ZIPPASS, [
        { filename: filename, content: JSON.stringify(data, undefined, 2) },
      ]);

      fxdWrite(safeRead(iM1QRCode), JSON.stringify(data));

      clearFields();
      deleteMachineBackups();
      APopup({
        type: "success",
        title: strMac.notif.save.title,
        text: strMac.notif.save.message,
        confirmButtonColor: strMac.notif.save.center,
      }).then(() => {
        GOTO(AvzRoute.machine);
      });
    }
  }

  function clearFields() {
    QUESTIONS.forEach((question) => {
      if (typeof question.setValue === "function") {
        question.setValue("");
      }
    });
  }
}

// /////////////////////////////////////////////////////////////////////////////
// MACHINE FORMAT //////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export interface MachineData {
  sM1ResName: string;
  sM1QRCode: string;
  sM1FrontVw: string;
  sM1SideVw: string;
  sM1SpecVw: string;
  sM1Acc: string;
  sM1Lat: string;
  sM1Lng: string;
  sM1Loc: string;
  sM1Prov: string;
  sM1Mun: string;
  sM1Brgy: string;
  sM1Type: string;
  sM1Serial: string;
  sM1CovHlng: string;
  sM2Brand: string;
  sM2Model: string;
  sM2RtdPwr: string;
  sM2TtlSrvArea: string;
  sM2AvgOpHrDy: string;
  sM2AvgOpDySsn: string;
  sM2LdCap: string;
  sM2LdCapUnit: string;
  sM2LdCapDay: string;
  sM2TmCom1Ha: string;
  sM2SgrcnYld: string;
  sM3TpTbWlls: string;
  sM3Brand: string;
  sM3Model: string;
  sM3SzPmp: string;
  sM3SrcPwr: string;
  sM3SrcBrand: string;
  sM3SrcModel: string;
  sM3RtdPwr: string;
  sM3DisCap: string;
  sM3DisCapUnit: string;
  sM3TtlSrvArea: string;
  sM3AvgOpHrDy: string;
  sM3AvgOpDySsn: string;
  sM4Usage: string;
  sM4Mode: string;
  sM4Agency: string;
  sM4YrAcqrd: string;
  sM4ConAcqrd: string;
  sM4PrsntCon: string;
  sM4YrUnused: string;
  sM4RsnUnused: string;
  sM4UuOthers: string;
  sM4YrUnserv: string;
  sM4RsnUnserv: string;
  sM4UsOthers: string;
  sM4NoBrkdwn: string;
  sM4CmnBrkdwn: string;
  sM5SameOwn: string;
  sM5BusCont1: string;
  sM5BusCont2: string;
  sM5Consent: string;
  sM5Ope1: string;
  sM5Ope1Rate: string;
  sM5Ope1Unit: string;
  sM5Ope1Fuel: string;
  sM5Ope2: string;
  sM5Ope2Rate: string;
  sM5Ope2Unit: string;
  sM5Ope2Fuel: string;
  sM5Ope3: string;
  sM5Ope3Rate: string;
  sM5Ope3Unit: string;
  sM5Ope3Fuel: string;
  sM5Ope4: string;
  sM5Ope4Rate: string;
  sM5Ope4Unit: string;
  sM5Ope4Fuel: string;
  sM5Ope5: string;
  sM5Ope5Rate: string;
  sM5Ope5Unit: string;
  sM5Ope5Fuel: string;
  sM5Ope6: string;
  sM5Ope6Rate: string;
  sM5Ope6Unit: string;
  sM5Ope6Fuel: string;
  sM5SrvcLoc1P: string;
  sM5SrvcLoc1M: string;
  sM5SrvcLoc2P: string;
  sM5SrvcLoc2M: string;
  sM5SrvcLoc3P: string;
  sM5SrvcLoc3M: string;
  sM6Total: string;
  sM6L2Formal: string;
  sM6L2NtFrml: string;
  sM6G2Formal: string;
  sM6G2NtFrml: string;
  sM7AirFltr: string;
  sM7AirFltrUnit: string;
  sM7EngOil: string;
  sM7EngOilUnit: string;
  sM7EngOilFltr: string;
  sM7EngOilFltrUnit: string;
  sM7HydOil: string;
  sM7HydOilUnit: string;
  sM7HydOilFltr: string;
  sM7HydOilFltrUnit: string;
  sM7Tire: string;
  sM7TireUnit: string;
  sM7SrvcPrvdr: string;
  sM7Prov: string;
  sM7Mun: string;
  sM7Brgy: string;
  sM7TimeRspnd: string;
  sM7PrtsAvail: string;
  sM8OthCrp: string;
  sM8Rice: string;
  sM8RiceHa: string;
  sM8Corn: string;
  sM8CornHa: string;
  sM8Othr1: string;
  sM8Othr1Ha: string;
  sM8Othr2: string;
  sM8Othr2Ha: string;
  sM8Othr3: string;
  sM8Othr3Ha: string;
  sM9Notes: string;
  sM0Uname: string;
  sM0Encoded: string;
  sM0Status: string;
}
