import "./EncodePage.css";
import "bootstrap/dist/css/bootstrap.min.css";

// import axios from "axios";
// import Swal from "sweetalert2";
// import { saveAs } from "file-saver";
// import { useEffect, useState } from "react";
import {
  LoaderFunctionArgs,
  NavLink,
  useNavigate,
  //  useNavigate
} from "react-router-dom";

import { LogoBar } from "../splash/SplashScreen";
// import { ProfileData } from "../profile/ProfilePage";
// import { MachineData } from "../machine/MachinePage";
// import { ImplementData } from "../implement/ImplementPage";

import {
  gotoLogin,
  AvzRoute,
  log,
  requireLoadProperly,
} from "../../app/Routes";
// import {
//   fxdUser,
//   fxdProfile,
//   fxdIsNull,
//   fxdRead,
//   readLabel,
//   fxdGen,
//   fxdWrite,
// } from "../../app/Storage";
// import { fxdMachine, fxdImplement } from "../../app/Storage";
// import { AppSettings } from "../../app/Settings";
// import { DATA_STATUS } from "../../app/GenEnums";

import {
  Card,
  // , VBar
} from "../../custom/afx/ABox";
// import { TGetSeverity } from "../../custom/afx/AType";
// import { IModPublic, TModButton } from "../../custom/afx/AFX";
// import { Mod, Pick, Popup, Toast } from "../../custom/afx/AFX";

// import { Fab, Paper, Table } from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { TableBody, TableCell } from "@mui/material";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import { TableContainer, TableHead, TableRow } from "@mui/material";

import PKG from "../../../package.json";
import {
  fxdImplement,
  fxdMachine,
  fxdProfile,
  fxdRead,
  fxdUser,
} from "../../app/Storage";
import farmerIcon from "./assets/img/843349.png";
import { ManageDevelopersCode } from "./DevelopersCode";
import { Nav, Table } from "react-bootstrap";
import TX from "./EncodePage.json";
import { ProfileData } from "../profile/ProfilePage";
import { DATA_STATUS } from "../../app/GenEnums";
import { MachineData } from "../machine/MachinePage";
import { ImplementData } from "../implement/ImplementPage";
import {
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { safeRead } from "../../app/GenFunctions";
// import {
//   ReadImplement,
//   ReadMachine,
//   ReadProfile,
//   UpdateImplementImage,
//   UpdateMachineImage,
//   UploadImplement,
//   UploadMachine,
//   UploadProfile,
// } from "../../app/Server";
// import { AUTH_PROVIDER } from "../../hooks/auth/auth";
// import { DevelopersCode, ManageDevelopersCode } from "./DevelopersCode";
// import { comDate, dateOnly } from "../../app/GenFunctions";

// /////////////////////////////////////////////////////////////////////////////
// PUBLIC //////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export function TitleBar(props: {
  category: string;
  noCard?: boolean;
  glass?: boolean;
}) {
  var titleBar = (
    <div id="title-bar" className={props.glass ? "avz-glass p-4" : ""}>
      {LogoBar("logo-bar", "splash-icon")}
      <p id="txt-title" className="small-text">
        {PKG.titleLong}
      </p>
      <h1 className="category">{props.category.toUpperCase()}</h1>
    </div>
  );

  return props.noCard ? titleBar : <Card>{titleBar}</Card>;
}

// /////////////////////////////////////////////////////////////////////////////
// ENCODE PAGE /////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export function EncodePageLoader({ request }: LoaderFunctionArgs) {
  return gotoLogin(request.url) ?? null;
}

/**
 * Tested for null profile, machine, and implement data.
 */
export default function EncodePage() {
  // var date = new Date();
  // if (
  //   fxdRead(fxdGen.backup) === null ||
  //   fxdRead(fxdGen.backup) !== dateOnly(date)
  // ) {
  //   const ucred = [fxdUser.name];
  //   if (fxdIsNull(ucred[0])) {
  //     Popup({
  //       type: "error",
  //       title: TX.backup.credErr.title,
  //       text: TX.backup.credErr.text,
  //       showCancelButton: true,
  //       confirmButtonText: TX.backup.credErr.confirm,
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         AUTH_PROVIDER.signout().then(() => {
  //           window.location.reload();
  //         });
  //       }
  //     });
  //   } else if (
  //     fxdIsNull(fxdProfile.profiles) ||
  //     fxdIsNull(fxdMachine.machines)
  //   ) {
  //   } else {
  //     var name = fxdRead(ucred[0])?.toUpperCase();
  //     let filename = "M3DAS_AB_" + name + "_" + comDate(date) + ".zip";
  //     var data = [];
  //     data.push(fxdRead(fxdProfile.profiles) ?? []);
  //     data.push(fxdRead(fxdMachine.machines) ?? []);
  //     data.push(fxdRead(fxdImplement.implements) ?? []);
  //     var fileToSave = new Blob([JSON.stringify(data, undefined, 2)], {
  //       type: "application/json",
  //     });
  //     saveAs(fileToSave, filename);
  //     fxdWrite(fxdGen.backup, dateOnly(date));

  //     Popup({
  //       icon: "success",
  //       type: "success",
  //       text: TX.backup.success.text + filename,
  //       confirmButtonText: TX.backup.success.confirm,
  //     });
  //   }
  // }

  // const [formData, setFormData] = useState({
  //   name: "NH",
  //   email: "EH",
  //   message: "MH",
  // });

  // const handleChange = (event: any) => {
  //   setFormData({ ...formData, [event.target.name]: event.target.value });
  // };

  // const handleSubmit = async (event: any) => {
  //   event.preventDefault();

  //   // try {
  //   //   const response = await fetch("/api/send-email", {
  //   //     method: "POST",
  //   //     headers: { "Content-Type": "application/json" },
  //   //     body: JSON.stringify(formData),
  //   //   });

  //   //   if (!response.ok) {
  //   //     throw new Error(`Error sending email: ${response.statusText}`);
  //   //   }

  //   //   console.log("Email sent successfully!");
  //   //   // Show a success message to the user
  //   // } catch (error) {
  //   //   console.error("Error sending email:", error);
  //   //   // Handle errors appropriately (e.g., show an error message)
  //   // }

  //   await axios
  //     .post(AppSettings.SERVER + "/emailzip", formData)
  //     .then((response) => {
  //       if (response.status !== 200) {
  //         throw new Error(`Error sending email: ${response.statusText}`);
  //       }
  //       console.log("Email sent successfully!");
  //     })
  //     .catch((error) => {
  //       console.error("Error sending email:", error);
  //     });
  // };

  // // Main Component ////////////////////////////////////////////////////////////
  return (
    requireLoadProperly(AvzRoute.encode) ?? (
      <div id="encode-page" className="main-bg-colored">
        <div id="vertically" className="v-bar">
          <TitleBar category=" " noCard={true} glass />
          {/* <form onSubmit={handleSubmit}> */}
          {/* Form fields for name, email, and message */}
          {/* // TODO <button type="submit">Send Email</button> */}
          {/* </form> */}
          <AddProfileCard />
        </div>
      </div>
    )
  );

  // Sub Component /////////////////////////////////////////////////////////////

  function AddProfileCard() {
    var profiles = fxdRead(fxdProfile.profiles);
    // TODO, Register this ID
    var id = "add-profile";
    return (
      <div id={id}>
        <div className="add-res">
          <FarmerImageCard />
          {profiles !== null && profiles.length > 0 && profiles !== ""
            ? JSON.parse(profiles).map((pfl: ProfileData) => LoadProfiles(pfl))
            : null}
        </div>
      </div>
    );
  }

  // // Farmer Image Card /////////////////////////////////////////////////////////

  function FarmerImageCard() {
    var count = 0;
    return (
      <div className="add-profile">
        <img
          alt="farmer-icon"
          id="farmer-icon"
          src={farmerIcon}
          onClick={() => (count = ManageDevelopersCode(count))}
        />
        <div className="btn-group btn-group-profile" role="group">
          <button className="btn btn-primary btn-add-profile" type="button">
            <Nav.Link
              key={AvzRoute.profile}
              as={NavLink}
              to={AvzRoute.profile}
              onClick={() => log(AvzRoute.profile)}
              end
            >
              {TX.farmer.add}
            </Nav.Link>
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-create-backup"
            // TODO Update this function
            // onClick={() => Backup()}
            disabled
          >
            {TX.farmer.backup}
          </button>
          <button
            type="button"
            className="btn btn-danger btn-restore-backup"
            // TODO Update this function
            // onClick={() => Restore()}
            disabled
          >
            {TX.farmer.restore}
          </button>
        </div>
      </div>
    );
  }

  // // Backup and Restore ////////////////////////////////////////////////////////

  // function Backup() {
  //   Popup({
  //     type: "success",
  //     title: TX.backup.title,
  //     text: TX.backup.text,
  //     confirmButtonText: TX.backup.confirm,
  //     showCancelButton: true,
  //     cancelButtonText: TX.backup.cancel,
  //     allowOutsideClick: true,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       const ucred = [fxdUser.name];
  //       if (fxdIsNull(ucred[0])) {
  //         Popup({
  //           type: "error",
  //           title: TX.backup.credErr.title,
  //           text: TX.backup.credErr.text,
  //           showCancelButton: true,
  //           confirmButtonText: TX.backup.credErr.confirm,
  //         }).then((result) => {
  //           if (result.isConfirmed) {
  //             AUTH_PROVIDER.signout().then(() => {
  //               window.location.reload();
  //             });
  //           }
  //         });
  //       } else if (
  //         fxdIsNull(fxdProfile.profiles) ||
  //         fxdIsNull(fxdMachine.machines)
  //       ) {
  //         Popup({
  //           type: "error",
  //           title: TX.backup.incData.title,
  //           text: TX.backup.incData.text,
  //           confirmButtonText: TX.backup.incData.confirm,
  //         });
  //       } else {
  //         var name = fxdRead(ucred[0])?.toUpperCase();
  //         let filename =
  //           "M3DAS_BU_" + name + "_" + comDate(new Date()) + ".zip";
  //         var data = [];
  //         data.push(fxdRead(fxdProfile.profiles) ?? []);
  //         data.push(fxdRead(fxdMachine.machines) ?? []);
  //         data.push(fxdRead(fxdImplement.implements) ?? []);
  //         var fileToSave = new Blob([JSON.stringify(data, undefined, 2)], {
  //           type: "application/json",
  //         });
  //         saveAs(fileToSave, filename);

  //         Popup({
  //           icon: "success",
  //           type: "success",
  //           text: TX.backup.success.text + filename,
  //           confirmButtonText: TX.backup.success.confirm,
  //         });
  //       }
  //     }
  //   });
  // }

  // function Restore() {
  //   Popup({
  //     icon: "warning",
  //     title: "Restore",
  //     text: "What do you want to restore?",
  //     confirmButtonText: "Full Restore",
  //     confirmButtonColor: "btn btn-danger",
  //     showCancelButton: true,
  //     cancelButtonText: "Single Data",
  //     cancelButtonColor: "btn btn-success ms-3",
  //     allowOutsideClick: true,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       Popup({
  //         type: "error",
  //         title: "Full Restore Warning",
  //         text: "This will restore all your encoded data from the backup file, deleting all current data shown in your dashboard. Are you sure?",
  //         confirmButtonText: "I Understand",
  //         showCancelButton: true,
  //         allowOutsideClick: true,
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           Popup({
  //             type: "error",
  //             title: "Restore Backup",
  //             text: "Upload a backup file to restore the data in this device.",
  //             input: "file",
  //             inputAttributes: {
  //               accept: ".zip,.json",
  //             },
  //             showCancelButton: true,
  //             confirmButtonText: "Restore",
  //             preConfirm: (file) => {
  //               var reader = new FileReader();
  //               reader.onload = function (e) {
  //                 var data = JSON.parse(reader.result as string);
  //                 var profiless = data[0];
  //                 var machiness = data[1];
  //                 var implementss = data[2];
  //                 if (profiless.length > 0)
  //                   localStorage.setItem(fxdProfile.profiles, data[0]);
  //                 if (machiness.length > 0)
  //                   localStorage.setItem(fxdMachine.machines, data[1]);
  //                 if (implementss.length > 0)
  //                   localStorage.setItem(fxdImplement.implements, data[2]);
  //               };
  //               reader.readAsText(file);
  //               window.location.reload();
  //             },
  //           });
  //         }
  //       });
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       Popup({
  //         type: "success",
  //         title: "Restore Backup",
  //         text: "Upload a backup file to restore the data in this device.",
  //         input: "file",
  //         inputAttributes: {
  //           accept: ".zip",
  //         },
  //         showCancelButton: true,
  //         confirmButtonText: "Restore",
  //         preConfirm: (file) => {
  //           var reader = new FileReader();
  //           reader.onload = function (e) {
  //             var data = JSON.parse(reader.result as string);
  //             var qr = data.qrCode ?? "";
  //             var adr;

  //             if (qr && qr.charAt(6) == "I") {
  //               adr = fxdImplement.implements;
  //             } else if (qr && qr.charAt(6) == "M") {
  //               adr = fxdMachine.machines;
  //             } else {
  //               adr = fxdProfile.profiles;
  //             }

  //             let encoded = fxdRead(adr);
  //             if (encoded != null) {
  //               const newEncode = data;
  //               const list = JSON.parse(encoded) as Array<any>;
  //               list.push(newEncode);
  //               localStorage.setItem(adr, JSON.stringify(list));
  //             } else {
  //               localStorage.setItem(adr, JSON.stringify([data]));
  //             }
  //           };
  //           reader.readAsText(file);
  //           window.location.reload();
  //         },
  //       });
  //     }
  //   });
  // }

  // // ///////////////////////////////////////////////////////////////////////////
  // // SUBCOMPONENTS /////////////////////////////////////////////////////////////
  // // ///////////////////////////////////////////////////////////////////////////

  // export function enlistedMachinesFromThisProfile(name: string) {
  //   const LIST_OF_MACHINES = fxdRead(fxdMachine.machines);
  //   if (LIST_OF_MACHINES == null || LIST_OF_MACHINES.length == 0) return;
  //   var machines: MachineData[] = [];
  //   JSON.parse(LIST_OF_MACHINES).filter((machine: MachineData) => {
  //     if ((machine.sM1ResName as any).label === name) machines.push(machine);
  //   });
  //   return machines;
  // }

  // export function enlistedImplementsFromThisProfile(name: string) {
  //   const LIST_OF_IMPLEMENTS = fxdRead(fxdImplement.implements);
  //   if (LIST_OF_IMPLEMENTS == null || LIST_OF_IMPLEMENTS.length == 0) return;
  //   var implementss: ImplementData[] = [];
  //   JSON.parse(LIST_OF_IMPLEMENTS).filter((implement: ImplementData) => {
  //     if ((implement.sI1ResName as any).label === name)
  //       implementss.push(implement);
  //   });
  //   return implementss;
  // }

  // function LoadProfile(name: string, role: string, status: string) {
  function LoadProfiles(profile: ProfileData) {
    var _date = (str: string) => new Date(Number(str)).toDateString();
    var _info = (prfl: ProfileData) =>
      safeRead(prfl.sP2Profile) + " - " + _date(prfl.sP0Encoded);

    var name = profile.sP1Name;
    var role = _info(profile);
    var status = profile.sP0Status;

    // var severity: TGetSeverity = status.includes(DATA_STATUS.local)
    //   ? "info"
    //   : status.includes(DATA_STATUS.online)
    //   ? "warning"
    //   : status.includes(DATA_STATUS.returned)
    //   ? "error"
    //   : "success";

    // Assign all local keys to a variable
    var keys = Object.keys(localStorage);
    // For each key, if the first character is "R" and the seventh is "M", return the key as an array
    // var enlistedMachinesFromThisProfile = (name: string) =>
    //   keys.filter((key) => key.charAt(0) === "R" && key.charAt(6) === "M");
    var enlistedMachinesFromThisProfile = (name: string) =>
      //   keys.filter((key) => {
      //     const item = fxdRead(key);
      //     if (item) {
      //       const parsedItem = JSON.parse(item);
      //       return (
      //         key.charAt(0) === "R" &&
      //         key.charAt(6) === "M" &&
      //         parsedItem.sM1ResName?.label === name
      //       );
      //     }
      //     return false;
      //   });
      keys.filter((key) => key.charAt(0) === "R" && key.charAt(6) === "M");

    // For each key, if the first character is "R" and the seventh is "I", return the key as an array
    var enlistedImplementsFromThisProfile = (name: string) =>
      //   keys.filter((key) => {
      //     const item = fxdRead(key);
      //     if (item) {
      //       const parsedItem = JSON.parse(item);
      //       return (
      //         key.charAt(0) === "R" &&
      //         key.charAt(6) === "I" &&
      //         parsedItem.sI1ResName?.label === name
      //       );
      //     }
      //     return false;
      //   });
      keys.filter((key) => key.charAt(0) === "R" && key.charAt(6) === "I");

    var mach = enlistedMachinesFromThisProfile(name);
    var machNo = mach === undefined ? 0 : mach?.length;
    var machArr = mach?.map((mach) => localStorage.getItem(mach));

    var impl = enlistedImplementsFromThisProfile(name);
    var implNo = impl === undefined ? 0 : impl?.length;
    var implArr = impl?.map((impl) => localStorage.getItem(impl));

    return (
      <div key={name} className="view-profile">
        <p className="profile-name">{name.toUpperCase()}</p>
        <div className="name-bar">
          {/* <CloudUploadIcon
            color={severity}
            onClick={() => {
              // showNotification({
              //   message: name + "'s profile is " + cleanStatus(status),
              //   severity: severity,
              // });
              Toast({
                type: severity,
                text: name + "'s profile is " + cleanStatus(status),
              });
            }}
          /> */}
          {/* <p className="profile">&nbsp;&nbsp;{role}</p> */}
          <p className="profile">{role}</p>
        </div>
        {/* <div className="btn-group btn-group-encode" role="group">
          <button className="btn btn-primary add-btn" type="button">
            <Nav.Link
              key={AvzRoute.machine}
              as={NavLink}
              to={AvzRoute.machine}
              onClick={() => log(AvzRoute.machine)}
              end
            >
              Add Machine
            </Nav.Link>
          </button>
          <button className="btn btn-secondary add-btn" type="button">
            <Nav.Link
              key={AvzRoute.implement}
              as={NavLink}
              to={AvzRoute.implement}
              onClick={() => log(AvzRoute.implement)}
              end
            >
              Add Implement
            </Nav.Link>
          </button>
        </div> */}
        <div id="accordion-1" className="accordion" role="tablist">
          {/* {machines < 1 ? null : MachineTab(name)}
          {implementss < 1 ? null : ImplementTab(name)}
          {machines < 1 && implementss < 1 ? (
            <div>
              <i>
                <p>
                  At present, no machine or implement has been encoded to {name}
                  .
                </p>
              </i>
            </div>
          ) : (
            SummaryTab(name)
          )} */}
          {Surveyed(
            name,
            machArr.map((mach) => (mach ? JSON.parse(mach) : null)),
            implArr.map((impl) => (impl ? JSON.parse(impl) : null))
          )}
          {OperationsTab(name)}
        </div>
      </div>
    );
  }

  // // ///////////////////////////////////////////////////////////////////////////

  // function SummaryTab(name: string) {
  //   var nameAsID = "l-" + name.replaceAll(" ", "-").replaceAll(".", "-");
  //   var completeID = "#accordion-1" + " .item-" + nameAsID;

  //   return (
  //     <div className="accordion-item">
  //       <h2 className="accordion-header" role="tab">
  //         <button
  //           className="accordion-button collapsed"
  //           type="button"
  //           data-bs-toggle="collapse"
  //           // data-bs-target="#accordion-1 .item-1"
  //           data-bs-target={completeID}
  //           aria-expanded="false"
  //           // aria-controls="accordion-1 .item-1"
  //           aria-controls={completeID}
  //         >
  //           SUMMARY
  //         </button>
  //       </h2>
  //       <div
  //         className={"accordion-collapse collapse item-" + nameAsID}
  //         role="tabpanel"
  //         // data-bs-parent={code}
  //       >
  //         {fxdRead(fxdProfile.profiles) === null
  //           ? null
  //           : JSON.parse(fxdRead(fxdProfile.profiles)!).map(
  //               (profile: ProfileData) => {
  //                 if (profile.sP1Name === name) {
  //                   return LoadContentsOld(
  //                     fxdRead(fxdMachine.machines) === null
  //                       ? null
  //                       : JSON.parse(fxdRead(fxdMachine.machines)!).filter(
  //                           (machine: MachineData) => {
  //                             return (machine.sM1ResName as any).label === name;
  //                           }
  //                         ),
  //                     fxdRead(fxdImplement.implements) === null
  //                       ? null
  //                       : JSON.parse(fxdRead(fxdImplement.implements)!).filter(
  //                           (implement: ImplementData) => {
  //                             return (
  //                               (implement.sI1ResName as any).label === name
  //                             );
  //                           }
  //                         )
  //                   );
  //                 }
  //               }
  //             )}
  //       </div>
  //     </div>
  //   );
  // }

  function Surveyed(
    name: string,
    machines: (MachineData | null)[],
    implementss: (ImplementData | null)[]
  ) {
    // var nameAsID = "l-" + name.replaceAll(" ", "-").replaceAll(".", "-");
    // var completeID = "#accordion-1 .item-" + nameAsID;

    return (
      <div>
        {machines.length > -1 ? (
          <Accordion>
            <AccordionSummary
              // expandIcon={}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <div className="flex items-center justify-between w-full">
                <div className="flex-item flex">
                  <KeyboardArrowDownIcon />
                  <Typography>List of Machines</Typography>
                </div>
                <button
                  className="btn btn-primary add-btn flex-item w-3/10"
                  type="button"
                >
                  <Nav.Link
                    key={AvzRoute.machine}
                    as={NavLink}
                    to={AvzRoute.machine}
                    onClick={() => log(AvzRoute.machine)}
                    end
                  >
                    Add
                  </Nav.Link>
                </button>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <LoadMachines name={name} machines={machines} />
            </AccordionDetails>
          </Accordion>
        ) : null}
        {implementss.length > -1 ? (
          <Accordion>
            <AccordionSummary
              // expandIcon={}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <div className="flex items-center justify-between w-full">
                <div className="flex-item flex">
                  <KeyboardArrowDownIcon />
                  <Typography>List of Implements</Typography>
                </div>
                <button
                  className="btn btn-primary add-btn flex-item w-3/10"
                  type="button"
                >
                  <Nav.Link
                    key={AvzRoute.implement}
                    as={NavLink}
                    to={AvzRoute.implement}
                    onClick={() => log(AvzRoute.implement)}
                    end
                  >
                    Add
                  </Nav.Link>
                </button>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <LoadImplements name={name} implementss={implementss} />
            </AccordionDetails>
          </Accordion>
        ) : null}
      </div>
      // <div className="accordion-item">
      //   <h2 className="accordion-header" role="tab">
      //     <button
      //       className="accordion-button collapsed"
      //       type="button"
      //       data-bs-toggle="collapse"
      //       // data-bs-target="#accordion-1 .item-1"
      //       data-bs-target={completeID}
      //       aria-expanded="true"
      //       // aria-controls="accordion-1 .item-1"
      //       aria-controls={completeID}
      //     >
      //       SURVEYED MACHINES AND IMPLEMENTS
      //     </button>
      //   </h2>
      //   <div
      //     className={"accordion-collapse collapse show item-" + nameAsID}
      //     role="tabpanel"
      //     // data-bs-parent={code}
      //   >
      //     {fxdRead(fxdProfile.profiles) === null
      //       ? null
      //       : JSON.parse(fxdRead(fxdProfile.profiles)!).map(
      //           (profile: ProfileData) => {
      //             if (profile.sP1Name === name) {
      //               return LoadContentsNew(
      //                 name,
      //                 fxdRead(fxdMachine.machines) === null
      //                   ? null
      //                   : JSON.parse(fxdRead(fxdMachine.machines)!).filter(
      //                       (machine: MachineData) => {
      //                         return (machine.sM1ResName as any).label === name;
      //                       }
      //                     ),
      //                 fxdRead(fxdImplement.implements) === null
      //                   ? null
      //                   : JSON.parse(fxdRead(fxdImplement.implements)!).filter(
      //                       (implement: ImplementData) => {
      //                         return (
      //                           (implement.sI1ResName as any).label === name
      //                         );
      //                       }
      //                     )
      //               );
      //             }
      //           }
      //         )}
      //   </div>
      // </div>
    );
  }

  function LoadMachines(props: {
    name: string;
    machines: (MachineData | null)[];
  }) {
    return (
      <>
        {props.machines.map((machine: MachineData | null) => {
          if (machine && (machine.sM1ResName as any).label === props.name) {
            return (
              <div
                key={machine.sM1QRCode}
                className="card p-2 flex-row justify-between"
              >
                <div key={machine.sM1QRCode} className="flex-item flex-center">
                  <p style={{ fontWeight: "bold", margin: "0" }}>
                    {machine.sM1QRCode}
                  </p>
                  <p style={{ margin: "0" }}>
                    {(machine.sM1Type as any).label ?? machine.sM1Type}
                  </p>
                  <p>{new Date(Number(machine.sM0Encoded)).toDateString()}</p>
                  <p style={{ margin: "0" }} className="mt-2">
                    <span
                      className={
                        machine.sM0Status.includes(DATA_STATUS.local)
                          ? "badge bg-info"
                          : machine.sM0Status.includes(DATA_STATUS.online)
                          ? "badge bg-warning"
                          : machine.sM0Status.includes(DATA_STATUS.returned)
                          ? "badge bg-danger"
                          : "badge bg-success"
                      }
                    >
                      {cleanStatus(machine.sM0Status)}
                    </span>
                  </p>
                </div>
                <div className="flex-item flex flex-col w-2/5">
                  <Button
                    // type="button"
                    color="success"
                    variant="outlined"
                    className="w-full"
                    startIcon={<CloudUploadIcon />}
                    style={{ justifyContent: "flex-start" }}
                  >
                    Upload
                  </Button>
                  <div style={{ height: "4px" }}></div>
                  <Button
                    // type="button"
                    color="warning"
                    variant="outlined"
                    className="w-full"
                    startIcon={<AppRegistrationIcon />}
                    style={{ justifyContent: "flex-start" }}
                  >
                    Edit
                  </Button>
                  <div style={{ height: "4px" }}></div>
                  <Button
                    // type="button"
                    color="error"
                    variant="outlined"
                    className="w-full"
                    startIcon={<DeleteForeverIcon />}
                    style={{ justifyContent: "flex-start" }}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  }

  function LoadImplements(props: {
    name: string;
    implementss: (ImplementData | null)[];
  }) {
    return (
      <>
        {props.implementss.map((implement: ImplementData | null) => {
          if (implement && (implement.sI1ResName as any).label === props.name) {
            var qr =
              (implement.sI1Machine as any).label?.split(" ")[0] ??
              implement.sI1Machine.split(" ")[0];

            return (
              <div key={qr} className="card p-2 flex-row justify-between">
                <div className="flex-item flex-center">
                  <p style={{ fontWeight: "bold", margin: "0" }}>{qr}</p>
                  <p style={{ margin: "0" }}>
                    {(implement.sI2Type as any).label ?? implement.sI2Type}
                  </p>
                  <p>{new Date(Number(implement.sI0Encoded)).toDateString()}</p>
                  <p style={{ margin: "0" }} className="mt-2">
                    <span
                      className={
                        implement.sI0Status.includes(DATA_STATUS.local)
                          ? "badge bg-info"
                          : implement.sI0Status.includes(DATA_STATUS.online)
                          ? "badge bg-warning"
                          : implement.sI0Status.includes(DATA_STATUS.returned)
                          ? "badge bg-danger"
                          : "badge bg-success"
                      }
                    >
                      {cleanStatus(implement.sI0Status)}
                    </span>
                  </p>
                </div>
                <div className="flex-item flex flex-col w-2/5">
                  <Button
                    // type="button"
                    color="success"
                    variant="outlined"
                    className="w-full"
                    startIcon={<CloudUploadIcon />}
                    style={{ justifyContent: "flex-start" }}
                  >
                    Upload
                  </Button>
                  <div style={{ height: "4px" }}></div>
                  <Button
                    // type="button"
                    color="warning"
                    variant="outlined"
                    className="w-full"
                    startIcon={<AppRegistrationIcon />}
                    style={{ justifyContent: "flex-start" }}
                  >
                    Edit
                  </Button>
                  <div style={{ height: "4px" }}></div>
                  <Button
                    // type="button"
                    color="error"
                    variant="outlined"
                    className="w-full"
                    startIcon={<DeleteForeverIcon />}
                    style={{ justifyContent: "flex-start" }}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  }

  // function LoadContentsOld(
  //   machinesList: MachineData[],
  //   implementsList: ImplementData[]
  // ) {
  //   return (
  //     <div className="list">
  //       <TableContainer component={Paper}>
  //         <Table size="small" aria-label="a dense table">
  //           <TableHead>
  //             <TableRow>
  //               <TableCell style={{ fontWeight: "bold" }}>QR CODE</TableCell>
  //               <TableCell align="right" style={{ fontWeight: "bold" }}>
  //                 STATUS
  //               </TableCell>
  //             </TableRow>
  //           </TableHead>
  //           <TableBody>
  //             {machinesList === null
  //               ? null
  //               : machinesList.map((machine) => (
  //                   <TableRow
  //                     sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
  //                   >
  //                     <TableCell component="th" scope="row">
  //                       <div>
  //                         <p style={{ fontWeight: "bold", margin: "0" }}>
  //                           {machine.sM1QRCode}
  //                         </p>
  //                         <p style={{ margin: "0" }}>
  //                           {(machine.sM1Type as any).label ?? machine.sM1Type}
  //                         </p>
  //                         <p style={{ margin: "0" }}>
  //                           {(machine.sM1ResName as any).label ??
  //                             machine.sM1ResName}
  //                         </p>
  //                       </div>
  //                     </TableCell>
  //                     <TableCell align="right">
  //                       <div>
  //                         <p style={{ margin: "0" }}>
  //                           <span
  //                             className={
  //                               machine.sM0Status.includes(DATA_STATUS.local)
  //                                 ? "badge bg-info"
  //                                 : machine.sM0Status.includes(
  //                                     DATA_STATUS.online
  //                                   )
  //                                 ? "badge bg-warning"
  //                                 : machine.sM0Status.includes(
  //                                     DATA_STATUS.returned
  //                                   )
  //                                 ? "badge bg-danger"
  //                                 : "badge bg-success"
  //                             }
  //                           >
  //                             {cleanStatus(machine.sM0Status)}
  //                           </span>
  //                         </p>
  //                         <p>
  //                           {new Date(
  //                             Number(machine.sM0Encoded)
  //                           ).toDateString()}
  //                         </p>
  //                       </div>
  //                     </TableCell>
  //                   </TableRow>
  //                 ))}
  //             {implementsList === null
  //               ? null
  //               : implementsList.map((implement) => (
  //                   <TableRow
  //                     sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
  //                   >
  //                     <TableCell component="th" scope="row">
  //                       <div>
  //                         <p style={{ fontWeight: "bold", margin: "0" }}>
  //                           {implement.sI1Machine}
  //                         </p>
  //                         <p style={{ margin: "0" }}>
  //                           {(implement.sI2Type as any).label ??
  //                             implement.sI2Type}
  //                         </p>
  //                         <p style={{ margin: "0" }}>
  //                           {(implement.sI1ResName as any).label ??
  //                             implement.sI1ResName}
  //                         </p>
  //                       </div>
  //                     </TableCell>
  //                     <TableCell align="right">
  //                       <div>
  //                         <p style={{ margin: "0" }}>
  //                           <span
  //                             className={
  //                               implement.sI0Status.includes(DATA_STATUS.local)
  //                                 ? "badge bg-info"
  //                                 : implement.sI0Status.includes(
  //                                     DATA_STATUS.online
  //                                   )
  //                                 ? "badge bg-warning"
  //                                 : implement.sI0Status.includes(
  //                                     DATA_STATUS.returned
  //                                   )
  //                                 ? "badge bg-danger"
  //                                 : "badge bg-success"
  //                             }
  //                           >
  //                             {cleanStatus(implement.sI0Status)}
  //                           </span>
  //                         </p>
  //                         <p>
  //                           {new Date(
  //                             Number(implement.sI0Encoded)
  //                           ).toDateString()}
  //                         </p>
  //                       </div>
  //                     </TableCell>
  //                   </TableRow>
  //                 ))}
  //           </TableBody>
  //         </Table>
  //       </TableContainer>
  //     </div>
  //   );
  // }

  function LoadContentsNew(
    name: string,
    machinesList: MachineData[],
    implementsList: ImplementData[]
  ) {
    var dbcd = fxdRead(fxdUser.mldt) ?? "";

    return (
      <p>Hey</p>
      // <div>
      //   <Accordion>
      //     <AccordionSummary
      //       expandIcon={<KeyboardArrowDownIcon />}
      //       aria-controls="panel2-content"
      //       id="panel2-header"
      //     >
      //       <Typography>Accordion 2</Typography>
      //     </AccordionSummary>
      //     <AccordionDetails>
      //       <Typography>
      //         Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      //         Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
      //         eget.
      //       </Typography>
      //     </AccordionDetails>
      //   </Accordion>
      // </div>
      // <div className="list">
      //   <TableContainer component={Paper}>
      //     <Table size="small" aria-label="a dense table">
      //       <TableHead>
      //         <TableRow>
      //           <TableCell style={{ fontWeight: "bold" }}></TableCell>
      //           <TableCell
      //             align="right"
      //             style={{ fontWeight: "bold" }}
      //           ></TableCell>
      //         </TableRow>
      //       </TableHead>
      //       <TableBody>
      //         {/* {machinesList === null
      //           ? null
      //           : machinesList.map((machine) => (
      //               <TableRow
      //                 sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      //               >
      //                 <TableCell component="th" scope="row">
      //                   <div>
      //                     <div style={{ display: "flex", gap: "8px" }}>
      //                       <p style={{ margin: "0", fontSize: "16px" }}>
      //                         <span
      //                           className={
      //                             machine.sM0Status.includes(DATA_STATUS.local)
      //                               ? "badge bg-info"
      //                               : machine.sM0Status.includes(
      //                                   DATA_STATUS.online
      //                                 )
      //                               ? "badge bg-warning"
      //                               : machine.sM0Status.includes(
      //                                   DATA_STATUS.returned
      //                                 )
      //                               ? "badge bg-danger"
      //                               : "badge bg-success"
      //                           }
      //                         >
      //                           {cleanStatus(machine.sM0Status)}
      //                         </span>
      //                       </p>
      //                     </div>
      //                     <p
      //                       style={{
      //                         fontWeight: "bold",
      //                         margin: "0",
      //                         fontSize: "16px",
      //                       }}
      //                     >
      //                       {machine.sM1QRCode}
      //                     </p>
      //                     <p style={{ margin: "0" }}>
      //                       {(machine.sM1Type as any).label ?? machine.sM1Type}
      //                     </p>
      //                     <p style={{ margin: "0" }}>
      //                       {(machine.sM1ResName as any).label ??
      //                         machine.sM1ResName}
      //                     </p>
      //                     <p>
      //                       {new Date(
      //                         Number(machine.sM0Encoded)
      //                       ).toDateString()}
      //                     </p>
      //                   </div>
      //                 </TableCell>
      //                 <TableCell align="right">
      //                   <div>
      //                     <div
      //                       className="btn-group-vertical"
      //                       role="group"
      //                       style={{
      //                         display: "flex",
      //                         flexDirection: "column",
      //                         gap: "4px",

      //                         alignContent: "center",
      //                         alignItems: "center",
      //                       }}
      //                     >
      //                       <button
      //                         className="btn btn-success"
      //                         type="button"
      //                         style={{
      //                           width: "28px",
      //                           height: "28px",
      //                           borderRadius: "14px",
      //                           margin: "0px",
      //                           padding: "0px",
      //                         }}
      //                         onClick={async () =>
      //                           uploadMachineInd(name, machine)
      //                         }
      //                       >
      //                         <CloudUploadIcon style={{ fontSize: "18px" }} />
      //                       </button>
      //                     </div>
      //                   </div>
      //                 </TableCell>
      //               </TableRow>
      //             ))} */}
      //         {/* {implementsList === null
      //           ? null
      //           : implementsList.map((implement) => (
      //               <TableRow
      //                 sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      //               >
      //                 <TableCell component="th" scope="row">
      //                   <div>
      //                     <div style={{ display: "flex", gap: "8px" }}>
      //                       <p style={{ margin: "0", fontSize: "16px" }}>
      //                         <span
      //                           className={
      //                             implement.sI0Status.includes(
      //                               DATA_STATUS.local
      //                             )
      //                               ? "badge bg-info"
      //                               : implement.sI0Status.includes(
      //                                   DATA_STATUS.online
      //                                 )
      //                               ? "badge bg-warning"
      //                               : implement.sI0Status.includes(
      //                                   DATA_STATUS.returned
      //                                 )
      //                               ? "badge bg-danger"
      //                               : "badge bg-success"
      //                           }
      //                         >
      //                           {cleanStatus(implement.sI0Status)}
      //                         </span>
      //                       </p>
      //                     </div>
      //                     <p
      //                       style={{
      //                         fontWeight: "bold",
      //                         margin: "0",
      //                         fontSize: "16px",
      //                       }}
      //                     >
      //                       {implement.sI1Machine}
      //                     </p>
      //                     <p style={{ margin: "0" }}>
      //                       {(implement.sI2Type as any).label ??
      //                         implement.sI2Type}
      //                     </p>
      //                     <p style={{ margin: "0" }}>
      //                       {(implement.sI1ResName as any).label ??
      //                         implement.sI1ResName}
      //                     </p>
      //                     <p>
      //                       {new Date(
      //                         Number(implement.sI0Encoded)
      //                       ).toDateString()}
      //                     </p>
      //                   </div>
      //                 </TableCell>
      //                 <TableCell align="right">
      //                   <div>
      //                     <div
      //                       className="btn-group-vertical"
      //                       role="group"
      //                       style={{
      //                         display: "flex",
      //                         flexDirection: "column",
      //                         gap: "4px",

      //                         alignContent: "center",
      //                         alignItems: "center",
      //                       }}
      //                     >
      //                       <button
      //                         className="btn btn-success"
      //                         type="button"
      //                         style={{
      //                           width: "28px",
      //                           height: "28px",
      //                           borderRadius: "14px",
      //                           margin: "0px",
      //                           padding: "0px",
      //                         }}
      //                         onClick={async () =>
      //                           uploadImplementInd(name, implement)
      //                         }
      //                       >
      //                         <CloudUploadIcon style={{ fontSize: "18px" }} />
      //                       </button>
      //                     </div>
      //                   </div>
      //                 </TableCell>
      //               </TableRow>
      //             ))} */}
      //       </TableBody>
      //     </Table>
      //   </TableContainer>
      // </div>
    );
  }

  // // ///////////////////////////////////////////////////////////////////////////
  // // OPERATIONS ////////////////////////////////////////////////////////////////
  // // ///////////////////////////////////////////////////////////////////////////

  function OperationsTab(name: string) {
    var nameAsID = "o-" + name.replaceAll(" ", "-").replaceAll(".", "-");
    var completeID = "#accordion-1 .item-" + nameAsID;
    const GOTO = useNavigate();

    return (
      <div className="ope-btn-bar">
        <button
          className="btn btn-success btn-sm"
          type="button"
          disabled
          // !!! TODO
          // onClick={() => {
          //   Upload(name);
          // }}
        >
          Upload
        </button>
        {/* <button
          className="btn btn-warning btn-sm"
          type="button"
          onClick={() => {
            // From m3profiles select this profile
            if (fxdRead(stgProfile.profiles) != null) {
              var profiles = JSON.parse(
                fxdRead(stgProfile.profiles)!
              );
              for (let i = 0; i < profiles.length; i++) {
                if (profiles[i].resName === name) {
                  // Delete Profile backups
                  var backupExists = false;
                  Object.values(stgProfileBackup).forEach((val, index) => {
                    if (
                      fxdRead(val) != null &&
                      val != stgProfileBackup.resScope
                    ) {
                      backupExists = true;
                    }
                  });
                  if (backupExists) {
                    showPopup({
                      title: "Overwrite Unsaved Profile",
                      content: (
                        <>
                          <p>
                            There are unsaved backup data for profile that will
                            be deleted from your device.
                          </p>
                        </>
                      ),
                      actionLeft: {
                        text: "Cancel",
                        variant: "text",
                        color: "warning",
                      },
                      actionCenter: {
                        text: "Restore",
                        color: "warning",
                        action: () => GOTO(Route.profile),
                      },
                      actionRight: {
                        text: "Overwrite",
                        color: "error",
                        action: () => editProfile(profiles[i] as ProfileData),
                      },
                    });
                  } else {
                    editProfile(profiles[i] as ProfileData);
                  }
                }
              }
            }
          }}
        >
          Edit
        </button> */}
        <button
          className="btn btn-warning btn-sm"
          type="button"
          disabled
          // !!! TODO
          // onClick={() => {
          //   Upload(name);
          // }}
        >
          Edit
        </button>
        <button
          className="btn btn-danger  btn-sm"
          type="button"
          disabled
          // !!! TODO
          // onClick={() => {
          //   DeleteThisProfile(name);
          // }}
        >
          Delete
        </button>
      </div>
      // <div className="accordion-item">
      //   <h2 className="accordion-header" role="tab">
      //     <button
      //       className="accordion-button collapsed"
      //       type="button"
      //       data-bs-toggle="collapse"
      //       // data-bs-target="#accordion-1 .item-1"
      //       data-bs-target={completeID}
      //       aria-expanded="false"
      //       // aria-controls="accordion-1 .item-1"
      //       aria-controls={completeID}
      //     >
      //       PROFILE OPERATIONS
      //     </button>
      //   </h2>
      //   <div
      //     className={"accordion-collapse collapse item-" + nameAsID}
      //     role="tabpanel"
      //     // data-bs-parent={code}
      //   >
      //     <div className="ope-bar">
      //       <b>UPLOAD PROFILE</b>
      //       <p>
      //         {"The profile of " +
      //           name +
      //           " will be uploaded to the system including the machines and implements incorporated with it."}
      //       </p>
      //       <Button variant="contained" color="success">
      //         Upload Profile
      //       </Button>
      //       <hr />
      //       <b>EDIT PROFILE</b>
      //       <p>{"Press to edit the profile information of " + name}</p>
      //       <Button variant="contained" color="warning">
      //         Edit Profile
      //       </Button>
      //       <hr />
      //       <b>DELETE PROFILE</b>
      //       <p>
      //         {name +
      //           " including all machines and implements incorporated with it will be deleted from your device."}
      //       </p>
      //       <Button variant="contained" color="error">
      //         Delete Profile
      //       </Button>
      //     </div>
      //   </div>
      // </div>
    );
  }

  // // ///////////////////////////////////////////////////////////////////////////
  // // OPERATIONS ////////////////////////////////////////////////////////////////
  // // ///////////////////////////////////////////////////////////////////////////

  // // ///////////////////////////////////////////////////////////////////////////
  // // UPLOAD PROFILE ////////////////////////////////////////////////////////////
  // // ///////////////////////////////////////////////////////////////////////////

  // interface UploadResponse {
  //   text: string;
  //   code: number;
  // }

  // function Upload(name: string) {
  //   // Check the user credentials if it is complete
  //   var isCredentialComplete = fxdIsNull([
  //     fxdUser.name,
  //     fxdUser.offc,
  //     fxdUser.role,
  //     fxdUser.mldt,
  //   ]);

  //   if (isCredentialComplete) {
  //     Popup({
  //       type: "error",
  //       title: "Failed to Upload",
  //       text: "Missing some user credentials. Kindly re-login to upload.",
  //       showCancelButton: true,
  //       confirmButtonText: "Logout",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         AUTH_PROVIDER.signout().then(() => {
  //           window.location.reload();
  //         });
  //       }
  //     });
  //     return;
  //   }

  //   var machToUpload = enlistedMachinesFromThisProfile(name);
  //   var machiness = machToUpload == undefined ? 0 : machToUpload?.length;
  //   var m_s = machiness > 1 ? "s" : "";

  //   var implToUpload = enlistedImplementsFromThisProfile(name);
  //   var implementss = implToUpload == undefined ? 0 : implToUpload?.length;
  //   var i_s = implementss > 1 ? "s" : "";

  //   Popup({
  //     type: "success",
  //     title: "Initializing Upload",
  //     html:
  //       "<div class='text-start'>" +
  //       "<div class='text-start'>" +
  //       "<p>The profile of <b>" +
  //       name +
  //       "</b> will be uploaded. There are <b>" +
  //       machiness +
  //       " machine" +
  //       m_s +
  //       "</b> and <b>" +
  //       implementss +
  //       " implement" +
  //       i_s +
  //       "</b> that will also be uploaded into the database.</p>" +
  //       "</div>" +
  //       "</div>",
  //     confirmButtonText: "Upload",
  //     showCancelButton: true,
  //     cancelButtonText: "Cancel",
  //     allowOutsideClick: true,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       Toast({
  //         type: "success",
  //         text: "Uploading... Please wait.",
  //       });

  //       UploadAction(name, machiness, implementss);
  //     }
  //   });
  // }

  // async function UploadAction(
  //   name: string,
  //   machiness: number,
  //   implementss: number
  // ) {
  //   Promise.all([
  //     await Promise.resolve(uploadProfile(name, machiness)),
  //     await Promise.resolve(uploadMachines(name, machiness)),
  //     await Promise.resolve(uploadImplements(name, implementss)),
  //   ]).then((values) => {
  //     var conP = values[0] ? values[0].text : null;
  //     var codeP = values[0] ? values[0].code : 0;

  //     var conM = values[1] ? values[1].text : null;
  //     var codeM = values[1] ? values[1].code : 0;

  //     var conI = values[2] ? values[2].text : null;
  //     var codeI = values[2] ? values[2].code : 0;

  //     var code = codeP + codeM + codeI;

  //     Popup({
  //       type: code == 0 ? "success" : "error",
  //       title: "Upload Result",
  //       html:
  //         "<div class='text-start'>" +
  //         (conP ? "<p>" + conP + "</p>" : "") +
  //         (conM ? "<p>" + conM + "</p>" : "") +
  //         (conI ? "<p>" + conI + "</p>" : "") +
  //         "</div>",
  //       confirmButtonText: "Close",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         window.location.reload();
  //       }
  //     });
  //   });
  // }

  // // ///////////////////////////////////////////////////////////////////////////

  // async function uploadProfile(
  //   name: string,
  //   machiness: number
  // ): Promise<UploadResponse> {
  //   var response: UploadResponse = {
  //     text: "Failed to upload <b>" + name + "</b>.",
  //     code: 1,
  //   };

  //   if (machiness < 1) {
  //     response = {
  //       text:
  //         "Found no machines to upload. The profile of <b>" +
  //         name +
  //         "</b> will not be uploaded.",
  //       code: 0,
  //     };
  //   } else {
  //     const LIST_OF_PROFILES = fxdRead(fxdProfile.profiles) ?? "";
  //     var newListOfProfiles: any[] = [];
  //     var profileToUpload: ProfileData | null = null;

  //     JSON.parse(LIST_OF_PROFILES!).filter(async (profile: ProfileData) => {
  //       var curName = (profile.sP1Name as any).label ?? profile.sP1Name;
  //       if (curName !== name) {
  //         newListOfProfiles.push(profile);
  //       } else {
  //         profileToUpload = profile;
  //       }
  //     });

  //     var profileNotYetUploaded = true;
  //     var dbcd = fxdRead(fxdUser.mldt) ?? "";
  //     await axios.get(ReadProfile(dbcd, name)).then((res) => {
  //       if (res.data.length > 0) {
  //         if (
  //           res.data[0].sP0Uname.replaceAll('"', "") ===
  //           profileToUpload!.sP0Uname
  //         ) {
  //           response = {
  //             text:
  //               "Failed to upload <b>" +
  //               name +
  //               "</b>. The profile is already uploaded.",
  //             code: 0,
  //           };
  //           profileNotYetUploaded = false;

  //           var id = res.data[0].id;
  //           var profileJSON = JSON.parse(JSON.stringify(profileToUpload));
  //           profileJSON.status =
  //             res.data[0].status.replaceAll('"', "") +
  //             ". ID-" +
  //             dbcd.toUpperCase() +
  //             " " +
  //             id;

  //           newListOfProfiles.push(profileJSON);
  //           localStorage.removeItem(fxdProfile.profiles);
  //           localStorage.setItem(
  //             fxdProfile.profiles,
  //             JSON.stringify(newListOfProfiles)
  //           );
  //         }
  //       }
  //     });

  //     if (profileToUpload !== null) {
  //       // Check if to be acquired exists.
  //       // TODO Require
  //       // if (JSON.stringify(profileToUpload).includes("Data to be acquired")) {
  //       //   response = {
  //       //     text:
  //       //       "Failed to upload <b>" +
  //       //       name +
  //       //       "</b>. Some field/s needs to be acquired.",
  //       //     code: 1,
  //       //   };
  //       // } else
  //       if (profileNotYetUploaded) {
  //         await axios
  //           .post(UploadProfile(dbcd), profileToUpload as any)
  //           .then((res) => {
  //             if (res.data.insertId) {
  //               var insID = res.data.insertId;
  //               var profileJSON = JSON.parse(JSON.stringify(profileToUpload));
  //               profileJSON.status =
  //                 DATA_STATUS.online +
  //                 ". ID-" +
  //                 dbcd.toUpperCase() +
  //                 " " +
  //                 insID;

  //               newListOfProfiles.push(profileJSON);
  //               localStorage.removeItem(fxdProfile.profiles);
  //               localStorage.setItem(
  //                 fxdProfile.profiles,
  //                 JSON.stringify(newListOfProfiles)
  //               );

  //               response = {
  //                 text:
  //                   "The profile of <b>" +
  //                   name +
  //                   "</b> has been successfully uploaded to the system.",
  //                 code: 0,
  //               };
  //             } else {
  //               response = {
  //                 text: "Failed to upload <b>" + name + "</b>",
  //                 code: 1,
  //               };
  //             }
  //           })
  //           .catch((reason) => {
  //             response = {
  //               text: reason,
  //               code: 1,
  //             };
  //           });
  //       }
  //     }
  //   }
  //   return response;
  // }

  // async function uploadMachines(
  //   name: string,
  //   machiness: number
  // ): Promise<UploadResponse> {
  //   // Default response
  //   var response: UploadResponse = {
  //     text: "Failed to upload all machines.",
  //     code: 1,
  //   };

  //   if (machiness < 1) {
  //     response = {
  //       text: "Found no machines to upload.",
  //       code: 0,
  //     };
  //   }

  //   // Check if profile is uploaded
  //   var profileNotUploaded = true;
  //   var dbcd = fxdRead(fxdUser.mldt) ?? "";
  //   await axios.get(ReadProfile(dbcd, name)).then((res) => {
  //     if (res.data.length < 1) {
  //       response = {
  //         text:
  //           "<b>Failed to upload all machines</b>. The profile must be uploaded first." +
  //           "<i>The error of uploading the profile might be the cause of this error. Retry uploading to fix this issue.</i>",
  //         code: 1,
  //       };
  //       profileNotUploaded = false;
  //     }
  //   });

  //   if (profileNotUploaded) {
  //     response = await processMachines(name, dbcd);
  //   }

  //   return response;
  // }

  // async function processMachines(name: string, dbcd: string) {
  //   const ALLMACHINES = fxdRead(fxdMachine.machines) ?? "";
  //   var newListMac: any[] = [];
  //   var results: { qr: string; status: string }[] = [];
  //   var errCount = 0;
  //   var allMachines = JSON.parse(ALLMACHINES!);

  //   for (let i = 0; i < allMachines.length; i++) {
  //     var resName =
  //       (allMachines[i].sM1ResName as any).label ?? allMachines[i].sM1ResName;

  //     if (resName !== name) {
  //       newListMac.push(allMachines[i]);
  //     } else {
  //       await axios
  //         .get(ReadMachine(dbcd, allMachines[i].qrCode))
  //         .then(async (res) => {
  //           if (res.data.length > 0) {
  //             results.push({
  //               qr: allMachines[i].qrCode,
  //               status: "Already Uploaded",
  //             });

  //             var id = res.data[0].id;
  //             var machineJSON = JSON.parse(JSON.stringify(allMachines[i]));
  //             machineJSON.status =
  //               res.data[0].status + ". ID-" + dbcd.toUpperCase() + " " + id;

  //             newListMac.push(machineJSON);
  //           } else {
  //             var newMachine = allMachines[i] as any;
  //             newMachine.classv = allMachines[i].class;
  //             newMachine.yieldv = allMachines[i].yield;

  //             var front = newMachine.imageFront;
  //             var side = newMachine.imageSide;
  //             var plate = newMachine.imagePlate;

  //             newMachine.imageFront = "";
  //             newMachine.imageSide = "";
  //             newMachine.imagePlate = "";

  //             // Check if to be acquired exists.
  //             // TODO Require
  //             // if (JSON.stringify(newMachine).includes("Data to be acquired")) {
  //             //   newMachine.status = DATA_STATUS.local + " - Incomplete Data";
  //             //   newListMac.push(newMachine);
  //             //   results.push({
  //             //     qr: newMachine.qrCode,
  //             //     status: "Incomplete Data",
  //             //   });
  //             //   errCount++;
  //             // } else {
  //             await axios
  //               .post(UploadMachine(dbcd), newMachine as any)
  //               .then((res) => {
  //                 if (res.data.insertId) {
  //                   const ID = res.data.insertId;
  //                   var insID = res.data.insertId;
  //                   var machineJSON = JSON.parse(
  //                     JSON.stringify(allMachines[i])
  //                   );
  //                   machineJSON.status =
  //                     DATA_STATUS.online +
  //                     ". ID-" +
  //                     dbcd.toUpperCase() +
  //                     " " +
  //                     insID;

  //                   machineJSON.imageFront = front;
  //                   machineJSON.imageSide = side;
  //                   machineJSON.imagePlate = plate;

  //                   newListMac.push(machineJSON);

  //                   axios.patch(UpdateMachineImage(dbcd, "id", ID), {
  //                     imageFront: front,
  //                   });

  //                   axios.patch(UpdateMachineImage(dbcd, "id", ID), {
  //                     imageSide: side,
  //                   });

  //                   axios.patch(UpdateMachineImage(dbcd, "id", ID), {
  //                     imagePlate: plate,
  //                   });

  //                   results.push({
  //                     qr: allMachines[i].sM1QRCode,
  //                     status: "Successfully Uploaded",
  //                   });
  //                 } else {
  //                   results.push({
  //                     qr: allMachines[i].sM1QRCode,
  //                     status: "Failed to Upload",
  //                   });
  //                   errCount++;
  //                 }
  //               });
  //             // }
  //           }
  //         });
  //     }
  //   }

  //   localStorage.removeItem(fxdMachine.machines);
  //   localStorage.setItem(fxdMachine.machines, JSON.stringify(newListMac));

  //   // var txt = results.map((item) => item.qr + " - " + item.status);

  //   var txt = "";
  //   for (let i = 0; i < results.length; i++) {
  //     txt += "<b>" + results[i].qr + "</b> - " + results[i].status;
  //     if (i < results.length - 1) {
  //       txt += "<br>";
  //     }
  //   }

  //   return {
  //     content: (
  //       <>
  //         {results.map((item) => (
  //           <p>
  //             {item.qr} - {item.status}
  //           </p>
  //         ))}
  //       </>
  //     ),
  //     text: txt,
  //     code: errCount,
  //   };
  // }

  // async function uploadImplements(
  //   name: string,
  //   implementss: number
  // ): Promise<UploadResponse> {
  //   // Default response
  //   var response: UploadResponse = {
  //     text: "Failed to upload all implements.",
  //     code: 1,
  //   };

  //   if (implementss < 1) {
  //     response = {
  //       text: "Found no implement to upload.",
  //       code: 0,
  //     };
  //   }

  //   // Check if profile is uploaded
  //   var profileNotUploaded = true;
  //   var dbcd = fxdRead(fxdUser.mldt) ?? "";
  //   await axios.get(ReadProfile(dbcd, name)).then((res) => {
  //     if (res.data.length < 1) {
  //       response = {
  //         text:
  //           "<b>Failed to upload all implements</b>. The profile and machine must be uploaded first." +
  //           "<i>The error of uploading the profile or machine might be the cause of this error. Retry uploading to fix this issue.</i>",
  //         code: 1,
  //       };
  //       profileNotUploaded = false;
  //     }
  //   });

  //   if (profileNotUploaded) {
  //     response = await processImplements(name, dbcd);
  //   }

  //   return response;
  // }

  // async function processImplements(name: string, dbcd: string) {
  //   const ALLIMPLEMENTS = fxdRead(fxdImplement.implements) ?? "";
  //   var newListOfImp: any[] = [];
  //   var results: { qr: string; status: string }[] = [];
  //   var errCount = 0;
  //   var allImplements = JSON.parse(ALLIMPLEMENTS!);

  //   for (let i = 0; i < allImplements.length; i++) {
  //     var resName =
  //       (allImplements[i].sI1ResName as any).label ??
  //       allImplements[i].sI1ResName;

  //     if (resName !== name) {
  //       newListOfImp.push(allImplements[i]);
  //     } else {
  //       await axios
  //         .get(ReadImplement(dbcd, allImplements[i].qrCode))
  //         .then(async (res) => {
  //           if (res.data.length > 0) {
  //             results.push({
  //               qr: allImplements[i].qrCode,
  //               status: "Already Uploaded",
  //             });

  //             var id = res.data[0].id;
  //             var implementJSON = JSON.parse(JSON.stringify(allImplements[i]));
  //             implementJSON.status =
  //               res.data[0].status + ". ID-" + dbcd.toUpperCase() + " " + id;

  //             newListOfImp.push(implementJSON);
  //           } else {
  //             var newImplement = allImplements[i] as any;
  //             newImplement.classv = allImplements[i].class; // TODO Seems not needed
  //             newImplement.yieldv = allImplements[i].yield; // TODO Seems not needed

  //             var vfront = newImplement.front;
  //             var vside = newImplement.side;
  //             var vplate = newImplement.plate;

  //             newImplement.front = "";
  //             newImplement.side = "";
  //             newImplement.plate = "";

  //             // Check if to be acquired exists.
  //             // TODO Require
  //             // if (
  //             //   JSON.stringify(newImplement).includes("Data to be acquired")
  //             // ) {
  //             //   newImplement.status = DATA_STATUS.local + " - Incomplete Data";
  //             //   newListOfImp.push(newImplement);
  //             //   results.push({
  //             //     qr: newImplement.qrCode,
  //             //     status: "Incomplete Data",
  //             //   });
  //             //   errCount++;
  //             // } else {
  //             await axios
  //               .post(UploadImplement(dbcd), newImplement as any)
  //               .then((res) => {
  //                 if (res.data.insertId) {
  //                   const ID = res.data.insertId;

  //                   var insID = res.data.insertId;
  //                   var implementJSON = JSON.parse(
  //                     JSON.stringify(allImplements[i])
  //                   );
  //                   implementJSON.status =
  //                     DATA_STATUS.online +
  //                     ". ID-" +
  //                     dbcd.toUpperCase() +
  //                     " " +
  //                     insID;

  //                   implementJSON.front = vfront;
  //                   implementJSON.side = vside;
  //                   implementJSON.plate = vplate;

  //                   newListOfImp.push(implementJSON);

  //                   axios.patch(UpdateImplementImage(dbcd, "id", ID), {
  //                     front: vfront,
  //                   });

  //                   axios.patch(UpdateImplementImage(dbcd, "id", ID), {
  //                     side: vside,
  //                   });

  //                   axios.patch(UpdateImplementImage(dbcd, "id", ID), {
  //                     plate: vplate,
  //                   });

  //                   results.push({
  //                     qr: allImplements[i].sI1QRCode,
  //                     status: "Successfully Uploaded",
  //                   });
  //                 } else {
  //                   results.push({
  //                     qr: allImplements[i].sI1QRCode,
  //                     status: "Failed to Upload",
  //                   });
  //                   errCount++;
  //                 }
  //               });
  //             // }
  //           }
  //         });
  //     }
  //   }

  //   localStorage.removeItem(fxdImplement.implements);
  //   localStorage.setItem(fxdImplement.implements, JSON.stringify(newListOfImp));

  //   // var txt = results.map((item) => item.qr + " - " + item.status);
  //   var txt = "";
  //   for (var i = 0; i < results.length; i++) {
  //     txt += "<b>" + results[i].qr + "</b> - " + results[i].status;
  //     if (i < results.length - 1) {
  //       txt += "<br>";
  //     }
  //   }

  //   return {
  //     content: (
  //       <>
  //         {results.map((item) => (
  //           <p>
  //             {item.qr} - {item.status}
  //           </p>
  //         ))}
  //       </>
  //     ),
  //     text: txt,
  //     code: errCount,
  //   };
  // }

  // // /////////////////////////////////////////////////////////////////////////
  // // EDIT FUNCTION ///////////////////////////////////////////////////////////
  // // /////////////////////////////////////////////////////////////////////////

  // function editProfile(data: ProfileData) {
  //   // const BK = stgProfileBackup;
  //   // s(BK.resName, r(data.resName));
  //   // s(BK.resAge, r(data.resAge));
  //   // s(BK.resSex, r(data.resSex));
  //   // s(BK.resEduc, r(data.resEduc));
  //   // s(BK.resCont1, r(data.resCont1));
  //   // s(BK.resCont2, r(data.resCont2));
  //   // s(BK.resProv, r(data.resProv));
  //   // s(BK.resMun, r(data.resMun));
  //   // s(BK.resBrgy, r(data.resBrgy));
  //   // s(BK.resProf, r(data.resProf));
  //   // s(BK.resScope, r(data.resScope));
  //   // s(BK.resPos, r(data.resPos));
  //   // s(BK.owName, r(data.owName));
  //   // s(BK.owClass, r(data.owClass));
  //   // s(BK.owCont1, r(data.owCont1));
  //   // s(BK.owCont2, r(data.owCont2));
  //   // s(BK.owProv, r(data.owProv));
  //   // s(BK.owMun, r(data.owMun));
  //   // s(BK.owBrgy, r(data.owBrgy));
  //   // s(BK.accre, r(data.accre));
  //   // s(BK.area, r(data.area));
  //   // s(BK.mach, r(data.mach));
  //   // s(BK.impl, r(data.impl));
  //   // s(BK.mngt, r(data.mngt));
  //   // s(BK.varPltd, r(data.varPltd));
  //   // s(BK.varPref, r(data.varPref));
  //   // s(BK.strPltng, r(data.startPltng));
  //   // s(BK.endPltng, r(data.endPltng));
  //   // s(BK.startHarv, r(data.startHarv));
  //   // s(BK.endHarv, r(data.endHarv));
  //   // s(BK.tPltng, r(data.ttlPltng));
  //   // s(BK.tHarv, r(data.ttlHarv));
  //   // s(BK.avgYld, r(data.avgYld));
  //   // s(BK.moRato, r(data.moRato));
  //   // s(BK.avgRato, r(data.avgRato));
  //   // s(BK.thresh, r(data.thresh));
  //   // s(BK.sgrMill, r(data.sgrMill));
  //   // s(BK.notes, r(data.notes));
  //   // localStorage.setItem(stgProfile.edit, "true");
  //   // GOTO(Route.profile);
  // }

  // // r for Read
  // function r(data: any) {
  //   return data.label ?? data;
  // }

  // function s(des: string, data: any) {
  //   localStorage.setItem(des, data);
  //   if (data == "") localStorage.removeItem(des);
  // }
  // // }

  // // ///////////////////////////////////////////////////////////////////////////
  // // DELETE FUNCTION ///////////////////////////////////////////////////////////
  // // ///////////////////////////////////////////////////////////////////////////

  // function DeleteThisProfile(name: string) {
  //   var mach = enlistedMachinesFromThisProfile(name);
  //   var machiness = mach == undefined ? 0 : mach?.length;
  //   var m_s = machiness > 1 ? "s" : "";

  //   var impl = enlistedImplementsFromThisProfile(name);
  //   var implementss = impl == undefined ? 0 : impl?.length;
  //   var i_s = implementss > 1 ? "s" : "";

  //   Popup({
  //     type: "error",
  //     title: "Delete Profile from Local Storage",
  //     html:
  //       "<p>Are you sure you want to delete the profile of <b>" +
  //       name +
  //       "</b>? This action cannot be undone. There are <b>" +
  //       machiness +
  //       " machine" +
  //       m_s +
  //       "</b> and <b>" +
  //       implementss +
  //       " implement" +
  //       i_s +
  //       "</b> that will <b>PERMANENTLY</b> be deleted in this device.</p>",
  //     confirmButtonText: "Delete",
  //     showCancelButton: true,
  //     cancelButtonText: "Cancel",
  //     allowOutsideClick: true,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       deleteProfile(name, machiness, implementss);
  //     }
  //   });
  // }

  // function deleteProfile(name: string, mach: number, impl: number) {
  //   if (mach > 0) {
  //     const LIST_OF_MACHINES = fxdRead(fxdMachine.machines);
  //     var newListOfMachine: any[] = [];
  //     JSON.parse(LIST_OF_MACHINES!).filter((machine: MachineData) => {
  //       if ((machine.sM1ResName as any).label !== name) {
  //         newListOfMachine.push(machine);
  //       }
  //     });
  //     if (newListOfMachine.length > 0) {
  //       localStorage.setItem(
  //         fxdMachine.machines,
  //         JSON.stringify(newListOfMachine)
  //       );
  //     } else {
  //       localStorage.removeItem(fxdMachine.machines);
  //     }
  //   }

  //   if (impl > 0) {
  //     const LIST_OF_IMPLEMENTS = fxdRead(fxdImplement.implements);
  //     var newListOfImplement: any[] = [];
  //     JSON.parse(LIST_OF_IMPLEMENTS!).filter((implement: ImplementData) => {
  //       if ((implement.sI1ResName as any).label !== name) {
  //         newListOfImplement.push(implement);
  //       }
  //     });
  //     if (newListOfImplement.length > 0) {
  //       localStorage.setItem(
  //         fxdImplement.implements,
  //         JSON.stringify(newListOfImplement)
  //       );
  //     } else {
  //       localStorage.removeItem(fxdImplement.implements);
  //     }
  //   }

  //   const LIST_OF_PROFILES = fxdRead(fxdProfile.profiles);
  //   var newListOfProfiles: any[] = [];
  //   JSON.parse(LIST_OF_PROFILES!).filter((profile: ProfileData) => {
  //     if (profile.sP1Name !== name) {
  //       newListOfProfiles.push(profile);
  //     }
  //   });
  //   if (newListOfProfiles.length > 0) {
  //     localStorage.setItem(
  //       fxdProfile.profiles,
  //       JSON.stringify(newListOfProfiles)
  //     );
  //   } else {
  //     localStorage.removeItem(fxdProfile.profiles);
  //   }
  //   window.location.reload();
  // }
}

function cleanStatus(status: string) {
  return status.includes(DATA_STATUS.local)
    ? DATA_STATUS.local
    : status.includes(DATA_STATUS.online)
    ? DATA_STATUS.online
    : status.includes(DATA_STATUS.returned)
    ? DATA_STATUS.returned
    : status.includes(DATA_STATUS.verified)
    ? DATA_STATUS.verified
    : "Error";
}

// // ///////////////////////////////////////////////////////////////////////////

// async function uploadMachineInd(name: string, machine: MachineData) {
//   Toast({
//     type: "success",
//     text: "Uploading Machine...",
//   });

//   var dbcd = fxdRead(fxdUser.mldt) ?? "";

//   await axios.get(ReadProfile(dbcd, name)).then(async (res) => {
//     if (res.data.length < 1) {
//       Popup({
//         icon: "error",
//         type: "error",
//         text:
//           "Failed to upload " +
//           machine.sM1QRCode +
//           " machine. The profile must be uploaded first.",
//       });
//     } else {
//       // Continue the upload of machine.

//       await axios
//         .get(ReadMachine(dbcd, machine.sM1QRCode))
//         .then(async (res) => {
//           if (res.data.length > 0) {
//             Popup({
//               icon: "warning",
//               type: "warning",
//               text:
//                 "Failed to upload " +
//                 machine.sM1QRCode +
//                 " machine. The machine is already uploaded.",
//             });
//           } else {
//             var newMachine = machine as any;
//             // Rev5 newMachine.classv = machine.class;
//             newMachine.yieldv = machine.sM2SgrcnYld;

//             var front = newMachine.imageFront;
//             var side = newMachine.imageSide;
//             var plate = newMachine.imagePlate;

//             newMachine.imageFront = "";
//             newMachine.imageSide = "";
//             newMachine.imagePlate = "";

//             // Check if to be acquired exists.
//             // TODO Require
//             // if (
//             //   JSON.stringify(
//             //     newMachine
//             //   ).includes("Data to be acquired")
//             // ) {
//             //   Toast({
//             //     type: "error",
//             //     text:
//             //       "Failed to upload " +
//             //       machine.qrCode +
//             //       " machine. Incomplete Data",
//             //   });
//             // } else {

//             await axios
//               .post(UploadMachine(dbcd), newMachine as any)
//               .then((res) => {
//                 if (res.data.insertId) {
//                   const ID = res.data.insertId;
//                   var insID = res.data.insertId;

//                   // Added
//                   const ALLMACHINES = fxdRead(fxdMachine.machines) ?? "";
//                   var allMachines = JSON.parse(ALLMACHINES!);
//                   var newListMac: any[] = [];

//                   var machineJSON = JSON.parse(JSON.stringify(machine));
//                   machineJSON.status =
//                     DATA_STATUS.online +
//                     ". ID-" +
//                     dbcd.toUpperCase() +
//                     " " +
//                     insID;

//                   machineJSON.imageFront = front;
//                   machineJSON.imageSide = side;
//                   machineJSON.imagePlate = plate;

//                   for (var m of allMachines) {
//                     if (m.qrCode === machine.sM1QRCode) {
//                       newListMac.push(machineJSON);
//                     } else {
//                       newListMac.push(m);
//                     }
//                   }

//                   localStorage.removeItem(fxdMachine.machines);
//                   localStorage.setItem(
//                     fxdMachine.machines,
//                     JSON.stringify(newListMac)
//                   );

//                   axios.patch(UpdateMachineImage(dbcd, "id", ID), {
//                     imageFront: front,
//                   });

//                   axios.patch(UpdateMachineImage(dbcd, "id", ID), {
//                     imageSide: side,
//                   });

//                   axios.patch(UpdateMachineImage(dbcd, "id", ID), {
//                     imagePlate: plate,
//                   });

//                   Popup({
//                     icon: "success",
//                     type: "success",
//                     text:
//                       "Successfully Uploaded " + machine.sM1QRCode + " Machine",
//                   }).then((result) => {
//                     if (result.isConfirmed) {
//                       window.location.reload();
//                     }
//                   });
//                 } else {
//                   Popup({
//                     icon: "error",
//                     type: "error",
//                     text: "Failed to Upload " + machine.sM1QRCode + " Machine",
//                   });
//                 }
//               });
//             // }
//           }
//         });
//     }
//   });
// }

// async function uploadImplementInd(name: string, implement: ImplementData) {
//   Toast({
//     type: "success",
//     text: "Uploading Implement...",
//   });

//   var dbcd = fxdRead(fxdUser.mldt) ?? "";

//   await axios.get(ReadProfile(dbcd, name)).then(async (res) => {
//     if (res.data.length < 1) {
//       Popup({
//         icon: "error",
//         type: "error",
//         text:
//           "Failed to upload " +
//           implement.sI1Machine +
//           " implement. The profile must be uploaded first.",
//       });
//     } else {
//       var machineQR = String((implement.sI1QRCode as any).label).split(" ")[0];

//       console.log(machineQR);

//       await axios.get(ReadMachine(dbcd, machineQR)).then(async (res) => {
//         if (res.data.length < 1) {
//           Popup({
//             icon: "error",
//             type: "error",
//             text:
//               "Failed to upload " +
//               implement.sI1Machine +
//               " implement. The machine with QR code " +
//               machineQR +
//               " must be uploaded first.",
//           });
//         } else {
//           // Continue the upload of implement.

//           await axios
//             .get(ReadImplement(dbcd, implement.sI1Machine))
//             .then(async (res) => {
//               if (res.data.length > 0) {
//                 Popup({
//                   icon: "warning",
//                   type: "warning",
//                   text:
//                     "Failed to upload " +
//                     implement.sI1Machine +
//                     " implement. The implement is already uploaded.",
//                 });
//               } else {
//                 var newImplement = implement as any;
//                 // TODO Seems not needed
//                 // newImplement.classv =
//                 //   implement.class;
//                 // newImplement.yieldv =
//                 //   implement.yield;

//                 var vfront = newImplement.front;
//                 var vside = newImplement.side;
//                 var vplate = newImplement.plate;

//                 newImplement.front = "";
//                 newImplement.side = "";
//                 newImplement.plate = "";

//                 // Check if to be acquired exists.
//                 // TODO Require
//                 // if (
//                 //   JSON.stringify(
//                 //     newImplement
//                 //   ).includes(
//                 //     "Data to be acquired"
//                 //   )
//                 // ) {
//                 //   Toast({
//                 //     type: "error",
//                 //     text:
//                 //       "Failed to upload " +
//                 //       implement.qrCode +
//                 //       " implement. Incomplete Data",
//                 //   });
//                 // } else {
//                 await axios
//                   .post(UploadImplement(dbcd), newImplement as any)
//                   .then((res) => {
//                     if (res.data.insertId) {
//                       const ID = res.data.insertId;
//                       var insID = res.data.insertId;

//                       // Added
//                       const ALLIMPLEMENTS =
//                         fxdRead(fxdImplement.implements) ?? "";
//                       var allImplements = JSON.parse(ALLIMPLEMENTS!);
//                       var newListImp: any[] = [];

//                       var implementJSON = JSON.parse(JSON.stringify(implement));
//                       implementJSON.status =
//                         DATA_STATUS.online +
//                         ". ID-" +
//                         dbcd.toUpperCase() +
//                         " " +
//                         insID;

//                       implementJSON.front = vfront;
//                       implementJSON.side = vside;
//                       implementJSON.plate = vplate;

//                       for (var i of allImplements) {
//                         if (i.qrCode === implement.sI1Machine) {
//                           newListImp.push(implementJSON);
//                         } else {
//                           newListImp.push(i);
//                         }
//                       }

//                       localStorage.removeItem(fxdImplement.implements);
//                       localStorage.setItem(
//                         fxdImplement.implements,
//                         JSON.stringify(newListImp)
//                       );

//                       axios.patch(UpdateImplementImage(dbcd, "id", ID), {
//                         front: vfront,
//                       });

//                       axios.patch(UpdateImplementImage(dbcd, "id", ID), {
//                         side: vside,
//                       });

//                       axios.patch(UpdateImplementImage(dbcd, "id", ID), {
//                         plate: vplate,
//                       });

//                       Popup({
//                         icon: "success",
//                         type: "success",
//                         text:
//                           "Successfully Uploaded " +
//                           implement.sI1Machine +
//                           " Implement",
//                       }).then((result) => {
//                         if (result.isConfirmed) {
//                           window.location.reload();
//                         }
//                       });
//                     } else {
//                       Popup({
//                         icon: "error",
//                         type: "error",
//                         text:
//                           "Failed to Upload " +
//                           implement.sI1Machine +
//                           " Implement",
//                       });
//                     }
//                   });
//                 // }
//               }
//             });
//         }
//       });
//     }
//   });
// }

// // ///////////////////////////////////////////////////////////////////////////
// // MACHINE TAB ///////////////////////////////////////////////////////////////
// // ///////////////////////////////////////////////////////////////////////////

// function MachineTab(name: string) {
//   const [iMachine, setIMachine] = useState("");

//   useEffect(() => {
//     var macQR = (iMachine && (iMachine as any).label) ?? "";

//     if (macQR === "") {
//       setVContent(false);
//       return;
//     }

//     JSON.parse(fxdRead(fxdMachine.machines)!).map((machine: MachineData) => {
//       if (machine.sM1QRCode === macQR) {
//         var resName = (machine.sM1ResName as any).label ?? machine.sM1ResName;
//         var status = (machine.sM0Status as any).label ?? machine.sM0Status;
//         setIStatus(cleanStatus(status));
//         setIMachineCode((machine.sM1QRCode as any).label ?? machine.sM1QRCode);
//         setIRespondentName(resName);

//         JSON.parse(fxdRead(fxdProfile.profiles)!).map(
//           (profile: ProfileData) => {
//             if (profile.sP1Name === resName) {
//               setIRespondentRole(
//                 (profile.sP2Profile as any).label ?? profile.sP2Profile
//               );
//             }
//           }
//         );

//         setIDateInterviewed(
//           new Date(
//             Number((machine.sM0Encoded as any).label ?? machine.sM0Encoded)
//           ).toDateString()
//         );
//         setVContent(true);
//       }
//     });
//     setVContent(true);
//   }, [iMachine]);

//   let oMachines: { label: string }[] = [];
//   JSON.parse(fxdRead(fxdMachine.machines)!).map((element: MachineData) => {
//     if ((element.sM1ResName as any).label === name) {
//       oMachines.push({
//         label: element.sM1QRCode,
//       });
//     }
//   });

//   const [vContent, setVContent] = useState(false);
//   const [iStatus, setIStatus] = useState("-");
//   const [iMachineCode, setIMachineCode] = useState("-");
//   const [iRespondentName, setIRespondentName] = useState("-");
//   const [iRespondentRole, setIRespondentRole] = useState("-");
//   const [iDateInterviewed, setIDateInterviewed] = useState("-");

//   const [vFeatureEditMachine, setVFeatureEditMachine] = useState(false);

//   var nameAsID = "m-" + name.replaceAll(" ", "-").replaceAll(".", "-");
//   var completeID = "#accordion-1" + " .item-" + nameAsID;

//   return (
//     <div className="accordion-item">
//       <h2 className="accordion-header" role="tab">
//         <button
//           className="accordion-button collapsed"
//           type="button"
//           data-bs-toggle="collapse"
//           // data-bs-target="#accordion-1 .item-1"
//           data-bs-target={completeID}
//           aria-expanded="false"
//           // aria-controls="accordion-1 .item-1"
//           aria-controls={completeID}
//         >
//           LIST OF MACHINES
//         </button>
//       </h2>
//       <div
//         className={"accordion-collapse collapse item-" + nameAsID}
//         role="tabpanel"
//         // data-bs-parent={code}
//       >
//         <div className="machine-list">
//           <Pick
//             text={{
//               label: "List of machines",
//               placeholder: "Select a machine",
//             }}
//             options={oMachines}
//             value={[iMachine, setIMachine]}
//             width={100}
//             // Validation and backup are not needed
//             validation={null}
//             backup=""
//           />
//         </div>

//         <VBar in={vContent}>
//           <div className="machine-content">
//             <div className="text-row">
//               <p className="category">Machine Code</p>
//               <p className="value">{iMachineCode}</p>
//             </div>
//             <div className="text-row">
//               <p className="category">Respondent Name</p>
//               <p className="value">{iRespondentName}</p>
//             </div>
//             <div className="text-row">
//               <p className="category">Respondent Role</p>
//               <p className="value">{iRespondentRole}</p>
//             </div>
//             <div className="text-row">
//               <p className="category">Date Interviewed</p>
//               <p className="value">{iDateInterviewed}</p>
//             </div>
//             <div className="text-row">
//               <p className="category">Status</p>
//               <span
//                 className={
//                   iStatus.includes(DATA_STATUS.local)
//                     ? "badge bg-info"
//                     : iStatus.includes(DATA_STATUS.online)
//                     ? "badge bg-warning"
//                     : iStatus.includes(DATA_STATUS.returned)
//                     ? "badge bg-danger"
//                     : "badge bg-success"
//                 }
//               >
//                 {iStatus}
//               </span>
//             </div>
//             {/* <HBar in={vFeatureEditMachine}>
//                 <Alert
//                   severity="warning"
//                   onClose={() => {
//                     setVFeatureEditMachine(false);
//                   }}
//                   sx={{ width: "100%" }}
//                 >
//                   On going feature.
//                 </Alert>
//               </HBar>
//               <button
//                 className="btn btn-primary btn-edit"
//                 type="button"
//                 onClick={() => {
//                   setVFeatureEditMachine(true);
//                 }}
//               >
//                 Edit
//               </button> */}
//           </div>
//         </VBar>
//       </div>
//       {/* <div className="content-holder">
//         {fxdRead(stgMachine.machines) === null
//           ? null
//           : JSON.parse(fxdRead(stgMachine.machines)!).map(
//               (element: MachineData) => {
//                 if (element.resName === name) {
//                   return Machine(element.qrCode);
//                 }
//               }
//             )}
//       </div> */}
//     </div>
//   );
// }

// // ///////////////////////////////////////////////////////////////////////////
// // IMPLEMENT TAB /////////////////////////////////////////////////////////////
// // ///////////////////////////////////////////////////////////////////////////

// function ImplementTab(name: string) {
//   const [iImplement, setIImplement] = useState("");

//   useEffect(() => {
//     var impQR = (iImplement && (iImplement as any).label) ?? "";

//     if (impQR === "") {
//       setVContent(false);
//       return;
//     }

//     JSON.parse(fxdRead(fxdImplement.implements)!).map(
//       (implement: ImplementData) => {
//         if (implement.sI1Machine === impQR) {
//           var resName =
//             (implement.sI1ResName as any).label ?? implement.sI1ResName;
//           var status =
//             (implement.sI0Status as any).label ?? implement.sI0Status;
//           setIStatus(cleanStatus(status));
//           setIImplementCode(
//             (implement.sI1Machine as any).label ?? implement.sI1Machine
//           );
//           setIRespondentName(resName);

//           JSON.parse(fxdRead(fxdProfile.profiles)!).map(
//             (profile: ProfileData) => {
//               if (profile.sP1Name === resName) {
//                 setIRespondentRole(
//                   (profile.sP2Profile as any).label ?? profile.sP2Profile
//                 );
//               }
//             }
//           );
//           setIDateInterviewed(
//             new Date(
//               Number((implement.sI0Status as any).label ?? implement.sI0Status)
//             ).toDateString()
//           );
//           setVContent(true);
//         }
//       }
//     );
//     setVContent(true);
//   }, [iImplement]);

//   let oImplements: { label: string }[] = [];
//   JSON.parse(fxdRead(fxdImplement.implements)!).map(
//     (element: ImplementData) => {
//       if ((element.sI1ResName as any).label === name) {
//         oImplements.push({
//           label: element.sI1Machine,
//         });
//       }
//     }
//   );

//   const [vContent, setVContent] = useState(false);
//   const [iStatus, setIStatus] = useState("-");
//   const [iImplementCode, setIImplementCode] = useState("-");
//   const [iRespondentName, setIRespondentName] = useState("-");
//   const [iRespondentRole, setIRespondentRole] = useState("-");
//   const [iDateInterviewed, setIDateInterviewed] = useState("-");

//   const [vFeatureEditMachine, setVFeatureEditMachine] = useState(false);

//   var nameAsID = "i-" + name.replaceAll(" ", "-").replaceAll(".", "-");
//   var completeID = "#accordion-1" + " .item-" + nameAsID;

//   return (
//     <div className="accordion-item">
//       <h2 className="accordion-header" role="tab">
//         <button
//           className="accordion-button collapsed"
//           type="button"
//           data-bs-toggle="collapse"
//           // data-bs-target="#accordion-1 .item-1"
//           data-bs-target={completeID}
//           aria-expanded="false"
//           // aria-controls="accordion-1 .item-1"
//           aria-controls={completeID}
//         >
//           LIST OF IMPLEMENTS
//         </button>
//       </h2>
//       <div
//         className={"accordion-collapse collapse item-" + nameAsID}
//         role="tabpanel"
//         // data-bs-parent={code}
//       >
//         <div className="machine-list">
//           <Pick
//             text={{
//               label: "List of implements",
//               placeholder: "Select an implement",
//             }}
//             options={oImplements}
//             value={[iImplement, setIImplement]}
//             width={100}
//             // Validation and backup are not needed
//             validation={null}
//             backup=""
//           />
//         </div>

//         <VBar in={vContent}>
//           <div className="machine-content">
//             <div className="text-row">
//               <p className="category">Implement Code</p>
//               <p className="value">{iImplementCode}</p>
//             </div>
//             <div className="text-row">
//               <p className="category">Respondent Name</p>
//               <p className="value">{iRespondentName}</p>
//             </div>
//             <div className="text-row">
//               <p className="category">Respondent Role</p>
//               <p className="value">{iRespondentRole}</p>
//             </div>
//             <div className="text-row">
//               <p className="category">Date Interviewed</p>
//               <p className="value">{iDateInterviewed}</p>
//             </div>
//             <div className="text-row">
//               <p className="category">Status</p>
//               <span
//                 className={
//                   iStatus.includes(DATA_STATUS.local)
//                     ? "badge bg-info"
//                     : iStatus.includes(DATA_STATUS.online)
//                     ? "badge bg-warning"
//                     : iStatus.includes(DATA_STATUS.returned)
//                     ? "badge bg-danger"
//                     : "badge bg-success"
//                 }
//               >
//                 {iStatus}
//               </span>
//             </div>
//             {/* <HBar in={vFeatureEditMachine}>
//                 <Alert
//                   severity="warning"
//                   onClose={() => {
//                     setVFeatureEditMachine(false);
//                   }}
//                   sx={{ width: "100%" }}
//                 >
//                   On going feature.
//                 </Alert>
//               </HBar>
//               <button
//                 className="btn btn-primary btn-edit"
//                 type="button"
//                 onClick={() => {
//                   setVFeatureEditMachine(true);
//                 }}
//               >
//                 Edit
//               </button> */}
//           </div>
//         </VBar>
//       </div>
//       {/* <div className="content-holder">
//         {fxdRead(stgMachine.machines) === null
//           ? null
//           : JSON.parse(fxdRead(stgMachine.machines)!).map(
//               (element: MachineData) => {
//                 if (element.resName === name) {
//                   return Machine(element.qrCode);
//                 }
//               }
//             )}
//       </div> */}
//     </div>
//   );
// }

// function ImplementTab(name: string) {
//   const [iMachine, setIMachine] = useState("");

//   useEffect(() => {
//     console.log(iMachine);
//   }, [iMachine]);

//   const machines = fxdRead(stgMachine.machines);
//   let oMachines: { label: string }[] = [];
//   if (machines == null) {
//     alert("No machines found. Please encode a machine first.");
//   } else {
//     const machs = JSON.parse(machines);
//     for (let i = 0; i < machs.length; i++) {
//       oMachines.push({
//         label: machs[i].qrCode,
//       });
//     }
//   }

//   return (
//     <div className="accordion-item">
//       <h2 className="accordion-header" role="tab">
//         <button
//           className="accordion-button collapsed"
//           type="button"
//           data-bs-toggle="collapse"
//           data-bs-target="#accordion-2 .item-2"
//           aria-expanded="false"
//           aria-controls="accordion-2 .item-2"
//         >
//           IMPLEMENTS
//         </button>
//       </h2>
//       <div className="machine-list">
//         <Pick
//           text={{ label: "List of machines", placeholder: "Select a machine" }}
//           options={oMachines}
//           sVal={setIMachine}
//           w={100}
//         />
//       </div>
//       {/* <div className="content-holder">
//         {fxdRead(stgMachine.machines) === null
//           ? null
//           : JSON.parse(fxdRead(stgMachine.machines)!).map(
//               (element: MachineData) => {
//                 if (element.resName === name) {
//                   return Machine(element.qrCode);
//                 }
//               }
//             )}
//       </div> */}
//     </div>
//   );
// }

// function Machine(code: string) {
//   const codeAsID = "#" + code;
//   return (
//     <div
//       className={"accordion-collapse collapse item-1"}
//       role="tabpanel"
//       data-bs-parent={code}
//     >
//       <div className="accordion-body-mid">
//         <div id={code} className="accordion" role="tablist">
//           <div className="accordion-item">
//             <h2 className="accordion-header" role="tab">
//               <button
//                 className="accordion-button"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target={codeAsID + " .item-1"}
//                 aria-expanded="false"
//                 aria-controls={code + " .item-1"}
//               >
//                 {code}
//               </button>
//             </h2>
//             <div
//               className="accordion-collapse collapse show item-1"
//               role="tabpanel"
//               data-bs-parent={codeAsID}
//             >
//               <div className="accordion-body">
//                 <p className="mb-0 status-title">Encoding</p>
//                 <p className="mb-0 status">SAVED LOCALY</p>
//                 <p className="mb-0 status-title">Status</p>
//                 <p className="mb-0 status">UNVERIFIED</p>
//                 <button className="btn btn-primary btn-edit" type="button">
//                   Edit Machine
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// function ImplementTab(name: string) {
//   return (
//     <div className="accordion-item">
//       <h2 className="accordion-header" role="tab">
//         <button
//           className="accordion-button collapsed"
//           type="button"
//           data-bs-toggle="collapse"
//           data-bs-target="#accordion-2 .item-2"
//           aria-expanded="false"
//           aria-controls="accordion-2 .item-2"
//         >
//           Implements
//         </button>
//       </h2>
//       {fxdRead(stgImplement.implements) === null
//         ? null
//         : JSON.parse(fxdRead(stgImplement.implements)!).map(
//             (element: ImplementData) => {
//               if (element.resName === name) {
//                 return Implement(element.qrCode);
//               }
//             }
//           )}
//     </div>
//   );
// }

// function Implement(code: string) {
//   const codeAsID = "#" + code;
//   return (
//     <div
//       className="accordion-collapse collapse item-2"
//       role="tabpanel"
//       data-bs-parent="#accordion-2"
//     >
//       <div className="accordion-body-mid">
//         <div id={code} className="accordion" role="tablist">
//           <div className="accordion-item">
//             <h2 className="accordion-header" role="tab">
//               <button
//                 className="accordion-button"
//                 type="button"
//                 data-bs-toggle="collapse"
//                 data-bs-target={codeAsID + " .item-2"}
//                 aria-expanded="true"
//                 aria-controls={code + " .item-2"}
//               >
//                 {code}
//               </button>
//             </h2>
//             <div
//               className="accordion-collapse collapse show item-2"
//               role="tabpanel"
//               data-bs-parent={codeAsID}
//             >
//               <div className="accordion-body">
//                 <p className="mb-0 status-title">Encoding</p>
//                 <p className="mb-0 status">SAVED LOCALY</p>
//                 <p className="mb-0 status-title">Status</p>
//                 <p className="mb-0 status">UNVERIFIED</p>
//                 <button className="btn btn-primary btn-edit" type="button">
//                   Edit Implement
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
// }
