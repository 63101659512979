// <!-------------------------------------------------------------------------->
// <!-- M3DAS Web App Phase 3 Source Code ------------------------------------->
// <!-- Programmer: Richmond Bautista  Website: https://rbautista.pro --------->
// <!-- Version and Deployment Information: v3.40 ----------------------------->
// <!-------------------------------------------------------------------------->

import React from "react";
import ReactDOM from "react-dom/client";

import { ID } from "./app/ElementID";
import { AppSettings } from "./app/Settings";
import Application from "./pages/app/App";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "@mui/material/styles";

// /////////////////////////////////////////////////////////////////////////////
// The Web Application /////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

const ROOT = document.getElementById(ID.HTMLRoot);
const VERSION = AppSettings.TITLE_VERSION;
const THEME = AppSettings.MUI_THEME;

ReactDOM.createRoot(ROOT!).render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <title>{VERSION}</title>
      </Helmet>
    </HelmetProvider>

    <ThemeProvider theme={THEME}>
      <Application />
    </ThemeProvider>
  </React.StrictMode>
);

// /////////////////////////////////////////////////////////////////////////////

// TODO Auto adjust or resize windows zoom based on screen size.
// https://medium.com/@josephat94/building-a-simple-react-hook-to-detect-screen-
// size-404a867fa2d2
// https://blog.logrocket.com/adding-zoom-pan-pinch-react-web-apps/
// https://codesandbox.io/p/sandbox/react-zoom-pan-pinch-example-20hhkn?file=%2F
// src%2FApp.js&from-embed=
// const useScreenSize = () => {
//   const [screenSize, setScreenSize] = useState({
//     width: window.innerWidth,
//     height: window.innerHeight,
//   });

//   useEffect(() => {
//     const handleResize = () => {
//       setScreenSize({
//         width: window.innerWidth,
//         height: window.innerHeight,
//       });
//     };

//     window.addEventListener("resize", handleResize);

//     // Clean up the event listener when the component unmounts
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   return screenSize;
// };
