import "leaflet/dist/leaflet.css";
import "../../assets/scss/Forms.css";
import strImp from "./ImplementPage.json";
import strPrf from "../profile/ProfilePage.json";
import strMac from "../machine/MachinePage.json";
import {
  LoaderFunctionArgs,
  NavLink,
  redirect,
  useNavigate,
} from "react-router-dom";
import { AvzRoute, gotoLogin, requireLoadProperly } from "../../app/Routes";
import { fxdDelete, fxdGen } from "../../app/Storage";
import { fxdRead, fxdUser, fxdWrite } from "../../app/Storage";
import { fxdImplement, fxdProfile } from "../../app/Storage";
import { fxdImplementBackup } from "../../app/Storage";
import { AAlert, AButton, APopup, AText, ATick } from "../../custom/afx/AFX";
import { ATextInput, OverrideBackup } from "../../custom/afx/AFX";
import { useEffect, useState } from "react";
import { TGetString, TGetValidation } from "../../custom/afx/AType";
import { TSetAny, TSetString, TSetValidation } from "../../custom/afx/AType";
import { Card, HBar, VBar } from "../../custom/afx/ABox";
import { TitleBar } from "../encode/EncodePage";
import { GeoLocationPane } from "../../app/GeoLocation";
import WebCam from "../../custom/Camera";
import { Address, CreateNewAddress } from "../../custom/RAddress";
import { Province, Municipality, Barangay } from "../../custom/RAddress";
import { BrowserQRCodeReader } from "@zxing/library";
import L from "leaflet";
import { AppSettings } from "../../app/Settings";
import iImplement from "../../assets/img/icon/map/implement.png";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { createZIP, safeRead } from "../../app/GenFunctions";
import { ID } from "../../app/ElementID";
import { DATA_STATUS, FORMS } from "../../app/GenEnums";
import { noValidation } from "../../custom/Validations";
import SaveIcon from "@mui/icons-material/Save";
import { Nav } from "react-bootstrap";

// /////////////////////////////////////////////////////////////////////////////
// CONSTANTS ///////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

const tMolBrdPlow = strImp.basicInfo.type.options[0].label;
const tDiscPlow = strImp.basicInfo.type.options[1].label;
const tDiscHarrow = strImp.basicInfo.type.options[2].label;
const tFurrower = strImp.basicInfo.type.options[3].label;
const tSubsoiler = strImp.basicInfo.type.options[4].label;
const tChiselPlow = strImp.basicInfo.type.options[5].label;
const tPowerHarrow = strImp.basicInfo.type.options[6].label;
const tSpkTthHarrow = strImp.basicInfo.type.options[7].label;
const tRotTiller = strImp.basicInfo.type.options[8].label;
const tDitcher = strImp.basicInfo.type.options[9].label;
const tDozer = strImp.basicInfo.type.options[10].label;
const tExcavator = strImp.basicInfo.type.options[11].label;
const tMechPlanter = strImp.basicInfo.type.options[12].label;
const tFldCltvtr = strImp.basicInfo.type.options[13].label;
const tRtnManager = strImp.basicInfo.type.options[14].label;
const tTrashRake = strImp.basicInfo.type.options[15].label;
const tMulcher = strImp.basicInfo.type.options[16].label;
const tItrRowCltvtr = strImp.basicInfo.type.options[17].label;
const tCutAwayCltvtr = strImp.basicInfo.type.options[18].label;
const tTrashInc = strImp.basicInfo.type.options[19].label;
const tGranFertAppl = strImp.basicInfo.type.options[20].label;
const tFertApplCutAway = strImp.basicInfo.type.options[21].label;
const tWeeder = strImp.basicInfo.type.options[22].label;
const tBoomSprayer = strImp.basicInfo.type.options[23].label;
const tMechHarvester = strImp.basicInfo.type.options[24].label;
const tCaneGrabLoader = strImp.basicInfo.type.options[25].label;
const tTrailer = strImp.basicInfo.type.options[26].label;
// const tNa = strImp.basicInfo.type.options[27].label;

// /////////////////////////////////////////////////////////////////////////////
// PRELOADER ///////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

const IMBKUP = fxdImplementBackup as Record<string, any>;
export function ImplementPageLoader({ request }: LoaderFunctionArgs) {
  var keys = Object.keys(localStorage);
  var enlistedMachinesFromThisProfile = () =>
    keys.filter((key) => key.charAt(0) === "R" && key.charAt(6) === "M");
  var mach = enlistedMachinesFromThisProfile();
  var machNo = mach === undefined ? 0 : mach?.length;

  if (machNo < 1) {
    APopup({
      icon: "error",
      title: strImp.notif.noMachine.title,
      text: strImp.notif.noMachine.message,
      confirmButtonText: strImp.notif.noMachine.button,
    });
    return redirect(AvzRoute.machine);
  } else {
    fxdWrite(fxdGen.editing, FORMS.implement);
    return gotoLogin(request.url) ?? null;
  }
}

export default function ImplementPage() {
  // ///////////////////////////////////////////////////////////////////////////

  const GOTO = useNavigate();

  const restoreFromLocalStorage = () => {
    var restoreFromBackup: Record<string, any> = {};
    Object.keys(IMBKUP).forEach((key) => {
      restoreFromBackup[key] = fxdRead(IMBKUP[key])
        ? JSON.parse(fxdRead(IMBKUP[key]) || "")
        : "";
    });
    return restoreFromBackup;
  };

  const isAllEmpty = (backup: Record<string, any>) => {
    return Object.values(backup).every((x) => safeRead(x) === "");
  };

  const backup = restoreFromLocalStorage();

  const restoreImplementBackups = (from: Record<string, any>) => {
    for (let i = 0; i < QUESTIONS.length; i++) {
      var val = from["b" + QUESTIONS[i].backup.slice(3)];
      if (val && typeof QUESTIONS[i].setValue === "function") {
        QUESTIONS[i].setValue(val);
      }
    }
  };

  function deleteMachineBackups() {
    Object.values(IMBKUP).forEach((val) => fxdDelete(val));
    fxdDelete(fxdImplement.backup);
  }

  useEffect(() => {
    if (!isAllEmpty(backup)) {
      fxdWrite(fxdImplement.backup, JSON.stringify(backup));
    }

    var restored = JSON.parse(fxdRead(fxdImplement.backup) || "{}");

    if (!isAllEmpty(restored)) {
      APopup({
        title: strMac.notif.backup.title,
        text: strMac.notif.backup.message,
        confirmButtonText: "Recover",
        showDenyButton: true,
        denyButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          restoreImplementBackups(restored);
        } else if (result.isDenied) {
          deleteMachineBackups();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ///////////////////////////////////////////////////////////////////////////
  // STATE /////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  const [iI1ResName, setII1ResName] = useState("");
  const [eI1ResName, setEI1ResName] = useState(noValidation);

  const [iI1QRCode, setII1QRCode] = useState("");
  const [eI1QRCode, setEI1QRCode] = useState(noValidation);

  const [iI1Machine, setII1Machine] = useState("");
  const [eI1Machine, setEI1Machine] = useState(noValidation);

  const [iI1FrontVw, setII1FrontVw] = useState("");
  const [eI1FrontVw, setEI1FrontVw] = useState(noValidation);

  const [iI1SideVw, setII1SideVw] = useState("");
  const [eI1SideVw, setEI1SideVw] = useState(noValidation);

  const [iI1SpecVw, setII1SpecVw] = useState("");
  const [eI1SpecVw, setEI1SpecVw] = useState(noValidation);

  const [iI1Acc, setII1Acc] = useState("");
  const [eI1Acc, setEI1Acc] = useState(noValidation);

  const [iI1Lat, setII1Lat] = useState("");
  const [eI1Lat, setEI1Lat] = useState(noValidation);

  const [iI1Lng, setII1Lng] = useState("");
  const [eI1Lng, setEI1Lng] = useState(noValidation);

  const [iI1Loc, setII1Loc] = useState("");
  const [eI1Loc, setEI1Loc] = useState(noValidation);

  const [iI1Prov, setII1Prov] = useState("");
  const [eI1Prov, setEI1Prov] = useState(noValidation);

  const [iI1Mun, setII1Mun] = useState("");
  const [eI1Mun, setEI1Mun] = useState(noValidation);

  const [iI1Bgry, setII1Bgry] = useState("");
  const [eI1Bgry, setEI1Bgry] = useState(noValidation);

  const [iI2Type, setII2Type] = useState("");
  const [eI2Type, setEI2Type] = useState(noValidation);

  const [iI2Brand, setII2Brand] = useState("");
  const [eI2Brand, setEI2Brand] = useState(noValidation);

  const [iI2Model, setII2Model] = useState("");
  const [eI2Model, setEI2Model] = useState(noValidation);

  const [iI2NoBtm1, setII2NoBtm1] = useState("");
  const [eI2NoBtm1, setEI2NoBtm1] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.bottoms },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  const [iI2NoBtm2, setII2NoBtm2] = useState("");
  const [eI2NoBtm2, setEI2NoBtm2] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.bottoms },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  const [iI2SzDk, setII2SzDk] = useState("");
  const [eI2SzDk, setEI2SzDk] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.disk },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  const [iI2NoBtm3, setII2NoBtm3] = useState("");
  const [eI2NoBtm3, setEI2NoBtm3] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.bottoms },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  const [iI2DisRow1, setII2DisRow1] = useState("");
  const [eI2DisRow1, setEI2DisRow1] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.distance },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  const [iI2WdCut, setII2WdCut] = useState("");
  const [eI2WdCut, setEI2WdCut] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.wcut },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  const [iI2LdCap, setII2LdCap] = useState("");
  const [eI2LdCap, setEI2LdCap] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.capacity },
    range: { min: 0, max: 9999 },
  } as TGetValidation);

  const [iI2LdCapUnit, setII2LdCapUnit] = useState("");
  const [eI2LdCapUnit, setEI2LdCapUnit] = useState(noValidation);

  const [iI2NoRows1, setII2NoRows1] = useState("");
  const [eI2NoRows1, setEI2NoRows1] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.rows },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  const [iI2DisRow2, setII2DisRow2] = useState("");
  const [eI2DisRow2, setEI2DisRow2] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.drows },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  const [iI2NoNzls, setII2NoNzls] = useState("");
  const [eI2NoNzls, setEI2NoNzls] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.nozzle },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  const [iI2DisNzls, setII2DisNzls] = useState("");
  const [eI2DisNzls, setEI2DisNzls] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.dnozzle },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  const [iI2TnkCap1, setII2TnkCap1] = useState("");
  const [eI2TnkCap1, setEI2TnkCap1] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.capacity },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  const [iI2NoOutlets, setII2NoOutlets] = useState("");
  const [eI2NoOutlets, setEI2NoOutlets] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.outlet },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  const [iI2DisOutlets, setII2DisOutlets] = useState("");
  const [eI2DisOutlets, setEI2DisOutlets] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.doutlet },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  const [iI2TnkCap2, setII2TnkCap2] = useState("");
  const [eI2TnkCap2, setEI2TnkCap2] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.capacity },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  const [iI2TnkCap2Unit, setII2TnkCap2Unit] = useState("");
  const [eI2TnkCap2Unit, setEI2TnkCap2Unit] = useState(noValidation);

  const [iI2TpPlntr, setII2TpPlntr] = useState("");
  const [eI2TpPlntr, setEI2TpPlntr] = useState(noValidation);

  const [iI2NoRows2, setII2NoRows2] = useState("");
  const [eI2NoRows2, setEI2NoRows2] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.rows },
    range: { min: 0, max: 9999 },
  } as TGetValidation);

  const [iI2TpCnGrbAttch, setII2TpCnGrbAttch] = useState("");
  const [eI2TpCnGrbAttch, setEI2TpCnGrbAttch] = useState(noValidation);

  const [iI3Prim, setII3Prim] = useState("");
  const [eI3Prim, setEI3Prim] = useState(noValidation);

  const [iI3Sec, setII3Sec] = useState("");
  const [eI3Sec, setEI3Sec] = useState(noValidation);

  const [iI3Ter, setII3Ter] = useState("");
  const [eI3Ter, setEI3Ter] = useState(noValidation);

  const [iI4Usage, setII4Usage] = useState("");
  const [eI4Usage, setEI4Usage] = useState(noValidation);

  const [iI4Mode, setII4Mode] = useState("");
  const [eI4Mode, setEI4Mode] = useState(noValidation);

  const [iI4Agency, setII4Agency] = useState("");
  const [eI4Agency, setEI4Agency] = useState(noValidation);

  const [iI4YrAcqrd, setII4YrAcqrd] = useState("");
  const [eI4YrAcqrd, setEI4YrAcqrd] = useState({
    required: false,
    condition: "year",
    message: { label: strPrf.validation.year },
    range: { min: 1949, max: 2026 },
  } as TGetValidation);

  const [iI4ConAcqrd, setII4ConAcqrd] = useState("");
  const [eI4ConAcqrd, setEI4ConAcqrd] = useState(noValidation);

  const [iI4PrsntCon, setII4PrsntCon] = useState("");
  const [eI4PrsntCon, setEI4PrsntCon] = useState(noValidation);

  const [iI4YrUnused, setII4YrUnused] = useState("");
  const [eI4YrUnused, setEI4YrUnused] = useState({
    required: false,
    condition: "year",
    message: { label: strPrf.validation.year },
    range: { min: 1949, max: 2026 },
  } as TGetValidation);

  const [iI4RsnUnused, setII4RsnUnused] = useState("");
  const [eI4RsnUnused, setEI4RsnUnused] = useState(noValidation);

  const [iI4UuOthers, setII4UuOthers] = useState("");
  const [eI4UuOthers, setEI4UuOthers] = useState(noValidation);

  const [iI4YrUnserv, setII4YrUnserv] = useState("");
  const [eI4YrUnserv, setEI4YrUnserv] = useState({
    required: false,
    condition: "year",
    message: { label: strPrf.validation.year },
    range: { min: 1949, max: 2026 },
  } as TGetValidation);

  const [iI4RsnUnserv, setII4RsnUnserv] = useState("");
  const [eI4RsnUnserv, setEI4RsnUnserv] = useState(noValidation);

  const [iI4UsOthers, setII4UsOthers] = useState("");
  const [eI4UsOthers, setEI4UsOthers] = useState(noValidation);

  const [iI4WithTractor, setII4WithTractor] = useState("");
  const [eI4WithTractor, setEI4WithTractor] = useState(noValidation);

  const [iI4SvcRtHa, setII4SvcRtHa] = useState("");
  const [eI4SvcRtHa, setEI4SvcRtHa] = useState(noValidation);

  const [iI4SvcRtHaNon, setII4SvcRtHaNon] = useState("");
  const [eI4SvcRtHaNon, setEI4SvcRtHaNon] = useState(noValidation);

  const [iI4SameOwn, setII4SameOwn] = useState("");
  const [eI4SameOwn, setEI4SameOwn] = useState(noValidation);

  const [iI4BusCont1, setII4BusCont1] = useState("");
  const [eI4BusCont1, setEI4BusCont1] = useState({
    required: false,
    condition: "contact",
    message: { label: strPrf.validation.contact },
  } as TGetValidation);

  const [iI4BusCont2, setII4BusCont2] = useState("");
  const [eI4BusCont2, setEI4BusCont2] = useState({
    required: false,
    condition: "contact",
    message: { label: strPrf.validation.contact },
  } as TGetValidation);

  const [iI4Consent, setII4Consent] = useState("");
  const [eI4Consent, setEI4Consent] = useState(noValidation);

  const [iI4SrvcLoc1P, setII4SrvcLoc1P] = useState("");
  const [eI4SrvcLoc1P, setEI4SrvcLoc1P] = useState(noValidation);

  const [iI4SrvcLoc1M, setII4SrvcLoc1M] = useState("");
  const [eI4SrvcLoc1M, setEI4SrvcLoc1M] = useState(noValidation);

  const [iI4SrvcLoc2P, setII4SrvcLoc2P] = useState("");
  const [eI4SrvcLoc2P, setEI4SrvcLoc2P] = useState(noValidation);

  const [iI4SrvcLoc2M, setII4SrvcLoc2M] = useState("");
  const [eI4SrvcLoc2M, setEI4SrvcLoc2M] = useState(noValidation);

  const [iI4SrvcLoc3P, setII4SrvcLoc3P] = useState("");
  const [eI4SrvcLoc3P, setEI4SrvcLoc3P] = useState(noValidation);

  const [iI4SrvcLoc3M, setII4SrvcLoc3M] = useState("");
  const [eI4SrvcLoc3M, setEI4SrvcLoc3M] = useState(noValidation);

  const [iI5TmCom1Ha, setII5TmCom1Ha] = useState("");
  const [eI5TmCom1Ha, setEI5TmCom1Ha] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.time },
    range: { min: 0, max: 24 },
  } as TGetValidation);

  const [iI5NoPasses, setII5NoPasses] = useState("");
  const [eI5NoPasses, setEI5NoPasses] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.passes },
    range: { min: 0, max: 10 },
  } as TGetValidation);

  const [iI5GrnFrtWt, setII5GrnFrtWt] = useState("");
  const [eI5GrnFrtWt, setEI5GrnFrtWt] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.rate },
    range: { min: 0, max: 9999 },
  } as TGetValidation);

  const [iI5GrnFrtWtUnit, setII5GrnFrtWtUnit] = useState("");
  const [eI5GrnFrtWtUnit, setEI5GrnFrtWtUnit] = useState(noValidation);

  const [iI5FrtAppEffA, setII5FrtAppEffA] = useState("");
  const [eI5FrtAppEffA, setEI5FrtAppEffA] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.area },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  const [iI5FrtDelRate, setII5FrtDelRate] = useState("");
  const [eI5FrtDelRate, setEI5FrtDelRate] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.rate },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  const [iI5SgrYld, setII5SgrYld] = useState("");
  const [eI5SgrYld, setEI5SgrYld] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.yield },
    range: { min: 0, max: 99 },
  } as TGetValidation);

  const [iI5LdCap, setII5LdCap] = useState("");
  const [eI5LdCap, setEI5LdCap] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.capacity },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  const [iI5LdCapUnit, setII5LdCapUnit] = useState("");
  const [eI5LdCapUnit, setEI5LdCapUnit] = useState(noValidation);

  const [iI5NoBrkdwn, setII5NoBrkdwn] = useState("");
  const [eI5NoBrkdwn, setEI5NoBrkdwn] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.breakdown },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  const [iI5CmnBrkdwn, setII5CmnBrkdwn] = useState("");
  const [eI5CmnBrkdwn, setEI5CmnBrkdwn] = useState(noValidation);

  const [iI5MntcDone, setII5MntcDone] = useState("");
  const [eI5MntcDone, setEI5MntcDone] = useState(noValidation);

  const [iI6Notes, setII6Notes] = useState("");
  const [eI6Notes, setEI6Notes] = useState(noValidation);

  // ///////////////////////////////////////////////////////////////////////////
  // Questions /////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  type TQuestion = {
    value: TGetString | undefined;
    setValue: TSetString | TSetAny;
    errorMessage: TGetValidation;
    setErrorMessage: TSetValidation;
    backup: TGetString;
    label: TGetString;
  };

  const QUESTIONS: TQuestion[] = [
    {
      value: iI1ResName,
      setValue: setII1ResName,
      errorMessage: eI1ResName,
      setErrorMessage: setEI1ResName,
      backup: IMBKUP.bI1ResName,
      label: strImp.basicInfo.resName.label,
    },
    {
      value: iI1Machine,
      setValue: setII1Machine,
      errorMessage: eI1Machine,
      setErrorMessage: setEI1Machine,
      backup: IMBKUP.bI1Machine,
      label: strImp.basicInfo.machine.label,
    },
    {
      value: iI1QRCode,
      setValue: setII1QRCode,
      errorMessage: eI1QRCode,
      setErrorMessage: setEI1QRCode,
      backup: IMBKUP.bI1QRCode,
      label: strImp.basicInfo.qrCode.label,
    },
    {
      value: iI1FrontVw,
      setValue: setII1FrontVw,
      errorMessage: eI1FrontVw,
      setErrorMessage: setEI1FrontVw,
      backup: IMBKUP.bI1FrontVw,
      label: strImp.basicInfo.front.label,
    },
    {
      value: iI1SideVw,
      setValue: setII1SideVw,
      errorMessage: eI1SideVw,
      setErrorMessage: setEI1SideVw,
      backup: IMBKUP.bI1SideVw,
      label: strImp.basicInfo.side.label,
    },
    {
      value: iI1SpecVw,
      setValue: setII1SpecVw,
      errorMessage: eI1SpecVw,
      setErrorMessage: setEI1SpecVw,
      backup: IMBKUP.bI1SpecVw,
      label: strImp.basicInfo.plate.label,
    },
    {
      value: iI1Acc,
      setValue: setII1Acc,
      errorMessage: eI1Acc,
      setErrorMessage: setEI1Acc,
      backup: IMBKUP.bI1Acc,
      label: strImp.basicInfo.acc.label,
    },
    {
      value: iI1Lat,
      setValue: setII1Lat,
      errorMessage: eI1Lat,
      setErrorMessage: setEI1Lat,
      backup: IMBKUP.bI1Lat,
      label: strImp.basicInfo.lat.label,
    },
    {
      value: iI1Lng,
      setValue: setII1Lng,
      errorMessage: eI1Lng,
      setErrorMessage: setEI1Lng,
      backup: IMBKUP.bI1Lng,
      label: strImp.basicInfo.lng.label,
    },
    {
      value: iI1Loc,
      setValue: setII1Loc,
      errorMessage: eI1Loc,
      setErrorMessage: setEI1Loc,
      backup: IMBKUP.bI1Loc,
      label: strImp.basicInfo.loc.label,
    },
    {
      value: iI1Prov,
      setValue: setII1Prov,
      errorMessage: eI1Prov,
      setErrorMessage: setEI1Prov,
      backup: IMBKUP.bI1Prov,
      label: strImp.basicInfo.prov.label,
    },
    {
      value: iI1Mun,
      setValue: setII1Mun,
      errorMessage: eI1Mun,
      setErrorMessage: setEI1Mun,
      backup: IMBKUP.bI1Mun,
      label: strImp.basicInfo.mun.label,
    },
    {
      value: iI1Bgry,
      setValue: setII1Bgry,
      errorMessage: eI1Bgry,
      setErrorMessage: setEI1Bgry,
      backup: IMBKUP.bI1Bgry,
      label: strImp.basicInfo.brgy.label,
    },
    {
      value: iI2Type,
      setValue: setII2Type,
      errorMessage: eI2Type,
      setErrorMessage: setEI2Type,
      backup: IMBKUP.bI2Type,
      label: strImp.basicInfo.type.label,
    },
    {
      value: iI2Brand,
      setValue: setII2Brand,
      errorMessage: eI2Brand,
      setErrorMessage: setEI2Brand,
      backup: IMBKUP.bI2Brand,
      label: strImp.specifications.brand.label,
    },
    {
      value: iI2Model,
      setValue: setII2Model,
      errorMessage: eI2Model,
      setErrorMessage: setEI2Model,
      backup: IMBKUP.bI2Model,
      label: strImp.specifications.model.label,
    },
    {
      value: iI2NoBtm1,
      setValue: setII2NoBtm1,
      errorMessage: eI2NoBtm1,
      setErrorMessage: setEI2NoBtm1,
      backup: IMBKUP.bI2NoBtm1,
      label: strImp.specifications.bottoms.label,
    },
    {
      value: iI2NoBtm2,
      setValue: setII2NoBtm2,
      errorMessage: eI2NoBtm2,
      setErrorMessage: setEI2NoBtm2,
      backup: IMBKUP.bI2NoBtm2,
      label: strImp.specifications.bottoms.label,
    },
    {
      value: iI2SzDk,
      setValue: setII2SzDk,
      errorMessage: eI2SzDk,
      setErrorMessage: setEI2SzDk,
      backup: IMBKUP.bI2SzDk,
      label: strImp.specifications.disk.label,
    },
    {
      value: iI2NoBtm3,
      setValue: setII2NoBtm3,
      errorMessage: eI2NoBtm3,
      setErrorMessage: setEI2NoBtm3,
      backup: IMBKUP.bI2NoBtm3,
      label: strImp.specifications.bottoms.label,
    },
    {
      value: iI2DisRow1,
      setValue: setII2DisRow1,
      errorMessage: eI2DisRow1,
      setErrorMessage: setEI2DisRow1,
      backup: IMBKUP.bI2DisRow1,
      label: strImp.specifications.distance.label,
    },
    {
      value: iI2WdCut,
      setValue: setII2WdCut,
      errorMessage: eI2WdCut,
      setErrorMessage: setEI2WdCut,
      backup: IMBKUP.bI2WdCut,
      label: strImp.specifications.width.label,
    },
    {
      value: iI2LdCap,
      setValue: setII2LdCap,
      errorMessage: eI2LdCap,
      setErrorMessage: setEI2LdCap,
      backup: IMBKUP.bI2LdCap,
      label: strImp.perf.lcap.label,
    },
    {
      value: iI2LdCapUnit,
      setValue: setII2LdCapUnit,
      errorMessage: eI2LdCapUnit,
      setErrorMessage: setEI2LdCapUnit,
      backup: IMBKUP.bI2LdCapUnit,
      label: strImp.perf.lcap.label + " " + strImp.perf.lcap.unitval.label,
    },
    {
      value: iI2NoRows1,
      setValue: setII2NoRows1,
      errorMessage: eI2NoRows1,
      setErrorMessage: setEI2NoRows1,
      backup: IMBKUP.bI2NoRows1,
      label: strImp.specifications.rows.label,
    },
    {
      value: iI2DisRow2,
      setValue: setII2DisRow2,
      errorMessage: eI2DisRow2,
      setErrorMessage: setEI2DisRow2,
      backup: IMBKUP.bI2DisRow2,
      label: strImp.specifications.distance.label,
    },
    {
      value: iI2NoNzls,
      setValue: setII2NoNzls,
      errorMessage: eI2NoNzls,
      setErrorMessage: setEI2NoNzls,
      backup: IMBKUP.bI2NoNzls,
      label: strImp.specifications.noz.label,
    },
    {
      value: iI2DisNzls,
      setValue: setII2DisNzls,
      errorMessage: eI2DisNzls,
      setErrorMessage: setEI2DisNzls,
      backup: IMBKUP.bI2DisNzls,
      label: strImp.specifications.dNoz.label,
    },
    {
      value: iI2TnkCap1,
      setValue: setII2TnkCap1,
      errorMessage: eI2TnkCap1,
      setErrorMessage: setEI2TnkCap1,
      backup: IMBKUP.bI2TnkCap1,
      label: strImp.specifications.tkCap.label,
    },
    {
      value: iI2NoOutlets,
      setValue: setII2NoOutlets,
      errorMessage: eI2NoOutlets,
      setErrorMessage: setEI2NoOutlets,
      backup: IMBKUP.bI2NoOutlets,
      label: strImp.specifications.out.label,
    },
    {
      value: iI2DisOutlets,
      setValue: setII2DisOutlets,
      errorMessage: eI2DisOutlets,
      setErrorMessage: setEI2DisOutlets,
      backup: IMBKUP.bI2DisOutlets,
      label: strImp.specifications.dOut.label,
    },
    {
      value: iI2TnkCap2,
      setValue: setII2TnkCap2,
      errorMessage: eI2TnkCap2,
      setErrorMessage: setEI2TnkCap2,
      backup: IMBKUP.bI2TnkCap2,
      label: strImp.specifications.tkCap2.label,
    },
    {
      value: iI2TnkCap2Unit,
      setValue: setII2TnkCap2Unit,
      errorMessage: eI2TnkCap2Unit,
      setErrorMessage: setEI2TnkCap2Unit,
      backup: IMBKUP.bI2TnkCap2Unit,
      label:
        strImp.specifications.tkCap2.label +
        " " +
        strImp.specifications.tkCap2.unitval.label,
    },
    {
      value: iI2TpPlntr,
      setValue: setII2TpPlntr,
      errorMessage: eI2TpPlntr,
      setErrorMessage: setEI2TpPlntr,
      backup: IMBKUP.bI2TpPlntr,
      label: strImp.specifications.type1.text.label,
    },
    {
      value: iI2NoRows2,
      setValue: setII2NoRows2,
      errorMessage: eI2NoRows2,
      setErrorMessage: setEI2NoRows2,
      backup: IMBKUP.bI2NoRows2,
      label: strImp.specifications.rows.label,
    },
    {
      value: iI2TpCnGrbAttch,
      setValue: setII2TpCnGrbAttch,
      errorMessage: eI2TpCnGrbAttch,
      setErrorMessage: setEI2TpCnGrbAttch,
      backup: IMBKUP.bI2TpCnGrbAttch,
      label: strImp.specifications.type2.text.label,
    },
    {
      value: iI3Prim,
      setValue: setII3Prim,
      errorMessage: eI3Prim,
      setErrorMessage: setEI3Prim,
      backup: IMBKUP.bI3Prim,
      label: strImp.operations.pri.label,
    },
    {
      value: iI3Sec,
      setValue: setII3Sec,
      errorMessage: eI3Sec,
      setErrorMessage: setEI3Sec,
      backup: IMBKUP.bI3Sec,
      label: strImp.operations.sec.label,
    },
    {
      value: iI3Ter,
      setValue: setII3Ter,
      errorMessage: eI3Ter,
      setErrorMessage: setEI3Ter,
      backup: IMBKUP.bI3Ter,
      label: strImp.operations.ter.label,
    },
    {
      value: iI4Usage,
      setValue: setII4Usage,
      errorMessage: eI4Usage,
      setErrorMessage: setEI4Usage,
      backup: IMBKUP.bI4Usage,
      label: strImp.usage.label,
    },
    {
      value: iI4Mode,
      setValue: setII4Mode,
      errorMessage: eI4Mode,
      setErrorMessage: setEI4Mode,
      backup: IMBKUP.bI4Mode,
      label: strImp.mode.label,
    },
    {
      value: iI4Agency,
      setValue: setII4Agency,
      errorMessage: eI4Agency,
      setErrorMessage: setEI4Agency,
      backup: IMBKUP.bI4Agency,
      label: strImp.agency.label,
    },
    {
      value: iI4YrAcqrd,
      setValue: setII4YrAcqrd,
      errorMessage: eI4YrAcqrd,
      setErrorMessage: setEI4YrAcqrd,
      backup: IMBKUP.bI4YrAcqrd,
      label: strImp.acqYear.label,
    },
    {
      value: iI4ConAcqrd,
      setValue: setII4ConAcqrd,
      errorMessage: eI4ConAcqrd,
      setErrorMessage: setEI4ConAcqrd,
      backup: IMBKUP.bI4ConAcqrd,
      label: strImp.cond.label,
    },
    {
      value: iI4PrsntCon,
      setValue: setII4PrsntCon,
      errorMessage: eI4PrsntCon,
      setErrorMessage: setEI4PrsntCon,
      backup: IMBKUP.bI4PrsntCon,
      label: strImp.pCond.label,
    },
    {
      value: iI4YrUnused,
      setValue: setII4YrUnused,
      errorMessage: eI4YrUnused,
      setErrorMessage: setEI4YrUnused,
      backup: IMBKUP.bI4YrUnused,
      label: strImp.unused.uuYear.label,
    },
    {
      value: iI4RsnUnused,
      setValue: setII4RsnUnused,
      errorMessage: eI4RsnUnused,
      setErrorMessage: setEI4RsnUnused,
      backup: IMBKUP.bI4RsnUnused,
      label: strImp.unused.subtitle,
    },
    {
      value: iI4UuOthers,
      setValue: setII4UuOthers,
      errorMessage: eI4UuOthers,
      setErrorMessage: setEI4UuOthers,
      backup: IMBKUP.bI4UuOthers,
      label: strImp.unused.subtitle + " " + strImp.unused.uuOthers.label,
    },
    {
      value: iI4YrUnserv,
      setValue: setII4YrUnserv,
      errorMessage: eI4YrUnserv,
      setErrorMessage: setEI4YrUnserv,
      backup: IMBKUP.bI4YrUnserv,
      label: strImp.unserv.usYear.label,
    },
    {
      value: iI4RsnUnserv,
      setValue: setII4RsnUnserv,
      errorMessage: eI4RsnUnserv,
      setErrorMessage: setEI4RsnUnserv,
      backup: IMBKUP.bI4RsnUnserv,
      label: strImp.unserv.subtitle,
    },
    {
      value: iI4UsOthers,
      setValue: setII4UsOthers,
      errorMessage: eI4UsOthers,
      setErrorMessage: setEI4UsOthers,
      backup: IMBKUP.bI4UsOthers,
      label: strImp.unserv.subtitle + " " + strImp.unserv.usOthers.label,
    },
    {
      value: iI4SvcRtHa,
      setValue: setII4SvcRtHa,
      errorMessage: eI4SvcRtHa,
      setErrorMessage: setEI4SvcRtHa,
      backup: IMBKUP.bI4SvcRtHa,
      label: strImp.rent.rate.label,
    },
    {
      value: iI4SameOwn,
      setValue: setII4SameOwn,
      errorMessage: eI4SameOwn,
      setErrorMessage: setEI4SameOwn,
      backup: IMBKUP.bI4SameOwn,
      label: strImp.usage2.contact.same,
    },
    {
      value: iI4BusCont1,
      setValue: setII4BusCont1,
      errorMessage: eI4BusCont1,
      setErrorMessage: setEI4BusCont1,
      backup: IMBKUP.bI4BusCont1,
      label: strImp.usage2.contact1.label,
    },
    {
      value: iI4BusCont2,
      setValue: setII4BusCont2,
      errorMessage: eI4BusCont2,
      setErrorMessage: setEI4BusCont2,
      backup: IMBKUP.bI4BusCont2,
      label: strImp.usage2.contact2.label,
    },
    {
      value: iI4Consent,
      setValue: setII4Consent,
      errorMessage: eI4Consent,
      setErrorMessage: setEI4Consent,
      backup: IMBKUP.bI4Consent,
      label: strImp.usage2.contact.consent,
    },
    {
      value: iI4SrvcLoc1P,
      setValue: setII4SrvcLoc1P,
      errorMessage: eI4SrvcLoc1P,
      setErrorMessage: setEI4SrvcLoc1P,
      backup: IMBKUP.bI4SrvcLoc1P,
      label: strImp.rent.service.label,
    },
    {
      value: iI4SrvcLoc1M,
      setValue: setII4SrvcLoc1M,
      errorMessage: eI4SrvcLoc1M,
      setErrorMessage: setEI4SrvcLoc1M,
      backup: IMBKUP.bI4SrvcLoc1M,
      label: strImp.rent.service.label,
    },
    {
      value: iI4SrvcLoc2P,
      setValue: setII4SrvcLoc2P,
      errorMessage: eI4SrvcLoc2P,
      setErrorMessage: setEI4SrvcLoc2P,
      backup: IMBKUP.bI4SrvcLoc2P,
      label: strImp.rent.service.label,
    },
    {
      value: iI4SrvcLoc2M,
      setValue: setII4SrvcLoc2M,
      errorMessage: eI4SrvcLoc2M,
      setErrorMessage: setEI4SrvcLoc2M,
      backup: IMBKUP.bI4SrvcLoc2M,
      label: strImp.rent.service.label,
    },
    {
      value: iI4SrvcLoc3P,
      setValue: setII4SrvcLoc3P,
      errorMessage: eI4SrvcLoc3P,
      setErrorMessage: setEI4SrvcLoc3P,
      backup: IMBKUP.bI4SrvcLoc3P,
      label: strImp.rent.service.label,
    },
    {
      value: iI4SrvcLoc3M,
      setValue: setII4SrvcLoc3M,
      errorMessage: eI4SrvcLoc3M,
      setErrorMessage: setEI4SrvcLoc3M,
      backup: IMBKUP.bI4SrvcLoc3M,
      label: strImp.rent.service.label,
    },
    {
      value: iI5TmCom1Ha,
      setValue: setII5TmCom1Ha,
      errorMessage: eI5TmCom1Ha,
      setErrorMessage: setEI5TmCom1Ha,
      backup: IMBKUP.bI5TmCom1Ha,
      label: strImp.capacity.time.label,
    },
    {
      value: iI5NoPasses,
      setValue: setII5NoPasses,
      errorMessage: eI5NoPasses,
      setErrorMessage: setEI5NoPasses,
      backup: IMBKUP.bI5NoPasses,
      label: strImp.capacity.nPasses.label,
    },
    {
      value: iI5GrnFrtWt,
      setValue: setII5GrnFrtWt,
      errorMessage: eI5GrnFrtWt,
      setErrorMessage: setEI5GrnFrtWt,
      backup: IMBKUP.bI5GrnFrtWt,
      label: strImp.delivery.fert.label,
    },
    {
      value: iI5GrnFrtWtUnit,
      setValue: setII5GrnFrtWtUnit,
      errorMessage: eI5GrnFrtWtUnit,
      setErrorMessage: setEI5GrnFrtWtUnit,
      backup: IMBKUP.bI5GrnFrtWtUnit,
      label:
        strImp.delivery.fert.label + " " + strImp.delivery.fert.unitval.label,
    },
    {
      value: iI5FrtAppEffA,
      setValue: setII5FrtAppEffA,
      errorMessage: eI5FrtAppEffA,
      setErrorMessage: setEI5FrtAppEffA,
      backup: IMBKUP.bI5FrtAppEffA,
      label: strImp.delivery.eArea.label,
    },
    {
      value: iI5FrtDelRate,
      setValue: setII5FrtDelRate,
      errorMessage: eI5FrtDelRate,
      setErrorMessage: setEI5FrtDelRate,
      backup: IMBKUP.bI5FrtDelRate,
      label: strImp.delivery.rate.label,
    },
    {
      value: iI5SgrYld,
      setValue: setII5SgrYld,
      errorMessage: eI5SgrYld,
      setErrorMessage: setEI5SgrYld,
      backup: IMBKUP.bI5SgrYld,
      label: strImp.capacity.yield.label,
    },
    {
      value: iI5LdCap,
      setValue: setII5LdCap,
      errorMessage: eI5LdCap,
      setErrorMessage: setEI5LdCap,
      backup: IMBKUP.bI5LdCap,
      label: strImp.perf.lcap.label,
    },
    {
      value: iI5LdCapUnit,
      setValue: setII5LdCapUnit,
      errorMessage: eI5LdCapUnit,
      setErrorMessage: setEI5LdCapUnit,
      backup: IMBKUP.bI5LdCapUnit,
      label: strImp.perf.lcap.label + " " + strImp.perf.lcap.unitval.label,
    },
    {
      value: iI5NoBrkdwn,
      setValue: setII5NoBrkdwn,
      errorMessage: eI5NoBrkdwn,
      setErrorMessage: setEI5NoBrkdwn,
      backup: IMBKUP.bI5NoBrkdwn,
      label: strImp.performance.breakdowns.label,
    },
    {
      value: iI5CmnBrkdwn,
      setValue: setII5CmnBrkdwn,
      errorMessage: eI5CmnBrkdwn,
      setErrorMessage: setEI5CmnBrkdwn,
      backup: IMBKUP.bI5CmnBrkdwn,
      label: strImp.performance.pComBrk.label,
    },
    {
      value: iI5MntcDone,
      setValue: setII5MntcDone,
      errorMessage: eI5MntcDone,
      setErrorMessage: setEI5MntcDone,
      backup: IMBKUP.bI5MntcDone,
      label: strImp.performance.pMaint.label,
    },
    {
      value: iI6Notes,
      setValue: setII6Notes,
      errorMessage: eI6Notes,
      setErrorMessage: setEI6Notes,
      backup: IMBKUP.bI6Notes,
      label: strImp.note.label,
    },
  ];

  // ///////////////////////////////////////////////////////////////////////////
  // CONSTANTS /////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  // Address ///////////////////////////////////////////////////////////////////

  const implement: Address = CreateNewAddress();
  const srvc1: Address = CreateNewAddress();
  const srvc2: Address = CreateNewAddress();
  const srvc3: Address = CreateNewAddress();

  // Camera ////////////////////////////////////////////////////////////////////

  const [camUser, setCamUser] = useState<CameraUsers>();

  enum CameraUsers {
    QRCode,
    FrontView,
    SideView,
    SpecView,
  }

  const [qrDevice, setQRDevice] = useState("");
  const [qrVideos, setQRVideos] = useState([]);
  const [qrCameras, setQRCameras] = useState<
    { label: string; value: string }[]
  >([]);

  const [qrReader, setQRReader] = useState(new BrowserQRCodeReader());

  useEffect(() => {
    setQRCameras(
      qrVideos.map((element: any) => ({
        label: element.label,
        value: element.deviceId,
      }))
    );
  }, [qrVideos]);

  function resetQR() {
    qrReader.reset();
    setII1QRCode("");
  }

  function stopQR() {
    qrReader.reset();
    setQRReader(new BrowserQRCodeReader());
  }

  function againQR() {
    qrReader
      .getVideoInputDevices()
      .then((qrVideo) => {
        setupDevices(qrVideo);
      })
      .catch((err) => {
        console.error(err);
      });
    decodeOnce(qrReader, qrDevice);
  }

  function setupDevices(videos: any) {
    setQRDevice(videos[0].deviceId);
    if (videos.length >= 1) setQRVideos(videos);
  }

  function decodeOnce(codeReader: any, selectedDeviceId: any) {
    codeReader
      .decodeFromInputVideoDevice(selectedDeviceId, "video")
      .then((result: any) => {
        let res = result!.getText();
        res = res.substring(res.lastIndexOf("/") + 1);
        if (
          res.charAt(6) === "I" &&
          res.length === 12 &&
          res.charAt(0) === "R"
        ) {
          setII1QRCode(res);
        } else {
          APopup({
            title: strMac.notif.qrError.title,
            text: strMac.notif.qrError.message,
            denyButtonText: strMac.notif.qrError.center,
          });
          setTimeout(() => {
            resetQR();
            againQR();
          }, 5000);
        }
      })
      .catch((err: any) => {
        if (err.name === "NotFoundException") {
          // Video stream has ended before any code could be detected.
        } else console.error(err);
      });
  }

  useEffect(() => {
    if (iI1QRCode.length > 0) stopQR();
  }, [iI1QRCode, stopQR]);

  // Geolocation ///////////////////////////////////////////////////////////////

  var gLat: any, gLong: any, gAcc: any;
  const str = (n: any) => String(n);
  const isNum = (n: any) => !isNaN(n);
  const num = (n: any) => Number(n);

  function loadImplementMap() {
    APopup({
      icon: "info",
      title: strMac.notif.geoloc.init.title,
      text: strMac.notif.geoloc.init.message,
      confirmButtonText: strMac.notif.geoloc.init.button,
    });
    var map = L.map(ID.GeoLocationPaneMap).setView([14.16255, 121.248497], 6);
    // var lyr = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
    var lyr = "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}";
    var osm = L.tileLayer(lyr);
    osm.addTo(map);

    var refreshMap: any;
    if (!navigator.geolocation) {
      APopup({
        icon: "error",
        title: strMac.notif.geoloc.initError.title,
        text: strMac.notif.geoloc.initError.message,
        confirmButtonText: strMac.notif.geoloc.initError.button,
      });
    } else {
      refreshMap = setInterval(() => {
        if (isNum(gLat) && isNum(gLong) && isNum(gAcc)) {
          setII1Lat(str(gLat));
          setII1Lng(str(gLong));
          setII1Acc(str(gAcc));
        }

        var dev = AppSettings.SERVER === "https://127.0.0.1:4433";
        var locValid = isNum(gLat) && isNum(gLong) && isNum(gAcc);
        var accValid = gAcc <= AppSettings.MIN_ACCURACY;

        if (locValid && (accValid || dev)) {
          clearInterval(refreshMap);
          APopup({
            icon: "success",
            title: strMac.notif.geoloc.success.title,
            text:
              strMac.notif.geoloc.success.lat +
              gLat +
              strMac.notif.geoloc.success.lng +
              gLong +
              strMac.notif.geoloc.success.acc +
              gAcc,
            confirmButtonText: strMac.notif.geoloc.success.button,
          });
          setII1Lat(str(gLat));
          setII1Lng(str(gLong));
          setII1Acc(str(gAcc));
        } else {
          navigator.geolocation.getCurrentPosition(getPosition);
        }
      }, 3000);
    }

    var marker: any, circle: any;
    function getPosition(position: any) {
      gLat = position.coords.latitude;
      gLong = position.coords.longitude;
      gAcc = position.coords.accuracy / 3;
      if (marker) map.removeLayer(marker);
      if (circle) map.removeLayer(circle);

      var machineIcon = L.icon({
        iconUrl: iImplement,
        iconSize: [32, 32],
        popupAnchor: [0, -16],
      });

      marker = L.marker([gLat, gLong], { icon: machineIcon }).addTo(map);
      circle = L.circle([gLat, gLong], { radius: gAcc });
      var featureGroup = L.featureGroup([circle]).addTo(map);
      map.fitBounds(featureGroup.getBounds());
    }
  }

  // Implement type based visibility ///////////////////////////////////////////

  const [, setVTypeGrp] = useState(0);
  const [v01, setV01] = useState(false);
  const [v02, setV02] = useState(false);
  const [v03, setV03] = useState(false);
  const [v04, setV04] = useState(false);
  const [v05, setV05] = useState(false);
  const [v06, setV06] = useState(false);
  const [v07, setV07] = useState(false);
  const [v08, setV08] = useState(false);
  const [v09, setV09] = useState(false);
  const [v10, setV10] = useState(false);
  const [v11, setV11] = useState(false);

  function hideSpecsCard() {
    setV01(false);
    setV02(false);
    setV03(false);
    setV04(false);
    setV05(false);
    setV06(false);
    setV07(false);
    setV08(false);
    setV09(false);
    setV10(false);
    setV11(false);
  }

  function oICType(e: any, val: any) {
    if (val === tMolBrdPlow || val === tSubsoiler || val === tChiselPlow) {
      setVTypeGrp(1);
      hideSpecsCard();
      setV01(true);
    } else if (val === tDiscPlow || val === tDiscHarrow) {
      setVTypeGrp(2);
      hideSpecsCard();
      setV02(true);
    } else if (
      val === tFurrower ||
      val === tItrRowCltvtr ||
      val === tCutAwayCltvtr ||
      val === tTrashInc ||
      val === tDitcher
    ) {
      setVTypeGrp(3);
      hideSpecsCard();
      setV03(true);
    } else if (
      val === tPowerHarrow ||
      val === tSpkTthHarrow ||
      val === tFldCltvtr ||
      val === tRtnManager ||
      val === tTrashRake ||
      val === tRotTiller ||
      val === tMulcher ||
      val === tDozer
    ) {
      setVTypeGrp(4);
      hideSpecsCard();
      setV04(true);
    } else if (val === tTrailer || val === tExcavator) {
      setVTypeGrp(5);
      hideSpecsCard();
      setV05(true);
    } else if (val === tWeeder) {
      setVTypeGrp(6);
      hideSpecsCard();
      setV06(true);
    } else if (val === tBoomSprayer) {
      setVTypeGrp(7);
      hideSpecsCard();
      setV07(true);
    } else if (val === tGranFertAppl || val === tFertApplCutAway) {
      setVTypeGrp(8);
      hideSpecsCard();
      setV08(true);
    } else if (val === tMechPlanter) {
      setVTypeGrp(9);
      hideSpecsCard();
      setV09(true);
    } else if (val === tMechHarvester) {
      setVTypeGrp(10);
      hideSpecsCard();
      setV10(true);
    } else if (val === tCaneGrabLoader) {
      setVTypeGrp(11);
      hideSpecsCard();
      setV11(true);
    } else {
      setVTypeGrp(0);
      hideSpecsCard();
    }
  }

  // Load Profiles and Machines ////////////////////////////////////////////////

  const [oProfiles, setOProfiles] = useState<{ label: string }[]>([]);
  const [oMachines, setOMachines] = useState<{ label: string }[]>([]);

  useEffect(() => {
    // Load Profiles ///////////////////////////////////////////////////////////
    const profiles = fxdRead(fxdProfile.profiles);
    if (profiles) {
      const profs = JSON.parse(profiles);
      var oUsernames = [];
      for (let i = 0; i < profs.length; i++) {
        oUsernames.push({ label: profs[i].sP1Name });
      }
      setOProfiles(oUsernames);
    } else setOProfiles([{ label: strImp.notif.noProfile.title }]);

    // Load Machines ///////////////////////////////////////////////////////////
    var keys = Object.keys(localStorage);
    var enlistedMachinesFromThisProfile = (name: string) =>
      keys.filter((key) => {
        const item = fxdRead(key);
        if (item) {
          let parsedItem;
          try {
            parsedItem = JSON.parse(item);
          } catch (e) {
            parsedItem = "";
          }
          return (
            key.charAt(0) === "R" &&
            key.charAt(6) === "M" &&
            parsedItem.sM1ResName?.label === name
          );
        }
        return false;
      });

    if (enlistedMachinesFromThisProfile(safeRead(iI1ResName)).length === 0) {
      setOMachines([{ label: strImp.notif.noMachine.title }]);
    } else {
      setOMachines(
        enlistedMachinesFromThisProfile(safeRead(iI1ResName)).map((key) => {
          const item = fxdRead(key);
          if (item) {
            let parsedItem;
            try {
              parsedItem = JSON.parse(item);
            } catch (e) {
              parsedItem = "";
            }
            return {
              label:
                parsedItem.sM1QRCode +
                " | " +
                parsedItem.sM1Type.label +
                " | " +
                parsedItem.sM1ResName.label,
            };
          }
          return { label: strImp.notif.noMachine.title };
        })
      );
    }
  }, [oProfiles, oMachines, iI1ResName]);

  // ///////////////////////////////////////////////////////////////////////////
  // LAYOUT ////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  return (
    requireLoadProperly(AvzRoute.implement) ?? (
      <VBar class="main-bg-form form-design">
        <VBar class="v-bar">
          <TitleBar category={strImp.category} />
          <Card title={strImp.basicInfo.title} titleStyle="title">
            <ATextInput
              text={strImp.basicInfo.resName}
              options={oProfiles}
              value={[iI1ResName, setII1ResName]}
              validation={eI1ResName}
              backup={IMBKUP.bI1ResName}
            />
            <ATextInput
              text={strImp.basicInfo.machine}
              options={oMachines}
              value={[iI1Machine, setII1Machine]}
              validation={eI1Machine}
              backup={IMBKUP.bI1Machine}
            />
            <HBar>
              <AButton
                disabled={(() => {
                  var noUser = !camUser;
                  var qrIsUser = camUser === CameraUsers.QRCode;
                  return !(noUser || qrIsUser);
                })()}
                position="left"
                icon={<QrCode2Icon />}
                onClick={() => {
                  if (camUser === CameraUsers.QRCode) {
                    setCamUser(undefined);
                    stopQR();
                  } else {
                    setCamUser(CameraUsers.QRCode);
                    resetQR();
                    againQR();
                  }
                }}
              ></AButton>
              <ATextInput
                disabled
                position="right"
                text={strImp.basicInfo.qrCode}
                value={[iI1QRCode, setII1QRCode]}
                validation={eI1QRCode}
                backup={IMBKUP.bI1QRCode}
              />
            </HBar>
            <VBar
              force
              in={
                camUser === CameraUsers.QRCode && String(iI1QRCode).length === 0
              }
              class="spacer"
            >
              <ATextInput
                text={strImp.basicInfo.qrcodevid}
                options={qrCameras}
                onInputChange={(e, val: any) => {
                  resetQR();
                  let v = qrCameras.find((o) => o.label === val)?.value;
                  if (v !== undefined) {
                    setQRDevice(v);
                    resetQR();
                    decodeOnce(qrReader, v);
                  }
                }}
                value={[
                  qrCameras.find((o) => o.value === qrDevice)?.label,
                  null,
                ]}
                validation={null}
                backup={OverrideBackup}
              />
              <video id="video" />
            </VBar>
            <HBar>
              <AButton
                disabled={(() => {
                  var noUser = !camUser;
                  var frontIsUser = camUser === CameraUsers.FrontView;
                  return !(noUser || frontIsUser);
                })()}
                position="left"
                icon={<CameraAltIcon />}
                onClick={() => {
                  if (camUser === CameraUsers.FrontView) {
                    setCamUser(undefined);
                  } else setCamUser(CameraUsers.FrontView);
                }}
              ></AButton>
              <ATextInput
                disabled
                position="right"
                text={strImp.basicInfo.front}
                value={[iI1FrontVw, setII1FrontVw]}
                validation={eI1FrontVw}
                backup={IMBKUP.bI1FrontVw}
              />
            </HBar>
            <VBar force in={camUser === CameraUsers.FrontView} class="spacer">
              <WebCam setImage={setII1FrontVw} />
            </VBar>
            <HBar>
              <AButton
                disabled={(() => {
                  var noUser = !camUser;
                  var sideIsUser = camUser === CameraUsers.SideView;
                  return !(noUser || sideIsUser);
                })()}
                position="left"
                icon={<CameraAltIcon />}
                onClick={() => {
                  if (camUser === CameraUsers.SideView) {
                    setCamUser(undefined);
                  } else setCamUser(CameraUsers.SideView);
                }}
              ></AButton>
              <ATextInput
                disabled
                position="right"
                text={strImp.basicInfo.side}
                value={[iI1SideVw, setII1SideVw]}
                validation={eI1SideVw}
                backup={IMBKUP.bI1SideVw}
              />
            </HBar>
            <VBar force in={camUser === CameraUsers.SideView} class="spacer">
              <WebCam setImage={setII1SideVw} />
            </VBar>
            <HBar>
              <AButton
                disabled={(() => {
                  var noUser = !camUser;
                  var specIsUser = camUser === CameraUsers.SpecView;
                  return !(noUser || specIsUser);
                })()}
                position="left"
                icon={<CameraAltIcon />}
                onClick={() => {
                  if (camUser === CameraUsers.SpecView) {
                    setCamUser(undefined);
                  } else setCamUser(CameraUsers.SpecView);
                }}
              ></AButton>
              <ATextInput
                disabled
                position="right"
                text={strImp.basicInfo.plate}
                value={[iI1SpecVw, setII1SpecVw]}
                validation={eI1SpecVw}
                backup={IMBKUP.bI1SpecVw}
              />
            </HBar>
            <VBar force in={camUser === CameraUsers.SpecView} class="spacer">
              <WebCam setImage={setII1SpecVw} />
            </VBar>
            <HBar>
              <AButton
                position="left"
                icon={<MyLocationIcon />}
                onClick={() => {
                  loadImplementMap();
                }}
              ></AButton>
              <ATextInput
                disabled
                position="right"
                text={strImp.basicInfo.coordinates}
                value={[iI1Acc, setII1Acc]}
                validation={eI1Acc}
                backup={IMBKUP.bI1Acc}
              />
            </HBar>
            <VBar in={iI1Acc.length > 0} class="spacer">
              <ATextInput
                disabled
                text={strImp.basicInfo.lat}
                value={[iI1Lat, setII1Lat]}
                validation={eI1Lat}
                backup={IMBKUP.bI1Lat}
              />
              <ATextInput
                disabled
                text={strImp.basicInfo.lng}
                value={[iI1Lng, setII1Lng]}
                validation={eI1Lng}
                backup={IMBKUP.bI1Lng}
              />
              <AAlert
                severity={(() => {
                  var acc = num(iI1Acc);
                  return acc === undefined
                    ? "error"
                    : isNum(acc) && acc <= 5
                    ? "success"
                    : isNum(acc) && acc <= 8
                    ? "warning"
                    : "error";
                })()}
                sx={{ width: "100%" }}
              >
                {(() => {
                  var acc = num(iI1Acc);
                  return acc === undefined
                    ? strMac.notif.accuracy.init
                    : acc <= 5
                    ? strMac.notif.accuracy.high
                    : acc <= 8
                    ? strMac.notif.accuracy.low
                    : strMac.notif.accuracy.search;
                })()}
              </AAlert>
            </VBar>
            <GeoLocationPane />
            <ATextInput
              text={strImp.basicInfo.during}
              options={strImp.basicInfo.during.options}
              value={[iI1Loc, setII1Loc]}
              validation={eI1Loc}
              backup={IMBKUP.bI1Loc}
            />
            <hr />
            <AText text={strImp.basicInfo.garage} />
            <Province
              text={strImp.basicInfo.prov}
              address={implement}
              value={[iI1Prov, setII1Prov]}
              validation={eI1Prov}
              backup={IMBKUP.bI1Prov}
            />
            <Municipality
              text={strImp.basicInfo.mun}
              address={implement}
              value={[iI1Mun, setII1Mun]}
              validation={eI1Mun}
              backup={IMBKUP.bI1Mun}
            />
            <Barangay
              text={strImp.basicInfo.brgy}
              address={implement}
              value={[iI1Bgry, setII1Bgry]}
              validation={eI1Bgry}
              backup={IMBKUP.bI1Bgry}
            />
            <hr />
            <ATextInput
              group={(o) => o.type}
              text={strImp.basicInfo.type}
              options={strImp.basicInfo.type.options}
              onInputChange={oICType}
              value={[iI2Type, setII2Type]}
              validation={eI2Type}
              backup={IMBKUP.bI2Type}
            />
          </Card>
          <Card
            in={safeRead(iI2Type).length > 0}
            title={strImp.specifications.title}
            titleStyle="title"
          >
            <ATextInput
              free
              text={strImp.specifications.brand}
              options={[{ label: "Not Applicable" }]}
              value={[iI2Brand, setII2Brand]}
              validation={eI2Brand}
              backup={IMBKUP.bI2Brand}
            />
            <ATextInput
              free
              text={strImp.specifications.model}
              options={[{ label: "Not Applicable" }]}
              value={[iI2Model, setII2Model]}
              validation={eI2Model}
              backup={IMBKUP.bI2Model}
            />
            <HBar in={v01}>
              <ATextInput
                text={strImp.specifications.bottoms}
                value={[iI2NoBtm1, setII2NoBtm1]}
                validation={eI2NoBtm1}
                backup={IMBKUP.bI2NoBtm1}
              />
            </HBar>
            <HBar in={v02} gap={10}>
              <ATextInput
                text={strImp.specifications.bottoms}
                value={[iI2NoBtm2, setII2NoBtm2]}
                validation={eI2NoBtm2}
                backup={IMBKUP.bI2NoBtm2}
              />
              <ATextInput
                text={strImp.specifications.disk}
                value={[iI2SzDk, setII2SzDk]}
                validation={eI2SzDk}
                backup={IMBKUP.bI2SzDk}
              />
            </HBar>
            <HBar in={v03} gap={10}>
              <ATextInput
                text={strImp.specifications.bottoms}
                value={[iI2NoBtm3, setII2NoBtm3]}
                validation={eI2NoBtm3}
                backup={IMBKUP.bI2NoBtm3}
              />
              <ATextInput
                text={strImp.specifications.distance}
                value={[iI2DisRow1, setII2DisRow1]}
                validation={eI2DisRow1}
                backup={IMBKUP.bI2DisRow1}
              />
            </HBar>
            <HBar in={v04 || v10}>
              <ATextInput
                text={strImp.specifications.width}
                value={[iI2WdCut, setII2WdCut]}
                validation={eI2WdCut}
                backup={IMBKUP.bI2WdCut}
              />
            </HBar>
            <HBar in={v05}>
              <ATextInput
                position="left"
                text={strImp.specifications.loading}
                value={[iI2LdCap, setII2LdCap]}
                validation={eI2LdCap}
                backup={IMBKUP.bI2LdCap}
              />
              <ATextInput
                position="right"
                text={strImp.specifications.loading.unitval}
                options={strImp.specifications.loading.unitval.options}
                value={[iI2LdCapUnit, setII2LdCapUnit]}
                validation={eI2LdCapUnit}
                backup={IMBKUP.bI2LdCapUnit}
              />
            </HBar>
            <HBar in={v06} gap={10}>
              <ATextInput
                text={strImp.specifications.rows}
                value={[iI2NoRows1, setII2NoRows1]}
                validation={eI2NoRows1}
                backup={IMBKUP.bI2NoRows1}
              />
              <ATextInput
                text={strImp.specifications.distance}
                value={[iI2DisRow2, setII2DisRow2]}
                validation={eI2DisRow2}
                backup={IMBKUP.bI2DisRow2}
              />
            </HBar>
            <VBar in={v07} class="spacer">
              <HBar gap={10}>
                <ATextInput
                  text={strImp.specifications.noz}
                  value={[iI2NoNzls, setII2NoNzls]}
                  validation={eI2NoNzls}
                  backup={IMBKUP.bI2NoNzls}
                />
                <ATextInput
                  text={strImp.specifications.dNoz}
                  value={[iI2DisNzls, setII2DisNzls]}
                  validation={eI2DisNzls}
                  backup={IMBKUP.bI2DisNzls}
                />
              </HBar>
              <ATextInput
                text={strImp.specifications.tkCap}
                value={[iI2TnkCap1, setII2TnkCap1]}
                validation={eI2TnkCap1}
                backup={IMBKUP.bI2TnkCap1}
              />
            </VBar>
            <VBar in={v08} class="spacer">
              <HBar gap={10}>
                <ATextInput
                  text={strImp.specifications.out}
                  value={[iI2NoOutlets, setII2NoOutlets]}
                  validation={eI2NoOutlets}
                  backup={IMBKUP.bI2NoOutlets}
                />
                <ATextInput
                  text={strImp.specifications.dOut}
                  value={[iI2DisOutlets, setII2DisOutlets]}
                  validation={eI2DisOutlets}
                  backup={IMBKUP.bI2DisOutlets}
                />
              </HBar>
              <HBar>
                <ATextInput
                  position="left"
                  text={strImp.specifications.tkCap2}
                  value={[iI2TnkCap2, setII2TnkCap2]}
                  validation={eI2TnkCap2}
                  backup={IMBKUP.bI2TnkCap2}
                />
                <ATextInput
                  position="right"
                  text={strImp.specifications.tkCap2.unitval}
                  options={strImp.specifications.tkCap2.unitval.options}
                  value={[iI2TnkCap2Unit, setII2TnkCap2Unit]}
                  validation={eI2TnkCap2Unit}
                  backup={IMBKUP.bI2TnkCap2Unit}
                />
              </HBar>
            </VBar>
            <VBar in={v09} class="spacer">
              <ATextInput
                text={strImp.specifications.type1.text}
                options={strImp.specifications.type1.options}
                value={[iI2TpPlntr, setII2TpPlntr]}
                validation={eI2TpPlntr}
                backup={IMBKUP.bI2TpPlntr}
              />
              <ATextInput
                text={strImp.specifications.rows}
                value={[iI2NoRows2, setII2NoRows2]}
                validation={eI2NoRows2}
                backup={IMBKUP.bI2NoRows2}
              />
            </VBar>
            <VBar in={v11} class="spacer">
              <ATextInput
                text={strImp.specifications.type2.text}
                options={strImp.specifications.type2.options}
                value={[iI2TpCnGrbAttch, setII2TpCnGrbAttch]}
                validation={eI2TpCnGrbAttch}
                backup={IMBKUP.bI2TpCnGrbAttch}
              />
            </VBar>
          </Card>
          <Card
            in={safeRead(iI2Type).length > 0}
            title={strImp.operations.title}
            titleStyle="title"
          >
            <ATextInput
              value={[iI3Prim, setII3Prim]}
              text={strImp.operations.pri}
              options={strImp.rent.fOper.options}
              validation={eI3Prim}
              backup={IMBKUP.bI3Prim}
            />
            <HBar in={safeRead(iI3Prim).length > 0}>
              <ATextInput
                value={[iI3Sec, setII3Sec]}
                text={strImp.operations.sec}
                options={strImp.rent.fOper.options}
                validation={eI3Sec}
                backup={IMBKUP.bI3Sec}
              />
            </HBar>
            <HBar in={safeRead(iI3Sec).length > 0}>
              <ATextInput
                value={[iI3Ter, setII3Ter]}
                text={strImp.operations.ter}
                options={strImp.rent.fOper.options}
                validation={eI3Ter}
                backup={IMBKUP.bI3Ter}
              />
            </HBar>
          </Card>
          <Card
            in={safeRead(iI2Type).length > 0}
            title={strImp.usage2.title}
            titleStyle="title"
          >
            <ATextInput
              text={strImp.usage2.usage}
              options={strImp.usage2.usage.options}
              value={[iI4Usage, setII4Usage]}
              validation={eI4Usage}
              backup={IMBKUP.bI4Usage}
            />
            <ATextInput
              text={strImp.usage2.mode}
              options={strImp.usage2.mode.options}
              value={[iI4Mode, setII4Mode]}
              validation={eI4Mode}
              backup={IMBKUP.bI4Mode}
            />
            <HBar
              in={
                safeRead(iI4Mode) === safeRead(strImp.usage2.mode.options[2]) ||
                safeRead(iI4Mode) === safeRead(strImp.usage2.mode.options[3])
              }
            >
              <ATextInput
                text={strImp.usage2.agency}
                value={[iI4Agency, setII4Agency]}
                validation={eI4Agency}
                backup={IMBKUP.bI4Agency}
              />
            </HBar>
            <ATextInput
              text={strImp.usage2.acqYear}
              value={[iI4YrAcqrd, setII4YrAcqrd]}
              validation={eI4YrAcqrd}
              backup={IMBKUP.bI4YrAcqrd}
            />
            <ATextInput
              text={strImp.usage2.cond}
              options={strImp.usage2.cond.options}
              value={[iI4ConAcqrd, setII4ConAcqrd]}
              validation={eI4ConAcqrd}
              backup={IMBKUP.bI4ConAcqrd}
            />
            <ATextInput
              value={[iI4PrsntCon, setII4PrsntCon]}
              text={strImp.usage2.pCond}
              options={strImp.usage2.pCond.options}
              validation={eI4PrsntCon}
              backup={IMBKUP.bI4PrsntCon}
            />
            <VBar
              in={safeRead(iI4PrsntCon) === strMac.usage.pCond.options[1].label}
              id="unused"
              class="spacer"
            >
              <ATextInput
                text={strImp.unused.uuYear}
                value={[iI4YrUnused, setII4YrUnused]}
                validation={eI4YrUnused}
                backup={IMBKUP.bI4YrUnused}
              />
              <AText text={strImp.unused.subtitle} />
              <ATick
                options={strImp.unused.options}
                value={[iI4RsnUnused, setII4RsnUnused]}
                backup={IMBKUP.bI4RsnUnused}
              />
              <ATextInput
                text={strImp.unused.uuOthers}
                value={[iI4UuOthers, setII4UuOthers]}
                validation={eI4UuOthers}
                backup={IMBKUP.bI4UuOthers}
              />
            </VBar>
            <VBar
              in={safeRead(iI4PrsntCon) === strMac.usage.pCond.options[2].label}
              id="unserviceable"
              class="spacer"
            >
              <ATextInput
                text={strImp.unserv.usYear}
                value={[iI4YrUnserv, setII4YrUnserv]}
                validation={eI4YrUnserv}
                backup={IMBKUP.bI4YrUnserv}
              />
              <AText text={strImp.unserv.subtitle} />
              <ATick
                options={strImp.unserv.options}
                value={[iI4RsnUnserv, setII4RsnUnserv]}
                backup={IMBKUP.bI4RsnUnserv}
              />
              <ATextInput
                text={strImp.unserv.usOthers}
                value={[iI4UsOthers, setII4UsOthers]}
                validation={eI4UsOthers}
                backup={IMBKUP.bI4UsOthers}
              />
            </VBar>
          </Card>
          <Card
            in={
              (safeRead(iI4Usage) === strImp.usage.options[1].label ||
                safeRead(iI4Usage) === strImp.usage.options[2].label) &&
              safeRead(iI2Type).length > 0
            }
            title={strImp.rent.title}
            titleStyle="title"
          >
            <ATextInput
              text={strImp.rent.with}
              options={strImp.rent.with.options}
              value={[iI4WithTractor, setII4WithTractor]}
              validation={eI4WithTractor}
              backup={IMBKUP.bI4WithTractor}
            />
            <VBar
              gap={10}
              in={
                safeRead(iI4WithTractor) === strImp.rent.with.options[1].label
              }
            >
              <ATextInput
                unitPosition="start"
                text={strImp.rent.rate}
                value={[iI4SvcRtHa, setII4SvcRtHa]}
                validation={eI4SvcRtHa}
                backup={IMBKUP.bI4SvcRtHa}
              />
              <HBar in={safeRead(iI4Usage) === strImp.usage.options[2].label}>
                <ATextInput
                  unitPosition="start"
                  text={strImp.rent.rateNon}
                  value={[iI4SvcRtHaNon, setII4SvcRtHaNon]}
                  validation={eI4SvcRtHaNon}
                  backup={IMBKUP.bI4SvcRtHaNon}
                />
              </HBar>
              <AText text={strImp.usage2.contact.title} />
              <ATick
                options={[{ label: strImp.usage2.contact.same, key: 0 }]}
                value={[iI4SameOwn, setII4SameOwn]}
                backup={IMBKUP.bI4SameOwn}
              />
              <HBar>
                <ATextInput
                  width={50}
                  position="left"
                  text={strImp.usage2.contact1}
                  value={[iI4BusCont1, setII4BusCont1]}
                  validation={eI4BusCont1}
                  backup={IMBKUP.bI4BusCont1}
                />
                <ATextInput
                  width={50}
                  position="right"
                  text={strImp.usage2.contact2}
                  value={[iI4BusCont2, setII4BusCont2]}
                  validation={eI4BusCont2}
                  backup={IMBKUP.bI4BusCont2}
                />
              </HBar>
              <ATick
                options={[{ label: strImp.usage2.contact.consent, key: 0 }]}
                value={[iI4Consent, setII4Consent]}
                backup={IMBKUP.bI4Consent}
              />
              <hr />
              <AText text={strImp.rent.service.label} />
              <HBar gap={10}>
                <Province
                  text={strImp.basicInfo.prov}
                  address={srvc1}
                  value={[iI4SrvcLoc1P, setII4SrvcLoc1P]}
                  validation={eI4SrvcLoc1P}
                  backup={IMBKUP.bI4SrvcLoc1P}
                />
                <Municipality
                  text={strImp.basicInfo.mun}
                  address={srvc1}
                  value={[iI4SrvcLoc1M, setII4SrvcLoc1M]}
                  validation={eI4SrvcLoc1M}
                  backup={IMBKUP.bI4SrvcLoc1M}
                />
              </HBar>
              <HBar in={safeRead(iI4SrvcLoc1M).length > 0} gap={10}>
                <Province
                  text={strImp.basicInfo.prov}
                  address={srvc2}
                  value={[iI4SrvcLoc2P, setII4SrvcLoc2P]}
                  validation={eI4SrvcLoc2P}
                  backup={IMBKUP.bI4SrvcLoc2P}
                />
                <Municipality
                  text={strImp.basicInfo.mun}
                  address={srvc2}
                  value={[iI4SrvcLoc2M, setII4SrvcLoc2M]}
                  validation={eI4SrvcLoc2M}
                  backup={IMBKUP.bI4SrvcLoc2M}
                />
              </HBar>
              <HBar in={safeRead(iI4SrvcLoc2M).length > 0} gap={10}>
                <Province
                  text={strImp.basicInfo.prov}
                  address={srvc3}
                  value={[iI4SrvcLoc3P, setII4SrvcLoc3P]}
                  validation={eI4SrvcLoc3P}
                  backup={IMBKUP.bI4SrvcLoc3P}
                />
                <Municipality
                  text={strImp.basicInfo.mun}
                  address={srvc3}
                  value={[iI4SrvcLoc3M, setII4SrvcLoc3M]}
                  validation={eI4SrvcLoc3M}
                  backup={IMBKUP.bI4SrvcLoc3M}
                />
              </HBar>
            </VBar>
          </Card>
          <Card
            in={safeRead(iI2Type).length > 0}
            title={strImp.perf.title}
            titleStyle="title"
          >
            <ATextInput
              text={strImp.perf.time}
              value={[iI5TmCom1Ha, setII5TmCom1Ha]}
              validation={eI5TmCom1Ha}
              backup={IMBKUP.bI5TmCom1Ha}
            />
            <VBar
              in={v01 || v02 || v03 || v04 || v06 || v07 || v08 || v09}
              class="spacer"
            >
              <HBar in={v01 || v02 || v03 || v04 || v06}>
                <ATextInput
                  text={strImp.perf.passes}
                  value={[iI5NoPasses, setII5NoPasses]}
                  validation={eI5NoPasses}
                  backup={IMBKUP.bI5NoPasses}
                />
              </HBar>
            </VBar>
            <VBar in={v08} class="spacer">
              <HBar>
                <ATextInput
                  position="left"
                  text={strImp.perf.gran}
                  value={[iI5GrnFrtWt, setII5GrnFrtWt]}
                  validation={eI5GrnFrtWt}
                  backup={IMBKUP.bI5GrnFrtWt}
                />
                <ATextInput
                  position="right"
                  text={strImp.perf.gran.unitval}
                  options={strImp.perf.gran.unitval.options}
                  value={[iI5GrnFrtWtUnit, setII5GrnFrtWtUnit]}
                  validation={eI5GrnFrtWtUnit}
                  backup={IMBKUP.bI5GrnFrtWtUnit}
                />
              </HBar>
              <ATextInput
                text={strImp.perf.fertapp}
                value={[iI5FrtAppEffA, setII5FrtAppEffA]}
                validation={eI5FrtAppEffA}
                backup={IMBKUP.bI5FrtAppEffA}
              />
              <ATextInput
                text={strImp.perf.fertdel}
                value={[iI5FrtDelRate, setII5FrtDelRate]}
                validation={eI5FrtDelRate}
                backup={IMBKUP.bI5FrtDelRate}
              />
            </VBar>
            <VBar in={v10} class="spacer">
              <ATextInput
                text={strImp.perf.sugar}
                value={[iI5SgrYld, setII5SgrYld]}
                validation={eI5SgrYld}
                backup={IMBKUP.bI5SgrYld}
              />
            </VBar>
            <VBar in={v11} class="spacer">
              <HBar>
                <ATextInput
                  position="left"
                  text={strImp.perf.lcap}
                  value={[iI5LdCap, setII5LdCap]}
                  validation={eI5LdCap}
                  backup={IMBKUP.bI2LdCap}
                />
                <ATextInput
                  position="right"
                  text={strImp.perf.lcap.unitval}
                  options={strImp.perf.lcap.unitval.options}
                  value={[iI5LdCapUnit, setII5LdCapUnit]}
                  validation={eI5LdCapUnit}
                  backup={IMBKUP.bI2LdCapUnit}
                />
              </HBar>
            </VBar>
            <ATextInput
              text={strImp.perf.breakd}
              value={[iI5NoBrkdwn, setII5NoBrkdwn]}
              validation={eI5NoBrkdwn}
              backup={IMBKUP.bI5NoBrkdwn}
            />
            <ATextInput
              max={3}
              text={strImp.perf.combreak}
              value={[iI5CmnBrkdwn, setII5CmnBrkdwn]}
              options={strImp.perf.combreak.options}
              validation={eI5CmnBrkdwn}
              backup={IMBKUP.bI5CmnBrkdwn}
            />
            <ATextInput
              free
              max={strImp.perf.maint.options.length - 1}
              text={strImp.perf.maint}
              value={[iI5MntcDone, setII5MntcDone]}
              options={strImp.perf.maint.options}
              validation={eI5MntcDone}
              backup={IMBKUP.bI5MntcDone}
            />
          </Card>
          <Card>
            <ATextInput
              rows={7}
              text={strImp.note}
              value={[iI6Notes, setII6Notes]}
              validation={eI6Notes}
              backup={IMBKUP.bI6Notes}
            />
          </Card>
          <AButton
            label={strImp.save}
            color="secondary"
            icon={<SaveIcon />}
            onClick={async () => {
              saveEncodedImplement();
            }}
            height={50}
          />
          <div className="btn-group" role="group">
            <button className="btn btn-primary" type="button">
              <Nav.Link
                key={AvzRoute.encode}
                as={NavLink}
                to={AvzRoute.encode}
                end
              >
                Go to Encode
              </Nav.Link>
            </button>
            <button className="btn btn-primary" type="button">
              <Nav.Link
                key={AvzRoute.machine}
                as={NavLink}
                to={AvzRoute.machine}
                end
              >
                Go to Machine
              </Nav.Link>
            </button>
          </div>
        </VBar>
      </VBar>
    )
  );

  async function saveEncodedImplement() {
    const Question = (value: any) => {
      return QUESTIONS.find((q) => q.label === value);
    };

    var required = [
      Question(iI1ResName),
      Question(iI1QRCode),
      Question(iI1Machine),
      Question(iI1FrontVw),
      Question(iI1SideVw),
      Question(iI1SpecVw),
      Question(iI1Acc),
      Question(iI1Lat),
      Question(iI1Lng),
      Question(iI1Loc),
      Question(iI1Prov),
      Question(iI1Mun),
      Question(iI1Bgry),
      Question(iI2Type),
    ];

    if (safeRead(iI2Type).length > 0) {
      required.push(Question(iI2Brand));
      required.push(Question(iI2Model));

      if (v01) {
        required.push(Question(iI2NoBtm1));
      }
      if (v02) {
        required.push(Question(iI2NoBtm2));
        required.push(Question(iI2SzDk));
      }
      if (v03) {
        required.push(Question(iI2NoBtm3));
        required.push(Question(iI2DisRow1));
      }
      if (v04 || v10) {
        required.push(Question(iI2WdCut));
      }
      if (v05) {
        required.push(Question(iI2LdCap));
        required.push(Question(iI2LdCapUnit));
      }
      if (v06) {
        required.push(Question(iI2NoRows1));
        required.push(Question(iI2DisRow2));
      }
      if (v07) {
        required.push(Question(iI2NoNzls));
        required.push(Question(iI2DisNzls));
        required.push(Question(iI2TnkCap1));
      }
      if (v08) {
        required.push(Question(iI2NoOutlets));
        required.push(Question(iI2DisOutlets));
        required.push(Question(iI2TnkCap2));
        required.push(Question(iI2TnkCap2Unit));
      }
      if (v09) {
        required.push(Question(iI2TpPlntr));
        required.push(Question(iI2NoRows2));
      }
      if (v11) {
        required.push(Question(iI2TpCnGrbAttch));
      }
    }

    if (safeRead(iI2Type).length > 0) {
      required.push(Question(iI3Prim));
      if (safeRead(iI3Prim).length > 0) {
        required.push(Question(iI3Sec));
      }
      if (safeRead(iI3Sec).length > 0) {
        required.push(Question(iI3Ter));
      }
    }

    if (safeRead(iI2Type).length > 0) {
      required.push(Question(iI4Usage));
      required.push(Question(iI4Mode));
      if (
        safeRead(iI4Usage) === safeRead(strImp.usage.options[1].label) ||
        safeRead(iI4Usage) === safeRead(strImp.usage.options[2].label)
      ) {
        required.push(Question(iI4Agency));
      }
      required.push(Question(iI4YrAcqrd));
      required.push(Question(iI4ConAcqrd));
      required.push(Question(iI4PrsntCon));
      if (safeRead(iI4PrsntCon) === strMac.usage.pCond.options[1].label) {
        required.push(Question(iI4YrUnused));
        required.push(Question(iI4RsnUnused));
        required.push(Question(iI4UuOthers));
      }
      if (safeRead(iI4PrsntCon) === strMac.usage.pCond.options[2].label) {
        required.push(Question(iI4YrUnserv));
        required.push(Question(iI4RsnUnserv));
        required.push(Question(iI4UsOthers));
      }
    }

    if (
      (safeRead(iI4Usage) === strImp.usage.options[1].label ||
        safeRead(iI4Usage) === strImp.usage.options[2].label) &&
      safeRead(iI2Type).length > 0
    ) {
      required.push(Question(iI4WithTractor));

      if (safeRead(iI4WithTractor) === strImp.rent.with.options[1].label) {
        required.push(Question(iI4SvcRtHa));
        if (safeRead(iI4Usage) === strImp.usage.options[2].label) {
          required.push(Question(iI4SvcRtHaNon));
        }
        required.push(Question(iI4SameOwn));
        if (safeRead(iI4SameOwn) === strImp.usage2.contact.same) {
          required.push(Question(iI4BusCont1));
          required.push(Question(iI4BusCont2));
          required.push(Question(iI4Consent));
        }
        required.push(Question(iI4SrvcLoc1P));
        required.push(Question(iI4SrvcLoc1M));
        if (safeRead(iI4SrvcLoc1M).length > 0) {
          required.push(Question(iI4SrvcLoc2P));
          required.push(Question(iI4SrvcLoc2M));
        }
        if (safeRead(iI4SrvcLoc2M).length > 0) {
          required.push(Question(iI4SrvcLoc3P));
          required.push(Question(iI4SrvcLoc3M));
        }
      }
    }

    if (safeRead(iI2Type).length > 0) {
      required.push(Question(iI5TmCom1Ha));
      if (v01 || v02 || v03 || v04 || v06 || v07 || v08 || v09) {
        required.push(Question(iI5NoPasses));
      }
      if (v08) {
        required.push(Question(iI5GrnFrtWt));
        required.push(Question(iI5GrnFrtWtUnit));
        required.push(Question(iI5FrtAppEffA));
        required.push(Question(iI5FrtDelRate));
      }
      if (v10) {
        required.push(Question(iI5SgrYld));
      }
      if (v11) {
        required.push(Question(iI5LdCap));
        required.push(Question(iI5LdCapUnit));
      }
      required.push(Question(iI5NoBrkdwn));
      required.push(Question(iI5CmnBrkdwn));
      required.push(Question(iI5MntcDone));
    }

    required.forEach((r) => {
      if (r) r.errorMessage.required = true;
    });

    var error;
    var hasError = required.some((r) => {
      if (r) {
        if (r.value === null || r.value === "") {
          error = r.label;
          return true;
        }
      }
      return false;
    });

    if (typeof JSON == "undefined") {
      APopup({
        type: "error",
        text: strPrf.notif.browserError,
      });
    } else if (hasError && error) {
      APopup({
        type: "error",
        title: error,
        text: strPrf.notif.missing,
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      APopup({
        type: "success",
        text: strPrf.notif.saveIni,
      });
    }

    if (!hasError || !error) {
      let data = {
        sI1ResName: iI1ResName,
        sI1QRCode: iI1QRCode,
        sI1Machine: iI1Machine,
        sI1FrontVw: iI1FrontVw,
        sI1SideVw: iI1SideVw,
        sI1SpecVw: iI1SpecVw,
        sI1Acc: iI1Acc,
        sI1Lat: iI1Lat,
        sI1Lng: iI1Lng,
        sI1Loc: iI1Loc,
        sI1Prov: iI1Prov,
        sI1Mun: iI1Mun,
        sI1Bgry: iI1Bgry,
        sI2Type: iI2Type,
        sI2Brand: iI2Brand,
        sI2Model: iI2Model,
        sI2NoBtm1: iI2NoBtm1,
        sI2NoBtm2: iI2NoBtm2,
        sI2SzDk: iI2SzDk,
        sI2NoBtm3: iI2NoBtm3,
        sI2DisRow1: iI2DisRow1,
        sI2WdCut: iI2WdCut,
        sI2LdCap: iI2LdCap,
        sI2LdCapUnit: iI2LdCapUnit,
        sI2NoRows1: iI2NoRows1,
        sI2DisRow2: iI2DisRow2,
        sI2NoNzls: iI2NoNzls,
        sI2DisNzls: iI2DisNzls,
        sI2TnkCap1: iI2TnkCap1,
        sI2NoOutlets: iI2NoOutlets,
        sI2DisOutlets: iI2DisOutlets,
        sI2TnkCap2: iI2TnkCap2,
        sI2TnkCap2Unit: iI2TnkCap2Unit,
        sI2TpPlntr: iI2TpPlntr,
        sI2NoRows2: iI2NoRows2,
        sI2TpCnGrbAttch: iI2TpCnGrbAttch,
        sI3Prim: iI3Prim,
        sI3Sec: iI3Sec,
        sI3Ter: iI3Ter,
        sI4Usage: iI4Usage,
        sI4Mode: iI4Mode,
        sI4Agency: iI4Agency,
        sI4YrAcqrd: iI4YrAcqrd,
        sI4ConAcqrd: iI4ConAcqrd,
        sI4PrsntCon: iI4PrsntCon,
        sI4YrUnused: iI4YrUnused,
        sI4RsnUnused: iI4RsnUnused,
        sI4UuOthers: iI4UuOthers,
        sI4YrUnserv: iI4YrUnserv,
        sI4RsnUnserv: iI4RsnUnserv,
        sI4UsOthers: iI4UsOthers,
        sI4WithhTractor: iI4WithTractor,
        sI4SvcRtHa: iI4SvcRtHa,
        sI4SvcRtHaNon: iI4SvcRtHaNon,
        sI4SameOwn: iI4SameOwn,
        sI4BusCont1: iI4BusCont1,
        sI4BusCont2: iI4BusCont2,
        sI4Consent: iI4Consent,
        sI4SrvcLoc1P: iI4SrvcLoc1P,
        sI4SrvcLoc1M: iI4SrvcLoc1M,
        sI4SrvcLoc2P: iI4SrvcLoc2P,
        sI4SrvcLoc2M: iI4SrvcLoc2M,
        sI4SrvcLoc3P: iI4SrvcLoc3P,
        sI4SrvcLoc3M: iI4SrvcLoc3M,
        sI5TmCom1Ha: iI5TmCom1Ha,
        sI5NoPasses: iI5NoPasses,
        sI5GrnFrtWt: iI5GrnFrtWt,
        sI5GrnFrtWtUnit: iI5GrnFrtWtUnit,
        sI5FrtAppEffA: iI5FrtAppEffA,
        sI5FrtDelRate: iI5FrtDelRate,
        sI5SgrYld: iI5SgrYld,
        sI5LdCap: iI5LdCap,
        sI5LdCapUnit: iI5LdCapUnit,
        sI5NoBrkdwn: iI5NoBrkdwn,
        sI5CmnBrkdwn: iI5CmnBrkdwn,
        sI5MntcDone: iI5MntcDone,
        sI6Notes: iI6Notes,
        sI0Uname: fxdRead(fxdUser.name),
        sI0Encoded: Date.now().toString(),
        sI0Status: DATA_STATUS.local,
      } as ImplementData;

      fxdWrite(fxdGen.editing, FORMS.null);
      var zipname = safeRead(iI1QRCode) + ".zip";
      var filename = safeRead(iI1QRCode) + ".json";

      createZIP(zipname, AppSettings.ZIPPASS, [
        { filename: filename, content: JSON.stringify(data, undefined, 2) },
      ]);

      fxdWrite(safeRead(iI1QRCode), JSON.stringify(data));

      clearFields();
      deleteMachineBackups();
      APopup({
        type: "success",
        title: strImp.notif.save.title,
        text: strImp.notif.save.message,
        confirmButtonColor: strImp.notif.save.center,
      }).then(() => {
        GOTO(AvzRoute.machine);
      });
    }
  }

  function clearFields() {
    QUESTIONS.forEach((question) => {
      if (typeof question.setValue === "function") {
        question.setValue("");
      }
    });
  }
}

// /////////////////////////////////////////////////////////////////////////////
// IMPLEMENT FORMAT ////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export interface ImplementData {
  sI1ResName: string;
  sI1QRCode: string;
  sI1Machine: string;
  sI1FrontVw: string;
  sI1SideVw: string;
  sI1SpecVw: string;
  sI1Acc: string;
  sI1Lat: string;
  sI1Lng: string;
  sI1Loc: string;
  sI1Prov: string;
  sI1Mun: string;
  sI1Bgry: string;
  sI2Type: string;
  sI2Brand: string;
  sI2Model: string;
  sI2NoBtm1: string;
  sI2NoBtm2: string;
  sI2SzDk: string;
  sI2NoBtm3: string;
  sI2DisRow1: string;
  sI2WdCut: string;
  sI2LdCap: string;
  sI2LdCapUnit: string;
  sI2NoRows1: string;
  sI2DisRow2: string;
  sI2NoNzls: string;
  sI2DisNzls: string;
  sI2TnkCap1: string;
  sI2NoOutlets: string;
  sI2DisOutlets: string;
  sI2TnkCap2: string;
  sI2TnkCap2Unit: string;
  sI2TpPlntr: string;
  sI2NoRows2: string;
  sI2TpCnGrbAttch: string;
  sI3Prim: string;
  sI3Sec: string;
  sI3Ter: string;
  sI4Usage: string;
  sI4Mode: string;
  sI4Agency: string;
  sI4YrAcqrd: string;
  sI4ConAcqrd: string;
  sI4PrsntCon: string;
  sI4YrUnused: string;
  sI4RsnUnused: string;
  sI4UuOthers: string;
  sI4YrUnserv: string;
  sI4RsnUnserv: string;
  sI4UsOthers: string;
  sI4WithhTractor: string;
  sI4SvcRtHa: string;
  sI4SvcRtHaNon: string;
  sI4SameOwn: string;
  sI4BusCont1: string;
  sI4BusCont2: string;
  sI4Consent: string;
  sI4SrvcLoc1P: string;
  sI4SrvcLoc1M: string;
  sI4SrvcLoc2P: string;
  sI4SrvcLoc2M: string;
  sI4SrvcLoc3P: string;
  sI4SrvcLoc3M: string;
  sI5TmCom1Ha: string;
  sI5NoPasses: string;
  sI5GrnFrtWt: string;
  sI5GrnFrtWtUnit: string;
  sI5FrtAppEffA: string;
  sI5FrtDelRate: string;
  sI5SgrYld: string;
  sI5LdCap: string;
  sI5LdCapUnit: string;
  sI5NoBrkdwn: string;
  sI5CmnBrkdwn: string;
  sI5MntcDone: string;
  sI6Notes: string;
  sI0Uname: string;
  sI0Encoded: string;
  sI0Status: string;
}
