export var SRA_OFFICES: GeoJSON.FeatureCollection = {
  type: "FeatureCollection",
  // name: "SRA DISTRICT OFFICE 2",
  // crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: { Name: "CARSUMCO MILL DISTRICT" },
      geometry: {
        type: "Point",
        coordinates: [121.538264402000081, 17.710146591000065, 0.0],
      },
    },
    {
      type: "Feature",
      properties: { Name: "ISABELA MILL DISTRICT" },
      geometry: {
        type: "Point",
        coordinates: [121.855899935999986, 17.066381208000053, 0.0],
      },
    },
    {
      type: "Feature",
      properties: { Name: "TARLAC MILL DISTRICT" },
      geometry: {
        type: "Point",
        coordinates: [120.62724259900115, 15.330645199999916, 0.0],
      },
    },
    {
      type: "Feature",
      properties: { Name: "PAMPANGA MILL DISTRICT" },
      geometry: {
        type: "Point",
        coordinates: [120.530608466001766, 14.988026342999881, 0.0],
      },
    },
    {
      type: "Feature",
      properties: { Name: "DON PEDRO MILL DISTRICT" },
      geometry: {
        type: "Point",
        coordinates: [120.673123588001488, 14.051513935999942, 0.0],
      },
    },
    {
      type: "Feature",
      properties: { Name: "PENSUMIL DISTRICT" },
      geometry: {
        type: "Point",
        coordinates: [123.268188657000081, 13.563164704000028, 0.0],
      },
    },
    {
      type: "Feature",
      properties: { Name: "BUKIDNON MILL DISTRICT" },
      geometry: {
        type: "Point",
        coordinates: [125.057362238998564, 7.831179191000022, 0.0],
      },
    },
    {
      type: "Feature",
      properties: { Name: "DAVAO MILL DISTRICT" },
      geometry: {
        type: "Point",
        coordinates: [125.343723889995971, 6.682140911999952, 0.0],
      },
    },
    {
      type: "Feature",
      properties: { Name: "SUGAR REGULATORY ADMINISTRATION" },
      geometry: {
        type: "Point",
        coordinates: [122.9376038, 10.653387300000077, 0.0],
      },
    },
    {
      type: "Feature",
      properties: { Name: "ILOILO  MILL DISTRICT" },
      geometry: {
        type: "Point",
        coordinates: [122.660361111000014, 11.105861111000026, 0.0],
      },
    },
    {
      type: "Feature",
      properties: { Name: "MA-AO MILL DISTRICT" },
      geometry: {
        type: "Point",
        coordinates: [122.997196557000024, 10.482455259000062, 0.0],
      },
    },
    {
      type: "Feature",
      properties: { Name: "LA CARLOTA/BISCOM  MILL DISTRICT" },
      geometry: {
        type: "Point",
        coordinates: [122.987476117000014, 10.40819030800003, 0.0],
      },
    },
    {
      type: "Feature",
      properties: { Name: "BAIS-URSUMCO MILL DISTRICT" },
      geometry: {
        type: "Point",
        coordinates: [122.930743700000079, 9.717855200000031, 0.0],
      },
    },
    {
      type: "Feature",
      properties: { Name: "TOLONG MILL DISTRICT" },
      geometry: {
        type: "Point",
        coordinates: [122.85360146900004, 9.351244992000032, 0.0],
      },
    },
    {
      type: "Feature",
      properties: { Name: "VICTORIAS MILL DISTRICT" },
      geometry: {
        type: "Point",
        coordinates: [123.073975691000086, 10.903481996000041, 0.0],
      },
    },
    {
      type: "Feature",
      properties: { Name: "BACOLOD-MURCIA/FIRST FARMERS MILL DISTRICT" },
      geometry: {
        type: "Point",
        coordinates: [122.9376038, 10.653387300000077, 0.0],
      },
    },
    {
      type: "Feature",
      properties: { Name: "HPCO MILL DISTRICT" },
      geometry: {
        type: "Point",
        coordinates: [122.9706194, 10.788760300000037, 0.0],
      },
    },
    {
      type: "Feature",
      properties: { Name: "LOPEZ/ SAGAY-DANAO MILL DISTRICT" },
      geometry: {
        type: "Point",
        coordinates: [123.41320956400007, 10.892028517000028, 0.0],
      },
    },
    {
      type: "Feature",
      properties: { Name: "SAN CARLOS MILL DISTRICT" },
      geometry: {
        type: "Point",
        coordinates: [123.4200387180001, 10.481424293000032, 0.0],
      },
    },
    {
      type: "Feature",
      properties: { Name: "SONEDCO-DACONGCOGON MILL DISTRICT" },
      geometry: {
        type: "Point",
        coordinates: [122.812747760000065, 9.996230978000028, 0.0],
      },
    },
    {
      type: "Feature",
      properties: { Name: "BOGO-MEDELLIN MILL DISTRICT" },
      geometry: {
        type: "Point",
        coordinates: [123.963867013, 11.111268197000072, 0.0],
      },
    },
    {
      type: "Feature",
      properties: { Name: "ORMOC-HISUMCO MILL DISTRICT" },
      geometry: {
        type: "Point",
        coordinates: [124.605012962999822, 11.030294266000048, 0.0],
      },
    },
  ],
};

export var SUGAR_MILL: GeoJSON.FeatureCollection = {
  type: "FeatureCollection",
  // name: "SUGAR MILL2",
  // crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        NAME_1: "Negros Oriental",
        NAME_2: "Bais City",
        NAME_MILL: "UNIVERSAL ROBINA CORP. - URSUMCO",
      },
      geometry: {
        type: "Point",
        coordinates: [123.100852724173919, 9.634624280785999, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Cebu",
        NAME_2: "Medellin",
        NAME_MILL: "BOGO - MEDELLIN/DURANO MILLING COMPANY INC.",
      },
      geometry: {
        type: "Point",
        coordinates: [123.964270476101646, 11.113861233109446, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Negros Occidental",
        NAME_2: "La Carlota City",
        NAME_MILL: "LA CARLOTA SUGAR CENTRAL",
      },
      geometry: {
        type: "Point",
        coordinates: [122.936653256742474, 10.393150097798808, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Negros Occidental",
        NAME_2: "Binalbagan",
        NAME_MILL: "BINALBAGAN ISABELA SUGAR CO.",
      },
      geometry: {
        type: "Point",
        coordinates: [122.867122802275588, 10.203899439207817, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Negros Occidental",
        NAME_2: "Victorias City",
        NAME_MILL: "VICTORIAS MILLING CO.",
      },
      geometry: {
        type: "Point",
        coordinates: [123.063609417740494, 10.88165714506124, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Negros Occidental",
        NAME_2: "Silay City",
        NAME_MILL: "HAWAIIAN-PHILIPPINES COMPANY SUGAR CENTRAL",
      },
      geometry: {
        type: "Point",
        coordinates: [123.004977837257428, 10.830298220086833, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Negros Occidental",
        NAME_2: "Talisay City",
        NAME_MILL: "FIRST FARMERS HOLDING CORP.",
      },
      geometry: {
        type: "Point",
        coordinates: [123.04240361883204, 10.737205126015793, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Negros Oriental",
        NAME_2: "Tanjay City",
        NAME_MILL: "CENTRAL AZUCARERA DE BAIS INC.",
      },
      geometry: {
        type: "Point",
        coordinates: [123.099400296586495, 9.539363790906295, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Negros Occidental",
        NAME_2: "Kabankalan City",
        NAME_MILL: "DACONGCOGON SUGAR MILL",
      },
      geometry: {
        type: "Point",
        coordinates: [122.793291260982969, 9.770012137250374, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Negros Occidental",
        NAME_2: "Kabankalan City",
        NAME_MILL: "SONEDCO SUGAR MILL",
      },
      geometry: {
        type: "Point",
        coordinates: [122.803084381638982, 9.958293919328979, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Negros Occidental",
        NAME_2: "Sagay City",
        NAME_MILL: "LOPEZ SAGAY CENTRAL",
      },
      geometry: {
        type: "Point",
        coordinates: [123.358669749609348, 10.900163750256924, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Negros Occidental",
        NAME_2: "Sagay City",
        NAME_MILL: "SAGAY CENTRAL INC.",
      },
      geometry: {
        type: "Point",
        coordinates: [123.375347321721591, 10.810224729687546, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Negros Oriental",
        NAME_2: "Bayawan City",
        NAME_MILL: "UNIVERSAL ROBINA CORP. - TOLONG",
      },
      geometry: {
        type: "Point",
        coordinates: [122.852441563227657, 9.354855867204547, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Leyte",
        NAME_2: "Kananga",
        NAME_MILL: "HIDECO SUGAR MILLING COMPANY INC.",
      },
      geometry: {
        type: "Point",
        coordinates: [124.584059573590267, 11.133694717836981, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Iloilo",
        NAME_2: "Passi City",
        NAME_MILL: "CENTRAL AZUCARERA DE SAN ANTONIO",
      },
      geometry: {
        type: "Point",
        coordinates: [122.641046417658089, 11.105572350883335, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Capiz",
        NAME_2: "President Roxas",
        NAME_MILL: "CAPIZ SUGAR CENTRAL INC.",
      },
      geometry: {
        type: "Point",
        coordinates: [122.931688035874203, 11.425721602270187, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Iloilo",
        NAME_2: "San Enrique",
        NAME_MILL: "UNIVERSAL ROBINA CORP. - PASSI",
      },
      geometry: {
        type: "Point",
        coordinates: [122.653346295994865, 11.094096124736438, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Negros Occidental",
        NAME_2: "Sagay City",
        NAME_MILL: "ORGANIC PRODUCERS IN THE ISLAND OF NEGROS - MPC",
      },
      geometry: {
        type: "Point",
        coordinates: [123.401376280091284, 10.878217332663837, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Cagayan",
        NAME_2: "Piat",
        NAME_MILL: "URC SUGAR CARSUMCO",
      },
      geometry: {
        type: "Point",
        coordinates: [121.514998948043043, 17.732290750517787, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Bukidnon",
        NAME_2: "Quezon",
        NAME_MILL: "BUSCO SUGAR MILLING COR",
      },
      geometry: {
        type: "Point",
        coordinates: [125.072867194064159, 7.80684783266136, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Tarlac",
        NAME_2: "Tarlac City",
        NAME_MILL: "CENTRAL AZUCARERA DE TA",
      },
      geometry: {
        type: "Point",
        coordinates: [120.642295411804852, 15.432737080551755, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Batangas",
        NAME_2: "Lian",
        NAME_MILL: "CENTRAL AZUCARERA DON P",
      },
      geometry: {
        type: "Point",
        coordinates: [120.664480194706826, 14.049992581392475, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Camarines Sur",
        NAME_2: "Pili",
        NAME_MILL: "PENAFRANCIA SUGAR MILL",
      },
      geometry: {
        type: "Point",
        coordinates: [123.306271247919739, 13.526715616032901, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Batangas",
        NAME_2: "Balayan",
        NAME_MILL: "URC SURE (FORMERLY BATA",
      },
      geometry: {
        type: "Point",
        coordinates: [120.717031218717139, 13.955552738168274, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Davao del Sur",
        NAME_2: "Hagonoy",
        NAME_MILL: "DAVAO SUGAR CENTRAL COM",
      },
      geometry: {
        type: "Point",
        coordinates: [125.341958022640583, 6.680325956277828, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "North Cotabato",
        NAME_2: "Matalam",
        NAME_MILL: "COTABATO SUGAR CENTRAL",
      },
      geometry: {
        type: "Point",
        coordinates: [124.891924253100896, 7.111872653899909, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Bukidnon",
        NAME_2: "Maramag",
        NAME_MILL: "CRYTAL SUGAR MILL",
      },
      geometry: {
        type: "Point",
        coordinates: [125.023911826868016, 7.764624670692033, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Isabela",
        NAME_2: "San Mariano",
        NAME_MILL: "GREEN FUTURE INNOVATION",
      },
      geometry: {
        type: "Point",
        coordinates: [121.994791709469794, 16.988116716713137, 0.0],
      },
    },
    {
      type: "Feature",
      properties: {
        NAME_1: "Cavite",
        NAME_2: "Magallanes",
        NAME_MILL: "CAVITE BIOFUELS",
      },
      geometry: {
        type: "Point",
        coordinates: [120.771552431600526, 14.143373347002333, 0.0],
      },
    },
  ],
};
