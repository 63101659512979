import "../../assets/scss/Forms.css";
import strPrf from "./ProfilePage.json";
import { useEffect, useState } from "react";
import { LoaderFunctionArgs, NavLink, useNavigate } from "react-router-dom";
import { DATA_STATUS, FORMS } from "../../app/GenEnums";
import { AvzRoute } from "../../app/Routes";
import { gotoLogin, requireLoadProperly } from "../../app/Routes";
import { fxdProfileBackup, fxdWrite, fxdGen, fxdUser } from "../../app/Storage";
import { fxdDelete, fxdRead, fxdProfile } from "../../app/Storage";
import { Card, HBar, VBar } from "../../custom/afx/ABox";
import { ATextInput, AText, ATick } from "../../custom/afx/AFX";
import { APopup, AButton } from "../../custom/afx/AFX";
import { optMonths } from "../../custom/afx/AFX";
import { TGetString, TGetValidation } from "../../custom/afx/AType";
import { TSetAny, TSetString, TSetValidation } from "../../custom/afx/AType";
import { TitleBar } from "../encode/EncodePage";
import { Address, CreateNewAddress } from "../../custom/RAddress";
import { Barangay, Municipality, Province } from "../../custom/RAddress";
import { createZIP, safeRead } from "../../app/GenFunctions";
import { machines } from "../machine/MachineBrandsAndModels";
import { noValidation } from "../../custom/Validations";
import { ConsentStatementText } from "./PrivacyConsent";
import { Nav } from "react-bootstrap";
import SaveIcon from "@mui/icons-material/Save";
import { oVarities } from "./Varities";
import { AppSettings } from "../../app/Settings";

// /////////////////////////////////////////////////////////////////////////////
// PRELOADER ///////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

const PFBKUP = fxdProfileBackup as Record<string, any>;
export function ProfileLoader({ request }: LoaderFunctionArgs) {
  fxdWrite(fxdGen.editing, FORMS.profile);
  return gotoLogin(request.url) ?? null;
}

// /////////////////////////////////////////////////////////////////////////////
// THE PROFILE PAGE ////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export default function ProfilePage() {
  // ///////////////////////////////////////////////////////////////////////////

  const GOTO = useNavigate();

  const restoreFromLocalStorage = () => {
    var restoreFromBackup: Record<string, any> = {};
    Object.keys(PFBKUP).forEach((key) => {
      restoreFromBackup[key] = fxdRead(PFBKUP[key])
        ? JSON.parse(fxdRead(PFBKUP[key]) || "")
        : "";
    });
    return restoreFromBackup;
  };

  const isAllEmpty = (backup: Record<string, any>) => {
    var { bP4Plntg, bP4Hrvstg, ...checkFrom } = backup;
    return Object.values(checkFrom).every((x) => safeRead(x) === "");
  };

  const backup = restoreFromLocalStorage();

  const restoreProfileBackups = (from: Record<string, any>) => {
    for (let i = 0; i < QUESTIONS.length; i++) {
      var val = from["b" + QUESTIONS[i].backup.slice(3)];
      if (val && typeof QUESTIONS[i].setValue === "function") {
        QUESTIONS[i].setValue(val);
      }
    }
  };

  function deleteProfileBackups() {
    Object.values(PFBKUP).forEach((val) => fxdDelete(val));
    fxdDelete(fxdProfile.backup);
  }

  useEffect(() => {
    if (!isAllEmpty(backup)) {
      fxdWrite(fxdProfile.backup, JSON.stringify(backup));
    }

    var restored = JSON.parse(fxdRead(fxdProfile.backup) || "{}");

    if (!isAllEmpty(restored)) {
      APopup({
        title: strPrf.recover.title,
        text: strPrf.recover.message,
        confirmButtonText: "Recover",
        showDenyButton: true,
        denyButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          restoreProfileBackups(restored);
        } else if (result.isDenied) {
          deleteProfileBackups();
          APopup({
            html: ConsentStatementText(),
            confirmButtonText: "Accept",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
      });
    } else {
      APopup({
        html: ConsentStatementText(),
        confirmButtonText: "Accept",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ///////////////////////////////////////////////////////////////////////////
  // STATES ////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  const [iP1Name, setIP1Name] = useState("");
  const [eP1Name, setEP1Name] = useState({
    required: false,
    condition: "duplicate",
    message: {
      label: strPrf.validation.name,
      duplicate: strPrf.validation.duplicate,
    },
  } as TGetValidation);

  const [iP1Age, setIP1Age] = useState("");
  const [eP1Age, setEP1Age] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.age },
    range: { min: 15, max: 150 },
  } as TGetValidation);

  const [iP1Sex, setIP1Sex] = useState("");
  const [eP1Sex, setEP1Sex] = useState(noValidation);

  const [iP1Educ, setIP1Educ] = useState("");
  const [eP1Educ, setEP1Educ] = useState(noValidation);

  const [iP1Cont1, setIP1Cont1] = useState("");
  const [eP1Cont1, setEP1Cont1] = useState({
    required: false,
    condition: "contact",
    message: { label: strPrf.validation.contact },
  } as TGetValidation);

  const [iP1Cont2, setIP1Cont2] = useState("");
  const [eP1Cont2, setEP1Cont2] = useState({
    required: false,
    condition: "contact",
    message: { label: strPrf.validation.contact },
  } as TGetValidation);

  const [iP1Prov, setIP1Prov] = useState("");
  const [eP1Prov, setEP1Prov] = useState(noValidation);

  const [iP1Mun, setIP1Mun] = useState("");
  const [eP1Mun, setEP1Mun] = useState(noValidation);

  const [iP1Brgy, setIP1Brgy] = useState("");
  const [eP1Brgy, setEP1Brgy] = useState(noValidation);

  const [iP2Profile, setIP2Profile] = useState("");
  const [eP2Profile, setEP2Profile] = useState(noValidation);

  const [iP2Scope, setIP2Scope] = useState("");
  const [eP2Scope, setEP2Scope] = useState(noValidation);

  const [iP2Position, setIP2Position] = useState("");
  const [eP2Position, setEP2Position] = useState(noValidation);

  const [iP3Name, setIP3Name] = useState("");
  const [eP3Name, setEP3Name] = useState({
    required: false,
    condition: "name",
    message: {
      label: strPrf.validation.name,
    },
  } as TGetValidation);

  const [iP3Class, setIP3Class] = useState("");
  const [eP3Class, setEP3Class] = useState(noValidation);

  const [iP3Cont1, setIP3Cont1] = useState("");
  const [eP3Cont1, setEP3Cont1] = useState({
    required: false,
    condition: "contact",
    message: { label: strPrf.validation.contact },
  } as TGetValidation);

  const [iP3Cont2, setIP3Cont2] = useState("");
  const [eP3Cont2, setEP3Cont2] = useState({
    required: false,
    condition: "contact",
    message: { label: strPrf.validation.contact },
  } as TGetValidation);

  const [iP3Prov, setIP3Prov] = useState("");
  const [eP3Prov, setEP3Prov] = useState(noValidation);

  const [iP3Mun, setIP3Mun] = useState("");
  const [eP3Mun, setEP3Mun] = useState(noValidation);

  const [iP3Brgy, setIP3Brgy] = useState("");
  const [eP3Brgy, setEP3Brgy] = useState(noValidation);

  const [iP4Name, setIP4Name] = useState("");
  const [eP4Name, setEP4Name] = useState(noValidation);

  const [iP4Accre, setIP4Accre] = useState("");
  const [eP4Accre, setEP4Accre] = useState(noValidation);

  const [iP4Area, setIP4Area] = useState("");
  const [eP4Area, setEP4Area] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.area },
    range: { min: 1, max: 99999 },
  } as TGetValidation);

  const [iP4Mach, setIP4Mach] = useState("");
  const [eP4Mach, setEP4Mach] = useState(noValidation);

  const [iP4Impl, setIP4Impl] = useState("");
  const [eP4Impl, setEP4Impl] = useState(noValidation);

  const [iP4Mngmt, setIP4Mngmt] = useState("");
  const [eP4Mngmt, setEP4Mngmt] = useState(noValidation);

  const [iP4Irrig, setIP4Irrig] = useState("");
  const [eP4Irrig, setEP4Irrig] = useState(noValidation);

  const [iP4Irrig1, setIP4Irrig1] = useState("");
  const [eP4Irrig1, setEP4Irrig1] = useState(noValidation);

  const [iP4Irrig2, setIP4Irrig2] = useState("");
  const [eP4Irrig2, setEP4Irrig2] = useState(noValidation);

  const [iP4Irrig3, setIP4Irrig3] = useState("");
  const [eP4Irrig3, setEP4Irrig3] = useState(noValidation);

  const [iP4PlntdVar, setIP4PlntdVar] = useState("");
  const [eP4PlntdVar, setEP4PlntdVar] = useState(noValidation);

  const [iP4PrefVar, setIP4PrefVar] = useState("");
  const [eP4PrefVar, setEP4PrefVar] = useState(noValidation);

  const [iP4PlntgStart, setIP4PlntgStart] = useState("");
  const [eP4PlntgStart, setEP4PlntgStart] = useState(noValidation);

  const [iP4PlntgEnd, setIP4PlntgEnd] = useState("");
  const [eP4PlntgEnd, setEP4PlntgEnd] = useState(noValidation);

  const [iP4HrvstgStart, setIP4HrvstgStart] = useState("");
  const [eP4HrvstgStart, setEP4HrvstgStart] = useState(noValidation);

  const [iP4HrvstgEnd, setIP4HrvstgEnd] = useState("");
  const [eP4HrvstgEnd, setEP4HrvstgEnd] = useState(noValidation);

  const [iP4Plntg, setIP4Plntg] = useState("");
  const [eP4Plntg, setEP4Plntg] = useState(noValidation);

  const [iP4Hrvstg, setIP4Hrvstg] = useState("");
  const [eP4Hrvstg, setEP4Hrvstg] = useState(noValidation);

  const [iP4AvgYield, setIP4AvgYield] = useState("");
  const [eP4AvgYield, setEP4AvgYield] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.yield },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  const [iP4RtnMnths, setIP4RtnMnths] = useState("");
  const [eP4RtnMnths, setEP4RtnMnths] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.month },
    range: { min: 0, max: 12 },
  } as TGetValidation);

  const [iP4RtnYield, setIP4RtnYield] = useState("");
  const [eP4RtnYield, setEP4RtnYield] = useState({
    required: false,
    condition: "range",
    message: { label: strPrf.validation.yield },
    range: { min: 0, max: 999 },
  } as TGetValidation);

  const [iP4RtnMax, setIP4RtnMax] = useState("");
  const [eP4RtnMax, setEP4RtnMax] = useState({
    required: false,
    condition: "none",
    message: { label: strPrf.validation.ratoon },
    range: { min: 0, max: 10 },
  } as TGetValidation);

  const [iP4SgrMills, setIP4SgrMills] = useState("");
  const [eP4SgrMills, setEP4SgrMills] = useState(noValidation);

  const [iP5Notes, setIP5Notes] = useState("");
  const [eP5Notes, setEP5Notes] = useState(noValidation);

  // ///////////////////////////////////////////////////////////////////////////
  // QUESTIONS /////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  const _start = (txt: string) => txt + " " + safeRead(strPrf.farm.start);
  const _end = (txt: string) => txt + " " + safeRead(strPrf.farm.end);
  const _number = (txt: string) => txt + " " + safeRead(strPrf.farm.number);

  type TQuestion = {
    value: TGetString | undefined;
    setValue: TSetString | TSetAny;
    errorMessage: TGetValidation;
    setErrorMessage: TSetValidation;
    backup: TGetString;
    label: TGetString;
  };

  const QUESTIONS: TQuestion[] = [
    {
      value: iP1Name,
      setValue: setIP1Name,
      errorMessage: eP1Name,
      setErrorMessage: setEP1Name,
      backup: PFBKUP.bP1Name,
      label: strPrf.res.name.label,
    },
    {
      value: iP1Age,
      setValue: setIP1Age,
      errorMessage: eP1Age,
      setErrorMessage: setEP1Age,
      backup: PFBKUP.bP1Age,
      label: strPrf.res.age.label,
    },
    {
      value: iP1Sex,
      setValue: setIP1Sex,
      errorMessage: eP1Sex,
      setErrorMessage: setEP1Sex,
      backup: PFBKUP.bP1Sex,
      label: strPrf.res.sex.label,
    },
    {
      value: iP1Educ,
      setValue: setIP1Educ,
      errorMessage: eP1Educ,
      setErrorMessage: setEP1Educ,
      backup: PFBKUP.bP1Educ,
      label: strPrf.res.educ.label,
    },
    {
      value: iP1Cont1,
      setValue: setIP1Cont1,
      errorMessage: eP1Cont1,
      setErrorMessage: setEP1Cont1,
      backup: PFBKUP.bP1Cont1,
      label: strPrf.res.cont1.label,
    },
    {
      value: iP1Cont2,
      setValue: setIP1Cont2,
      errorMessage: eP1Cont2,
      setErrorMessage: setEP1Cont2,
      backup: PFBKUP.bP1Cont2,
      label: strPrf.res.cont2.label,
    },
    {
      value: iP1Prov,
      setValue: setIP1Prov,
      errorMessage: eP1Prov,
      setErrorMessage: setEP1Prov,
      backup: PFBKUP.bP1Prov,
      label: strPrf.res.prov.label,
    },
    {
      value: iP1Mun,
      setValue: setIP1Mun,
      errorMessage: eP1Mun,
      setErrorMessage: setEP1Mun,
      backup: PFBKUP.bP1Mun,
      label: strPrf.res.mun.label,
    },
    {
      value: iP1Brgy,
      setValue: setIP1Brgy,
      errorMessage: eP1Brgy,
      setErrorMessage: setEP1Brgy,
      backup: PFBKUP.bP1Brgy,
      label: strPrf.res.brgy.label,
    },
    {
      value: iP2Profile,
      setValue: setIP2Profile,
      errorMessage: eP2Profile,
      setErrorMessage: setEP2Profile,
      backup: PFBKUP.bP2Profile,
      label: strPrf.res.profile.label,
    },
    {
      value: iP2Scope,
      setValue: setIP2Scope,
      errorMessage: eP2Scope,
      setErrorMessage: setEP2Scope,
      backup: PFBKUP.bP2Scope,
      label: strPrf.res.scope.label,
    },
    {
      value: iP2Position,
      setValue: setIP2Position,
      errorMessage: eP2Position,
      setErrorMessage: setEP2Position,
      backup: PFBKUP.bP2Position,
      label: strPrf.res.pos.label,
    },
    {
      value: iP3Name,
      setValue: setIP3Name,
      errorMessage: eP3Name,
      setErrorMessage: setEP3Name,
      backup: PFBKUP.bP3Name,
      label: strPrf.owner.name.label,
    },
    {
      value: iP3Class,
      setValue: setIP3Class,
      errorMessage: eP3Class,
      setErrorMessage: setEP3Class,
      backup: PFBKUP.bP3Class,
      label: strPrf.owner.class.label,
    },
    {
      value: iP3Cont1,
      setValue: setIP3Cont1,
      errorMessage: eP3Cont1,
      setErrorMessage: setEP3Cont1,
      backup: PFBKUP.bP3Cont1,
      label: strPrf.owner.cont1.label,
    },
    {
      value: iP3Cont2,
      setValue: setIP3Cont2,
      errorMessage: eP3Cont2,
      setErrorMessage: setEP3Cont2,
      backup: PFBKUP.bP3Cont2,
      label: strPrf.owner.cont2.label,
    },
    {
      value: iP3Prov,
      setValue: setIP3Prov,
      errorMessage: eP3Prov,
      setErrorMessage: setEP3Prov,
      backup: PFBKUP.bP3Prov,
      label: strPrf.owner.prov.label,
    },
    {
      value: iP3Mun,
      setValue: setIP3Mun,
      errorMessage: eP3Mun,
      setErrorMessage: setEP3Mun,
      backup: PFBKUP.bP3Mun,
      label: strPrf.owner.mun.label,
    },
    {
      value: iP3Brgy,
      setValue: setIP3Brgy,
      errorMessage: eP3Brgy,
      setErrorMessage: setEP3Brgy,
      backup: PFBKUP.bP3Brgy,
      label: strPrf.owner.brgy.label,
    },
    {
      value: iP4Name,
      setValue: setIP4Name,
      errorMessage: eP4Name,
      setErrorMessage: setEP4Name,
      backup: PFBKUP.bP4Name,
      label: strPrf.farm.name.label,
    },
    {
      value: iP4Accre,
      setValue: setIP4Accre,
      errorMessage: eP4Accre,
      setErrorMessage: setEP4Accre,
      backup: PFBKUP.bP4Accre,
      label: strPrf.farm.accre.label,
    },
    {
      value: iP4Area,
      setValue: setIP4Area,
      errorMessage: eP4Area,
      setErrorMessage: setEP4Area,
      backup: PFBKUP.bP4Area,
      label: strPrf.farm.area.label,
    },
    {
      value: iP4Mach,
      setValue: setIP4Mach,
      errorMessage: eP4Mach,
      setErrorMessage: setEP4Mach,
      backup: PFBKUP.bP4Mach,
      label: strPrf.farm.mach.label,
    },
    {
      value: iP4Impl,
      setValue: setIP4Impl,
      errorMessage: eP4Impl,
      setErrorMessage: setEP4Impl,
      backup: PFBKUP.bP4Impl,
      label: strPrf.farm.impl.label,
    },
    {
      value: iP4Mngmt,
      setValue: setIP4Mngmt,
      errorMessage: eP4Mngmt,
      setErrorMessage: setEP4Mngmt,
      backup: PFBKUP.bP4Mngmt,
      label: strPrf.farm.mngmt.label,
    },
    {
      value: iP4Irrig,
      setValue: setIP4Irrig,
      errorMessage: eP4Irrig,
      setErrorMessage: setEP4Irrig,
      backup: PFBKUP.bP4Irrig,
      label: strPrf.farm.irrig.label,
    },
    {
      value: iP4Irrig1,
      setValue: setIP4Irrig1,
      errorMessage: eP4Irrig1,
      setErrorMessage: setEP4Irrig1,
      backup: PFBKUP.bP4Irrig1,
      label: strPrf.farm.irrig1.label,
    },
    {
      value: iP4Irrig2,
      setValue: setIP4Irrig2,
      errorMessage: eP4Irrig2,
      setErrorMessage: setEP4Irrig2,
      backup: PFBKUP.bP4Irrig2,
      label: strPrf.farm.irrig2.label,
    },
    {
      value: iP4Irrig3,
      setValue: setIP4Irrig3,
      errorMessage: eP4Irrig3,
      setErrorMessage: setEP4Irrig3,
      backup: PFBKUP.bP4Irrig3,
      label: strPrf.farm.irrig3.label,
    },
    {
      value: iP4PlntdVar,
      setValue: setIP4PlntdVar,
      errorMessage: eP4PlntdVar,
      setErrorMessage: setEP4PlntdVar,
      backup: PFBKUP.bP4PlntdVar,
      label: strPrf.farm.plntdVar.label,
    },
    {
      value: iP4PrefVar,
      setValue: setIP4PrefVar,
      errorMessage: eP4PrefVar,
      setErrorMessage: setEP4PrefVar,
      backup: PFBKUP.bP4PrefVar,
      label: strPrf.farm.prefVar.label,
    },
    {
      value: iP4PlntgStart,
      setValue: setIP4PlntgStart,
      errorMessage: eP4PlntgStart,
      setErrorMessage: setEP4PlntgStart,
      backup: PFBKUP.bP4PlntgStart,
      label: _start(strPrf.farm.planting),
    },
    {
      value: iP4PlntgEnd,
      setValue: setIP4PlntgEnd,
      errorMessage: eP4PlntgEnd,
      setErrorMessage: setEP4PlntgEnd,
      backup: PFBKUP.bP4PlntgEnd,
      label: _end(strPrf.farm.planting),
    },
    {
      value: iP4HrvstgStart,
      setValue: setIP4HrvstgStart,
      errorMessage: eP4HrvstgStart,
      setErrorMessage: setEP4HrvstgStart,
      backup: PFBKUP.bP4HrvstgStart,
      label: _start(strPrf.farm.harvesting),
    },
    {
      value: iP4HrvstgEnd,
      setValue: setIP4HrvstgEnd,
      errorMessage: eP4HrvstgEnd,
      setErrorMessage: setEP4HrvstgEnd,
      backup: PFBKUP.bP4HrvstgEnd,
      label: _end(strPrf.farm.harvesting),
    },
    {
      value: iP4Plntg,
      setValue: setIP4Plntg,
      errorMessage: eP4Plntg,
      setErrorMessage: setEP4Plntg,
      backup: PFBKUP.bP4Plntg,
      label: _number(strPrf.farm.planting),
    },
    {
      value: iP4Hrvstg,
      setValue: setIP4Hrvstg,
      errorMessage: eP4Hrvstg,
      setErrorMessage: setEP4Hrvstg,
      backup: PFBKUP.bP4Hrvstg,
      label: _number(strPrf.farm.harvesting),
    },
    {
      value: iP4AvgYield,
      setValue: setIP4AvgYield,
      errorMessage: eP4AvgYield,
      setErrorMessage: setEP4AvgYield,
      backup: PFBKUP.bP4AvgYield,
      label: strPrf.farm.avgYield.label,
    },
    {
      value: iP4RtnMnths,
      setValue: setIP4RtnMnths,
      errorMessage: eP4RtnMnths,
      setErrorMessage: setEP4RtnMnths,
      backup: PFBKUP.bP4RtnMnths,
      label: strPrf.farm.rtnMnths.label,
    },
    {
      value: iP4RtnYield,
      setValue: setIP4RtnYield,
      errorMessage: eP4RtnYield,
      setErrorMessage: setEP4RtnYield,
      backup: PFBKUP.bP4RtnYield,
      label: strPrf.farm.rtnYield.label,
    },
    {
      value: iP4RtnMax,
      setValue: setIP4RtnMax,
      errorMessage: eP4RtnMax,
      setErrorMessage: setEP4RtnMax,
      backup: PFBKUP.bP4RtnMax,
      label: strPrf.farm.rtnMax.label,
    },
    {
      value: iP4SgrMills,
      setValue: setIP4SgrMills,
      errorMessage: eP4SgrMills,
      setErrorMessage: setEP4SgrMills,
      backup: PFBKUP.bP4SgrMills,
      label: strPrf.farm.sgrMills.label,
    },
    {
      value: iP5Notes,
      setValue: setIP5Notes,
      errorMessage: eP5Notes,
      setErrorMessage: setEP5Notes,
      backup: PFBKUP.bP5Notes,
      label: strPrf.notes.label,
    },
  ];

  // ///////////////////////////////////////////////////////////////////////////
  // CONSTANTS /////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  // Address ///////////////////////////////////////////////////////////////////

  const adRes: Address = CreateNewAddress();
  const adOwn: Address = CreateNewAddress();

  // LOGIC /////////////////////////////////////////////////////////////////////

  // Autocompute total months of planting
  useEffect(() => {
    if (iP4PlntgStart && iP4PlntgEnd) {
      var start = (iP4PlntgStart as any).intgr;
      var end = (iP4PlntgEnd as any).intgr;
      var pltng = start > end ? 12 - start + end : end - start;
      if (pltng < 13) setIP4Plntg(Math.abs(pltng).toString());
    } else setIP4Plntg("");
  }, [iP4PlntgStart, iP4PlntgEnd]);

  // Autocompute total months of harvesting
  useEffect(() => {
    if (iP4HrvstgStart && iP4HrvstgEnd) {
      var start = (iP4HrvstgStart as any).intgr;
      var end = (iP4HrvstgEnd as any).intgr;
      var hrvst = start > end ? 12 - start + end : end - start;
      if (hrvst < 13) setIP4Hrvstg(Math.abs(hrvst).toString());
    } else setIP4Hrvstg("");
  }, [iP4HrvstgStart, iP4HrvstgEnd]);

  // ///////////////////////////////////////////////////////////////////////////
  // LAYOUT ////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  return (
    requireLoadProperly(AvzRoute.profile) ?? (
      <VBar class="main-bg-form form-design">
        <VBar class="v-bar">
          <TitleBar category={strPrf.category} />
          <Card title={strPrf.res.title} titleStyle="title">
            <ATextInput
              text={strPrf.res.name}
              value={[iP1Name, setIP1Name]}
              validation={eP1Name}
              backup={PFBKUP.bP1Name}
            />
            <ATextInput
              text={strPrf.res.age}
              value={[iP1Age, setIP1Age]}
              validation={eP1Age}
              backup={PFBKUP.bP1Age}
            />
            <ATextInput
              text={strPrf.res.sex}
              options={strPrf.res.sex.options}
              value={[iP1Sex, setIP1Sex]}
              validation={eP1Sex}
              backup={PFBKUP.bP1Sex}
            />
            <ATextInput
              text={strPrf.res.educ}
              options={strPrf.res.educ.options}
              value={[iP1Educ, setIP1Educ]}
              validation={eP1Educ}
              backup={PFBKUP.bP1Educ}
            />
            <HBar>
              <ATextInput
                position="left"
                text={strPrf.res.cont1}
                value={[iP1Cont1, setIP1Cont1]}
                validation={eP1Cont1}
                backup={PFBKUP.bP1Cont1}
              />
              <ATextInput
                position="right"
                text={strPrf.res.cont2}
                value={[iP1Cont2, setIP1Cont2]}
                validation={eP1Cont2}
                backup={PFBKUP.bP1Cont2}
              />
            </HBar>
            <Province
              text={strPrf.res.prov}
              address={adRes}
              value={[iP1Prov, setIP1Prov]}
              validation={eP1Prov}
              backup={PFBKUP.bP1Prov}
            />
            <Municipality
              text={strPrf.res.mun}
              address={adRes}
              value={[iP1Mun, setIP1Mun]}
              validation={eP1Mun}
              backup={PFBKUP.bP1Mun}
            />
            <Barangay
              text={strPrf.res.brgy}
              address={adRes}
              value={[iP1Brgy, setIP1Brgy]}
              validation={eP1Brgy}
              backup={PFBKUP.bP1Brgy}
            />
          </Card>
          <Card>
            <ATextInput
              text={strPrf.res.profile}
              options={strPrf.res.profile.options}
              value={[iP2Profile, setIP2Profile]}
              validation={eP2Profile}
              backup={PFBKUP.bP2Profile}
            />
            <VBar
              in={(() => {
                var _owner = strPrf.res.profile.options[0].label;
                var resProfText = safeRead(iP2Profile) ?? null;
                return resProfText === _owner;
              })()}
              class="spacer"
            >
              <AText text={strPrf.res.scope.label} />
              <ATick
                row={false}
                options={strPrf.res.scope.options}
                value={[iP2Scope, setIP2Scope]}
                backup={PFBKUP.bP2Scope}
              />
            </VBar>
            <VBar
              in={(() => {
                var _representative = strPrf.res.profile.options[1].label;
                var resProfText = safeRead(iP2Profile) ?? null;
                return resProfText === _representative;
              })()}
              class="spacer"
            >
              <ATextInput
                free
                text={strPrf.res.pos}
                options={strPrf.res.pos.options}
                value={[iP2Position, setIP2Position]}
                validation={eP2Position}
                backup={PFBKUP.bP2Position}
              />
            </VBar>
          </Card>
          <Card
            in={(() => {
              var _representative = strPrf.res.profile.options[1].label;
              var resProfText = safeRead(iP2Profile) ?? null;
              return resProfText === _representative;
            })()}
            title={strPrf.owner.title}
            titleStyle="title"
          >
            <ATextInput
              text={strPrf.owner.name}
              value={[iP3Name, setIP3Name]}
              validation={eP3Name}
              backup={PFBKUP.bP3Name}
            />
            <ATextInput
              text={strPrf.owner.class}
              options={strPrf.owner.class.options}
              value={[iP3Class, setIP3Class]}
              validation={eP3Class}
              backup={PFBKUP.bP3Class}
            />
            <HBar>
              <ATextInput
                position="left"
                text={strPrf.owner.cont1}
                value={[iP3Cont1, setIP3Cont1]}
                validation={eP3Cont1}
                backup={PFBKUP.bP3Cont1}
              />
              <ATextInput
                position="right"
                text={strPrf.owner.cont2}
                value={[iP3Cont2, setIP3Cont2]}
                validation={eP3Cont2}
                backup={PFBKUP.bP3Cont2}
              />
            </HBar>
            <Province
              text={strPrf.owner.prov}
              address={adOwn}
              value={[iP3Prov, setIP3Prov]}
              validation={eP3Prov}
              backup={PFBKUP.bP3Prov}
            />
            <Municipality
              text={strPrf.owner.mun}
              address={adOwn}
              value={[iP3Mun, setIP3Mun]}
              validation={eP3Mun}
              backup={PFBKUP.bP3Mun}
            />
            <Barangay
              text={strPrf.owner.brgy}
              address={adOwn}
              value={[iP3Brgy, setIP3Brgy]}
              validation={eP3Brgy}
              backup={PFBKUP.bP3Brgy}
            />
          </Card>
          <Card
            in={(() => {
              var _respresentative = strPrf.res.profile.options[1].label;
              var _farm = strPrf.res.scope.options[0].label;
              var scope = iP2Scope ?? null;
              return (
                safeRead(iP2Profile) === _respresentative ||
                (Array.isArray(scope) &&
                  scope.some((s: any) => safeRead(s) === _farm))
              );
            })()}
            title={strPrf.farm.title}
            titleStyle="title"
          >
            <ATextInput
              text={strPrf.farm.name}
              value={[iP4Name, setIP4Name]}
              validation={eP4Name}
              backup={PFBKUP.bP4Name}
            />
            <ATextInput
              text={strPrf.farm.accre}
              options={strPrf.farm.accre.options}
              value={[iP4Accre, setIP4Accre]}
              validation={eP4Accre}
              backup={PFBKUP.bP4Accre}
            />
            <ATextInput
              free
              text={strPrf.farm.area}
              options={strPrf.farm.tobe}
              value={[iP4Area, setIP4Area]}
              validation={eP4Area}
              backup={PFBKUP.bP4Area}
            />
            <ATextInput
              text={strPrf.farm.mach}
              options={machines.types}
              value={[iP4Mach, setIP4Mach]}
              validation={eP4Mach}
              backup={PFBKUP.bP4Mach}
            />
            <ATextInput
              text={strPrf.farm.impl}
              options={strPrf.farm.impl.options}
              value={[iP4Impl, setIP4Impl]}
              group={(g) => g.type}
              validation={eP4Impl}
              backup={PFBKUP.bP4Impl}
            />
            <ATextInput
              text={strPrf.farm.mngmt}
              options={strPrf.farm.mngmt.options}
              value={[iP4Mngmt, setIP4Mngmt]}
              validation={eP4Mngmt}
              backup={PFBKUP.bP4Mngmt}
            />
            <AText text={strPrf.farm.irrig.label} />
            <ATextInput
              max={strPrf.farm.irrig.options.length}
              text={strPrf.farm.irrig.field}
              options={strPrf.farm.irrig.options}
              value={[iP4Irrig, setIP4Irrig]}
              validation={eP4Irrig}
              backup={PFBKUP.bP4Irrig}
            />
            <ATextInput
              text={strPrf.farm.irrig1}
              options={optMonths}
              value={[iP4Irrig1, setIP4Irrig1]}
              validation={eP4Irrig1}
              backup={PFBKUP.bP4Irrig1}
            />
            <HBar in={safeRead(iP4Irrig1).length > 0}>
              <ATextInput
                text={strPrf.farm.irrig2}
                options={optMonths}
                value={[iP4Irrig2, setIP4Irrig2]}
                validation={eP4Irrig2}
                backup={PFBKUP.bP4Irrig2}
              />
            </HBar>
            <HBar in={safeRead(iP4Irrig2).length > 0}>
              <ATextInput
                text={strPrf.farm.irrig3}
                options={optMonths}
                value={[iP4Irrig3, setIP4Irrig3]}
                validation={eP4Irrig3}
                backup={PFBKUP.bP4Irrig3}
              />
            </HBar>
            <AText text={strPrf.farm.varieties} />
            <ATextInput
              free
              max={3}
              text={strPrf.farm.plntdVar}
              options={oVarities}
              value={[iP4PlntdVar, setIP4PlntdVar]}
              validation={eP4PlntdVar}
              backup={PFBKUP.bP4PlntdVar}
            />
            <ATextInput
              free
              max={3}
              text={strPrf.farm.prefVar}
              options={oVarities}
              value={[iP4PrefVar, setIP4PrefVar]}
              validation={eP4PrefVar}
              backup={PFBKUP.bP4PrefVar}
            />
            <AText text={strPrf.farm.planting} />
            <HBar gap={10}>
              <ATextInput
                text={strPrf.farm.start}
                options={optMonths}
                value={[iP4PlntgStart, setIP4PlntgStart]}
                validation={eP4PlntgStart}
                backup={PFBKUP.bP4PlntgStart}
              />
              <ATextInput
                text={strPrf.farm.end}
                options={optMonths}
                value={[iP4PlntgEnd, setIP4PlntgEnd]}
                validation={eP4PlntgEnd}
                backup={PFBKUP.bP4PlntgEnd}
              />
            </HBar>
            <AText text={strPrf.farm.harvesting} />
            <HBar gap={10}>
              <ATextInput
                text={strPrf.farm.start}
                options={optMonths}
                value={[iP4HrvstgStart, setIP4HrvstgStart]}
                validation={eP4HrvstgStart}
                backup={PFBKUP.bP4HrvstgStart}
              />
              <ATextInput
                text={strPrf.farm.end}
                options={optMonths}
                value={[iP4HrvstgEnd, setIP4HrvstgEnd]}
                validation={eP4HrvstgEnd}
                backup={PFBKUP.bP4HrvstgEnd}
              />
            </HBar>
            <AText text={strPrf.farm.number} />
            <HBar gap={10}>
              <ATextInput
                open
                disabled
                text={strPrf.farm.moPlanting}
                value={[iP4Plntg, setIP4Plntg]}
                validation={eP4Plntg}
                backup={PFBKUP.bP4Plntg}
              />
              <ATextInput
                open
                disabled
                text={strPrf.farm.moHarvesting}
                value={[iP4Hrvstg, setIP4Hrvstg]}
                validation={eP4Hrvstg}
                backup={PFBKUP.bP4Hrvstg}
              />
            </HBar>
            <AText text={strPrf.farm.avgYield.label} />
            <ATextInput
              free
              text={strPrf.farm.avgYield.field}
              options={strPrf.farm.tobe}
              value={[iP4AvgYield, setIP4AvgYield]}
              validation={eP4AvgYield}
              backup={PFBKUP.bP4AvgYield}
            />
            <AText text={strPrf.farm.rtnMnths.label} />
            <ATextInput
              free
              text={strPrf.farm.rtnMnths.field}
              options={strPrf.farm.tobe}
              value={[iP4RtnMnths, setIP4RtnMnths]}
              validation={eP4RtnMnths}
              backup={PFBKUP.bP4RtnMnths}
            />
            <AText text={strPrf.farm.rtnYield.label} />
            <ATextInput
              free
              text={strPrf.farm.rtnYield.field}
              options={strPrf.farm.tobe}
              value={[iP4RtnYield, setIP4RtnYield]}
              validation={eP4RtnYield}
              backup={PFBKUP.bP4RtnYield}
            />
            <AText text={strPrf.farm.rtnMax.label} />
            <ATextInput
              free
              text={strPrf.farm.rtnMax.field}
              options={strPrf.farm.tobe}
              value={[iP4RtnMax, setIP4RtnMax]}
              validation={eP4RtnMax}
              backup={PFBKUP.bP4RtnMax}
            />
            <AText text={strPrf.farm.sgrMills.label} />
            <ATextInput
              max={3}
              text={strPrf.farm.sgrMills.field}
              options={strPrf.farm.sgrMills.options}
              value={[iP4SgrMills, setIP4SgrMills]}
              validation={eP4SgrMills}
              backup={PFBKUP.bP4SgrMills}
            />
          </Card>
          <Card>
            <ATextInput
              rows={7}
              text={strPrf.notes}
              value={[iP5Notes, setIP5Notes]}
              validation={eP5Notes}
              backup={PFBKUP.bP5Notes}
            />
          </Card>
          <AButton
            label={strPrf.save}
            color="primary"
            width={100}
            height={50}
            icon={<SaveIcon />}
            onClick={async () => {
              saveEncodedProfile();
            }}
          />
          <div className="btn-group" role="group">
            <button className="btn btn-primary" type="button">
              <Nav.Link
                key={AvzRoute.encode}
                as={NavLink}
                to={AvzRoute.encode}
                end
              >
                Go to Encode
              </Nav.Link>
            </button>
            <button className="btn btn-primary" type="button">
              <Nav.Link
                key={AvzRoute.machine}
                as={NavLink}
                to={AvzRoute.machine}
                end
              >
                Go to Machine
              </Nav.Link>
            </button>
          </div>
        </VBar>
      </VBar>
    )
  );

  async function saveEncodedProfile() {
    const Question = (value: any) => {
      return QUESTIONS.find((q) => q.value === value);
    };

    var required = [
      Question(iP1Name),
      Question(iP1Age),
      Question(iP1Sex),
      Question(iP1Educ),
      Question(iP1Cont1),
      // qtns(iP1Cont2),
      Question(iP1Prov),
      Question(iP1Mun),
      Question(iP1Brgy),
      Question(iP2Profile),
    ];

    var _owner = strPrf.res.profile.options[0].label;
    var resProfText = safeRead(iP2Profile) ?? null;
    if (resProfText === _owner) {
      required.push(Question(iP2Scope));
    }

    var _representative = strPrf.res.profile.options[1].label;
    if (resProfText === _representative) {
      required.push(Question(iP2Position));
    }

    if (resProfText === _representative) {
      required.push(Question(iP3Name));
      required.push(Question(iP3Class));
      required.push(Question(iP3Cont1));
      // qtns(iP3Cont2),
      required.push(Question(iP3Prov));
      required.push(Question(iP3Mun));
      required.push(Question(iP3Brgy));
    }

    var _respresentative = strPrf.res.profile.options[1].label;
    var _farm = strPrf.res.scope.options[0].label;
    var scope = iP2Scope ?? null;
    if (
      safeRead(iP2Profile) === _respresentative ||
      (Array.isArray(scope) && scope.some((s: any) => safeRead(s) === _farm))
    ) {
      required.push(Question(iP4Name));
      required.push(Question(iP4Accre));
      required.push(Question(iP4Area));
      required.push(Question(iP4Mach));
      required.push(Question(iP4Impl));
      required.push(Question(iP4Mngmt));
      required.push(Question(iP4Irrig));
      required.push(Question(iP4Irrig1));

      if (safeRead(iP4Irrig1).length > 0) {
        required.push(Question(iP4Irrig2));
      }

      if (safeRead(iP4Irrig2).length > 0) {
        required.push(Question(iP4Irrig3));
      }

      required.push(Question(iP4PlntdVar));
      required.push(Question(iP4PrefVar));
      required.push(Question(iP4PlntgStart));
      required.push(Question(iP4PlntgEnd));
      required.push(Question(iP4HrvstgStart));
      required.push(Question(iP4HrvstgEnd));
      required.push(Question(iP4Plntg));
      required.push(Question(iP4Hrvstg));

      required.push(Question(iP4AvgYield));
      required.push(Question(iP4RtnMnths));
      required.push(Question(iP4RtnYield));
      required.push(Question(iP4RtnMax));
      required.push(Question(iP4SgrMills));
    }

    required.forEach((r) => {
      if (r) r.errorMessage.required = true;
    });

    var error;
    var hasError = required.some((r) => {
      if (r) {
        if (r.value === null || r.value === "") {
          error = r.label;
          return true;
        }
      }
      return false;
    });

    if (typeof JSON == "undefined") {
      APopup({
        type: "error",
        text: strPrf.notif.browserError,
      });
    } else if (hasError && error) {
      APopup({
        type: "error",
        title: error,
        text: strPrf.notif.missing,
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      APopup({
        type: "success",
        text: strPrf.notif.saveIni,
      });
    }

    if (!hasError || !error) {
      let data = {
        sP1Name: iP1Name,
        sP1Age: iP1Age,
        sP1Sex: iP1Sex,
        sP1Educ: iP1Educ,
        sP1Cont1: iP1Cont1,
        sP1Cont2: iP1Cont2,
        sP1Prov: iP1Prov,
        sP1Mun: iP1Mun,
        sP1Brgy: iP1Brgy,
        sP2Profile: iP2Profile,
        sP2Scope: iP2Scope,
        sP2Position: iP2Position,
        sP3Name: iP3Name,
        sP3Class: iP3Class,
        sP3Cont1: iP3Cont1,
        sP3Cont2: iP3Cont2,
        sP3Prov: iP3Prov,
        sP3Mun: iP3Mun,
        sP3Brgy: iP3Brgy,
        sP4Name: iP4Name,
        sP4Accre: iP4Accre,
        sP4Area: iP4Area,
        sP4Mach: iP4Mach,
        sP4Impl: iP4Impl,
        sP4Mngmt: iP4Mngmt,
        sP4Irrig: iP4Irrig,
        sP4Irrig1: iP4Irrig1,
        sP4Irrig2: iP4Irrig2,
        sP4Irrig3: iP4Irrig3,
        sP4PlntdVar: iP4PlntdVar,
        sP4PrefVar: iP4PrefVar,
        sP4PlntgStart: iP4PlntgStart,
        sP4PlntgEnd: iP4PlntgEnd,
        sP4HrvstgStart: iP4HrvstgStart,
        sP4HrvstgEnd: iP4HrvstgEnd,
        sP4Plntg: iP4Plntg,
        sP4Hrvstg: iP4Hrvstg,
        sP4AvgYield: iP4AvgYield,
        sP4RtnMnths: iP4RtnMnths,
        sP4RtnYield: iP4RtnYield,
        sP4RtnMax: iP4RtnMax,
        sP4SgrMills: iP4SgrMills,
        sP5Notes: iP5Notes,
        sP0Uname: fxdRead(fxdUser.name),
        sP0Encoded: Date.now().toString(),
        sP0Status: DATA_STATUS.local,
      } as ProfileData;

      fxdWrite(fxdGen.editing, FORMS.null);
      var fname = iP1Name.replaceAll(".", "").replaceAll(" ", "");
      var zipname = fname + ".zip";
      var filename = fname + ".json";

      createZIP(zipname, AppSettings.ZIPPASS, [
        { filename: filename, content: JSON.stringify(data, undefined, 2) },
      ]);

      let profiles = fxdRead(fxdProfile.profiles);
      if (profiles != null) {
        const newProfile = data;
        const profs = JSON.parse(profiles) as Array<any>;
        profs.push(newProfile);
        fxdWrite(fxdProfile.profiles, JSON.stringify(profs));
      } else {
        fxdWrite(fxdProfile.profiles, JSON.stringify([data]));
      }

      clearFields();
      deleteProfileBackups();
      APopup({
        type: "success",
        title: strPrf.saveNotif.title,
        text: strPrf.saveNotif.content,
        confirmButtonColor: strPrf.saveNotif.actionCenter,
      }).then(() => {
        GOTO(AvzRoute.machine);
      });
    }
  }

  function clearFields() {
    QUESTIONS.forEach((question) => {
      if (typeof question.setValue === "function") {
        question.setValue("");
      }
    });
  }
}

// /////////////////////////////////////////////////////////////////////////////
// PROFILE FORMAT //////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export interface ProfileData {
  sP1Name: string;
  sP1Age: string;
  sP1Sex: string;
  sP1Educ: string;
  sP1Cont1: string;
  sP1Cont2: string;
  sP1Prov: string;
  sP1Mun: string;
  sP1Brgy: string;
  sP2Profile: string;
  sP2Scope: string;
  sP2Position: string;
  sP3Name: string;
  sP3Class: string;
  sP3Cont1: string;
  sP3Cont2: string;
  sP3Prov: string;
  sP3Mun: string;
  sP3Brgy: string;
  sP4Name: string;
  sP4Accre: string;
  sP4Area: string;
  sP4Mach: string;
  sP4Impl: string;
  sP4Mngmt: string;
  sP4Irrig: string;
  sP4Irrig1: string;
  sP4Irrig2: string;
  sP4Irrig3: string;
  sP4PlntdVar: string;
  sP4PrefVar: string;
  sP4PlntgStart: string;
  sP4PlntgEnd: string;
  sP4HrvstgStart: string;
  sP4HrvstgEnd: string;
  sP4Plntg: string;
  sP4Hrvstg: string;
  sP4AvgYield: string;
  sP4RtnMnths: string;
  sP4RtnYield: string;
  sP4RtnMax: string;
  sP4SgrMills: string;
  sP5Notes: string;
  sP0Uname: string;
  sP0Encoded: string;
  sP0Status: string;
}
