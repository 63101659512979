// <!-------------------------------------------------------------------------->
// <!-- M3DAS Web App Phase 3 Source Code ------------------------------------->
// <!-- Programmer: Richmond Bautista  Website: https://rbautista.pro --------->
// <!-- Version and Deployment Information: v3.40 ----------------------------->
// <!-------------------------------------------------------------------------->

import { useState, useEffect } from "react";
import { IError, ATextInput } from "./afx/AFX";
import { SET, GET, SSA } from "./afx/AType";
import { TGetValidation, TGetText } from "./afx/AType";
import { TSetAny, TSetBoolean, AnyState } from "./afx/AType";
import { provinces, municipalities, barangays } from "./Philippines";

// /////////////////////////////////////////////////////////////////////////////
// CONSTANTS AND TYPES /////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

const provs = provinces;
const muns = municipalities;
const brgys = barangays;

type BooleanST = TSetBoolean;
type SetProv = SSA<typeof provs>;
type SetMun = SSA<typeof muns>;
type SetBrgy = SSA<typeof brgys>;

type GetProvUndf = typeof provs | undefined;
type SetProvUndf = SSA<GetProvUndf>;
type GetMunUndf = typeof muns | undefined;
type SetMunUndf = SSA<GetMunUndf>;
type GetBrgyUndf = typeof brgys | undefined;
type SetBrgyUndf = SSA<GetBrgyUndf>;

// /////////////////////////////////////////////////////////////////////////////
// INITIALIZER /////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export const CreateNewAddress = (): Address => {
  const [provVal, setProvVal] = useState<typeof provs>();
  const [provOpt, setProvOpt] = useState(provinces);
  const [provDis, setProvDis] = useState(true);
  const [munVal, setMunVal] = useState<typeof muns>();
  const [munOpt, setMunOpt] = useState(municipalities);
  const [munDis, setMunDis] = useState(true);
  const [brgyVal, setBrgyVal] = useState<typeof brgys>();
  const [brgyOpt, setBrgyOpt] = useState(barangays);
  const [bgryDis, setBrgyDis] = useState(true);
  const [bgryKey, setBrgyKey] = useState(0);

  return {
    province: {
      value: [provVal, setProvVal],
      options: [provOpt, setProvOpt],
      disabled: [provDis, setProvDis],
    },
    municipality: {
      value: [munVal, setMunVal],
      options: [munOpt, setMunOpt],
      disabled: [munDis, setMunDis],
    },
    barangay: {
      value: [brgyVal, setBrgyVal],
      options: [brgyOpt, setBrgyOpt],
      disabled: [bgryDis, setBrgyDis],
      key: [bgryKey, setBrgyKey],
    },
  };
};

// /////////////////////////////////////////////////////////////////////////////
// ADDRESS /////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

interface GenProps {
  text: TGetText;
  address: Address;
  value: AnyState;
  backup: string;
  validation: TGetValidation;
  onFocus?: (e: any) => void;
}

interface IProvs {
  value: [GetProvUndf, SetProvUndf];
  options: [typeof provs, SetProv];
  disabled: [boolean, BooleanST];
}

interface IMuns {
  value: [GetMunUndf, SetMunUndf];
  options: [typeof muns, SetMun];
  disabled: [boolean, BooleanST];
}

interface IBrgys {
  value: [GetBrgyUndf, SetBrgyUndf];
  options: [typeof brgys, SetBrgy];
  disabled: [boolean, BooleanST];
  key: [any, TSetAny];
}

export interface Address {
  province: IProvs;
  municipality: IMuns;
  barangay: IBrgys;
}

// /////////////////////////////////////////////////////////////////////////////
// PROVINCE ////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export function Province(props: GenProps & IError) {
  const province = props.address.province;
  const municipality = props.address.municipality;
  const setMunValue = municipality.value[SET];
  const setMunOptions = municipality.options[SET];
  const setMunDisabled = municipality.disabled[SET];
  const options = province.options[GET].map(mapOpt);

  return (
    <ATextInput
      value={props.value}
      text={props.text}
      options={options}
      color={props.color}
      backup={props.backup}
      validation={props.validation}
      onFocus={props.onFocus}
      onInputChange={(e, value) => {
        if (setMunOptions) {
          setMunDisabled!(!value);
          setMunValue!(undefined);
          setMunOptions!(
            muns.filter(
              (mun) =>
                mun.prov_code ===
                provs
                  .filter((prov) => prov.label === value)
                  .map((prov) => prov.prov_code)[0]
            )
          );
        }
      }}
    />
  );
}

// /////////////////////////////////////////////////////////////////////////////
// MUNICIPALITY ////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export function Municipality(props: GenProps & IError) {
  const [key, setKey] = useState(0);
  const municipality = props.address.municipality;
  const barangay = props.address.barangay;
  const disabled = municipality.disabled[GET];
  const setBrgyValue = barangay.value[SET];
  const setBrgyOptions = barangay.options[SET];
  const brgyDisabled = barangay.disabled[GET];
  const setBrgyDisabled = barangay.disabled[SET];
  const setBarangayKey = barangay.key[SET];
  const options = municipality.options[GET].map(mapOpt);

  useEffect(() => {
    if (disabled && !brgyDisabled) {
      setBrgyDisabled!(true);
      setBarangayKey!(Math.random());
      setKey(key + 1);
    }
  }, [brgyDisabled, disabled, key, setBarangayKey, setBrgyDisabled]);

  return (
    <ATextInput
      value={props.value}
      key={key}
      disabled={disabled}
      text={props.text}
      options={options}
      color={props.color}
      backup={props.backup}
      validation={props.validation}
      onFocus={props.onFocus}
      onInputChange={(e, value) => {
        if (setBrgyOptions) {
          setBrgyDisabled!(!value);
          setBrgyValue!(undefined);
          setBrgyOptions!(
            brgys.filter(
              (brgy) =>
                brgy.mun_code ===
                muns
                  .filter((mun) => mun.label === value)
                  .map((mun) => mun.mun_code)[0]
            )
          );
        }

        if (value === "") setBarangayKey!(Math.random());
      }}
    />
  );
}

// /////////////////////////////////////////////////////////////////////////////
// BARANGAY ////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export function Barangay(props: GenProps & IError) {
  const barangay = props.address.barangay;
  const disabled = barangay.disabled[GET];
  const key = barangay.key[GET];
  const options = barangay.options[GET].map(mapOpt);

  return (
    <ATextInput
      value={props.value}
      key={key}
      disabled={disabled}
      text={props.text}
      options={options}
      color={props.color}
      backup={props.backup}
      validation={props.validation}
      onFocus={props.onFocus}
    />
  );
}

// /////////////////////////////////////////////////////////////////////////////
// HELPER FUNCTIONS ////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

const mapOpt = (option: any) => ({ label: option.label });
