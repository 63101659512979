// TODO Replace Raw CSS with TailwindCSS
// !!! Add clear storage and special key support

import TX from "../pages/app/App.json";
import { AvzRoute } from "../app/Routes";

// /////////////////////////////////////////////////////////////////////////////
// Page Not Found //////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export interface ErrorPageProps {
  title: string;
  heading: string;
  message: string;
  button: string;
}

// A website for converting HTML to JSX
// https://magic.reactjs.net/htmltojsx.htm
export default function ErrorPage(props?: ErrorPageProps) {
  const str = TX.app.pagenotfound;

  return (
    <div
      className="error-page main-bg-form"
      style={{ minWidth: "100vw", minHeight: "100vh" }}
    >
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h1 className="title">{props?.heading ?? str.heading}</h1>
      <p className="message">{props?.message ?? str.message}</p>
      <button className="button">
        <a
          href={AvzRoute.home}
          style={{ textDecoration: "none", color: "#333" }}
        >
          {props?.button ?? str.button}
        </a>
      </button>
    </div>
  );
}
