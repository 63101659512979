import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertProps,
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { fxdDelete, fxdWrite } from "../../app/Storage";
import {
  SET,
  GET,
  AnyState,
  TGetString,
  TGetBoolean,
  TGetAny,
  TGetNumber,
  TSetAny,
  TGetVariantButton,
  TGetMUIColor,
  TGetPop,
} from "./AType";
import { TGetText, TGetSeverity, TGetPosition } from "./AType";
import { Validate } from "../Validations";
import { assistBorderRadius, assistWidth } from "./AFunc";
import {
  INPUTSEPARATOR,
  inputToString,
  safeRead,
} from "../../app/GenFunctions";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Swal, {
  SweetAlertOptions,
  SweetAlertPosition,
  SweetAlertIcon,
} from "sweetalert2";
import { AppSettings } from "../../app/Settings";

// CONSTANT ////////////////////////////////////////////////////////////////////

export const optMonths = [
  { label: "January", intgr: 1 },
  { label: "February", intgr: 2 },
  { label: "March", intgr: 3 },
  { label: "April", intgr: 4 },
  { label: "May", intgr: 5 },
  { label: "June", intgr: 6 },
  { label: "July", intgr: 7 },
  { label: "August", intgr: 8 },
  { label: "September", intgr: 9 },
  { label: "October", intgr: 10 },
  { label: "November", intgr: 11 },
  { label: "December", intgr: 12 },
];

const TX = {
  max: "Maximum input reached",
  error: {
    backup: " is not defined in local storage.",
    null: " trying to backup null storage.",
  },
};

// /////////////////////////////////////////////////////////////////////////////
// GENERAL FUNCTIONS ///////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export const OverrideBackup = "override";

export function backup(storage: string, val: any) {
  if (storage === "") {
    console.warn("Tried to backup to an empty storage.");
    return;
  } else if (storage === OverrideBackup) return;
  var isEmpty = (val && !JSON.parse(val)) || val === "[]";
  if (storage) isEmpty ? fxdDelete(storage) : fxdWrite(storage, val);
  else console.error("{" + storage + "} " + TX.error.backup);
}

// /////////////////////////////////////////////////////////////////////////////
// NON-INPUT COMPONENT /////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// TEXT ////////////////////////////////////////////////////////////////////////

export function AText(props: { text: TGetString; className?: string }) {
  return <p className={props.className}>{props.text}</p>;
}

// BUTTON //////////////////////////////////////////////////////////////////////

interface IButtonProps {
  /**  */
  label?: string;
  /**  */
  variant?: TGetVariantButton;
  /**  */
  icon?: JSX.Element;
  /**  */
  onClick?: () => void;
  /**  */
  color?: TGetMUIColor;
  /**  */
  width?: number;
  /**  */
  height?: number;
  /**  */
  borderRadius?: number;
  /**  */
  position?: TGetPosition;
  /**  */
  disabled?: boolean;
  /**  */
  // fullWidth?: boolean;
}

export function AButton(props: IButtonProps) {
  return (
    <Button
      variant={props.variant ?? "contained"}
      startIcon={props.icon}
      onClick={props.onClick}
      color={props.color}
      style={{
        width: assistWidth({ width: props.width }),
        minWidth: assistWidth({ width: props.width }),
        height: props.height,
      }}
      sx={{
        boxShadow: 0,
        borderRadius: assistBorderRadius({
          borderRadius: props.borderRadius,
          horPosition: props.position,
        }),
      }}
      disabled={props.disabled}
      // fullWidth={props.fullWidth}
    >
      {props.label}
    </Button>
  );
}

// TOAST AND POPUP /////////////////////////////////////////////////////////////

export function AToast(props: SweetAlertOptions & { type?: TGetPop }) {
  var isMobile = window.innerWidth < AppSettings.WIDTHBREAKPOINT;
  var position: SweetAlertPosition = isMobile ? "bottom" : "top-right";

  const toast = Swal.mixin({
    toast: true,
    position: position,
    showConfirmButton: false,
    timer: props.timer ?? 7000,
    icon: props.icon ?? getIcon(props.type),
    iconColor: props.iconColor ?? "white",
    timerProgressBar: props.timerProgressBar ?? true,
    customClass: {
      popup: getBackgroundColor(props.type),
    },
    didOpen: (toast: any) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const { type, ...restProps } = props;
  return toast.fire(restProps);
}

export function APopup(props: SweetAlertOptions & { type?: TGetPop }) {
  props.type = props.icon ? getStyleByIcon(props.icon) : props.type;
  var confirmBtn = getStyle(props.type ?? "primary");

  const toast = Swal.mixin({
    buttonsStyling: false,
    allowEscapeKey: props.allowEscapeKey ?? false,
    allowOutsideClick: props.allowOutsideClick ?? false,
    customClass: {
      confirmButton: props.confirmButtonColor ?? confirmBtn,
      cancelButton: props.cancelButtonColor ?? "btn btn-white",
      denyButton: props.denyButtonColor ?? "btn btn-white",
    },
  });

  const { type, ...restProps } = props;
  return toast.fire(restProps);
}

function getIcon(type?: TGetPop): SweetAlertIcon | undefined {
  switch (type) {
    case "info":
      return "info";
    case "warning":
      return "warning";
    case "error":
      return "error";
    case "success":
      return "success";
    case "question":
      return "question";
    default:
      return undefined;
  }
}

function getBackgroundColor(type?: TGetPop) {
  switch (type) {
    case "primary":
      return "bg-primary text-light";
    case "secondary":
      return "bg-secondary text-light";
    case "info":
      return "bg-info text-light";
    case "warning":
      return "bg-warning text-light";
    case "error":
      return "bg-danger text-light";
    case "success":
      return "bg-success text-light";
    case "question":
      return "bg-info text-light";
    case "light":
      return "bg-light text-dark";
    case "dark":
      return "bg-dark text-light";
    default:
      return "bg-primary text-light";
  }
}

function getStyleByIcon(icon: string) {
  switch (icon) {
    case "info":
      return "info";
    case "warning":
      return "warning";
    case "error":
      return "error";
    case "success":
      return "success";
    case "question":
      return "info";
  }
}

function getStyle(type: TGetPop) {
  switch (type) {
    case "primary":
      return "btn btn-primary";
    case "secondary":
      return "btn btn-secondary";
    case "info":
      return "btn btn-info";
    case "warning":
      return "btn btn-warning";
    case "error":
      return "btn btn-danger";
    case "success":
      return "btn btn-success";
    case "question":
      return "btn btn-info";
    case "light":
      return "btn btn-light";
    case "dark":
      return "btn btn-dark";
    default:
      return "btn btn-primary";
  }
}

export function AAlert(props: AlertProps) {
  return <Alert {...props} />;
}

// /////////////////////////////////////////////////////////////////////////////
// COMPONENTS //////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export interface ICommon {
  /**  */
  text: TGetText;
  /**  */
  value: AnyState;
  /**  */
  backup: TGetString;
  /**  */
  validation?: TGetAny;
}

export interface IError {
  /**  */
  // error?: { status: boolean; msg: string };
  /**  */
  color?: TGetSeverity;
}

export interface IRequired extends ICommon, IError {}

// TEXTINPUT ///////////////////////////////////////////////////////////////////

interface ITextInputProps extends IRequired {
  /**  */
  options?: { label: any }[];
  /**  */
  max?: number;
  /**  */
  free?: boolean;
  /**  */
  rows?: number;
  /**  */
  // id?: string;
  /**  */
  // className?: string;
  /**  */
  width?: number;
  /**  */
  borderRadius?: TGetNumber;
  /**  */
  position?: TGetPosition;
  /**  */
  disabled?: TGetBoolean;
  /**  */
  onFocus?: (e: any) => void;
  /**  */
  // ! Check if this is being used, remove if not
  onChange?: (e: any, value: any) => void;
  /**  */
  // ! Check if this is being used, remove if not
  onInputChange?: (e: any, value: any) => void;
  /**  */
  group?: (option: any) => string;
  /**  */
  open?: TGetBoolean;
  /**  */
  unitPosition?: "start" | "end";
  /**  */
  // textfieldID: string;
  /**  */
  // textfieldClassName?: string;
  /**  */
  // _renderOption?: TGetRenderInput;
  /**  */
  // _disableCloseOnSelect?: boolean;
  /**  */
}

export function ATextInput(props: ITextInputProps) {
  const { rows, ...acp } = props;
  const free = acp.options === undefined;
  const multiple = acp.max ? true : false;
  const multiline = props.rows ? true : false;

  const [helperText, setHelperText] = useState(acp.text.helperText);
  const [errorStatus, setErrorStatus] = useState(false);
  const [localValue, setLocalValue] = useState<any>();
  const [focus, setFocus] = useState(acp.open || false);

  useEffect(() => {
    // var isAnArray = Array.isArray(acp.value[GET]);
    var isAnArray = Array.isArray(localValue);
    var maxInputReached = isAnArray && localValue.length > acp.max!;
    if (maxInputReached) {
      setErrorStatus(true);
      setHelperText(TX.max + " (" + acp.max + ").");
    } else if (!errorStatus || (acp.max && !maxInputReached)) {
      setErrorStatus(false);
      setHelperText(acp.text.helperText);
    }
  }, [acp.max, acp.value, acp.text, errorStatus, helperText, localValue]);

  const onChangeInternal = (e: any, val: any) => {
    acp.onChange?.(e, val);
    onValueChange(val);
  };

  const onInputChangeInternal = (e: any, val: any) => {
    acp.onInputChange?.(e, val);
    onValueChange(val);
  };

  const onValueChange = (val: any) => {
    setLocalValue(val);

    if (val instanceof Array) {
      var maxInputReached = acp.max && val.length > acp.max;
      if (!maxInputReached) setValue(val);
    } else setValue(val);

    if (acp.validation) {
      Validate({
        val: val,
        validation: acp.validation,
        setStatus: setErrorStatus,
        setText: setHelperText,
      });
    }
  };

  const setValue = (val: any) => {
    acp.value[SET]?.(val);
    backup(acp.backup, JSON.stringify(val));
  };

  const isEqual = (opt: any, val: any) => {
    return safeRead(opt) === safeRead(val);
  };

  const autoloadValue = () => {
    var isEmpty = acp.value[GET] === "";
    var value = isEmpty
      ? multiple
        ? []
        : multiline
        ? ""
        : null
      : acp.value[GET];
    backup(acp.backup, JSON.stringify(value));
    return value;
  };

  const { onChange, ...tfp } = props;

  return multiline ? (
    <TextField
      {...tfp}
      multiline
      rows={props.rows}
      value={autoloadValue()}
      onChange={(e) => onValueChange(e.target.value)}
      label={props.text.label}
      placeholder={props.text.placeholder}
    ></TextField>
  ) : (
    <Autocomplete
      fullWidth
      multiple={multiple}
      freeSolo={acp.free || free}
      options={acp.options || []}
      disabled={acp.disabled}
      onFocus={acp.onFocus}
      onChange={onChangeInternal}
      onInputChange={onInputChangeInternal}
      isOptionEqualToValue={isEqual}
      groupBy={acp.group}
      // value={props.value[GET] === "" || multiple ? [] : ""}
      value={autoloadValue()}
      // getOptionLabel={multiple ? (option) => option.label : (option) => option}
      // onClick={() => console.log(props.value[GET])}
      style={{
        width: assistWidth({ width: acp.width }),
        minWidth: assistWidth({ width: acp.width }),
      }}
      sx={{
        "& fieldset": {
          borderRadius: assistBorderRadius({
            borderRadius: acp.borderRadius,
            horPosition: acp.position,
          }),
        },
      }}
      renderInput={(params) => {
        var tfProps: TextFieldProps = params;
        tfProps.size = "small";
        tfProps.fullWidth = true;
        tfProps.label = acp.text.label;
        tfProps.placeholder = acp.text.placeholder;
        tfProps.helperText = helperText;
        tfProps.error = errorStatus;
        tfProps.color = acp.color;
        // tfProps.multiline = multiline;
        // tfProps.rows = props.rows;
        // tfProps.suppressTextareaWarning = true;

        return acp.options ? (
          <TextField {...params} />
        ) : (
          <TextField
            {...params}
            InputLabelProps={{ shrink: acp.open }}
            InputProps={{
              endAdornment: acp.unitPosition === null && focus && (
                <InputAdornment position="end">{acp.text.unit}</InputAdornment>
              ),
              startAdornment: acp.unitPosition === "start" && focus && (
                <InputAdornment position="start">
                  {acp.text.unit}
                </InputAdornment>
              ),
            }}
          />
        );
      }}
    />
  );
}

// CHECKS //////////////////////////////////////////////////////////////////////

type TTick = "radio" | "checkbox" | null;
type TTickOptions = { label: string; key: any; value?: any }[];

interface ITickProps extends Omit<IRequired, "text"> {
  /**  */
  type?: TTick;
  /**  */
  options: TTickOptions;
  /**  */
  row?: TGetBoolean;
  /**  */
  // id?: string;
  /**  */
  // className?: string;
  /**  */
  width?: TGetNumber;
  /**  */
  // listen?: TSetAny;
}

export function ATick(props: ITickProps) {
  const style = {
    width: assistWidth({ width: props.width }),
    minWidth: assistWidth({ width: props.width }),
  };

  const options = () => {
    return props.options.map((options: any, index: any) => (
      <AFormLabel
        type={props.type}
        label={options.label}
        value={props.value}
        backup={props.backup}
        options={props.options}
        key={options.key}
        // index={index}
      />
    ));
  };

  return props.type === "radio" ? (
    <RadioGroup row={props.row} style={style}>
      {options()}
    </RadioGroup>
  ) : (
    <FormGroup row={props.row} style={style}>
      {options()}
    </FormGroup>
  );
}

interface IFormControlLabelProps {
  /**  */
  type?: TTick;
  /**  */
  label: TGetString;
  /**  */
  value: AnyState;
  /**  */
  backup: TGetString;
  /**  */
  // index: TGetNumber;
  /**  */
  options: TGetAny;
}

function AFormLabel(props: IFormControlLabelProps) {
  const isChecked = React.useCallback(() => {
    return Array.isArray(props.value[GET])
      ? props.value[GET].some((item: any) => item.label === props.label)
      : false;
  }, [props.value, props.label]);

  const [checked, setChecked] = useState(isChecked());

  useEffect(() => {
    setChecked(isChecked());
  }, [isChecked, props.label, props.value]);

  return (
    <FormControlLabel
      label={props.label}
      // value={props.value == null ? props.label : props.value}
      value={props.value[GET]}
      control={
        props.type === "radio" ? (
          <Radio />
        ) : (
          <Checkbox
            // TODO Auto Restore Fuctionality
            // checked={(() => {
            //   var checked =
            //     props.backup && fxdRead(props.backup)?.includes(props.label);
            //   if (props.backup && checked) {
            //     props.value[SET]?.(fxdRead(props.backup));
            //     return true;
            //   } else return false;
            // })()}
            checked={checked}
            onChange={(event) => {
              var val = Array.isArray(props.value[GET]) ? props.value[GET] : [];
              var option = props.options.find(
                (option: any) => option.label === props.label
              );
              if (event.target.checked) {
                if (!val.some((item: any) => item === option)) {
                  val.push(option);
                  setChecked(true);
                }
              } else {
                val = val.filter((item: any) => item !== option);
                setChecked(false);
              }
              props.value[SET]?.(val);
              backup(props.backup, JSON.stringify(val));
            }}
          />
        )
      }
    />
  );
}

// DATE PICKER /////////////////////////////////////////////////////////////////

interface IDatePickerProps extends IRequired {
  /**  */
  views?: readonly ("year" | "month" | "day")[];
  /**  */
  text: TGetText;
}

export function APickDate(props: IDatePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        views={props.views}
        slotProps={{
          textField: {
            fullWidth: true,
            size: "small",
            variant: "outlined",
            label: props.text.label,
            placeholder: props.text.placeholder,
            helperText: props.text.helperText,
            color: props.color,
          },
        }}
        onChange={(date) => {
          props.value[SET]?.(date);
          backup(props.backup, JSON.stringify(date));
        }}
      />
    </LocalizationProvider>
  );
}
