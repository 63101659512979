// <!-------------------------------------------------------------------------->
// <!-- M3DAS Web App Phase 3 Source Code ------------------------------------->
// <!-- Programmer: Richmond Bautista  Website: https://rbautista.pro --------->
// <!-- Version and Deployment Information: v3.40 ----------------------------->
// <!-------------------------------------------------------------------------->

import { fxdIsEqual, fxdDelete } from "../../app/Storage";
import { fxdUser, fxdRead, fxdWrite } from "../../app/Storage";
import { sha512 } from "crypto-hash";
import axios from "axios";
import { UserCredentials } from "../../app/Server";
import { LogCount, LogUser } from "../../app/Server";
import { LOG } from "../../app/GenEnums";
import { AToast } from "../../custom/afx/AFX";
import tx from "../../pages/app/App.json";

// /////////////////////////////////////////////////////////////////////////////
// Authentication Provider /////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

interface AuthProvider {
  isAuthenticated: boolean;
  username: null | string;
  signin(username: string, password: string): Promise<void>;
  signout(): Promise<void>;
}

export const AUTH_PROVIDER: AuthProvider = {
  // TODO Improve this, add function to check if user is authenticated
  isAuthenticated: fxdIsEqual(fxdUser.auth, "true"),
  username: fxdRead(fxdUser.name) ?? "",

  async signin(username: string, password: string) {
    let auth = false;
    let name = "";
    let pass = await sha512(password);

    await axios
      .get(UserCredentials(username))
      .then(async (res) => {
        if (res.data.m3Password === pass) {
          auth = true;
          name = res.data.m3Username;
          fxdWrite(fxdUser.auth, "true");
          fxdWrite(fxdUser.id, res.data.m3ID);
          fxdWrite(fxdUser.name, res.data.m3Username);
          fxdWrite(fxdUser.pass, res.data.m3Password);
          fxdWrite(fxdUser.mail, res.data.m3Email);
          fxdWrite(fxdUser.cont, res.data.m3Contact);
          fxdWrite(fxdUser.lsNm, res.data.m3LastName);
          fxdWrite(fxdUser.frNm, res.data.m3FirstName);
          fxdWrite(fxdUser.offc, res.data.m3Office);
          fxdWrite(fxdUser.mldt, res.data.m3MillDistrict);
          fxdWrite(fxdUser.role, res.data.m3Role);
          fxdWrite(fxdUser.stts, res.data.m3Status);

          const uname = res.data.m3Username;
          const fname = res.data.m3FirstName;
          const lname = res.data.m3LastName;
          if (uname != null && uname.length > 0) {
            await axios.get(LogCount(uname)).then(async (count) => {
              var log = {
                Username: uname,
                Name: fname + " " + lname,
                Field01: count.data[0].ct + 1,
                Field02: LOG.loginCode,
                Field03: LOG.login,
              };

              await axios.post(LogUser(uname), log);
            });
          }
        } else {
          AToast({
            type: "error",
            title: tx.auth.err.title,
            text: tx.auth.err.textPass,
          });
        }
      })
      .catch((err) => {
        AToast({
          type: "error",
          title: tx.auth.err.title,
          text: err.message,
        });
      });

    AUTH_PROVIDER.isAuthenticated = auth;
    AUTH_PROVIDER.username = name;
  },

  async signout() {
    await new Promise((r) => setTimeout(r, 500));
    AUTH_PROVIDER.isAuthenticated = false;
    AUTH_PROVIDER.username = "";

    const uname = fxdRead(fxdUser.name);
    if (uname != null && uname.length > 0) {
      await axios.get(LogCount(uname)).then(async (count) => {
        var log = {
          Username: fxdRead(fxdUser.name),
          Name: fxdRead(fxdUser.frNm) + " " + fxdRead(fxdUser.lsNm),
          Field01: count.data[0].ct + 1,
          Field02: LOG.logoutCode,
          Field03: LOG.logout,
        };

        await axios.post(LogUser(uname), log);
      });
    }

    fxdDelete(fxdUser.auth);
    fxdDelete(fxdUser.id);
    fxdDelete(fxdUser.name);
    fxdDelete(fxdUser.pass);
    fxdDelete(fxdUser.mail);
    fxdDelete(fxdUser.cont);
    fxdDelete(fxdUser.lsNm);
    fxdDelete(fxdUser.frNm);
    fxdDelete(fxdUser.offc);
    fxdDelete(fxdUser.mldt);
    fxdDelete(fxdUser.role);
    fxdDelete(fxdUser.stts);
  },
};
