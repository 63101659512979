// /////////////////////////////////////////////////////////////////////////////

// M3DAS Web App Source Code
// Author: Richmond Bautista
// Since: April 01, 2024
// Last Clean:

// TODO SCSS not cleaned

// /////////////////////////////////////////////////////////////////////////////

import "./HomePage.css";

import axios from "axios";
import { useState } from "react";
import { Nav } from "react-bootstrap";
import { LoaderFunctionArgs, NavLink } from "react-router-dom";

import TX from "./HomePage_en_US.json";
import {
  gotoLogin,
  AvzRoute,
  log,
  requireLoadProperly,
} from "../../app/Routes";
import {
  fxdRead,
  // fxdRead,
  tmpRead,
} from "../../app/Storage";
// import { DATA_STATUS } from "../../app/GenEnums";
import { AppSettings } from "../../app/Settings";
import { tmpGen, fxdUser } from "../../app/Storage";
import { AuthStatusButton } from "../app/App";
import ErrorPage from "../../custom/ErrorPage";
import {
  APPVERSION,
  NOTIFICATION,
  // , UserCredentials
} from "../../app/Server";

// import { Tooltip } from "@mui/material";
// import SaveIcon from "@mui/icons-material/SaveOutlined";
// import WarningIcon from "@mui/icons-material/WarningAmberOutlined";
// import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlined";
// import CloudUploadIcon from "@mui/icons-material/CloudUploadOutlined";

import pkgJson from "../../../package.json";
import {
  APopup,
  // , Toast
} from "../../custom/afx/AFX";
import m3dasText from "../../assets/img/icon/m3das/text/m3das-text.png";
// import { sha512 } from "crypto-hash";
// import { AUTH_PROVIDER } from "../../hooks/auth/auth";

// /////////////////////////////////////////////////////////////////////////////
// HOMEPAGE ////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export function HomePageLoader({ request }: LoaderFunctionArgs) {
  var check = gotoLogin(request.url);
  if (check) return check;
  return null;
}

export default function HomePage() {
  const LOADER = LoaderPage();
  const HOME = HomeSuccessPage();

  var notified = tmpRead(tmpGen.notified) === "true";
  const [page, setPage] = useState(notified ? HOME : LOADER);

  async function updateNotif() {
    setTimeout(async () => {
      await axios
        .get(APPVERSION(fxdRead(fxdUser.name) ?? ""))
        .then(async (res) => {
          // Minimum version
          const minver = res.data[0].val0;
          // Current version
          const curver = pkgJson.version;

          // Exploded minimum version
          var minExp = minver.split(/[^\d]+/).map(Number);

          const mMaj = minExp[0];
          const mMin = minExp[1];
          const mFix = minExp[2];
          const mBld = minExp[3];

          // Exploded current version
          var curExp = curver.split(/[^\d]+/).map(Number);

          const cMaj = curExp[0];
          const cMin = curExp[1];
          const cFix = curExp[2];
          const cBld = curExp[3];

          const vSame = mMaj === cMaj && mMin === cMin;
          const vUpdated = vSame && mFix <= cFix && mBld <= cBld;
          const vAdvanced = mMaj === cMaj && mMin < cMin;

          if (vUpdated || vAdvanced) {
            await axios
              .get(NOTIFICATION(fxdRead(fxdUser.name) ?? ""))
              .then((res) => {
                if (
                  res.data.status === "active" &&
                  sessionStorage.getItem(tmpGen.notified) !== "true"
                ) {
                  APopup({
                    title: res.data.title,
                    text: res.data.message,
                    confirmButtonText: res.data.button,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      sessionStorage.setItem(tmpGen.notified, "true");
                      setPage(HOME);
                    }
                  });
                }
              })
              .catch((err) => {
                if (err.response) {
                  APopup({
                    title: TX.error.loadNotifError.title,
                    text: TX.error.loadNotifError.text,
                  });
                }
              });
          } else {
            setPage(
              <div id="home-page">
                <ErrorPage
                  title={TX.error.versionError.title}
                  heading={TX.error.versionError.heading}
                  message={TX.error.versionError.message}
                  button={TX.error.versionError.button}
                />
              </div>
            );
          }
        })
        .catch((err) => {
          if (err.message.includes("500")) {
            APopup(TX.error.networkError).then((result) => {
              if (result.isConfirmed) {
                setPage(HOME);
              }
            });
          } else if (err.response) {
            setPage(
              <div id="home-page">
                <ErrorPage
                  title={TX.error.loadPageError.title}
                  heading={TX.error.loadPageError.heading}
                  message={TX.error.loadPageError.message}
                  button={TX.error.loadPageError.button}
                />
              </div>
            );
          }
        });
    }, 2000);
  }

  if (page === LOADER) {
    updateNotif();
  }

  return page;
}

// /////////////////////////////////////////////////////////////////////////////

function LoaderPage() {
  return (
    <div id="home-page">
      <div className="gooey">
        <span className="dot"></span>
        <div className="dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <p className="loading">{TX.loading}</p>
    </div>
  );
}

function HomeSuccessPage() {
  return (
    requireLoadProperly(AvzRoute.home) ?? (
      <div id="home-page" className="main-bg-colored">
        <div id="title-pane">
          {/* <p id="txt-welcome">{t.welcome}</p> */}
          <p className="txt-welcome"> </p>
          <img
            alt="m3das-name"
            id="img-name"
            src={m3dasText}
            onClick={() => {
              APopup({
                icon: "info",
                type: "info",
                text: TX.versionText + pkgJson.version,
                confirmButtonText: "Okay",
              });
            }}
          />
          <p className="txt-welcome"> </p>
          <h3 id="txt-title" className="small-text">
            {TX.title.toUpperCase()}
          </h3>
        </div>
        <div id="card-pane">
          <div
            className="carousel slide"
            data-bs-ride="false"
            id="card-carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <EncodeCard />
              </div>
              <div className="carousel-item">{FlippableThirdCard()}</div>
              <div className="carousel-item">
                <WebMapCard />
              </div>
            </div>
            <div>
              <a
                className="carousel-control-prev"
                href="#card-carousel"
                role="button"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon"></span>
                <span className="visually-hidden">{TX.prev}</span>
              </a>
              <a
                className="carousel-control-next"
                href="#card-carousel"
                role="button"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon"></span>
                <span className="visually-hidden">{TX.next}</span>
              </a>
            </div>
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#card-carousel"
                data-bs-slide-to="0"
                className="active"
              ></button>{" "}
              <button
                type="button"
                data-bs-target="#card-carousel"
                data-bs-slide-to="1"
              ></button>{" "}
              <button
                type="button"
                data-bs-target="#card-carousel"
                data-bs-slide-to="2"
              ></button>
            </div>
          </div>
          <div id="card-holder">
            <EncodeCard />
            {FlippableThirdCard()}
            <WebMapCard />
          </div>
        </div>
      </div>
    )
  );
}

// /////////////////////////////////////////////////////////////////////////////
// HOME PAGE CARDS /////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

function WebMapCard() {
  return (
    <div className="card">
      <div className="card-text-pane">
        <p className="card-title h4">{TX.cWebMap.title}</p>
        <p className="card-subtitle">{TX.cWebMap.subtitle}</p>
        <p className="card-subtitle">&nbsp;&nbsp;</p>
        <p className="card-subtitle">&nbsp;&nbsp;</p>
        <p className="card-subtitle"></p>
        <p className="card-text small-text">
          <span>{TX.cWebMap.content}</span>
        </p>
      </div>
      <div className="card-button-pane">
        <button className="btn btn-primary" type="button" disabled>
          <Nav.Link
            key={AvzRoute.map}
            as={NavLink}
            to={AvzRoute.map}
            onClick={() => log(AvzRoute.map)}
            end
          >
            {TX.cWebMap.button}
          </Nav.Link>
        </button>
      </div>
    </div>
  );
}

function EncodeCard() {
  return (
    <div className="card">
      <div className="card-text-pane">
        <p className="card-title h4">{TX.cEncode.title}</p>
        <p className="card-subtitle">{TX.cEncode.subtitle}</p>
        <p className="card-subtitle">&nbsp;&nbsp;</p>
        <p className="card-subtitle">&nbsp;&nbsp;</p>
        <p className="card-subtitle"></p>
        <p className="card-text small-text">
          <span>{TX.cEncode.content}</span>
        </p>
        <p className="card-subtitle"> </p>
      </div>
      <div className="card-button-pane">
        <div className="btn-group" role="group">
          <button className="btn btn-primary" type="button">
            <Nav.Link
              key={AvzRoute.encode}
              as={NavLink}
              to={AvzRoute.encode}
              onClick={() => log(AvzRoute.encode)}
              end
            >
              {TX.cEncode.buttonLeft}
            </Nav.Link>
          </button>
        </div>
      </div>
    </div>
  );
}

function FlippableThirdCard() {
  var role = localStorage.getItem(fxdUser.role) ?? "";
  var desktop = window.innerWidth > AppSettings.WIDTHBREAKPOINT;

  var card = (
    <div className="back-only">
      <About viewLogout={true} />
    </div>
  );

  if (desktop && role === "Administrator") {
    card = (
      <div className="flip-card">
        <div className="content">
          <div className="back">
            <div className="effect">
              <About viewLogout={false} />
            </div>
          </div>
          <div className="front">
            <div className="effect">
              <VerifyCard />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return card;
}

function VerifyCard() {
  return (
    <div className="card card-effect">
      <div className="card-text-pane">
        <p className="card-title h4">{TX.cVerify.title}</p>
        <p className="card-subtitle">{TX.cVerify.subtitle1}</p>
        <p className="card-subtitle">&nbsp;&nbsp;</p>
        <p className="card-subtitle">&nbsp;&nbsp;</p>
        <p className="card-text small-text">
          <span>{TX.cVerify.content}</span>
        </p>
      </div>
      <div className="card-button-pane">
        <div className="btn-group" role="group">
          <button
            className="btn btn-primary"
            type="button"
            disabled
            // onClick={() => GotoVerify()}
          >
            <Nav.Link
              key={AvzRoute.verify}
              as={NavLink}
              to={AvzRoute.verify}
              onClick={() => log(AvzRoute.verify)}
              end
            >
              {TX.cVerify.button}
            </Nav.Link>
          </button>
          <AuthStatusButton border="right" />
        </div>
      </div>
    </div>
  );
}

function About(props: { viewLogout?: boolean }) {
  var name = localStorage.getItem(fxdUser.name) ?? "";
  // var auth = localStorage.getItem(fxdUser.auth) ?? "";
  // var mail = localStorage.getItem(fxdUser.mail) ?? "";
  var lsNm = localStorage.getItem(fxdUser.lsNm) ?? "";
  var frNm = localStorage.getItem(fxdUser.frNm) ?? "";
  var offc = localStorage.getItem(fxdUser.offc) ?? "";
  var role = localStorage.getItem(fxdUser.role) ?? "";
  // var dbcd = localStorage.getItem(stgUser.dbcd);
  // var cName = (frNm + " " + lsNm).toLocaleUpperCase();

  var logout = props.viewLogout ?? true;

  return (
    <div className="card card-effect">
      <div className="card-text-pane">
        <p className="card-title h4">{frNm + " " + lsNm}</p>
        <p className="card-subtitle">{offc + " " + role}</p>
        <p className="card-subtitle">{"@" + name}</p>
        <p className="card-subtitle">&nbsp;&nbsp;</p>
        <p className="card-subtitle"></p>
        <p className="card-text small-text">
          <span>{TX.cAbout.content}</span>
        </p>
        {/* {AboutInfo()} */}
        <br />
      </div>
      {logout ? logoutBar() : versionBar()}
    </div>
  );
}

// /////////////////////////////////////////////////////////////////////////////

function logoutBar() {
  return (
    <div className="card-button-pane">
      <div className="btn-group" role="group">
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => {
            APopup({
              icon: "info",
              type: "info",
              text: TX.versionText + pkgJson.version,
              confirmButtonText: "Okay",
            });
          }}
        >
          {/* <Nav.Link
            key={Route.home}
            as={NavLink}
            to={log(Route.home)}
            onClick={() => {
              APopup({
                icon: "info",
                type: "info",
                text: TX.versionText + pkgJson.version,
                confirmButtonText: "Okay",
              });
            }}
            end
          > */}
          {TX.cAbout.button}
          {/* </Nav.Link> */}
        </button>
        <AuthStatusButton border="right" />
      </div>
    </div>
  );
}

function versionBar() {
  return (
    <div className="card-button-pane">
      <div className="btn-group" role="group">
        <button className="btn btn-primary" type="button">
          <Nav.Link
            key={AvzRoute.home}
            as={NavLink}
            to={AvzRoute.home}
            onClick={() => log(AvzRoute.home)}
            end
          >
            {TX.version + pkgJson.version}
          </Nav.Link>
        </button>
      </div>
    </div>
  );
}

// /////////////////////////////////////////////////////////////////////////////

// function AboutInfo() {
//   return (
//     <div>
//       <div className="table-responsive">
//         <table className="table">
//           <thead>
//             <tr>
//               <th></th>
//               <th
//                 onClick={() => {
//                   Toast({
//                     type: "info",
//                     text: "Saved Locally",
//                   });
//                 }}
//               >
//                 {/* <SaveIcon style={{ color: "var(--bs-dark-text-emphasis)" }} /> */}
//                 <Tooltip title={DATA_STATUS.local} arrow>
//                   <SaveIcon style={{ color: "var(--bs-dark-text-emphasis)" }} />
//                 </Tooltip>
//               </th>
//               <th
//                 onClick={() => {
//                   Toast({
//                     type: "info",
//                     text: "Uploaded to Database",
//                   });
//                 }}
//               >
//                 <Tooltip title={DATA_STATUS.online} arrow>
//                   <CloudUploadIcon style={{ color: "var(--bs-primary)" }} />
//                 </Tooltip>
//               </th>
//               <th
//                 onClick={() => {
//                   Toast({
//                     type: "info",
//                     text: "Returned for Editing",
//                   });
//                 }}
//               >
//                 <Tooltip title={DATA_STATUS.returned} arrow>
//                   <WarningIcon style={{ color: "var(--bs-danger)" }} />
//                 </Tooltip>
//               </th>
//               <th
//                 onClick={() => {
//                   Toast({
//                     type: "info",
//                     text: "Verified",
//                   });
//                 }}
//               >
//                 <Tooltip title={DATA_STATUS.verified} arrow>
//                   <CheckCircleIcon style={{ color: "var(--bs-success)" }} />
//                 </Tooltip>
//               </th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>Profile</td>
//               <td>0</td>
//               <td>0</td>
//               <td>0</td>
//               <td>0</td>
//             </tr>
//             <tr>
//               <td>Machine</td>
//               <td>0</td>
//               <td>0</td>
//               <td>0</td>
//               <td>0</td>
//             </tr>
//             <tr>
//               <td>Implement</td>
//               <td>0</td>
//               <td>0</td>
//               <td>0</td>
//               <td>0</td>
//             </tr>
//             <tr>
//               <td>Total</td>
//               <td>0</td>
//               <td>0</td>
//               <td>0</td>
//               <td>0</td>
//             </tr>
//           </tbody>
//         </table>
//         {/* <small>
//           <i>Status values might not work yet</i>
//         </small> */}
//       </div>
//     </div>
//   );
// }

// /////////////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// function GotoVerify() {
//   APopup({
//     icon: "warning",
//     input: "password",
//     title: "Authentication Required",
//     text: "Kindly enter your password to proceed.",
//     confirmButtonText: "Verify",
//     allowOutsideClick: true,
//   }).then(async (code) => {
//     if (code.isConfirmed) {
//       var uname = fxdRead(fxdUser.name);
//       if (uname !== null) {
//         await axios.get(UserCredentials(uname)).then(async (res) => {
//           if (res.data.Password === (await sha512(String(code.value)))) {
//             window.location.href = AvzRoute.verify;
//           } else {
//             APopup({
//               type: "error",
//               title: "Failed to Authenticate",
//               text: "Incorrect password. Kindly retry to open web verification app.",
//               showCancelButton: true,
//               confirmButtonText: "Retry",
//             });
//           }
//         });
//       } else {
//         APopup({
//           type: "error",
//           title: "Failed to Authenticate",
//           text: "Missing some user credentials. Kindly re-login to open web verification app.",
//           showCancelButton: true,
//           confirmButtonText: "Logout",
//         }).then((result) => {
//           if (result.isConfirmed) {
//             AUTH_PROVIDER.signout().then(() => {
//               window.location.reload();
//             });
//           }
//         });
//       }
//     }
//   });
// }
