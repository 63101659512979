// /////////////////////////////////////////////////////////////////////////////

// M3DAS Web App Source Code
// Author: Richmond Bautista
// Since: June 19, 2023
// Last Clean:

// /////////////////////////////////////////////////////////////////////////////

import { fxdImplement, fxdMachine, fxdRead } from "../../app/Storage";
import { APopup } from "../../custom/afx/AFX";
import { ImplementData } from "../implement/ImplementPage";
import { MachineData } from "../machine/MachinePage";
import TX from "./DevelopersCode.json";
import { AppSettings } from "../../app/Settings";
import { createZIP } from "../../app/GenFunctions";

// /////////////////////////////////////////////////////////////////////////////

export function ManageDevelopersCode(count: number) {
  count++;
  if (count > 7) {
    APopup({
      icon: "info",
      input: "text",
      title: TX.activate.title,
      text: TX.activate.text,
      confirmButtonText: TX.activate.confirm,
      allowOutsideClick: true,
    }).then((code) => {
      if (code.isConfirmed) {
        DevelopersCode(code.value);
      }
    });
  }
  return count;
}

export function DevelopersCode(code: any) {
  if (String(code).toUpperCase() === "RICHMOND") {
    APopup({
      type: "success",
      title: TX.code + code,
      text: TX.RICHMOND.text,
      confirmButtonText: TX.RICHMOND.confirm,
    });
    // } else if (String(code).toUpperCase().includes("COUNT_")) {
    //   var name = code.split("_")[1];
    //   var mach = enlistedMachinesFromThisProfile(name)?.length ?? 0;
    //   var imp = enlistedImplementsFromThisProfile(name)?.length ?? 0;
    //   APopup({
    //     type: "success",
    //     title: TX.code + code,
    //     text: "Machines: " + mach + "\nImplements: " + imp,
    //     confirmButtonText: "Okay",
    //   });
  } else if (String(code).toUpperCase().includes("IMG")) {
    // var view = String(code).toUpperCase().charAt(3);
    var qr = String(code).toUpperCase().split("IMG")[1];
    var type = String(code).toUpperCase().charAt(9);
    var f, s, p;

    if (type === "M") {
      var machs = JSON.parse(fxdRead(fxdMachine.machines) ?? "[]");
      for (const machine of machs) {
        var mac: MachineData = machine;
        if (mac.sM1QRCode === qr) {
          f = fxdRead(mac.sM1FrontVw);
          s = fxdRead(mac.sM1SideVw);
          p = fxdRead(mac.sM1SpecVw);
        }
      }
    } else if (type === "I") {
      var imps = JSON.parse(fxdRead(fxdImplement.implements) ?? "[]");
      for (const implement of imps) {
        var imp: ImplementData = implement;
        if (imp.sI1Machine === qr) {
          f = fxdRead(imp.sI1FrontVw);
          s = fxdRead(imp.sI1SideVw);
          p = fxdRead(imp.sI1SpecVw);
        }
      }
    }

    APopup({
      type: "success",
      title: qr,
      html:
        "<div>" +
        "<h5>FRONT</h5><textarea>" +
        f +
        "</textarea>" +
        "<h5>SIDE</h5><textarea>" +
        s +
        "</textarea>" +
        "<h5>PLATE</h5><textarea>" +
        p +
        "</textarea>" +
        "</div>",
    });

    // if (type === "M") {
    //   var machs = JSON.parse(fxdRead(fxdMachine.machines) ?? "[]");
    //   for (const machine of machs) {
    //     var mac: MachineData = machine;
    //     if (mac.qrCode === qr) {
    //       // if (view === "F") {
    //       //   val = fxdRead(mac.imageFront);
    //       // } else if (view === "S") {
    //       //   val = fxdRead(mac.imageSide);
    //       // } else if (view === "P") {
    //       //   val = fxdRead(mac.imagePlate);
    //       // }
    //     }
    //   }
    // } else if (type === "I") {
    //   var imps = JSON.parse(fxdRead(fxdImplement.implements) ?? "[]");
    //   for (const implement of imps) {
    //     var imp: ImplementData = implement;
    //     if (imp.qrCode === qr) {
    //       if (view === "F") {
    //         val = fxdRead(imp.front);
    //       } else if (view === "S") {
    //         val = fxdRead(imp.side);
    //       } else if (view === "P") {
    //         val = fxdRead(imp.plate);
    //       }
    //     }
    //   }
    // }

    // let filename = qr + "_" + view + ".json";
    // var fileToSave = new Blob([JSON.stringify(val, undefined, 2)], {
    //   type: "application/json",
    // });
    // saveAs(fileToSave, filename);
  } else if (String(code).toUpperCase() === "SHOWALL") {
    AppSettings.showAll = !AppSettings.showAll;
    APopup({
      type: "success",
      title: TX.showAll.title,
      text: TX.showAll.text + " " + AppSettings.showAll,
      confirmButtonText: TX.showAll.confirm,
    });
  } else if (String(code).toUpperCase() === "SERVER") {
    var srv1 = "https://202.92.144.27:4433";
    var srv2 = "https://127.0.0.1:4434";
    var server = AppSettings.SERVER === srv1 ? srv2 : srv1;

    AppSettings.SERVER = server;
    APopup({
      type: "success",
      title: TX.code + code,
      text: TX.testServer.text + " " + server,
      confirmButtonText: TX.testServer.confirm,
    });
  } else if (String(code).toUpperCase() === "ZIP") {
    createZIP("m3das.zip", "123four", [
      {
        filename: "machines.json",
        content: JSON.stringify({ 1: "a0", 2: "2b" }),
      },
      {
        filename: "implements.json",
        content: JSON.stringify({ 3: "c0", 4: "4d" }),
      },
    ]);
  } else {
    APopup({
      type: "error",
      title: TX.code + code,
      text: TX.error.text,
      confirmButtonText: TX.error.confirm,
    });
  }
}
