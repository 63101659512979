// /////////////////////////////////////////////////////////////////////////////
// Arvouz Functions ////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// Import Declaration //////////////////////////////////////////////////////////

import { ReactNode } from "react";
import { TGetNumber } from "./AType";
import { Collapse } from "@mui/material";
import { AppSettings } from "../../app/Settings";

// /////////////////////////////////////////////////////////////////////////////
// Function Assist /////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// Constants ///////////////////////////////////////////////////////////////////

export const BORDER_RADIUS = 6;

// /////////////////////////////////////////////////////////////////////////////
// Assist Border Radius ////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

/**
 * @param borderRadius The border radius to apply for the component.
 * @param horPosition  The horizontal position of this component.
 * @returns The border radius value to apply for the component.
 */
export function assistBorderRadius(props: {
  borderRadius?: number;
  horPosition?: "left" | "center" | "right";
}) {
  const RADIUS =
    props.borderRadius != null
      ? props.borderRadius + "px"
      : BORDER_RADIUS + "px";

  const VALUE =
    props.horPosition === "center"
      ? "0"
      : props.horPosition === "left"
      ? RADIUS + " 0 0 " + RADIUS
      : props.horPosition === "right"
      ? "0 " + RADIUS + " " + RADIUS + "  0"
      : props.borderRadius != null
      ? props.borderRadius
      : BORDER_RADIUS + "px";
  return VALUE;
}

// /////////////////////////////////////////////////////////////////////////////
// Assist Width ////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

/**
 * @param width The width to translate, %.
 * @returns The width value in %.
 */
export function assistWidth(props: { width?: TGetNumber }) {
  // return p.width == "d10" ? "calc(50% - 5px)" : p.width + "%";
  // return props.width ?  : "100%";
  return props.width + "%";
}

// /////////////////////////////////////////////////////////////////////////////
// Assist Collapse /////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

/**
 * @param child The child component to collapse.
 * @param tranIN The transition in value.
 * @param force Whether to force the collapse to show child component.
 * @returns The collapsible child component.
 */
export function assistCollapse(
  child: ReactNode,
  tranIN: boolean,
  force?: boolean
) {
  return (
    <Collapse in={show(tranIN, force)} timeout="auto" unmountOnExit>
      {child}
    </Collapse>
  );
}

// /////////////////////////////////////////////////////////////////////////////
// Show ////////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

/**
 * @param showThis The value to whether show or hide a component.
 * @param force The value to whether force the value in ShowThis.
 * @returns True if the value of showAll in the Application settings is set to
 *          true, else return ShowThis value.
 */
export function show(showThis: boolean, force?: boolean) {
  return force ? showThis : AppSettings.showAll ?? showThis ?? false;
}
