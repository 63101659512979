// <!-------------------------------------------------------------------------->
// <!-- M3DAS Web App Phase 3 Source Code ------------------------------------->
// <!-- Programmer: Richmond Bautista  Website: https://rbautista.pro --------->
// <!-- Version and Deployment Information: v3.40 ----------------------------->
// <!-------------------------------------------------------------------------->

import { createTheme } from "@mui/material";

// /////////////////////////////////////////////////////////////////////////////
// MUI Theme Pallete ///////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export const MUI_THEME = createTheme({
  palette: {
    // Orange
    primary: { main: "#fd7e14" },
    // Green
    secondary: { main: "#20c997" },
    // Blue
    info: { main: "#0275d8" },
    // Red
    error: { main: "#dc4c64" },
    // Yellow
    warning: { main: "#e4a11b" },
    // Green
    success: { main: "#14a44d" },
  },
});
