import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
  GridApi,
  GridKeyValue,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import axios from "axios";
import { GetImage } from "../../app/Server";
// import { ProfileData } from "../profile/ProfilePage";
// import { MachineData } from "../machine/MachinePage";
// import { ImplementData } from "../implement/ImplementPage";
// import { M3Database } from "./VerifyPage";
import { fxdRead } from "../../app/Storage";
import { APopup } from "../../custom/afx/AFX";
import { LinearProgress, styled } from "@mui/material";
// import { DATA_STATUS } from "../../app/GenEnums";
import { Status } from "./ValueOptions";

// interface EditToolbarProps {
//   setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
//   setRowModesModel: (
//     newModel: (oldModel: GridRowModesModel) => GridRowModesModel
//   ) => void;
// }

// function EditToolbar(props: EditToolbarProps) {
//   const { setRows, setRowModesModel } = props;

//   const handleClick = () => {
//     // const id = Math.max(0, ...initialRows.map((row) => row.id)) + 1;
//     const id = Math.max(0, 1000) + 1;
//     setRows((oldRows) => [...oldRows, { id, name: "", age: "", isNew: true }]);
//     setRowModesModel((oldModel) => ({
//       ...oldModel,
//       [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
//     }));
//   };

//   return (
//     <GridToolbarContainer>
//       <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
//         Add record
//       </Button>
//     </GridToolbarContainer>
//   );
// }

const imageKeys = [
  "front",
  "side",
  "plate",
  "imageFront",
  "imageSide",
  "imagePlate",
];

export default function GenerateTable(props: { res: any; db: string }) {
  var cleanData: any = [];

  props.res.data.data.forEach((prodata: any) => {
    var cleanValues: any = {};

    Object.keys(prodata).forEach((key) => {
      let v = prodata[key];
      try {
        v = fxdRead(JSON.parse(v));
      } catch (error) {
        v = error;
      }
      cleanValues[key] = v;
    });
    if (!cleanData.includes(cleanValues)) {
      cleanData.push(cleanValues);
    }
  });

  const initialRows: GridRowsProp = cleanData;
  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit },
    });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    // setRows(rows.filter((row) => row.id !== id));
    APopup({
      icon: "error",
      type: "error",
      title: "Action Prohibited",
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    APopup({
      icon: "warning",
      type: "warning",
      text: "Revision will be lost. Are you sure you want to exit editing?",
      confirmButtonText: "Discard",
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow!.isNew) {
          setRows(rows.filter((row) => row.id !== id));
        }
      }
    });
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  var columns: GridColDef[] = [];
  // columns.push(
  Object.keys(props.res.keys.data[0]).forEach((key) => {
    if (key === "status") {
      columns.push({
        field: String(key),
        headerName: String(key).toUpperCase(),
        width: 180,
        editable: true,
        type: "singleSelect",
        valueOptions: Status,
      });
    } else if (imageKeys.includes(key)) {
      columns.push({
        field: String(key),
        headerName: String(key).toUpperCase(),
        sortable: false,
        renderCell: (params) => {
          const onClick = async (e: any) => {
            e.stopPropagation(); // don't select this row after clicking

            const api: GridApi = params.api;
            const thisRow: Record<string, GridKeyValue> = {};

            api
              .getAllColumns()
              .filter((c) => c.field !== "__check__" && !!c)
              .forEach((c) => (thisRow[c.field] = params.row[c.field]));

            await axios
              .get(
                GetImage(
                  props.db,
                  "id",
                  JSON.parse(JSON.stringify(thisRow, null, 4))["id"],
                  key
                )
              )
              .then((res) => {
                var html;
                if (res.data) {
                  html =
                    `<div class="ima" style="height: 500px; width: 100%;"></div>
                    <style>
                    .ima {
                        background: url(` +
                    res.data +
                    `) no-repeat;
                        background-size: cover;
                        
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                    </style>`;
                } else {
                  html = `<p>No picture</p>`;
                }

                APopup({
                  title: String(key).toUpperCase(),
                  html: html,
                  confirmButtonText: "Close",
                  allowOutsideClick: true,
                });
              });
          };

          return (
            <Button onClick={onClick} style={{ color: "black" }}>
              View
            </Button>
          );
        },
      });
    } else {
      columns.push({
        field: String(key),
        headerName: String(key).toUpperCase(),
        width:
          key === "id" || key === "resAge" ? 50 : key === "resSex" ? 100 : 180,
        editable: true,
      });
    }
  });
  columns.push({
    field: "actions",
    type: "actions",
    headerName: "Actions",
    width: 100,
    cellClassName: "actions",
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: "primary.main",
            }}
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(id)}
          color="inherit"
        />,
      ];
    },
  });
  return (
    <Box
      sx={{
        height: "calc(100vh - 80px)",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          // toolbar: EditToolbar as GridSlots["toolbar"],
          toolbar: CustomToolBar,
          loadingOverlay: LinearProgress as GridSlots["loadingOverlay"],
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        disableVirtualization
        initialState={{
          pagination: {
            paginationModel: { pageSize: 25, page: 0 },
          },
        }}
        checkboxSelection
      />
    </Box>
  );
}

// /////////////////////////////////////////////////////////////////////////////

const CustomToolBar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector
        slotProps={{ tooltip: { title: "Change density" } }}
      />
      <Box sx={{ flexGrow: 1 }} />
      {/* <EditToolbar setRows={setRows} setRowModesModel={setRowModesModel} /> */}
      <GridToolbarExport
        slotProps={{
          tooltip: { title: "Export data" },
          button: { variant: "outlined" },
        }}
      />
    </GridToolbarContainer>
  );
};

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}
