import LoginPage from "../pages/login/LoginPage";
import HomePage, { HomePageLoader } from "../pages/home/HomePage";
import WebMapPage, { WebMapPageLoader } from "../pages/webmap/WebMapPage";
import EncodePage, { EncodePageLoader } from "../pages/encode/EncodePage";
import VerifyPage, { VerifyPageLoader } from "../pages/verify/VerifyPage";

import ProfilePage from "../pages/profile/ProfilePage";
import { ProfileLoader } from "../pages/profile/ProfilePage";
import MachinePage from "../pages/machine/MachinePage";
import { MachinePageLoader } from "../pages/machine/MachinePage";
import ImplementPage from "../pages/implement/ImplementPage";
import { ImplementPageLoader } from "../pages/implement/ImplementPage";

import { createRef } from "react";
import { LoaderFunctionArgs } from "react-router-dom";
import { fxdRead, fxdUser, tmpGen, tmpRead, tmpWrite } from "./Storage";
import TX from "../pages/app/App.json";
import { AUTH_PROVIDER } from "../hooks/auth/auth";
import { AxiosError } from "axios";
import { redirect } from "react-router-dom";
import ErrorPage from "../custom/ErrorPage";

// /////////////////////////////////////////////////////////////////////////////
// Application Routes //////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

export enum AvzRoute {
  home = "/",
  map = "/map",
  encode = "/encode",
  profile = "/encode/profile",
  machine = "/encode/machine",
  implement = "/encode/implement",
  verify = "/verify",
  // TODO: Check if routes below are being used, delete if not.
  login = "/login",
  logout = "/logout",
  signup = "/signup",
  geoLoc = "/geoLoc",
}

export const AVZROUTES = [
  {
    path: AvzRoute.home,
    name: "Home",
    element: <HomePage />,
    nodeRef: createRef(),
    loader: HomePageLoader,
  },
  {
    path: AvzRoute.map,
    name: "Web Map",
    element: <WebMapPage />,
    nodeRef: createRef(),
    loader: WebMapPageLoader,
  },
  {
    path: AvzRoute.encode,
    name: "Encode",
    element: <EncodePage />,
    nodeRef: createRef(),
    // !!! Update before deployment.
    // loader: EncodePageLoader,
  },
  {
    path: AvzRoute.profile,
    name: "Profile",
    element: <ProfilePage />,
    nodeRef: createRef(),
    // !!! Update before deployment.
    // loader: ProfileLoader,
  },
  {
    path: AvzRoute.machine,
    name: "Machine",
    element: <MachinePage />,
    nodeRef: createRef(),
    // !!! Update before deployment.
    // loader: MachinePageLoader,
  },
  {
    path: AvzRoute.implement,
    name: "Implement",
    element: <ImplementPage />,
    nodeRef: createRef(),
    // !!! Update before deployment.
    // loader: ImplementPageLoader,
  },
  {
    path: AvzRoute.verify,
    name: "Verify",
    element: <VerifyPage />,
    nodeRef: createRef(),
    loader: VerifyPageLoader,
  },
  {
    path: AvzRoute.login,
    name: "Login",
    element: <LoginPage />,
    nodeRef: createRef(),
    action: loginAction,
    loader: loginLoader,
  },
];

// /////////////////////////////////////////////////////////////////////////////
// ACTIONS AND LOADERS /////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

async function loginAction({ request }: LoaderFunctionArgs) {
  var formData = await request.formData();
  var username = formData.get("username") as string | null;
  var password = formData.get("password") as string;

  if (!username || !password) {
    return {
      error: TX.routes.err.cred,
    };
  }

  try {
    var name = fxdRead(fxdUser.name) || "";
    var pass = fxdRead(fxdUser.pass) || "";
    var alreadyLoggedIn = name && name.length > 0 && pass && pass.length > 0;
    alreadyLoggedIn
      ? await _reLogIn(name, pass)
      : await _login(username, password);
  } catch (error: AxiosError | any) {
    if (error instanceof AxiosError) {
      return { error: error.message };
    } else {
      return { error: TX.routes.err.failed };
    }
  }

  let redirectTo = formData.get("redirectTo") as string | null;
  return redirect(redirectTo || AvzRoute.home);
}

async function _login(username: string, password: string) {
  await AUTH_PROVIDER.signin(username, password);
}

async function _reLogIn(uname: string, upass: string) {
  await AUTH_PROVIDER.signin(uname as string, upass as string);
}

async function loginLoader() {
  return AUTH_PROVIDER.isAuthenticated ? redirect(AvzRoute.home) : null;
}

export function gotoLogin(url: string) {
  if (!AUTH_PROVIDER.isAuthenticated) {
    let params = new URLSearchParams();
    params.set("from", new URL(url).pathname);
    return redirect("/login?" + params.toString());
  } else return null;
}

// !!! Unckeched !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export function writeAddress(address: AvzRoute) {
  tmpWrite(tmpGen.address, address);
  return tmpRead(tmpGen.address) === address;
}

export function log(address: AvzRoute) {
  writeAddress(address);
  return address;
}

// TODO Make it work smoothly.
export function requireLoadProperly(address: AvzRoute) {
  var PagesThatRequireProperLoading = [
    AvzRoute.verify,
    // AvzRoute.profile,
    // AvzRoute.machine,
    // AvzRoute.implement,
  ];

  if (PagesThatRequireProperLoading.includes(address)) {
    if (tmpRead(tmpGen.address) !== address) {
      return (
        <ErrorPage
          title={"Action Prohibited"}
          heading={"Action Prohibited"}
          message={"Do not use the URL to open this page directly."}
          button={"Go to M3DAS"}
        />
      );
    }
  } else log(address);

  return null;
}

// ! Delete if left unused.
// TODO Check if this works and if it is used.
// function geoLocation({ request }: LoaderFunctionArgs) {
//   loadMap();
//   return null;
// }

// ! Delete if left unused.
// TODO Check if this works and if it is used.
// function loadMap() {
//   // Map initialization
//   var map = L.map("map").setView([14.0860746, 100.608406], 6);

//   // osm layer
//   var osm = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
//     attribution:
//       '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
//   });
//   osm.addTo(map);

//   if (!navigator.geolocation) {
//     console.log("Your browser doesn't support geolocation feature!");
//   } else {
//     setInterval(() => {
//       navigator.geolocation.getCurrentPosition(getPosition);
//     }, 5000);
//   }

//   var marker: any;
//   var circle: any;

//   function getPosition(position: any) {
//     // console.log(position)
//     var lat = position.coords.latitude;
//     var long = position.coords.longitude;
//     var accuracy = position.coords.accuracy;

//     if (marker) {
//       map.removeLayer(marker);
//     }

//     if (circle) {
//       map.removeLayer(circle);
//     }

//     marker = L.marker([lat, long]);
//     circle = L.circle([lat, long], { radius: accuracy });

//     var featureGroup = L.featureGroup([marker, circle]).addTo(map);

//     map.fitBounds(featureGroup.getBounds());

//     console.log(
//       "Your coordinate is: Lat: " +
//         lat +
//         " Long: " +
//         long +
//         " Accuracy: " +
//         accuracy
//     );
//   }
// }
