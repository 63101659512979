// TODO Remove unnecessary comments.
// TODO Remove unnecessary keys.

// /////////////////////////////////////////////////////////////////////////////
// FUNCTIONS ///////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// ! Move to GenFunctions.ts
// ! To delete?
// export function arrayToString(array: any[] | any): string {
//   if (array === null) return "";
//   var str: string | undefined;
//   if (Array.isArray(array)) {
//     array.forEach((element) => {
//       if (str === undefined) {
//         str = readLabel(element);
//       } else {
//         str += ", " + readLabel(element);
//       }
//     });
//     return str === undefined ? "" : str;
//   } else {
//     return array === undefined ? "" : readLabel(array);
//   }
// }

// ! Move to GenFunctions.ts
export function readValue(key: string, data: any): any {
  return data[key] ?? data;
}

// ! Move to GenFunctions.ts
// ! To delete? see safeRead
// export function readLabel(value: any): string {
//   if (value === null || value === undefined) return "";
//   return readValue("label", value);
// }

// ! Move to GenFunctions.ts
// ! To delete?
// export function isNull(value: any): boolean {
//   return value === null;
// }

// Local Storage ///////////////////////////////////////////////////////////////
// FX for fixed

export function fxdRead(key: string): string | null {
  return localStorage.getItem(key);
}

export function fxdWrite(key: string, value: string): void {
  localStorage.setItem(key, value);
}

export function fxdDelete(key: string): void {
  localStorage.removeItem(key);
}

export function fxdIsEqual(key: string, value: string | null): boolean {
  return localStorage.getItem(key) === value;
}

export function fxdIsNull(values: string | string[]): boolean {
  if (typeof values === "string") return fxdRead(values) === null;
  for (const value of values) {
    if (fxdRead(value) === null) return true;
  }
  return false;
}

// Session Storage /////////////////////////////////////////////////////////////
// TM for temporary

export function tmpRead(key: string): string | null {
  return sessionStorage.getItem(key);
}

export function tmpWrite(key: string, value: string): void {
  sessionStorage.setItem(key, value);
}

export function tmpDelete(key: string): void {
  sessionStorage.removeItem(key);
}

export function tmpEqual(key: string, value: string | null): boolean {
  return sessionStorage.getItem(key) === value;
}

export function tmpIsNull(values: string | string[]): boolean {
  if (typeof values === "string") return tmpRead(values) === null;
  for (const value of values) {
    if (tmpRead(value) === null) return true;
  }
  return false;
}

// /////////////////////////////////////////////////////////////////////////////
// LOCAL STORAGE ///////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// User Account ////////////////////////////////////////////////////////////////

export enum fxdUser {
  auth = "m3Uauth",
  id = "m3Uid",
  name = "m3Uname",
  pass = "m3Upass",
  mail = "m3Umail",
  cont = "m3Ucont",
  lsNm = "m3Ultnm",
  frNm = "m3Ufrnm",
  offc = "m3Uoffc",
  mldt = "m3Umldt",
  role = "m3Urole",
  stts = "m3Ustat",
}

// General /////////////////////////////////////////////////////////////////////

export enum fxdGen {
  editing = "m3edit",
  backup = "m3backup",
}

// Profile /////////////////////////////////////////////////////////////////////

export enum fxdProfile {
  profiles = "m3profiles",
  backup = "m3Pbackup",
}

export enum fxdProfileBackup {
  bP1Name = "m3BP1Name",
  bP1Age = "m3BP1Age",
  bP1Sex = "m3BP1Sex",
  bP1Educ = "m3BP1Educ",
  bP1Cont1 = "m3BP1Cont1",
  bP1Cont2 = "m3BP1Cont2",
  bP1Prov = "m3BP1Prov",
  bP1Mun = "m3BP1Mun",
  bP1Brgy = "m3BP1Brgy",
  bP2Profile = "m3BP2Profile",
  bP2Scope = "m3BP2Scope",
  bP2Position = "m3BP2Position",
  bP3Name = "m3BP3Name",
  bP3Class = "m3BP3Class",
  bP3Cont1 = "m3BP3Cont1",
  bP3Cont2 = "m3BP3Cont2",
  bP3Prov = "m3BP3Prov",
  bP3Mun = "m3BP3Mun",
  bP3Brgy = "m3BP3Brgy",
  bP4Name = "m3BP4Name",
  bP4Accre = "m3BP4Accre",
  bP4Area = "m3BP4Area",
  bP4Mach = "m3BP4Mach",
  bP4Impl = "m3BP4Impl",
  bP4Mngmt = "m3BP4Mngmt",
  bP4Irrig = "m3BP4Irrig",
  bP4Irrig1 = "m3BP4Irrig1",
  bP4Irrig2 = "m3BP4Irrig2",
  bP4Irrig3 = "m3BP4Irrig3",
  bP4PlntdVar = "m3BP4PlntdVar",
  bP4PrefVar = "m3BP4PrefVar",
  bP4PlntgStart = "m3BP4PlntgStart",
  bP4PlntgEnd = "m3BP4PlntgEnd",
  bP4HrvstgStart = "m3BP4HrvstgStart",
  bP4HrvstgEnd = "m3BP4HrvstgEnd",
  bP4Plntg = "m3BP4Plntg",
  bP4Hrvstg = "m3BP4Hrvstg",
  bP4AvgYield = "m3BP4AvgYield",
  bP4RtnMnths = "m3BP4RtnMnths",
  bP4RtnYield = "m3BP4RtnYield",
  bP4RtnMax = "m3BP4RtnMax",
  bP4SgrMills = "m3BP4SgrMills",
  bP5Notes = "m3BP5Notes",
  bP0Uname = "m3BP0Uname",
  bP0Encoded = "m3BP0Encoded",
  bP0Status = "m3BP0Status",
}

// Machine /////////////////////////////////////////////////////////////////////

export enum fxdMachine {
  machines = "m3machines",
  backup = "m3Mbackup",
}

export enum fxdMachineBackup {
  bM1ResName = "m3BM1ResName",
  bM1QRCode = "m3BM1QRCode",
  bM1FrontVw = "m3BM1FrontVw",
  bM1SideVw = "m3BM1SideVw",
  bM1SpecVw = "m3BM1SpecVw",
  bM1Acc = "m3BM1Acc",
  bM1Lat = "m3BM1Lat",
  bM1Lng = "m3BM1Lng",
  bM1Loc = "m3BM1Loc",
  bM1Prov = "m3BM1Prov",
  bM1Mun = "m3BM1Mun",
  bM1Brgy = "m3BM1Brgy",
  bM1Type = "m3BM1Type",
  bM1Serial = "m3BM1Serial",
  bM1CovHlng = "m3BM1CovHlng",
  bM2Brand = "m3BM2Brand",
  bM2Model = "m3BM2Model",
  bM2RtdPwr = "m3BM2RtdPwr",
  bM2TtlSrvArea = "m3BM2TtlSrvArea",
  bM2AvgOpHrDy = "m3BM2AvgOpHrDy",
  bM2AvgOpDySsn = "m3BM2AvgOpDySsn",
  bM2LdCap = "m3BM2LdCap",
  bM2LdCapUnit = "m3BM2LdCapUnit",
  bM2LdCapDay = "m3BM2LdCapDay",
  bM2TmCom1Ha = "m3BM2TmCom1Ha",
  bM2SgrcnYld = "m3BM2SgrcnYld",
  bM3TpTbWlls = "m3BM3TpTbWlls",
  bM3Brand = "m3BM3Brand",
  bM3Model = "m3BM3Model",
  bM3SzPmp = "m3BM3SzPmp",
  bM3SrcPwr = "m3BM3SrcPwr",
  bM3SrcBrand = "m3BM3SrcBrand",
  bM3SrcModel = "m3BM3SrcModel",
  bM3RtdPwr = "m3BM3RtdPwr",
  bM3DisCap = "m3BM3DisCap",
  bM3DisCapUnit = "m3BM3DisCapUnit",
  bM3TtlSrvArea = "m3BM3TtlSrvArea",
  bM3AvgOpHrDy = "m3BM3AvgOpHrDy",
  bM3AvgOpDySsn = "m3BM3AvgOpDySsn",
  bM4Usage = "m3BM4Usage",
  bM4Mode = "m3BM4Mode",
  bM4Agency = "m3BM4Agency",
  bM4YrAcqrd = "m3BM4YrAcqrd",
  bM4ConAcqrd = "m3BM4ConAcqrd",
  bM4PrsntCon = "m3BM4PrsntCon",
  bM4YrUnused = "m3BM4YrUnused",
  bM4RsnUnused = "m3BM4RsnUnused",
  bM4UuOthers = "m3BM4UuOthers",
  bM4YrUnserv = "m3BM4YrUnserv",
  bM4RsnUnserv = "m3BM4RsnUnserv",
  bM4UsOthers = "m3BM4UsOthers",
  bM4NoBrkdwn = "m3BM4NoBrkdwn",
  bM4CmnBrkdwn = "m3BM4CmnBrkdwn",
  bM5SameOwn = "m3BM5SameOwn",
  bM5BusCont1 = "m3BM5BusCont1",
  bM5BusCont2 = "m3BM5BusCont2",
  bM5Consent = "m3BM5Consent",
  bM5Ope1 = "m3BM5Ope1",
  bM5Ope1Rate = "m3BM5Ope1Rate",
  bM5Ope1Unit = "m3BM5Ope1Unit",
  bM5Ope1Fuel = "m3BM5Ope1Fuel",
  bM5Ope2 = "m3BM5Ope2",
  bM5Ope2Rate = "m3BM5Ope2Rate",
  bM5Ope2Unit = "m3BM5Ope2Unit",
  bM5Ope2Fuel = "m3BM5Ope2Fuel",
  bM5Ope3 = "m3BM5Ope3",
  bM5Ope3Rate = "m3BM5Ope3Rate",
  bM5Ope3Unit = "m3BM5Ope3Unit",
  bM5Ope3Fuel = "m3BM5Ope3Fuel",
  bM5Ope4 = "m3BM5Ope4",
  bM5Ope4Rate = "m3BM5Ope4Rate",
  bM5Ope4Unit = "m3BM5Ope4Unit",
  bM5Ope4Fuel = "m3BM5Ope4Fuel",
  bM5Ope5 = "m3BM5Ope5",
  bM5Ope5Rate = "m3BM5Ope5Rate",
  bM5Ope5Unit = "m3BM5Ope5Unit",
  bM5Ope5Fuel = "m3BM5Ope5Fuel",
  bM5Ope6 = "m3BM5Ope6",
  bM5Ope6Rate = "m3BM5Ope6Rate",
  bM5Ope6Unit = "m3BM5Ope6Unit",
  bM5Ope6Fuel = "m3BM5Ope6Fuel",
  bM5SrvcLoc1P = "m3BM5SrvcLoc1P",
  bM5SrvcLoc1M = "m3BM5SrvcLoc1M",
  bM5SrvcLoc2P = "m3BM5SrvcLoc2P",
  bM5SrvcLoc2M = "m3BM5SrvcLoc2M",
  bM5SrvcLoc3P = "m3BM5SrvcLoc3P",
  bM5SrvcLoc3M = "m3BM5SrvcLoc3M",
  bM6Total = "m3BM6Total",
  bM6L2Formal = "m3BM6L2Formal",
  bM6L2NtFrml = "m3BM6L2NtFrml",
  bM6G2Formal = "m3BM6G2Formal",
  bM6G2NtFrml = "m3BM6G2NtFrml",
  bM7AirFltr = "m3BM7AirFltr",
  bM7AirFltrUnit = "m3BM7AirFltrUnit",
  bM7EngOil = "m3BM7EngOil",
  bM7EngOilUnit = "m3BM7EngOilUnit",
  bM7EngOilFltr = "m3BM7EngOilFltr",
  bM7EngOilFltrUnit = "m3BM7EngOilFltrUnit",
  bM7HydOil = "m3BM7HydOil",
  bM7HydOilUnit = "m3BM7HydOilUnit",
  bM7HydOilFltr = "m3BM7HydOilFltr",
  bM7HydOilFltrUnit = "m3BM7HydOilFltrUnit",
  bM7Tire = "m3BM7Tire",
  bM7TireUnit = "m3BM7TireUnit",
  bM7SrvcPrvdr = "m3BM7SrvcPrvdr",
  bM7Prov = "m3BM7Prov",
  bM7Mun = "m3BM7Mun",
  bM7Brgy = "m3BM7Brgy",
  bM7TimeRspnd = "m3BM7TimeRspnd",
  bM7PrtsAvail = "m3BM7PrtsAvail",
  bM8OthCrp = "m3BM8OthCrp",
  bM8Rice = "m3BM8Rice",
  bM8RiceHa = "m3BM8RiceHa",
  bM8Corn = "m3BM8Corn",
  bM8CornHa = "m3BM8CornHa",
  bM8Othr1 = "m3BM8Othr1",
  bM8Othr1Ha = "m3BM8Othr1Ha",
  bM8Othr2 = "m3BM8Othr2",
  bM8Othr2Ha = "m3BM8Othr2Ha",
  bM8Othr3 = "m3BM8Othr3",
  bM8Othr3Ha = "m3BM8Othr3Ha",
  bM9Notes = "m3BM9Notes",
  bM0Uname = "m3BM0Uname",
  bM0Encoded = "m3BM0Encoded",
  bM0Status = "m3BM0Status",
}

// Implement ///////////////////////////////////////////////////////////////////

export enum fxdImplement {
  implements = "m3implements",
  backup = "m3Ibackup",
}

export enum fxdImplementBackup {
  bI1ResName = "m3BI1ResName",
  bI1QRCode = "m3BI1QRCode",
  bI1Machine = "m3BI1Machine",
  bI1FrontVw = "m3BI1FrontVw",
  bI1SideVw = "m3BI1SideVw",
  bI1SpecVw = "m3BI1SpecVw",
  bI1Acc = "m3BI1Acc",
  bI1Lat = "m3BI1Lat",
  bI1Lng = "m3BI1Lng",
  bI1Loc = "m3BI1Loc",
  bI1Prov = "m3BI1Prov",
  bI1Mun = "m3BI1Mun",
  bI1Bgry = "m3BI1Bgry",
  bI2Type = "m3BI2Type",
  bI2Brand = "m3BI2Brand",
  bI2Model = "m3BI2Model",
  bI2NoBtm1 = "m3BI2NoBtm1",
  bI2NoBtm2 = "m3BI2NoBtm2",
  bI2SzDk = "m3BI2SzDk",
  bI2NoBtm3 = "m3BI2NoBtm3",
  bI2DisRow1 = "m3BI2DisRow1",
  bI2WdCut = "m3BI2WdCut",
  bI2LdCap = "m3BI2LdCap",
  bI2LdCapUnit = "m3BI2LdCapUnit",
  bI2NoRows1 = "m3BI2NoRows1",
  bI2DisRow2 = "m3BI2DisRow2",
  bI2NoNzls = "m3BI2NoNzls",
  bI2DisNzls = "m3BI2DisNzls",
  bI2TnkCap1 = "m3BI2TnkCap1",
  bI2NoOutlets = "m3BI2NoOutlets",
  bI2DisOutlets = "m3BI2DisOutlets",
  bI2TnkCap2 = "m3BI2TnkCap2",
  bI2TnkCap2Unit = "m3BI2TnkCap2Unit",
  bI2TpPlntr = "m3BI2TpPlntr",
  bI2NoRows2 = "m3BI2NoRows2",
  bI2TpCnGrbAttch = "m3BI2TpCnGrbAttch",
  bI3Prim = "m3BI3Prim",
  bI3Sec = "m3BI3Sec",
  bI3Ter = "m3BI3Ter",
  bI4Usage = "m3BI4Usage",
  bI4Mode = "m3BI4Mode",
  bI4Agency = "m3BI4Agency",
  bI4YrAcqrd = "m3BI4YrAcqrd",
  bI4ConAcqrd = "m3BI4ConAcqrd",
  bI4PrsntCon = "m3BI4PrsntCon",
  bI4YrUnused = "m3BI4YrUnused",
  bI4RsnUnused = "m3BI4RsnUnused",
  bI4UuOthers = "m3BI4UuOthers",
  bI4YrUnserv = "m3BI4YrUnserv",
  bI4RsnUnserv = "m3BI4RsnUnserv",
  bI4UsOthers = "m3BI4UsOthers",
  bI4WithTractor = "m3BI4WithhTractor",
  bI4SvcRtHa = "m3BI4SvcRtHa",
  bI4SvcRtHaNon = "m3BI4SvcRtHaNon",
  bI4SameOwn = "m3BI4SameOwn",
  bI4BusCont1 = "m3BI4BusCont1",
  bI4BusCont2 = "m3BI4BusCont2",
  bI4Consent = "m3BI4Consent",
  bI4SrvcLoc1P = "m3BI4SrvcLoc1P",
  bI4SrvcLoc1M = "m3BI4SrvcLoc1M",
  bI4SrvcLoc2P = "m3BI4SrvcLoc2P",
  bI4SrvcLoc2M = "m3BI4SrvcLoc2M",
  bI4SrvcLoc3P = "m3BI4SrvcLoc3P",
  bI4SrvcLoc3M = "m3BI4SrvcLoc3M",
  bI5TmCom1Ha = "m3BI5TmCom1Ha",
  bI5NoPasses = "m3BI5NoPasses",
  bI5GrnFrtWt = "m3BI5GrnFrtWt",
  bI5GrnFrtWtUnit = "m3BI5GrnFrtWtUnit",
  bI5FrtAppEffA = "m3BI5FrtAppEffA",
  bI5FrtDelRate = "m3BI5FrtDelRate",
  bI5SgrYld = "m3BI5SgrYld",
  bI5LdCap = "m3BI5LdCap",
  bI5LdCapUnit = "m3BI5LdCapUnit",
  bI5NoBrkdwn = "m3BI5NoBrkdwn",
  bI5CmnBrkdwn = "m3BI5CmnBrkdwn",
  bI5MntcDone = "m3BI5MntcDone",
  bI6Notes = "m3BI6Notes",
  bI0Uname = "m3BI0Uname",
  bI0Encoded = "m3BI0Encoded",
  bI0Status = "m3BI0Status",
}

// /////////////////////////////////////////////////////////////////////////////
// SESSION STORAGE /////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// General /////////////////////////////////////////////////////////////////////

export enum tmpGen {
  notified = "m3Gnotified",
  verified = "m3Gverified",
  address = "m3Gaddress",
}
